import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ContactTag, SystemTag, TagOwnershipType } from '@ui/shared/models';
@Component({
  selector: 'app-label-list-tag',
  templateUrl: './label-list-tag.component.html',
  styleUrls: ['./label-list-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelListTagComponent {
  @Input() tag: ContactTag | SystemTag;
  @Input() name: string;
  @Input() tagOwnershipType?: TagOwnershipType;
  @Input() isSystemTag = false;
  @Input() tagType: TagOwnershipType;
  @Input() readonly = false;

  @Output() unassignTag = new EventEmitter<ContactTag | SystemTag>();

  protected readonly SystemTag = SystemTag;
  protected readonly TagOwnershipType = TagOwnershipType;
}
