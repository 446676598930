import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Apollo } from 'apollo-angular';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  findDistrictByAddressQuery,
  FindDistrictByAddressResult
} from 'libs/infrastructure/base-state/gql-queries';
import { errorMessageParser } from 'libs/infrastructure/gql-client/error-message-parser';
import { ShowError } from 'libs/infrastructure/base-state/notification/notification.actions';
import { getResponseValidator } from 'libs/infrastructure/gql-client/response-validator';
import * as fromActions from './district.actions';

@Injectable()
export class DistrictEffects {
  constructor(
    private actions$: Actions,
    private apollo: Apollo
  ) {}

  fetchLocations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadDistrictsByAddress),
      switchMap(({ address }) =>
        this.apollo
          .query({
            query: findDistrictByAddressQuery,
            variables: {
              address
            },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            tap(getResponseValidator<FindDistrictByAddressResult>()),
            map(result =>
              fromActions.loadDistrictsByAddressSuccess({
                data: result.data.districtForAddress
              })
            ),
            catchError(err => [
              fromActions.loadDistrictsByAddressFailure({
                error: new Error(err.message)
              }),
              new ShowError(errorMessageParser(err))
            ])
          )
      )
    )
  );
}
