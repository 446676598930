import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Inject,
  Injector,
  Input,
  Output
} from '@angular/core';

import { BaseControl } from 'libs/components/legacy/form/controls/base-control';
import { RadioGroupComponent } from '../radio-group/radio-group.component';

const getUniqueId = (() => {
  let nextUniqueId = 0;
  return () => `app-radio-button-${++nextUniqueId}`;
})();

@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent extends BaseControl<string> {
  private _valueRadio = '';
  public currentValue: string = null;
  @Input() switchLabelCheck = false;

  // Use this equality check when the radio group fromControlName is an object
  @Input() equalityFieldName: string;

  @Input()
  get value() {
    return this._valueRadio;
  }
  set value(value: any) {
    this._valueRadio = value;
  }

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelect = new EventEmitter<{ event: Event }>();

  public getId() {
    return this.id || getUniqueId();
  }

  constructor(
    private cd: ChangeDetectorRef,
    @Host()
    @Inject(forwardRef(() => RadioGroupComponent))
    private radioGroup: RadioGroupComponent,
    protected injector: Injector
  ) {
    super(injector);
  }

  public check(event: MouseEvent) {
    event.preventDefault();
    if (this.isDisabled) return;
    this.radioGroup.writeValue(this.value);
    this.onSelect.emit({ event });
  }

  public get isChecked() {
    return (
      this.radioGroup.value === this._valueRadio ||
      this.radioGroup.value?.[this.equalityFieldName] === this._valueRadio
    );
  }

  public get isDisabled() {
    return this._disabled || this.radioGroup.disabled;
  }
}
