import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { FormGroup } from '@angular/forms';
import { Property, PropertyBean, SocialLogin } from '@ui/shared/models';

@Component({
  selector: 'app-register-card',
  templateUrl: './register-card.component.html',
  styleUrls: ['./register-card.component.scss']
})
export class RegisterCardComponent {
  @Input() form: FormGroup;
  @Input() property: Property & PropertyBean;
  @Input() logoRedirectUrl: string;
  @Input() disabled: boolean;
  @Input() errorMessage: string;
  @Input() socialLogin: SocialLogin;
  @Input() guestLoginRedirectUrl: string;
  @Input() showLoginAsGuest: boolean;

  @Output() registerEvent = new EventEmitter();
  @Output() loginRedirectEvent = new EventEmitter();
  @Output() socialLoginEvent = new EventEmitter<string>();
  @Output() loginAsGuestEvent = new EventEmitter();

  public get customerName() {
    return this.property?.customerName || this.property?.customer?.name;
  }

  public get logo() {
    return this.property?.customerLogo || this.property?.customer?.logo;
  }

  public get customerLogo() {
    return (
      this.logo ||
      this.themeService?.getTheme(this.themeService?.getDefaultTheme)?.logo?.url
    );
  }

  constructor(private themeService: ThemeService) {}

  public onRegister() {
    this.registerEvent.emit();
  }

  public onLoginRedirect() {
    this.loginRedirectEvent.emit();
  }

  public onSocialLogin($event: string) {
    this.socialLoginEvent.emit($event);
  }

  public onLoginAsGuest() {
    this.loginAsGuestEvent.emit();
  }
}
