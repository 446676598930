import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-data-disclaimer',
  templateUrl: './no-data-disclaimer.component.html',
  styleUrls: ['./no-data-disclaimer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoDataDisclaimerComponent {
  // TODO: set fallback translation keys here
  @Input() title: string;
  @Input() description: string;
  @Input() imageSrc = '../../../assets/images/no-data-illustration.svg';
  @Input() showImage = true;
  @Input() showDashedBorder = false;
  @Input() scale = 1;
}
