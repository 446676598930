<div
  class="dropdown"
  (openChange)="handleDropdownOpenChange($event)"
  ngbDropdown
>
  <button
    type="button"
    class="btn d-flex justify-content-between align-items-center dropdown-toggle"
    ngbDropdownToggle
    [disabled]="disabled"
  >
    <ng-container *ngIf="selectedChild(); else showPlaceholder">
      {{ selectedChild().name | translate }}
    </ng-container>
    <ng-template #showPlaceholder>
      {{ placeholderText() | translate }}
    </ng-template>

    <div class="d-flex align-items-center">
      <i
        *ngIf="selectedChild()"
        class="icon icon--close ms-2"
        (click)="clear($event)"
      ></i>
      <i
        [ngClass]="isDropdownOpened ? 'chevron-rotated' : 'chevron-non-rotated'"
        class="icon icon--chevron-down ms-2"
      ></i>
    </div>
  </button>
  <div ngbDropdownMenu class="dropdown-menu-custom">
    <div
      class="animation-container"
      [ngClass]="animationState==='showParents' ? 'show-parent' : 'show-child'"
    >
      <div class="view-container parents">
        <p class="back-button semibold">{{dropdownTitle()}}</p>
        <div *ngFor="let parent of items()" (click)="selectParent(parent)">
          <div class="d-flex justify-content-between align-items-center">
            <p class="m-0">{{ parent.name | translate }}</p>
            <i class="icon icon--chevron-right"></i>
          </div>
        </div>
      </div>
      <div class="view-container children">
        <app-button
          class="back-button"
          [type]="ButtonTypeEnum.LINK"
          [iconLeft]="IconTypeEnum.ArrowLeft"
          (clickEvent)="backToParents()"
        >
          {{'general.previous_a' | translate}}
        </app-button>
        <ng-container *ngIf="selectedParent()">
          <div
            *ngFor="let child of selectedParent().items"
            (click)="selectChild(child)"
          >
            {{ child.name | translate }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
