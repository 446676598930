<div class="ad-block-toasts-container">
  <div
    class="ad-block-toast"
    *ngFor="let toast of toasts"
    [@inOut]="animate"
    (@inOut.done)="onAnimationEnd($event)"
    (click)="toastClick(toast)"
  >
    <div class="ad-block-toast__content">
      <div class="ad-block-toast__message-container">
        <span
          class="ad-block-toast__message"
          [innerHTML]="toast.message"
        ></span>
      </div>
    </div>
  </div>
</div>
