<div class="wizard-progress">
  <div
    class="wizard-progress__step"
    *ngFor="let step of steps; let stepIndex = index"
    [ngClass]="{
      'wizard-progress__step--current': currentStepNumber === stepIndex+1,
      'wizard-progress__step--previous': currentStepNumber > stepIndex+1,
      'wizard-progress__step--selectable': step.selectable
    }"
    (click)="onSelectStep(step)"
  >
    <div class="wizard-progress__node"></div>
    <p class="default-s wizard-progress__name">{{step.nameNice | translate}}</p>
  </div>
</div>
