import { Pipe, PipeTransform } from '@angular/core';
import { BadgeColorEnum } from 'libs/components/atoms/badge/badge.enum';
import { Statuses } from '@ui/shared/models';

@Pipe({
  name: 'statusColor'
})
export class StatusColorPipe implements PipeTransform {
  transform(value: string): BadgeColorEnum {
    switch (value) {
      case Statuses.OVERDUE:
      case Statuses.ERROR:
      case Statuses.RENTED:
        return BadgeColorEnum.STATE_900;
      case Statuses.OPEN:
      case Statuses.CHECK_MANUALLY:
      case Statuses.PENDING:
      case Statuses.PENDING_PAY_OUT:
      case Statuses.REVOKED:
      case Statuses.RESERVED:
        return BadgeColorEnum.STATE_500;
      case Statuses.PAID:
      case Statuses.PAID_OUT:
      case Statuses.REFUNDED:
      case Statuses.CONNECTED:
      case Statuses.DEFAULT:
        return BadgeColorEnum.STATE_100;
      case Statuses.EXTERNAL:
        return BadgeColorEnum.PRIMARY_ACCENT_DARK;
      case Statuses.CANCELED:
        return BadgeColorEnum.NEUTRAL;
      default:
        return BadgeColorEnum.NEUTRAL;
    }
  }
}
