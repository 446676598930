import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import {
  AvailableLanguageCodesEnum,
  EditTaskType,
  HierarchicalQuestionActionType,
  HierarchicalQuestionAnswerId,
  HierarchicalQuestionModel,
  HierarchicalQuestionRangedAnswerData,
  HierarchicalQuestionType,
  QuestionActionModel,
  ContactTag
} from '@ui/shared/models';
import { HierarchicalQuestionService } from 'libs/services';
import { BadgeColorEnum } from 'libs/components/atoms/badge/badge.enum';

@Component({
  selector: 'app-hierarchical-questions-display-question',
  templateUrl: './hierarchical-questions-display-question.component.html',
  styleUrls: ['./hierarchical-questions-display-question.component.scss']
})
export class HierarchicalQuestionsDisplayQuestionComponent {
  @Input() question: HierarchicalQuestionModel;
  @Input() questions: HierarchicalQuestionModel[];
  @Input() tags: ContactTag[];
  @Input() isMainQuestion = false;
  @Input() subQuestionIndex: number;
  @Input() appCheckTemplateRef: TemplateRef<any>;
  @Input() editTaskType: EditTaskType;
  @Input() currentLanguage: AvailableLanguageCodesEnum;

  @Output() delete = new EventEmitter();
  @Output() edit = new EventEmitter();

  public get isEditTaskTypeDelete(): boolean {
    return this.editTaskType === EditTaskType.DELETE;
  }

  public get badgeType(): BadgeColorEnum {
    switch (this.editTaskType) {
      case EditTaskType.CREATE:
        return BadgeColorEnum.NEUTRAL;
      case EditTaskType.EDIT:
        return BadgeColorEnum.PRIMARY_ACCENT_LIGHT;
      case EditTaskType.DELETE:
        return BadgeColorEnum.STATE_900;
    }
  }

  public get sortedAnswers() {
    return [...this.question.answers].sort(
      this.hierarchicalQuestionService.sort
    );
  }

  public get isSelection() {
    return this.question.data.type === HierarchicalQuestionType.SELECTION;
  }

  public get isRanged() {
    return this.isRangedDate || this.isRangedNumber;
  }

  public get isRangedDate() {
    return this.question.data.type === HierarchicalQuestionType.RANGE_DATE;
  }

  public get isRangedNumber() {
    return this.question.data.type === HierarchicalQuestionType.RANGE_NUMBER;
  }

  constructor(
    private hierarchicalQuestionService: HierarchicalQuestionService
  ) {}

  public getFollowQuestionTitles(ids: string[]) {
    return ids
      .map(id => this.questions.find(question => question.id === id))
      .map(question => question?.data?.title[this.currentLanguage])
      .join(', ');
  }

  public getTags(actions: QuestionActionModel[]) {
    return actions
      .find(action => action.type === HierarchicalQuestionActionType.TAG)
      ?.tagIds.map(tagId => this.tags?.find(tag => tag.id === tagId))
      .filter(tag => !!tag);
  }

  public isRangedMain(answerId: HierarchicalQuestionAnswerId) {
    return answerId === HierarchicalQuestionAnswerId.RANGED_MAIN;
  }

  public getBound(answerData: HierarchicalQuestionRangedAnswerData) {
    switch (answerData.answerId) {
      case HierarchicalQuestionAnswerId.RANGED_LOWER_BOUND:
        return answerData.upperBound;
      case HierarchicalQuestionAnswerId.RANGED_UPPER_BOUND:
        return answerData.lowerBound;
      default:
        return '';
    }
  }

  public onDelete() {
    this.delete.emit();
  }

  public onEdit() {
    this.edit.emit();
  }
}
