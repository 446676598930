<form [formGroup]="form">
  <div class="appointment-list__scroll-container">
    <div
      class="appointment-list exclusive-appointment-list mt10 mb10"
      *ngIf="exclusiveAppointments && exclusiveAppointments?.length"
    >
      <p class="title-m mb8">
        {{ 'appointment.exclusive_slots_l' | translate }}
      </p>
      <app-radio-group formControlName="newActiveAppointmentId">
        <div
          class="appointment-item__container"
          *ngFor="let appointment of exclusiveAppointments; index as i"
        >
          <div
            class="appointment-item__inner-container"
            [ngClass]="{
                    'full': appointment.full,
                    'canceled' : getAppointmentCanceled(appointment),
                    'exclusive': appointment?.application
                  }"
          >
            <app-radio-button
              class="header-element header-check"
              [value]="appointment.id"
              [disabled]="getAppointmentCanceled(appointment)"
            >
              <div class="pr10 d-flex align-items-center">
                <svg-icon
                  src="/assets/images/icons/icon-calendar.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color path rect"
                ></svg-icon>
                <span class="pl10">{{appointment.date | appDateTime}}</span>
              </div>
              <div class="pr10 pl10 d-flex align-items-center">
                <svg-icon
                  src="/assets/images/icons/icon-clock.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color path circle polyline"
                ></svg-icon>
                <span class="pl10"
                  >{{ appointment.date | appDateTime: { withDate: false,
                  withTime: true } }}</span
                >
              </div>
            </app-radio-button>
          </div>
        </div>
      </app-radio-group>
    </div>
    <div class="appointment-list mt10 mb5" *ngIf="appointments?.length">
      <p class="title-m mb8">
        {{ 'appointment.appointment_slots_l' | translate }}
      </p>
      <app-radio-group formControlName="newActiveAppointmentId">
        <div
          class="appointment-item__container"
          *ngFor="let appointment of appointments; index as i"
        >
          <div
            class="appointment-item__inner-container"
            [ngClass]="{
                    'full': appointment.full,
                    'canceled' : getAppointmentCanceled(appointment),
                    'exclusive': appointment?.application
                  }"
          >
            <app-radio-button
              class="header-element header-check"
              [value]="appointment.id"
              [disabled]="getAppointmentCanceled(appointment)"
            >
              <div class="pr10 d-flex align-items-center">
                <svg-icon
                  src="/assets/images/icons/icon-calendar.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color path rect"
                ></svg-icon>
                <span class="pl10">{{appointment.date | appDateTime}}</span>
              </div>
              <div class="pr10 pl10 d-flex align-items-center">
                <svg-icon
                  src="/assets/images/icons/icon-clock.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="primary-text-svg-color path circle polyline"
                ></svg-icon>
                <span class="pl10"
                  >{{ appointment.date | appDateTime: { withDate: false,
                  withTime: true } }}</span
                >
              </div>
            </app-radio-button>
          </div>
        </div>
        <div
          class="appointment-item__container"
          *ngIf="showNotFittingOption"
          [class.active]="nextActiveAppointmentIdControl.value === 'non_fitting'"
        >
          <div
            class="appointment-item__inner-container appointment-item__non-fitting"
          >
            <app-radio-button
              class="header-element header-check"
              [value]="'non_fitting'"
            >
              <svg-icon
                src="/assets/images/icons/icon-no.svg"
                [applyClass]="true"
                [svgStyle]="{'width.px': 15}"
                class="primary-text-svg-color path"
                [class.white-svg-color]="nextActiveAppointmentIdControl.value === 'non_fitting'"
              ></svg-icon>
              <span class="pl10">
                {{ 'appointment.appointment_not_fitting' | translate }}
              </span>
            </app-radio-button>
          </div>
        </div>
      </app-radio-group>
    </div>
  </div>
</form>
