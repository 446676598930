import { Component, forwardRef, Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { coerceBooleanProperty } from 'libs/utils';
import { BaseControl } from 'libs/components/legacy/form/controls/base-control';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';

@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ColorPickerComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => ColorPickerComponent)
    }
  ]
})
export class ColorPickerComponent extends BaseControl<string> {
  public color: string;
  @Input() title: string;
  @Input() autoFocus = false;
  @Input() description: string;
  @ViewChild(NgControl, { static: true }) ngControl: NgControl;

  @Input()
  get required() {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  public get isDisabled() {
    return this._disabled;
  }

  public valueChange(value: string) {
    this.value = value.toUpperCase();
  }
}
