import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ContentChild,
  TemplateRef,
  AfterViewInit
} from '@angular/core';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { debounceTime, filter, switchMap, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-auto-complete-field',
  templateUrl: './auto-complete-field.component.html',
  styleUrls: ['./auto-complete-field.component.scss']
})
export class AutoCompleteFieldComponent implements OnInit, AfterViewInit {
  public isAutoCompleteOpen = true;

  @Input() selector$: Observable<any[]>;
  @Input() inputWatcher: Observable<string>;
  @Input() propertyName: string;
  @Input() debounceValue = 500;
  @Input() typeOffset = 2;
  @Input() withoutOffset = false;
  @Input() readonly = false;
  @Input() scrollDownForMore = false;
  @Input() loading = false;
  @Input() showClearButton = false;
  @Input() hasUserInteractedWithComponent = false;

  /* eslint-disable @angular-eslint/no-output-on-prefix */
  @Output() onInputChange = new EventEmitter<string>();
  @Output() onSelect = new EventEmitter<any>();
  @Output() clearValue = new EventEmitter();
  @Output() onScrollDownForMore = new EventEmitter();
  /* eslint-enable @angular-eslint/no-output-on-prefix */
  @Output() hasUserInteractedWithComponentChange = new EventEmitter<boolean>();

  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;

  public setFormControlValue(value: any) {
    this.isAutoCompleteOpen = false;
    this.dropdown.close();
    this.onSelect.emit(value);
  }

  ngOnInit(): void {
    this.inputWatcher
      ?.pipe(
        filter(
          term =>
            (this.withoutOffset && !!term) || term?.length >= this.typeOffset
        ),
        debounceTime(this.debounceValue),
        tap(term => this.onInputChange.emit(term)),
        switchMap(() => this.selector$),
        untilDestroyed(this)
      )
      .subscribe(() => {
        // Only open the dropdown when the user already clicked on it
        // If we don't check this, then the dropdown will automatically open on page load,
        // when data is already selected
        this.dropdown.open();
      });
  }

  public ngAfterViewInit() {
    this.dropdown.autoClose = true;
  }

  public onScroll(event: Event) {
    if (this.scrollDownForMore) {
      const target = event.target as HTMLElement;
      const pos = target.scrollTop + target.offsetHeight;
      const threshold = 150;
      if (pos >= target.scrollHeight - threshold) {
        this.onScrollDownForMore.emit();
      }
    }
  }

  public handleDropdownClick() {
    this.dropdown._open ? this.dropdown.close() : this.dropdown.open();
  }
}
