import { Inject, Injectable, Optional } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CONSTANTS_LOADER } from '../../constants/constants-loader-service.token';
import { ConstantsLoader } from '../../constants/constants-loader.service';
import { DefaultConstantsLoader } from '../../constants/default-constants-loader';

import * as fromActions from './constants.actions';

@Injectable()
export class ConstantsEffects {
  constructor(
    private actions$: Actions,
    @Optional()
    @Inject(CONSTANTS_LOADER)
    private constantsLoader: ConstantsLoader = new DefaultConstantsLoader()
  ) {}

  fetchConstants$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.FetchConstants>(fromActions.FETCH_CONSTANTS),
      switchMap(() =>
        this.constantsLoader.load().pipe(
          map(result => new fromActions.FetchConstantsSuccess(result)),
          catchError(err =>
            of(
              new fromActions.FetchConstantsFail(
                err ? err.message : 'Unexpected error'
              )
            )
          )
        )
      )
    )
  );
}
