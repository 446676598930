import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { KeyValue } from '@angular/common';

@Component({
  selector: 'app-grouped-city-map-component',
  templateUrl: './grouped-city-map.component.html',
  styleUrls: ['./grouped-city-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupedCityMapComponent {
  @Input() cityMap: KeyValue<string, any[]>;
}
