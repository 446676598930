import { NgModule } from '@angular/core';

import { ImageSizePipe } from './image-size.pipe';

const exportPipe = [ImageSizePipe];

@NgModule({
  declarations: exportPipe,
  exports: exportPipe,
  providers: exportPipe
})
export class ImageSizePipeModule {}
