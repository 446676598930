<app-custom-questions-form
  *ngIf="customQuestions?.length > 0"
  [formControl]="customQuestionResponsesControl"
  [customQuestions]="customQuestions"
  (validityChange)="onFormValidityChange($event)"
></app-custom-questions-form>

<app-hierarchical-root-question-form
  *ngIf="showHierarchicalRootQuestions"
  [formControl]="hierarchicalRootQuestionsControl"
  [hierarchicalRootQuestions]="rootQuestions"
  (validityChange)="onHierarchicalFormValidityChange($event)"
></app-hierarchical-root-question-form>

<div
  class="global-custom-questions__error"
  *ngIf="showErrorMessage && (customQuestionResponsesControl.errors || hierarchicalRootQuestionsControl.errors )"
>
  {{'forms.errors.field_required' | translate}}
</div>
