import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appFullName' })
export class FullNamePipe implements PipeTransform {
  transform(source: any): string {
    if (!source) return '';

    return [
      source.firstname || source.firstName,
      source.middlename || source.middleName,
      source.lastname || source.lastName || source.name
    ]
      .filter(part => !!part)
      .join(' ');
  }
}
