import {
  Component,
  forwardRef,
  ViewChild,
  OnInit,
  Input,
  ElementRef
} from '@angular/core';

import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';

@Component({
  selector: 'app-simple-text-input',
  templateUrl: './simple-text-input.component.html',
  styleUrls: ['./simple-text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SimpleTextInputComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SimpleTextInputComponent)
    }
  ]
})
export class SimpleTextInputComponent
  extends BaseControl<boolean>
  implements OnInit
{
  @ViewChild(NgControl, { static: true }) ngControl: NgControl;
  @ViewChild('textInput') inputEl: ElementRef<HTMLInputElement>;
  @Input() hideIcon = false;

  public ngOnInit() {
    this.ngControl.statusChanges.subscribe(() => this.stateChanges.next());

    if (this.disabled || this.forceDisabled) {
      this.ngControl.control.disable();
    }
  }

  public edit() {
    this.inputEl.nativeElement.focus();
  }
}
