<div class="row knockout-criteria">
  <div class="col-10">
    <span>{{ 'tenant_profile.knockout_criteria.text_l' | translate }}</span>
    <app-hint class="pl5"
      >{{ 'tenant_profile.knockout_criteria.hint_m' | translate }}</app-hint
    >
  </div>
  <div class="col-2 d-flex justify-content-end">
    <app-slide-toggle
      [disabled]="disabled"
      [formControl]="formControlInput"
    ></app-slide-toggle>
  </div>
</div>
