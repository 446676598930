<div [formGroup]="form" class="form__section">
  <app-form-field
    formGroupName="address"
    class="d-block mb20"
    [disabledClickArea]="true"
    *ngIf="countriesConfig?.length > 1"
  >
    <app-form-field-label
      >{{'registration_link.country_selection_l' |
      translate}}</app-form-field-label
    >
    <app-dropdown-select
      appInput
      class="col-12 col-md-6 pl0"
      [items]="countriesConfig"
      formControlName="country"
      [required]="true"
    >
      <ng-template let-item
        ><span>{{ item.name | translate }}</span></ng-template
      >
    </app-dropdown-select>
  </app-form-field>

  <div class="register-type__container">
    <app-form-field-label>
      {{'address_type_buttons_selecion_global_l' | translate}}
    </app-form-field-label>

    <app-form-field>
      <app-flat-select
        [items]="selectOptions"
        required
        formControlName="type"
      ></app-flat-select>
    </app-form-field>
  </div>

  <hr />

  <div
    *ngIf="showDistrictSelection && isDistrict; else radiusTemplate"
    class="mt10"
  >
    <app-search-profile-landlord-districts
      *ngIf="landlordCityPacket"
      [form]="form"
      [landlordCityPacket]="landlordCityPacket"
      [onlyShowConfiguredCityPartsToUser]="onlyShowConfiguredCityPartsToUser"
      [isNewSP]="!this.form.get('id').value"
    >
    </app-search-profile-landlord-districts>

    <ng-container *ngIf="!onlyShowConfiguredCityPartsToUser || !isRegistration">
      <div ngbAccordion #citiesAccordion="ngbAccordion" [closeOthers]="true">
        <ng-container
          *ngFor="let control of getDistrictsFormArray.controls; index as i"
        >
          <div
            [ngbAccordionItem]="'custom-panel-' + i"
            [collapsed]="this.form.get('id').value"
          >
            <div ngbAccordionHeader>
              <div
                class="d-flex justify-content-between align-items-center p-2"
              >
                <button class="btn btn-link p-0" ngbAccordionToggle>
                  {{control.get('city.name').value || ('landlord.pick_city_l' |
                  translate) }}
                </button>
                <svg-icon
                  *ngIf="getDistrictsFormArray.length > 1 || landlordCityPacket"
                  src="/assets/images/icons/icon-bin.svg"
                  [applyClass]="true"
                  [svgStyle]="{'width.px': 15}"
                  class="main-svg-color ms-auto"
                  (click)="removeCity(i)"
                ></svg-icon>
              </div>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <app-city-autocomplete
                    [formGroup]="control.get('city')"
                    [disableCityEdit]="!isRegistration && onlyShowConfiguredCityPartsToUser"
                    (cityChange)="control.get('districts').reset()"
                    [country]="form.get('address.country').value"
                  >
                  </app-city-autocomplete>
                  <div class="mt10">
                    <app-dropdown-multiselect
                      appInput
                      *ngIf="districtsMap.get(control.get('city.id').value)"
                      [itemValueKey]="'id'"
                      [items]="onlyShowConfiguredCityPartsToUser ? landlordCityPacket?.citiesDistrictsMap.get(control.get('city.id').value) : districtsMap.get(control.get('city.id').value)"
                      [showToggleAll]="true"
                      [showBadges]="true"
                      [formControl]="control.get('districts')"
                    >
                      <div dropdown-button>
                        {{ 'ALL_DISTRICTS_L' | translate }}
                      </div>
                      <ng-template let-item> {{ item.name }} </ng-template>
                    </app-dropdown-multiselect>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="mt20">
        <app-button
          [type]="'primary'"
          *ngIf="(getDistrictsFormArray.valid || (landlordCityPacket && !getDistrictsFormArray.length)) && !onlyShowConfiguredCityPartsToUser"
          [disabled]="getDistrictsFormArray.invalid && getDistrictsFormArray.length"
          (clickEvent)="addNewCity()"
        >
          {{'landlord.add_more_cities_a' | translate}}
        </app-button>
      </div>
    </ng-container>
  </div>

  <ng-template #radiusTemplate>
    <div formGroupName="address">
      <div class="row">
        <div class="col-12 col-md-6 mb10">
          <app-form-field class="inline">
            <app-form-field-label
              >{{'general.zip_code_l' | translate}}</app-form-field-label
            >
            <input
              appInput
              type="text"
              [required]="true"
              [placeholder]="'forms.type_desired_zip_code' | translate"
              formControlName="zipCode"
              class="form-control"
            />
          </app-form-field>
        </div>
        <div class="col-12 col-md-6 mb10">
          <app-form-field class="inline">
            <app-form-field-label
              >{{'general.city_l' | translate}}</app-form-field-label
            >
            <input
              appInput
              type="text"
              [required]="true"
              [placeholder]="'forms.type_city' | translate"
              formControlName="city"
              class="form-control"
            />
          </app-form-field>
        </div>
        <div class="col-12 col-md-6 mb10">
          <app-form-field class="inline">
            <app-form-field-label
              >{{'general.street_name' | translate}}</app-form-field-label
            >
            <input
              appInput
              type="text"
              [placeholder]="'forms.type_street_name' | translate"
              formControlName="street"
              class="form-control"
            />
          </app-form-field>
        </div>
        <div class="col-12 col-md-6 mb10">
          <app-form-field class="inline">
            <app-form-field-label
              >{{'general.house_number_l' | translate}}</app-form-field-label
            >
            <input
              appInput
              type="text"
              [placeholder]="'general.house_number_l' | translate"
              formControlName="houseNumber"
              class="form-control"
            />
          </app-form-field>
        </div>
        <div class="col-12">
          <app-form-field>
            <app-checkbox
              [label]="'general.searching_for_exact_address_l' | translate"
              [(ngModel)]="isExactAddress"
              (ngModelChange)="resetRadius()"
              [ngModelOptions]="{standalone: true}"
            ></app-checkbox>
          </app-form-field>
        </div>
      </div>
    </div>
    <ng-container *ngIf="isRadius && !isExactAddress">
      <hr />
      <app-form-field class="radius-range__container">
        <app-form-field-label
          >{{'general.range_distance_l' | translate}}</app-form-field-label
        >
        <app-flat-select
          [items]="radiusOptions"
          formControlName="radius"
        ></app-flat-select>
      </app-form-field>
    </ng-container>
  </ng-template>
</div>
