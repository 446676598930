import {
  Directive,
  Input,
  OnChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';

@Directive({
  selector: '[appHide]',
  exportAs: 'appHide'
})
export class HideDirective implements OnChanges {
  @Input() appHide: boolean;

  constructor(
    private view: ViewContainerRef,
    private template: TemplateRef<any>
  ) {}

  ngOnChanges() {
    if (this.appHide) {
      this.view.clear();
    } else {
      this.view.createEmbeddedView(this.template);
    }
  }
}
