import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { Apollo } from 'apollo-angular';

import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { notificationConfig } from 'libs/components/legacy/search-profile/model/search-profile.notification.config';

import * as fromNotification from '../notification/notification.actions';
import * as fromReducers from '../reducers';
import * as fromActions from './search-profiles.actions';
import {
  getCityDistrictsQuery,
  GetCityDistrictsQueryResponse,
  searchForCitiesQuery,
  SearchForCitiesQueryResponse
} from './search-profiles.queries';

@Injectable()
export class SearchProfilesEffects {
  constructor(
    private actions$: Actions,
    private store: Store<fromReducers.BaseState>,
    private apollo: Apollo
  ) {}

  searchForCities$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SearchForCities>(fromActions.SEARCH_FOR_CITIES),
      switchMap(({ country, name }) =>
        this.apollo
          .query<SearchForCitiesQueryResponse>({
            query: searchForCitiesQuery,
            variables: { country, name },
            fetchPolicy: 'no-cache'
          })
          .pipe(map(response => response.data.searchCities))
          .pipe(
            map(cities => new fromActions.SearchForCitiesSuccess(cities)),
            catchError(err => [
              new fromActions.SearchForCitiesFail(
                new Error(err ? err.message : 'Unexpected error')
              ),
              new fromNotification.ShowError(
                notificationConfig.searchProfile.searchCities.error
              )
            ])
          )
      )
    )
  );

  getCityDistricts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.LoadCityDistricts>(fromActions.LOAD_CITY_DISTRICTS),
      switchMap(({ cityId }) =>
        this.apollo
          .query<GetCityDistrictsQueryResponse>({
            query: getCityDistrictsQuery,
            variables: { cityId },
            fetchPolicy: 'no-cache'
          })
          .pipe(
            filter(
              response =>
                !response || !response.data || !!response.data.getDistricts
            ),
            map(response => response.data.getDistricts)
          )
          .pipe(
            map(
              districts =>
                new fromActions.LoadCityDistrictsSuccess(districts, cityId)
            ),
            catchError(err => [
              new fromActions.LoadCityDistrictsFail(
                new Error(err ? err.message : 'Unexpected error')
              ),
              new fromNotification.ShowError(
                notificationConfig.searchProfile.getDistricts.error
              )
            ])
          )
      )
    )
  );
}
