import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Angulartics2GoogleAnalytics } from 'angulartics2';
import { CookieService } from '../storage/cookies.service';
import { INFRASTRUCTURE_CONFIG } from '../infrastructure-config.token';
import { InfrastructureConfig } from '../infrastructure-config.model';

@Injectable()
export class GoogleAnalyticsService {
  constructor(
    private cookieService: CookieService,
    private googleAnalytics: Angulartics2GoogleAnalytics,
    @Inject(DOCUMENT) private doc: HTMLDocument,
    @Inject(INFRASTRUCTURE_CONFIG)
    private infrastructureConfig: InfrastructureConfig
  ) {}

  public setGTagManager() {
    if (
      !this.cookieService.isPerformanceAllowed() ||
      !this.infrastructureConfig.environment.google_analytics
    ) {
      return;
    }
    const openScriptTag = this.doc.createComment('Google Tag Manager');
    const gaScript = this.doc.createElement('script');
    gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.infrastructureConfig.environment.google_tag_manager}`;
    gaScript.async = true;
    const gaCode = this.doc.createElement('script');
    gaCode.type = 'text/javascript';
    gaCode.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${this.infrastructureConfig.environment.google_analytics}', { 'anonymize_ip': true });`;
    const closeScriptTag = this.doc.createComment('End Google Tag Manager');

    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(openScriptTag);
    head.appendChild(gaScript);
    head.appendChild(gaCode);
    head.appendChild(closeScriptTag);
  }

  public setGtagPixelManager() {
    if (
      !this.cookieService.isPerformanceAllowed() ||
      !this.infrastructureConfig.environment.google_tag_manager
    ) {
      return;
    }

    const openScriptTag = this.doc.createComment('Google Tag Manager');
    const gaScript = this.doc.createElement('script');
    gaScript.type = 'text/javascript';
    gaScript.async = true;
    gaScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${this.infrastructureConfig.environment.google_tag_manager}');`;
    const closeScriptTag = this.doc.createComment('End Google Tag Manager');

    const openNoScript = this.doc.createComment(
      'Google Tag Manager (noscript)'
    );
    const gaCode = this.doc.createElement('noscript');
    const gaCodeIframe = this.doc.createElement('iframe');
    gaCodeIframe.src = `https://www.googletagmanager.com/ns.html?id=${this.infrastructureConfig.environment.google_tag_manager}`;
    gaCodeIframe.width = '0';
    gaCodeIframe.height = '0';
    gaCodeIframe.setAttribute('style', 'display:none;visibility:hidden');
    gaCode.appendChild(gaCodeIframe);
    const closeNoScript = this.doc.createComment(
      'End Google Tag Manager (noscript)'
    );

    const body = this.doc.getElementsByTagName('body')[0];
    body.insertAdjacentElement('afterbegin', gaCode);
    body.insertBefore(openNoScript, gaCode);
    body.insertBefore(closeNoScript, gaCode.nextSibling);
    const head = this.doc.getElementsByTagName('head')[0];
    head.appendChild(openScriptTag);
    head.appendChild(gaScript);
    head.appendChild(closeScriptTag);
  }

  public track(trackingId: string, category: string, sendPageView: boolean) {
    if (this.cookieService.isPerformanceAllowed()) {
      this.googleAnalytics.eventTrack('action', {
        trackingId: trackingId,
        action: 'event',
        options: {
          send_to: trackingId,
          event_category: category,
          send_page_view: sendPageView
        }
      });
    }
  }
}
