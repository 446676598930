import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-invoice-payment-method',
  templateUrl: './invoice-payment-method.component.html',
  styleUrls: ['./invoice-payment-method.component.scss']
})
export class InvoicePaymentMethodComponent {
  @Input() form: FormGroup;
  public error: string | boolean;

  public get taxIdControlValue() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.get('taxId').value;
  }

  public get invoiceEmailControlValue() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.get('invoiceEmail').value;
  }

  public validate() {
    return {
      taxId: this.taxIdControlValue || '',
      invoiceEmail: this.invoiceEmailControlValue,
      error: this.error
    };
  }
}
