import { Component, Input, OnInit } from '@angular/core';
import {
  QesMethods,
  QESMethodsBadgeText
} from 'libs/components/legacy/digital-contract/qualified-electronic-signature/model';
import { BadgeColorEnum } from 'libs/components/atoms/badge/badge.enum';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, Validators } from '@angular/forms';
import moment from 'moment';

type QesMethodModalType = {
  header: string;
  label: { color: BadgeColorEnum; text: QESMethodsBadgeText };
  defaultNeededDocumentsText: string;
  defaultHowItWorksText: string;
  notSupportedBanks?: string;
  secondNeededDocumentsText?: string;
  secondHowItWorksText?: string;
};

const METHODS = {
  [QesMethods.ROBO_IDENT]: {
    header: 'digital_contract.qes_method_selfie_header_l',
    label: {
      color: BadgeColorEnum.DIGITAL_CONTRACT_NECT,
      text: QESMethodsBadgeText.ROBO_IDENT
    },
    defaultNeededDocumentsText:
      'digital_contract.qes_method_selfie_needed_documents_l',
    defaultHowItWorksText: 'digital_contract.qes_method_selfie_how_it_works_l'
  },
  [QesMethods.BANK]: {
    header: 'digital_contract.qes_method_bank_header_l',
    label: {
      color: BadgeColorEnum.DIGITAL_CONTRACT_BANK,
      text: QESMethodsBadgeText.BANK
    },
    defaultNeededDocumentsText:
      'digital_contract.qes_method_bank_needed_documents_l',
    defaultHowItWorksText: 'digital_contract.qes_method_bank_how_it_works_l',
    notSupportedBanks: 'digital_contract.qes_method_bank_not_supported_banks_l'
  },
  [QesMethods.VIDEO]: {
    header: 'digital_contract.qes_method_video_header_l',
    label: {
      color: BadgeColorEnum.DIGITAL_CONTRACT_IDENTITY,
      text: QESMethodsBadgeText.VIDEO
    },
    defaultNeededDocumentsText:
      'digital_contract.qes_method_video_needed_documents_l',
    defaultHowItWorksText: 'digital_contract.qes_method_video_how_it_works_l'
  },
  [QesMethods.E_ID]: {
    header: 'digital_contract.qes_method_id_header_l',
    label: {
      color: BadgeColorEnum.DIGITAL_CONTRACT_IDENTITY,
      text: QESMethodsBadgeText.E_ID
    },
    defaultNeededDocumentsText:
      'digital_contract.qes_method_id_needed_documents_phone_l',
    defaultHowItWorksText:
      'digital_contract.qes_method_id_how_it_works_phone_l',
    secondNeededDocumentsText:
      'digital_contract.qes_method_id_needed_documents_pc_l',
    secondHowItWorksText: 'digital_contract.qes_method_id_how_it_works_pc_l'
  }
};

@Component({
  selector: 'app-qualified-electronic-signature-method-modal',
  templateUrl: './qualified-electronic-signature-method-modal.component.html',
  styleUrls: ['./qualified-electronic-signature-method-modal.component.scss']
})
export class QualifiedElectronicSignatureMethodModalComponent
  implements OnInit
{
  @Input() qesMethod: QesMethods;
  public selectedMethod: QesMethodModalType;
  public qesMethods = QesMethods;
  public dateOfBirthControl: FormControl;

  public maxBirthDate: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };

  public get isDateOfBirthRequired() {
    return (
      this.qesMethod === QesMethods.BANK || this.qesMethod === QesMethods.E_ID
    );
  }

  constructor(private ngbActiveModal: NgbActiveModal) {}

  ngOnInit() {
    this.selectedMethod = METHODS[this.qesMethod];
    this.dateOfBirthControl = new FormControl('', {
      validators: this.isDateOfBirthRequired ? [Validators.required] : null
    });
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public close() {
    const payload = {
      provider: this.qesMethod
    } as any;

    if (this.isDateOfBirthRequired)
      payload.dateOfBirth = this.dateOfBirthControl.value;

    this.ngbActiveModal.close(payload);
  }
}
