import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { Store } from '@ngrx/store';
import {
  HierarchicalQuestionActionType,
  HierarchicalQuestionAnswerId,
  HierarchicalQuestionAnswerModel,
  HierarchicalQuestionDisplayType,
  HierarchicalQuestionModel,
  HierarchicalQuestionRangedAnswerData,
  HierarchicalQuestionScoreType,
  HierarchicalQuestionSelectionAnswerData,
  HierarchicalQuestionType,
  Language,
  MultiLanguageString,
  QuestionActionModel,
  QuestionIdentifierModel,
  RootQuestionContainerModel
} from '@ui/shared/models';
import { stripProperties, stripTypenameProperty } from 'libs/utils';

@Injectable()
@UntilDestroy()
export class HierarchicalQuestionCreateService {
  private availableLanguages: Language[];
  constructor(private store: Store<fromBaseState.AppState>) {
    this.store
      .select(fromBaseState.getAvailableLanguages)
      .pipe(untilDestroyed(this))
      .subscribe(languages => {
        this.availableLanguages = languages;
      });
  }
  private _formValue: RootQuestionContainerModel;
  public get emptyRootQuestionContainerModel(): RootQuestionContainerModel {
    return {
      rootQuestion: {
        mainQuestionIdentifier: {
          correlationId: '0'
        },
        questions: [this.emptyQuestion(0)]
      },
      importance: 0
    };
  }

  /**
   * Display label in dropdown of different questions types
   */
  public get questionTypes() {
    return Object.keys(HierarchicalQuestionDisplayType).map(value => ({
      // name: 'hierarchical_question.' + value.toLowerCase() + '_l',
      name: 'CUSTOMQUESTIONSTYPE_' + value, // TODO cq use new translation key, remove old after release
      value
    }));
  }

  /*
   * Display switch for ranged_number and ranged_date question
   */
  public get rangedAnswerTypes() {
    return [
      {
        name: 'CUSTOMQUESTIONSTYPE_RANGE_DATE',
        value: HierarchicalQuestionType.RANGE_DATE
      },
      {
        name: 'CUSTOMQUESTIONSTYPE_RANGE_VALUE',
        value: HierarchicalQuestionType.RANGE_NUMBER
      }
    ];
  }

  public getActionFormControls(
    questionActionModel: QuestionActionModel[]
  ): FormControl[] {
    const actions =
      questionActionModel?.length > 0
        ? questionActionModel
        : [
            {
              type: HierarchicalQuestionActionType.TAG,
              tagIds: []
            }
          ];
    return actions.map(action => {
      const actionControl = new FormControl();
      actionControl.patchValue(action);
      return actionControl;
    });
  }

  public emptyQuestion(index: number): HierarchicalQuestionModel {
    return {
      id: null,
      identifier: {
        id: null,
        correlationId: `${index}`
      },
      score: 1,
      data: {
        type: null,
        title: null,
        commentAllowed: false,
        commentHint: null,
        maxAnswers: null,
        order: index
      },
      answers: []
    };
  }

  /**
   * Checks for loops in questions and its subquestions
   * @param questions
   * @param rootQuestion
   */
  public hasLoopInHierarchy(
    rootQuestion: HierarchicalQuestionModel,
    questions: HierarchicalQuestionModel[]
  ): boolean {
    return this.checkQuestionForLoop(rootQuestion, questions, []);
  }

  private checkQuestionForLoop(
    question: HierarchicalQuestionModel,
    questions: HierarchicalQuestionModel[],
    askedQuestions: string[]
  ): boolean {
    const subQuestionIds = question.answers
      .map(a => a.questionIdentifiersDisplay || [])
      .reduce((a: string[], b: string[]) => a.concat(b), []);
    const subQuestions = subQuestionIds
      .map(id => questions.find(q => q.identifier.correlationId === id))
      .filter(value => value);
    return subQuestions
      .map(sq => {
        const id = sq.identifier.correlationId;
        // return true when loop found: question has already been asked
        return askedQuestions.indexOf(id) > -1
          ? true
          : this.checkQuestionForLoop(
              sq,
              questions,
              [...askedQuestions].concat(id)
            );
      })
      .reduce((a, b) => a || b, false);
  }

  /**
   * Adding answer option during hier. question creation: returns new option depending on questionType.
   * @param questionType
   * @param order to order the answers e.g. "Yes" before "No" on boolean selection
   */
  public getNextAnswer(
    questionType: HierarchicalQuestionType,
    order: number
  ): HierarchicalQuestionAnswerModel {
    switch (questionType) {
      case HierarchicalQuestionType.RANGE_DATE:
      case HierarchicalQuestionType.RANGE_NUMBER: {
        return this.getRangedMain(questionType);
      }
      case HierarchicalQuestionType.SELECTION: {
        return this.getSelectionAnswer(order);
      }
    }
  }

  /*
   * No all question types can add answer options: returns if option is available
   */
  public hasNextAnswer(displayType: HierarchicalQuestionDisplayType): boolean {
    switch (displayType) {
      case HierarchicalQuestionDisplayType.SELECT:
      case HierarchicalQuestionDisplayType.MULTISELECT: {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  /*
   * When choosing a question type some pre-defined answer options are shown: returns these pre-def. values.
   */
  public getInitializedAnswers(
    displayType: HierarchicalQuestionDisplayType,
    questionType: HierarchicalQuestionType
  ): HierarchicalQuestionAnswerModel[] {
    switch (displayType) {
      case HierarchicalQuestionDisplayType.RANGE: {
        return this.getRangedAnswers(questionType);
      }
      case HierarchicalQuestionDisplayType.SELECT:
      case HierarchicalQuestionDisplayType.MULTISELECT: {
        return this.getSelectionAnswers();
      }
      case HierarchicalQuestionDisplayType.BOOLEAN: {
        return this.getBooleanAnswers();
      }
      default:
        return [];
    }
  }

  /**
   * Get payload for create
   * @param formData
   */
  public getHierarchicalRootQuestionModelPayload(
    formData: RootQuestionContainerModel
  ): RootQuestionContainerModel {
    this._formValue = { ...formData };
    const { rootQuestion } = formData;
    const {
      mainQuestionIdentifier: mqIdentifier,
      questions,
      ...restRootQuestion
    } = rootQuestion;
    const { id, correlationId } = mqIdentifier;
    const mainQuestionIdentifier: Partial<QuestionIdentifierModel> = { id };
    if (!id) mainQuestionIdentifier.correlationId = correlationId;

    const rootQuestionContainerModel = {
      ...formData,
      rootQuestion: {
        ...restRootQuestion,
        mainQuestionIdentifier,
        questions: questions.map(q => {
          const { identifier: idt, data } = q;
          const { id, correlationId } = idt;
          const identifier: Partial<QuestionIdentifierModel> = { id };
          if (!id) identifier.correlationId = correlationId;
          const { displayType, ...restData } = data;
          const answers = this.getAnswerPayload(q);
          return {
            ...q,
            identifier,
            data: {
              ...restData,
              maxAnswers: this.getMaxAnswers(displayType, q.answers.length)
            },
            score: answers.some(a => a.preferredAnswer) ? 1 : 0,
            answers
          };
        })
      }
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return stripProperties(rootQuestionContainerModel, [
      'showImportance',
      'compliance',
      'displayType',
      'questionIdentifiersDisplay'
    ]);
  }

  /**
   * Get form data for edit
   * @param hierarchicalQuestion
   */
  public getFormData(
    hierarchicalQuestion: RootQuestionContainerModel
  ): RootQuestionContainerModel {
    const { rootQuestion, editTaskType, ...rest } = hierarchicalQuestion;
    return {
      ...rest,
      rootQuestion: {
        ...rootQuestion,
        mainQuestionIdentifier: {
          id: rootQuestion.mainQuestionId,
          correlationId: rootQuestion.mainQuestionId
        },
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        questions: [...rootQuestion.questions]
          .sort((a, b) => (a.data.order > b.data.order ? 1 : -1))
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          .map(({ id, ...restQuestion }) => ({
            ...stripTypenameProperty(restQuestion),
            identifier: {
              id,
              correlationId: id
            },
            data: {
              ...stripTypenameProperty(restQuestion.data),
              displayType: this.getDisplayType(restQuestion)
            },
            answers: [...restQuestion.answers]
              .sort((a, b) => (a.data.order > b.data.order ? 1 : -1))
              .map(
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                ({ questionIds, id, score, data, ...rest }) => ({
                  ...stripTypenameProperty(rest),
                  id,
                  questionIdentifiersDisplay: questionIds,
                  data: stripTypenameProperty(data)
                })
              )
          }))
      },
      showImportance: !!rest.importance
    };
  }

  private getDisplayType(
    question?: HierarchicalQuestionModel
  ): HierarchicalQuestionDisplayType {
    const type = question.data.type;
    const title = (
      question.answers[0].data as HierarchicalQuestionSelectionAnswerData
    ).title;
    const maxAnswers = question.data.maxAnswers;

    if (
      type === HierarchicalQuestionType.RANGE_DATE ||
      type === HierarchicalQuestionType.RANGE_NUMBER
    )
      return HierarchicalQuestionDisplayType.RANGE;

    if (
      title.de === 'hierarchical_questions.answer_boolean.option_no_l' ||
      title.de === 'hierarchical_questions.answer_boolean.option_yes_l'
    )
      return HierarchicalQuestionDisplayType.BOOLEAN;

    return maxAnswers > 1
      ? HierarchicalQuestionDisplayType.MULTISELECT
      : HierarchicalQuestionDisplayType.SELECT;
  }
  private getRangedAnswers(
    type: HierarchicalQuestionType
  ): HierarchicalQuestionAnswerModel[] {
    return [
      this.getRangedLowerBound(type),
      this.getRangedUpperBound(type),
      this.getRangedMain(type)
    ];
  }

  private getRangedMain(
    type: HierarchicalQuestionType
  ): HierarchicalQuestionAnswerModel {
    return {
      data: {
        type,
        answerId: HierarchicalQuestionAnswerId.RANGED_MAIN,
        lowerBound: null, // set to upper_bound of RANGED_LOWER_BOUND answer
        upperBound: null, // set to lower_bound of RANGED_UPPER_BOUND answer
        upperBoundIncluded: true, // must be true
        lowerBoundIncluded: true, // must be true
        scoreType: null,
        /* set to UPPER_BOUND, if RANGED_UPPER_BOUND is preferred
         * or LOWER_BOUND when RANGED_LOWER_BOUND is preferred.
         */ order: 1
      },
      preferredAnswer: true, // must be true
      knockout: false, // must be false
      questionIdentifiers: [],
      actions: []
    };
  }

  private getRangedUpperBound(
    type: HierarchicalQuestionType
  ): HierarchicalQuestionAnswerModel {
    return {
      data: {
        type,
        answerId: HierarchicalQuestionAnswerId.RANGED_UPPER_BOUND,
        lowerBound: null,
        lowerBoundIncluded: false,
        order: 2
      },
      preferredAnswer: false,
      knockout: false,
      questionIdentifiers: [],
      actions: []
    };
  }

  private getRangedLowerBound(
    type: HierarchicalQuestionType
  ): HierarchicalQuestionAnswerModel {
    return {
      data: {
        type,
        answerId: HierarchicalQuestionAnswerId.RANGED_LOWER_BOUND,
        upperBound: null,
        upperBoundIncluded: false,
        order: 0
      },
      preferredAnswer: false,
      knockout: false,
      questionIdentifiers: [],
      actions: []
    };
  }

  private getSelectionAnswers(): HierarchicalQuestionAnswerModel[] {
    return [this.getSelectionAnswer(0), this.getSelectionAnswer(1)];
  }

  private getBooleanAnswers(): HierarchicalQuestionAnswerModel[] {
    return [this.getBooleanAnswer(true), this.getBooleanAnswer(false)];
  }

  private getBooleanAnswer(value: boolean): HierarchicalQuestionAnswerModel {
    const booleanOptionYesAnswer = {} as MultiLanguageString;
    const booleanOptionNoAnswer = {} as MultiLanguageString;
    this.availableLanguages.forEach(language => {
      booleanOptionYesAnswer[language.code] =
        'hierarchical_questions.answer_boolean.option_yes_l';
      booleanOptionNoAnswer[language.code] =
        'hierarchical_questions.answer_boolean.option_no_l';
    });
    return {
      data: {
        type: HierarchicalQuestionType.SELECTION,
        answerId: HierarchicalQuestionAnswerId.SELECTION,
        title: value ? booleanOptionYesAnswer : booleanOptionNoAnswer,
        identifier: null, // leave empty for now
        order: value ? 0 : 1
      },
      preferredAnswer: false,
      knockout: false,
      questionIdentifiers: [],
      actions: []
    };
  }

  private getSelectionAnswer(order: number): HierarchicalQuestionAnswerModel {
    return {
      data: {
        type: HierarchicalQuestionType.SELECTION,
        answerId: HierarchicalQuestionAnswerId.SELECTION,
        title: null,
        identifier: null, // leave empty for now
        order
      },
      preferredAnswer: false,
      knockout: false,
      questionIdentifiers: [],
      actions: []
    };
  }

  private getMaxAnswers(
    displayType: HierarchicalQuestionDisplayType,
    numberOfAnswers: number
  ) {
    switch (displayType) {
      case HierarchicalQuestionDisplayType.SELECT:
      case HierarchicalQuestionDisplayType.BOOLEAN:
      case HierarchicalQuestionDisplayType.RANGE: {
        return 1;
      }
      case HierarchicalQuestionDisplayType.MULTISELECT: {
        return numberOfAnswers;
      }

      default: {
        return null;
      }
    }
  }

  private getAnswerPayload(question: HierarchicalQuestionModel) {
    switch (question.data.displayType) {
      case HierarchicalQuestionDisplayType.RANGE: {
        return this.getRangedAnswerPayload(question.answers);
      }
      default: {
        return question.answers.map(a =>
          this.getAnswerWithMappedIdentifiers(a)
        );
      }
    }
  }

  private getRangedAnswerPayload(answers: HierarchicalQuestionAnswerModel[]) {
    const rangedMain = answers.find(
      a => a.data.answerId === HierarchicalQuestionAnswerId.RANGED_MAIN
    );
    const lowerBound = answers.find(
      a => a.data.answerId === HierarchicalQuestionAnswerId.RANGED_LOWER_BOUND
    );
    const upperBound = answers.find(
      a => a.data.answerId === HierarchicalQuestionAnswerId.RANGED_UPPER_BOUND
    );
    const rangedFilled = {
      ...rangedMain,
      data: {
        ...rangedMain.data,
        lowerBound: (lowerBound.data as HierarchicalQuestionRangedAnswerData)
          .upperBound,
        upperBound: (upperBound.data as HierarchicalQuestionRangedAnswerData)
          .lowerBound,
        scoreType: lowerBound.preferredAnswer
          ? HierarchicalQuestionScoreType.LOWER_BOUND
          : HierarchicalQuestionScoreType.UPPER_BOUND
      },
      preferredAnswer: true,
      knockout: false
    };
    return [
      this.getAnswerWithMappedIdentifiers(lowerBound),
      this.getAnswerWithMappedIdentifiers(upperBound),
      this.getAnswerWithMappedIdentifiers(rangedFilled)
    ];
  }

  private getAnswerWithMappedIdentifiers(
    answer: HierarchicalQuestionAnswerModel
  ) {
    const { questionIdentifiersDisplay = [], id, ...rest } = answer;
    return {
      ...rest,
      id,
      questionIdentifiers: questionIdentifiersDisplay.map(identifier => {
        return this.isNewQuestion(identifier)
          ? {
              correlationId: identifier
            }
          : {
              id: identifier
            };
      })
    };
  }

  private isNewQuestion(questionId: string): boolean {
    return this._formValue.rootQuestion.questions.some(
      ({ identifier }) =>
        questionId === identifier.correlationId && !identifier.id
    );
  }
}
