import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  Conversation,
  ConversationMessage,
  ConversationMessagesCSSCLass,
  MessageTemplate
} from 'libs/components/legacy/messenger/model/interface';
import { Attachment, ContactType } from '@ui/shared/models';

@Component({
  selector: 'app-chat-text-message',
  templateUrl: './chat-text-message.component.html',
  styleUrls: ['./chat-text-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatTextMessageComponent {
  private _groupCssClass: string;
  @Input() message: ConversationMessage;
  @Input() sender: ContactType;
  @Input() activeConversation: Conversation;

  @Input() get groupCssClass(): string {
    return this._groupCssClass;
  }

  set groupCssClass(value: string) {
    this._groupCssClass = value;
    this.showImage = this.getShowImage();
  }

  @Input() isLandlord: boolean;
  @Input() isPropertySearcher: boolean;

  @Output() download = new EventEmitter<Attachment>();
  @Output() preview = new EventEmitter<Attachment>();
  @Output() createMessageAsTemplate = new EventEmitter<MessageTemplate>();

  public showImage: boolean;
  private xssRegEx = new RegExp(
    // eslint-disable-next-line no-control-regex
    '(\b)(onS+)(s*)=|javascript|(<s*)(/*)script',
    'gi'
  );

  private getShowImage() {
    return (
      this.groupCssClass === ConversationMessagesCSSCLass.SINGLE ||
      this.groupCssClass === ConversationMessagesCSSCLass.FIRST
    );
  }

  public onCheckMessage(message: string) {
    if (this.xssRegEx.exec(message)?.length > 0) {
      return '';
    }
    return message;
  }

  public onDownload(attachment: Attachment) {
    this.download.emit(attachment);
  }

  public onPreview(attachment: Attachment) {
    this.preview.emit(attachment);
  }

  public creatingMessageAsTemplate(messageData: ConversationMessage) {
    if (!this.isLandlord) return;
    const { attachments, message: content } = messageData;
    this.createMessageAsTemplate.emit({
      content: content.replace(/<br \/>/gi, '\n'),
      attachments: attachments?.[0]
    });
  }
}
