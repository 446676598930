<div *ngIf="redirectLink; then withLink else withoutLink"></div>

<ng-template #withLink>
  <a
    [routerLink]="redirectLink"
    [ngClass]="messageType"
    class="message clickable"
  >
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </a>
</ng-template>

<ng-template #withoutLink>
  <div [ngClass]="messageType" class="message">
    <ng-template [ngTemplateOutlet]="content"></ng-template>
  </div>
</ng-template>

<ng-template #content>
  <i *ngIf="messageType === 'info'" placement="top" class="info-icon">
    <img src="/assets/images/icons/icon-question.svg" class="question-mark" />
  </i>
  <p><ng-content></ng-content></p>
</ng-template>
