import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { tap, map } from 'rxjs/operators';

import * as fromBaseState from '../base-state';

@Injectable()
export class ConstantsGuard {
  constructor(private store: Store<fromBaseState.AppState>) {}

  canActivate() {
    return this.store.select(fromBaseState.getConstantsLoaded).pipe(
      tap(loaded => {
        if (!loaded) this.store.dispatch(new fromBaseState.FetchConstants());
      }),
      map(() => true)
    );
  }
}
