import { NgModule } from '@angular/core';

import { AutofocusDirective } from './autofocus/autofocus.directive';
import { ClickDetectorDirective } from './click-detector/click-detector.directive';
import { PortalStatusClassDirective } from './portal-status-class/portal-status-class.directive';
import { ElevationDirective } from './elevation';
import { FocusFormErrorDirective } from './focus-form-error/focus-form-error.directive';
import { HideDirective } from './hide.directive';
import { RippleDirective } from './ripple/ripple.directive';

const declarations = [
  AutofocusDirective,
  ClickDetectorDirective,
  PortalStatusClassDirective,
  ElevationDirective,
  FocusFormErrorDirective,
  HideDirective,
  RippleDirective
];

const exportDirectives = [...declarations];

@NgModule({
  declarations,
  imports: [],
  exports: exportDirectives
})
export class DirectivesModule {}
