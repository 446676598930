<div
  ngbDropdown
  [placement]="placement"
  [autoClose]="'outside'"
  class="dropdown-multiselect__container"
  (openChange)="setShowFilters($event)"
>
  <button
    type="button"
    class="btn d-flex justify-content-between align-items-center"
    ngbDropdownToggle
    [disabled]="disabled"
  >
    <span class="dropdown-toggle__inner">
      <ng-container *ngIf="!selectedItems.length">
        {{ placeholder | translate }}
      </ng-container>
      <ng-container
        *ngIf="selectedItems.length > maxDisplayCountOfSelectedItems"
      >
        {{ itemsSelectedPlaceholder | translate: {count: selectedItems.length}
        }}
      </ng-container>
      <ng-container
        *ngIf="selectedItems.length > 0 && selectedItems.length <= maxDisplayCountOfSelectedItems"
      >
        <ng-container
          *ngFor="let selectedItem of selectedItems; index as selectedItemIndex"
        >
          <ng-container
            *ngIf="selectedItemIndex > 0 && textDelimiterForSelectedItems"
          >
            <span class="pe-1">, </span>
          </ng-container>
          <span
            [ngClass]="{ 'ms-1': selectedItemIndex > 0 && !textDelimiterForSelectedItems }"
          >
            <app-badge
              *ngIf="selectedItems[selectedItemIndex].badgeColor; else plainTextSelectedItem"
              [color]="selectedItems[selectedItemIndex].badgeColor"
              class="d-inline-block"
            >
              <ng-container
                *ngTemplateOutlet="plainTextSelectedItem"
              ></ng-container>
            </app-badge>

            <ng-template #plainTextSelectedItem>
              {{ selectedItems[selectedItemIndex].name | translate }}
              <div
                [ngClass]="['symbol', selectedItems[selectedItemIndex].value ? 'symbol-true' : 'symbol-false']"
              ></div>
            </ng-template>
          </span>
        </ng-container>
      </ng-container>
    </span>

    <div class="d-flex align-items-center">
      <i
        *ngIf="selectedItems.length || !form.pristine"
        class="icon icon--close ms-2"
        (click)="clear($event)"
      ></i>
      <i
        [ngClass]="isDropdownOpened ? 'chevron-rotated' : 'chevron-non-rotated'"
        class="icon icon--chevron-down ms-2"
      ></i>
    </div>
  </button>

  <div ngbDropdownMenu>
    <div class="dropdown__items" [formGroup]="form">
      <ng-container
        *ngFor="let group of items | appKeyValueMaintainOrder; index as i"
      >
        <p class="uppercase-m semibold">{{group.value.title | translate }}</p>
        <ng-container *ngFor="let item of group.value.items">
          <app-form-field>
            <div class="checkbox-group">
              <app-check-intermediate
                class="dropdown__check"
                [formControlName]="item.value"
              >
                <app-form-field-label>
                  <app-badge
                    *ngIf="item.badgeColor; else plainTextItem"
                    [color]="item.badgeColor"
                  >
                    <ng-container
                      *ngTemplateOutlet="plainTextItem"
                    ></ng-container>
                  </app-badge>

                  <ng-template #plainTextItem
                    >{{item.name | translate}}</ng-template
                  >
                </app-form-field-label>
              </app-check-intermediate>
            </div>
          </app-form-field>
        </ng-container>
        <hr *ngIf="i < groupItemsLength - 1" />
      </ng-container>
    </div>
    <div *ngIf="showApplyButton" class="dropdown__actions">
      <button
        class="btn btn-apply"
        [disabled]="!groupItemsLength"
        (click)="apply()"
      >
        {{'APPLY_A' | translate}}
      </button>
    </div>
  </div>
</div>
