<div
  class="data-table-cell"
  [ngClass]="{
    'data-table-cell--without-padding-left': withoutPaddingLeft,
    'data-table-cell--hover': hover,
    'data-table-cell--clickable': clickable
  }"
  [class]="'data-table-cell--align-' + alignment"
  [class.p-0]="withoutPadding"
>
  <ng-container *ngIf="cellData || icon; else noData">
    <div *ngIf="icon" [class]="'ms-1 me-2 d-flex icon icon--' + icon"></div>
    <ng-container *ngIf="cellData;">
      <ng-container [ngTemplateOutlet]="cellData"></ng-container>
    </ng-container>
  </ng-container>

  <ng-template #noData> {{ 'shared.no_data' | translate }} </ng-template>
</div>
