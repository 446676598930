<div class="segment">
  <ng-container *ngFor="let segment of segments; last as isLast">
    <app-button
      [type]="segment.id === selectedSegment.id ? ButtonTypeEnum.SECONDARY : ButtonTypeEnum.PRIMARY_INVERTED"
      (click)="selectSegment(segment)"
    >
      <i
        [class]="'d-flex icon icon--'+segment.icon+' justify-content-center property-icon icon-padding'"
      ></i>
    </app-button>
    <div class="segment__divider" *ngIf="!isLast"></div>
  </ng-container>
</div>
