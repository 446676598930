import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import {
  locationSearchQuery,
  LocationSearchResult,
  LocationSearchResultEntryProperties
} from 'libs/queries/location-search.queries';

@Injectable({ providedIn: 'root' })
export class LocationSearchService {
  constructor(
    public apollo: Apollo,
    public translate: TranslateService
  ) {}

  getLocationLabel(
    location: LocationSearchResultEntryProperties,
    withType = false
  ): string {
    const { name, street, district, city, postcode, type, housenumber } =
      location;
    const locationLabel = [];
    if (name) {
      const nameLabel = [];
      nameLabel.push(name);

      if (withType && type && type !== 'house' && type !== 'street') {
        nameLabel.push(
          `(${
            this.translate.instant(`general.location_type_${type}`) as string
          })`
        );
      }
      locationLabel.push(nameLabel.join(' '));
    }
    if (street) {
      const streetNumber = housenumber ? `${street} ${housenumber}` : street;
      if (type === 'street') locationLabel.pop();
      locationLabel.push(streetNumber);
    }
    if (district && district !== city) {
      locationLabel.push(district);
    }
    if (postcode || city) {
      const cityLabel = [];
      if (postcode) cityLabel.push(postcode);
      if (city) cityLabel.push(city);
      locationLabel.push(cityLabel.join(' '));
    }
    return locationLabel.join(', ');
  }

  getLocations$(location) {
    return this.apollo
      .watchQuery<LocationSearchResult>({
        query: locationSearchQuery,
        variables: {
          input: location
        }
      })
      .valueChanges.pipe(
        map(data => {
          return data;
        })
      );
  }
}
