import {
  Component,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy
} from '@angular/core';

import { Attachment } from '@ui/shared/models';

@Component({
  selector: 'app-attachments-list-v2',
  templateUrl: './attachments-list-v2.component.html',
  styleUrls: ['./attachments-list-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentsListV2Component {
  @Input() public attachments: Attachment[] = [];
  @Input() public showDownload = true;
  @Input() public disableDownload = false;
  @Input() public blockDownload = false;
  @Input() public showRemove = false;
  @Input() public isDocument = false;
  @Input() public orderable = false;
  @Input() public editable = false;

  @Output() public remove = new EventEmitter<number>();
  @Output() public download = new EventEmitter<Attachment>();
  @Output() public preview = new EventEmitter<Attachment>();
  @Output() public moveUp = new EventEmitter<Attachment>();
  @Output() public moveDown = new EventEmitter<Attachment>();

  public onRemove(index: number) {
    this.remove.emit(index);
  }

  public onDownload(attachment: Attachment) {
    this.download.emit(attachment);
  }

  public onPreview(attachment: Attachment) {
    this.preview.emit(attachment);
  }

  public onMoveUp(attachment: Attachment) {
    this.moveUp.emit(attachment);
  }

  public onMoveDown(attachment: Attachment) {
    this.moveDown.emit(attachment);
  }
}
