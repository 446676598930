<div class="register-card">
  <div class="register-card__customer-info">
    <div
      *ngIf="customerLogo"
      class="register-card__customer-info__logo--immomio me-2"
    >
      <a href="{{logoRedirectUrl}}" target="_blank"
        ><img [src]="customerLogo" alt="logo"
      /></a>
    </div>
    <div *ngIf="!logo" class="register-card__customer-info__offered col p0">
      <div class="uppercase-m semibold register-card__customer-info__header">
        {{ 'property.offered_by_l' | translate }}
      </div>
      <div class="title-s register-card__customer-info__name">
        {{ customerName || ( 'general.private_landlord_l' | translate ) }}
      </div>
    </div>
    <img
      *ngIf="logo"
      class="register-card__logo--cooperation"
      src="/assets/images/logos/Immomio_Logo_Kooperation_Vertikal.svg"
      alt="{{ 'property.in_cooperation_with_immomio_l' | translate}}"
    />
  </div>

  <div *ngIf="property" class="register-card__apply-to">
    {{'register.register_and_apply_to' | translate}}
    <strong>"{{ property?.data?.name }}"</strong>.
  </div>

  <p
    class="register-card__social-login-success"
    *ngIf="socialLogin?.isSocialLogin"
  >
    {{'register.successful_social_login_m' | translate:{type: socialLogin?.type
    | titlecase} }}
  </p>

  <form [formGroup]="form">
    <div class="row mt15">
      <div class="col-12 col-md-6 mb15">
        <app-form-field>
          <input
            appInput
            type="text"
            required
            [placeholder]="'general.first_name_l' | translate"
            formControlName="firstName"
            class="form-control"
          />
        </app-form-field>
      </div>
      <div class="col-12 col-md-6 mb15">
        <app-form-field>
          <input
            appInput
            type="text"
            required
            [placeholder]="'general.last_name_l' | translate"
            formControlName="lastName"
            class="form-control"
          />
        </app-form-field>
      </div>

      <div class="col-12 mb15" *ngIf="!socialLogin?.isSocialLogin">
        <app-form-field>
          <input
            appInput
            type="text"
            required
            [placeholder]="'forms.type_email_address' | translate"
            formControlName="email"
            class="form-control"
          />
        </app-form-field>
      </div>
      <div class="col-12 mb15" *ngIf="!socialLogin?.isSocialLogin">
        <app-password
          [showRequiredBox]="true"
          [required]="true"
          [showLabel]="false"
          placeholder="{{ 'forms.type_password' | translate}}"
          formControlName="password"
        >
        </app-password>
      </div>

      <div class="col-12 mb15 register-card__terms-of-use">
        <app-card [padding]="'none'">
          <div class="register-card__checkbox-card">
            <app-form-field>
              <app-checkbox
                [required]="true"
                [innerHTML]="'register.agreement_with_terms_short_l' | translate"
                formControlName="acceptPolicies"
              ></app-checkbox>
            </app-form-field>
          </div>
        </app-card>
      </div>

      <div class="col-12 mb15 register-card__terms-of-use">
        <app-card [padding]="'none'">
          <div class="register-card__checkbox-card">
            <app-form-field>
              <app-checkbox
                [label]="'register.opt_in_prospect_l' | translate"
                formControlName="optInProspect"
              ></app-checkbox>
            </app-form-field>
          </div>
        </app-card>
      </div>
    </div>

    <div class="mb15">
      <app-button
        [disabled]="disabled || !form?.valid"
        [loading]="disabled"
        [type]="'primary'"
        (clickEvent)="onRegister()"
        >{{ 'register.register_a' | translate }}</app-button
      >
    </div>

    <app-message [messageType]="'error'" *ngIf="errorMessage">
      {{errorMessage | translate}}
    </app-message>
  </form>

  <div class="divider-with-label register-card__divider">
    <span> {{'registration.or_l' | translate}} </span>
  </div>

  <div class="register-card__bottom">
    <ng-container *ngIf="!socialLogin?.isSocialLogin">
      <app-social-login
        [horizontalLayout]="true"
        [showText]="false"
        (login)="onSocialLogin($event)"
      >
      </app-social-login>
    </ng-container>

    <app-button [type]="'light-bordered'" (clickEvent)="onLoginRedirect()">
      <span class="register-content__login-text">
        {{'register.existing_account_question_short_a' | translate }}&nbsp;
      </span>
      <span class="link register-content__login-link">
        {{'register.login_short_a' | translate }}</span
      >
    </app-button>

    <div *ngIf="showLoginAsGuest" class="register-card__login-as-guest">
      <a
        (click)="onLoginAsGuest()"
        [routerLink]="guestLoginRedirectUrl"
        class="app-link"
        >{{'login.continue_as_guest_a' | translate }} <span>&nbsp;⟩</span></a
      >
    </div>

    <div
      class="register-card__small-text"
      [innerHTML]="'register.data_protection_notice_m' | translate"
    ></div>
  </div>
</div>
