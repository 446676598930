import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { QuotaPackage } from '@ui/shared/models';
import { QuotaTableCheckout } from 'libs/components/organisms/quota-table/quota-table.models';

@Component({
  selector: 'app-quota-table',
  templateUrl: './quota-table.component.html',
  styleUrls: ['./quota-table.component.scss']
})
export class QuotaTableComponent implements OnChanges {
  @Input() quotaPackageCart: Map<string, number>;
  @Input() quotaPackages: QuotaPackage[];
  @Input() availableQuota: number | string;
  @Input() isLoading: boolean;

  @Input() availableQuotaLabel = 'quota_table.current_quota_l';
  @Input() subTotalLabel = 'quota_table.sub_total_l';
  @Input() checkoutLabel = 'quota_table.checkout_a';
  @Input() amountColumnLabel = 'quota_table.amount_column_l';
  @Input() priceColumnLabel = 'quota_table.price_column_l';
  @Input() cartColumnLabel = 'quota_table.cart_column_l';

  @Output() checkout = new EventEmitter<QuotaTableCheckout>();

  public totalAmount: number;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.quotaPackageCart || changes.quotaPackages) {
      this.refreshTotalAmount();
    }
  }

  public addToCart(id: string) {
    const currentQuantity = this.quotaPackageCart.get(id) || 0;
    this.quotaPackageCart.set(id, currentQuantity + 1);
    this.refreshTotalAmount();
  }

  public removeFromCart(id: string) {
    const newQuantity = (this.quotaPackageCart.get(id) || 0) - 1;
    if (newQuantity <= 0) {
      this.quotaPackageCart.delete(id);
    } else {
      this.quotaPackageCart.set(id, newQuantity);
    }
    this.refreshTotalAmount();
  }

  private refreshTotalAmount() {
    this.totalAmount = Array.from(this.quotaPackageCart.entries())
      .map(([id, quantity]) => {
        const quotaPackage = this.quotaPackages.find(
          quotaPackage => quotaPackage.id === id
        );
        return quotaPackage.postDiscountPrice * quantity;
      })
      .reduce((a, b) => a + b, 0);
  }

  public onCheckout() {
    const quotaPackageCart = Array.from(this.quotaPackageCart.entries()).map(
      ([quotaPackageId, quantity]) => ({ quotaPackageId, quantity })
    );
    this.checkout.emit(quotaPackageCart);
  }
}
