import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appSideSheetContent]'
})
export class SideSheetContentDirective {
  @Input() label: string;
  @Input() required: boolean;
  @Input() disabled: boolean;

  // Used as reference for the output of currentFormIdChange in the app-side-sheet component
  @Input() identifier?: string;
  constructor(public template: TemplateRef<any>) {}
}
