<div
  class="contact-list__contact-item"
  [class.active_chat]="activeConversation?.id === conversation?.id"
  (click)="onSelectConversation(conversation)"
>
  <div class="contact-list__contact d-flex justify-content-center">
    <div class="contact-list__contact-img col-2 p0">
      <div class="contact-list__contact-img-inner">
        <div
          class="object-image"
          *ngIf="conversation?.titleImage || isPropertySearcher"
        >
          <img
            [src]="conversation?.titleImage || '/assets/images/no-data-illustration.svg'"
            alt=""
          />
        </div>
        <app-avatar
          *ngIf="conversation?.mainConversationPartner && (isLandlord || conversation?.mainConversationPartner?.type === 'AGENT')"
          [name]="conversation.mainConversationPartner | appFullName"
          [imgSrc]="conversation.mainConversationPartner.portrait"
          [size]="conversation.titleImage || isPropertySearcher ? AvatarSizeEnum.TINY : AvatarSizeEnum.SMALL"
          [class.small]="conversation.titleImage || isPropertySearcher"
        >
        </app-avatar>
      </div>
    </div>
    <div class="contact-list__contact-content col-10">
      <div class="row">
        <div class="position-relative col-9">
          <div *ngIf="isLandlord" class="chat-badge-container">
            <app-badge
              *ngFor="let label of conversation?.labels"
              [color]="chatBadgeColor[label]?.color"
              [size]="'small'"
            >
              {{ chatBadgeColor[label]?.text | translate }}
            </app-badge>
          </div>
          <span
            *ngIf="conversation?.unreadMessages > 0"
            class="unread__messages"
          ></span>
          <h5>
            <ng-container *ngIf="isLandlord">
              {{conversation?.mainConversationPartner | appFullName}}
              <ng-container *ngIf="conversation?.otherParticipants > 1">
                +{{conversation?.otherParticipants}}</ng-container
              >
            </ng-container>
          </h5>
          <p>{{conversation?.subject}}</p>
          <p
            class="title-xs"
            [innerHTML]="removeBreaks(conversation?.lastMessageText)"
          ></p>
        </div>
        <div
          class="chat_date col-3"
          [class.unread]="conversation?.unreadMessages > 0"
        >
          <ng-container *ngIf="isToday(conversation?.lastMessageDate)">
            {{ conversation?.lastMessageDate | appDateTime: { withDate: false,
            withTime: true } }}
          </ng-container>
          <ng-container *ngIf="isLastSevenDays(conversation?.lastMessageDate)">
            {{ conversation?.lastMessageDate | appDateTime: { customPattern:
            'dd' } }}
          </ng-container>
          <ng-container *ngIf="isInThePast(conversation?.lastMessageDate)">
            {{ conversation?.lastMessageDate | appDateTime: { customPattern:
            'MMM DD' } }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
