<div class="day-separator" *ngIf="message?.showSeparator">
  <span
    >{{ message?.messageSent | appDateTime: {withFullNameOfDay: true} }}</span
  >
</div>
<div
  class="incoming_msg"
  *ngIf="sender !== message?.sender?.type"
  [ngClass]="groupCssClass"
>
  <div class="incoming_msg_img" *ngIf="showImage">
    <app-avatar
      [imgSrc]="{ url: message?.sender?.portrait } | appImageSize:'S'"
      [name]="message?.sender | appFullName"
      [size]="'small'"
    ></app-avatar>
  </div>
  <div class="received_msg">
    <span class="title-grey-xs d-block" *ngIf="showImage"
      >{{message?.sender | appFullName}}</span
    >
    <div
      [ngClass]="{'email_message': message.source === 'EMAIL'}"
      class="received_width_msg"
    >
      <p
        *ngIf="message?.message?.length"
        [innerHTML]="onCheckMessage(message?.message)"
      ></p>
      <app-chat-attachment
        *ngIf="message?.attachments"
        [attachments]="message?.attachments"
        [isDocument]="true"
        (download)="onDownload($event)"
        (preview)="onPreview($event)"
      ></app-chat-attachment>
      <span class="time_date">{{message?.messageSent | appMessageTime}}</span>
    </div>
  </div>
</div>
<div
  class="outgoing_msg"
  *ngIf="sender === message?.sender?.type"
  [ngClass]="groupCssClass"
>
  <div class="sent_msg p0">
    <div class="template-action" *ngIf="isLandlord">
      <app-button
        ngbTooltip="{{'messenger.save_message_as_template_l' | translate}}"
        (clickEvent)="creatingMessageAsTemplate(message)"
      >
        <div class="icon icon--add"></div>
      </app-button>
    </div>
    <p
      *ngIf="message?.message?.length"
      [innerHTML]="onCheckMessage(message?.message)"
    ></p>
    <app-chat-attachment
      *ngIf="message?.attachments"
      [attachments]="message?.attachments"
      [isDocument]="true"
      (download)="onDownload($event)"
      (preview)="onPreview($event)"
    ></app-chat-attachment>
    <span class="time_date">{{message?.messageSent | appMessageTime}}</span>
  </div>
  <div class="outgoing_msg_img" *ngIf="showImage">
    <app-avatar
      [imgSrc]="{ url: message?.sender?.portrait } | appImageSize:'S'"
      [name]="message?.sender | appFullName"
      [size]="'small'"
    ></app-avatar>
  </div>
</div>
