import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';

import { NotificationService } from 'libs/infrastructure/notification';
import * as fromNotificationActions from './notification.actions';

@Injectable()
export class NotificationEffects {
  constructor(
    private actions$: Actions<fromNotificationActions.notificationActions>,
    private notificationService: NotificationService
  ) {}

  showInfo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromNotificationActions.SHOW_INFO),
        map((action: fromNotificationActions.ShowInfo) => ({
          message: action.message,
          interpolateParams: action.interpolateParams
        })),
        tap(({ message, interpolateParams }) =>
          this.notificationService.showInfoToast(message, {}, interpolateParams)
        )
      ),
    { dispatch: false }
  );

  showError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromNotificationActions.SHOW_ERROR),
        map((action: fromNotificationActions.ShowError) => ({
          message: action.message,
          interpolateParams: action.interpolateParams
        })),
        tap(({ message, interpolateParams }) =>
          this.notificationService.showErrorToast(
            message,
            {},
            interpolateParams
          )
        )
      ),
    { dispatch: false }
  );

  showInfoDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromNotificationActions.SHOW_ACKNOWLEDGE),
        tap(action =>
          this.notificationService.showInfoModal(
            action.titleMessage,
            action.message,
            action.okButtonMessage
          )
        )
      ),
    { dispatch: false }
  );
}
