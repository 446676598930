<div>
  {{districtGroup.groupedDistrictsName ? districtGroup.groupedDistrictsName + ':
  ' : ''}}
  <span
    *ngFor="let district of districtGroup.districts |
  slice: 0: showAllCityParts ? districtGroup.districts.length : splitLength; let i = index"
    >{{district.name}}<ng-container
      *ngIf="i < districtGroup?.districts?.length - 1"
      >,
    </ng-container>
  </span>
  <p
    *ngIf="districtGroup.districts.length > splitLength"
    (click)="toggleShowAllCityParts()"
    class="mt-2 search-profile__districts__show-more"
  >
    {{(showAllCityParts ? 'search.show_less_a':
    'search_profile.load_more_city_parts_l' )| translate: {count:
    districtGroup.districts.length - splitLength} }}
  </p>
</div>
