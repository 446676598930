<div
  class="messenger__chat-area"
  #chatContainerArea
  (dragenter)="onDragOverFile($event)"
  (dragleave)="onDragOverFile($event)"
>
  <div
    class="mobile-chat-nav d-flex justify-content-between flexbox-column ios-padding-left ios-padding-right"
    *ngIf="isMobile || isTablet"
  >
    <app-button
      class="mobile-nav-item"
      (clickEvent)="setActivePanel(paneTypes.CONTACT_LIST)"
    >
      <img src="/assets/images/icons/icon-navigation-left.svg" />
      <span class="pl5">{{'messenger.return_a' | translate }}</span>
    </app-button>
    <app-button
      class="mobile-nav-item"
      *ngIf="isPropertySearcher"
      (clickEvent)="setActivePanel(paneTypes.INFO)"
    >
      {{'messenger.show_flat_info_a' | translate }}
      <span class="pl5"
        ><img src="/assets/images/icons/icon-navigation-right.svg"
      /></span>
    </app-button>
    <app-button
      class="mobile-nav-item"
      *ngIf="isLandlord"
      (clickEvent)="setActivePanel(paneTypes.INFO)"
      [iconRight]="'arrow-right'"
    >
      {{'messenger.show_property_searcher_info_a' | translate }}
    </app-button>
  </div>
  <div
    #chatHistory
    class="messenger__chat-history ios-padding-left ios-padding-right"
    [ngClass]="{
  'p0': isMobile || isTablet,
  'mobile-chat': isMobile,
  'tablet-chat': isTablet,
  'property-searcher__chat': isPropertySearcher
  }"
  >
    <div
      class="messenger__chat-header d-flex justify-content-end"
      *ngIf="activeConversation"
    >
      <form [formGroup]="selectTemplateForm" *ngIf="showMessageTemplates">
        <app-dropdown-select
          [items]="templates"
          [placement]="'bottom-right'"
          [itemValueKey]="'id'"
          [disabled]="templates?.length === 0"
          (scrollBottom)="scrollingToBottom()"
          formControlName="template"
        >
          <div class="chat-template-dropdown-btn" dropdown-button>
            <svg-icon
              src="/assets/images/icons/Icon_ChatTemplate.svg"
              [applyClass]="true"
              [svgStyle]="{'width.px': 20}"
              class="main-svg-color path rect"
            ></svg-icon>
          </div>
          <ng-template let-item>{{ item.title | translate }}</ng-template>
        </app-dropdown-select>
      </form>
      <app-button
        *ngIf="allowAttachmentUpload"
        class="secondary file-upload__link"
        [disabled]="!canAnswer || isApplicationBlocked"
        (clickEvent)="selectFile()"
        [type]="'light-bordered'"
      >
        <div class="icon icon--paper-clip"></div>
      </app-button>
    </div>
    <ng-scrollbar
      class="messenger__chat-history-content"
      [class.heightHide]="!activeConversation"
      [track]="'vertical'"
      [visibility]="'hover'"
    >
      <div class="loading-area" *ngIf="messagesActionState.pending">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <app-chat-text-message
        *ngFor="let message of messages; index as i"
        [sender]="sender"
        [message]="message"
        [groupCssClass]="groupMessages(i)"
        [activeConversation]="activeConversation"
        [isPropertySearcher]="isPropertySearcher"
        [isLandlord]="isLandlord"
        (download)="onDownload($event)"
        (preview)="onPreview($event)"
        (createMessageAsTemplate)="onCreateMessageAsTemplate($event)"
      ></app-chat-text-message>
      <ng-container *ngIf="activeConversation && isLandlord && !messages">
        <app-no-content
          headline="messages.no_message_l"
          message="message.send_message_now_l"
          [hideImage]="true"
        ></app-no-content>
      </ng-container>
    </ng-scrollbar>
    <ng-container *ngIf="!activeConversation">
      <app-no-content
        headline="messages.select_conversation_title_l"
        message="message.select_conversation_message_l"
        [hideImage]="true"
      ></app-no-content>
    </ng-container>
  </div>
  <div
    #chatInput
    class="type_msg"
    *ngIf="activeConversation"
    [class.mobile-chat]="isMobile"
    [class.tablet-chat]="isTablet"
  >
    <form [formGroup]="form">
      <app-form-field
        *ngIf="allowAttachmentUpload"
        [ngClass]="{
        'visible': dragEvent || attachmentControl.value,
        'hidden': !dragEvent && !attachmentControl.value
      }"
      >
        <app-form-field-label
          >{{'messenger.attachment_l' | translate}}</app-form-field-label
        >
        <app-attachments
          [showRemove]="true"
          [accept]="acceptedFileTypes"
          [size]="documentsMaxSize"
          (remove)="hideUploadArea()"
          formControlName="attachments"
        ></app-attachments>
      </app-form-field>
      <div
        class="input-container d-flex justify-content-between ios-padding-left ios-padding-right"
      >
        <app-form-field class="inline col-10 col-md-11 col-xl-11 p0 p-md-0">
          <textarea
            appInput
            rows="1"
            cols="100"
            type="text"
            name="chatInput"
            [placeholder]="'forms.type_message' | translate"
            formControlName="message"
            class="form-control"
            (keypress)="onSendMessage($event)"
          >
          </textarea>
        </app-form-field>
        <app-button
          class="default msg_send_btn"
          [disabled]="!canAnswer || isApplicationBlocked || areMessageAndAttachmentsEmpty"
          (clickEvent)="onSendMessage()"
        >
          <div class="icon icon--arrow-right"></div>
        </app-button>
        <app-file-upload
          class="file-upload__input"
          #fileInput
          [size]="documentsMaxSize"
          [accept]="acceptedFileTypes"
          (changeFileUpload)="onChange($event)"
        ></app-file-upload>
      </div>
    </form>
  </div>
</div>
