<app-card
  class="selection-card"
  [padding]="'none'"
  [borderStyle]="selected ? 'active' : null"
  [clickable]="selectable"
  (clickEvent)="toggleSelection()"
>
  <div class="d-flex flex-nowrap align-items-center">
    <app-check
      class="ms-3 me-3"
      *ngIf="selectable"
      [(ngModel)]="selected"
      (ngModelChange)="selection()"
    ></app-check>
    <div class="selection-card__content" [ngClass]="{'ps-0': selectable}">
      <div class="selection-card__image-wrapper flex-shrink-0">
        <app-image
          [defaultSrc]="imageUrlWithFallback"
          [alt]="title"
          [objectFit]="'cover'"
          [borderRadius]="'big'"
          [borderStyle]="!imageUrl ? 'neutral' : null"
        ></app-image>
      </div>
      <div class="d-flex w-100 justify-content-between semibold">
        <div class="d-flex flex-column">
          <div>{{title}}</div>
          <div>{{subTitle}}</div>
        </div>
        <div
          class="selection-card__additional-content d-flex flex-column justify-content-center"
        >
          <ng-content></ng-content>
        </div>
      </div>
    </div>
    <div class="selection-card__icon-container flex-shrink-0" *ngIf="icon">
      <div [class]="'icon icon--'+ icon"></div>
    </div>
  </div>
</app-card>
