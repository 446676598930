import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  OnInit,
  HostListener,
  ChangeDetectorRef
} from '@angular/core';

import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { FormControl } from '@angular/forms';

import { checkExifOrientation } from 'libs/utils';
import { Attachment } from '@ui/shared/models';
import { ModalService } from 'libs/components/legacy/modal';
import { EditAttachmentComponent } from 'libs/components/legacy/attachment/edit-attachment/edit-attachment.component';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { mixinAttachmentHelper } from '../attachment-component-helper.mixin';

class AttachmentItemComponentBase {}

const _AttachmentItemComponentMixinBase = mixinAttachmentHelper(
  AttachmentItemComponentBase
);

@UntilDestroy()
@Component({
  selector: 'app-attachment-item',
  templateUrl: './attachment-item.component.html',
  styleUrls: ['./attachment-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentItemComponent
  extends _AttachmentItemComponentMixinBase
  implements OnChanges, OnInit
{
  @Input() public attachment: Attachment;
  @Input() public index: number;
  @Input() public showDownload = true;
  @Input() public showPreview = true;
  @Input() public disableDownload = false;
  @Input() public blockDownload = false;
  @Input() public showRemove = false;
  @Input() public isDocument = false;
  @Input() public orderable = false;
  @Input() public editable = false;

  @Output() public remove = new EventEmitter<number>();
  @Output() public download = new EventEmitter<Attachment>();
  @Output() public preview = new EventEmitter<Attachment>();
  @Output() public moveUp = new EventEmitter<Attachment>();
  @Output() public moveDown = new EventEmitter<Attachment>();
  @Output() public updateAttachment = new EventEmitter<Attachment>();
  public imgSrc: SafeUrl | string;

  public hideIcons = true;

  public hasExifMetadata = false;

  public nameControl = new FormControl('');

  @HostListener('mouseenter')
  public onEnter() {
    this.hideIcons = false;
  }

  @HostListener('mouseleave')
  public onLeave() {
    this.hideIcons = true;
  }

  get isPDF() {
    return this.attachment.extension === 'pdf';
  }

  constructor(
    private sanitizer: DomSanitizer,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit() {
    this.nameControl.patchValue(this.name);

    this.nameControl.valueChanges
      .pipe(debounceTime(1000), untilDestroyed(this))
      .subscribe(title => this.updateAttachment.emit({ title }));

    checkExifOrientation(this.attachment.file, orientation => {
      this.hasExifMetadata = orientation > -1;
      this.cdr.markForCheck();
    });
  }

  public ngOnChanges() {
    if (!this.isDocument) {
      this.imgSrc = this.hasFile
        ? this.getSafeUrl(this.attachment.file || (this.attachment as Blob))
        : this.attachment.url;
    }
  }

  public isPdf(attachment: Attachment) {
    return (
      attachment.type === 'PDF' ||
      (attachment.title && attachment.title.toLowerCase().endsWith('.pdf'))
    );
  }

  public onRemove() {
    this.remove.emit(this.index);
  }

  public onDownload() {
    this.download.emit(this.attachment);
  }

  public onPreview() {
    this.preview.emit(this.attachment);
  }

  public getSafeUrl(file: Blob) {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  public onMoveUp() {
    if (this.orderable) {
      this.moveUp.emit(this.attachment);
    }
  }

  public onMoveDown() {
    if (this.orderable) {
      this.moveDown.emit(this.attachment);
    }
  }

  public editAttachment() {
    this.modalService
      .open<EditAttachmentComponent>(EditAttachmentComponent, {
        data: {
          attachment: { ...this.attachment },
          removeFn: () => this.remove.emit(this.index)
        }
      })
      .onClose()
      .subscribe((result: { attachment; formValue }) => {
        const title = result.formValue.title || '';
        const rotate = result.attachment.rotate || 0;
        const file = this.attachment.file || result.attachment.file;

        this.updateAttachment.emit({ title, rotate, file });
      });
  }
}
