import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appCardNumber' })
export class CardNumberPipe implements PipeTransform {
  transform(lastFour: string): string {
    if (!lastFour) return '';

    return `**** **** **** ${lastFour}`;
  }
}
