import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { map, mergeMap, switchMap, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { Apollo } from 'apollo-angular';
import { gql } from 'apollo-angular';

import { Translations } from '@ui/shared/models';
import { I18nService } from 'libs/infrastructure/i18n/i18n.service';

import * as fromActions from './translation.actions';

const translationQuery = gql`
  query translations(
    $appName: String!
    $langCode: String!
    $informal: Boolean!
  ) {
    translations(appName: $appName, langCode: $langCode, informal: $informal)
  }
`;

interface TranslationsResponse {
  translations: Translations;
}

@Injectable()
export class TranslationEffects {
  constructor(
    private apollo: Apollo,
    private actions$: Actions,
    private i18nService: I18nService
  ) {}

  checkTranslations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CHECK_TRANSLATIONS),
      mergeMap((action: fromActions.CheckTranslations) => {
        const actions = [];

        if (
          this.i18nService.translationExists(action.langCode, action.informal)
        ) {
          this.i18nService.setTranslations(
            action.langCode,
            action.informal,
            this.i18nService.getTranslations()
          );

          actions.push(
            new fromActions.LoadTranslationSuccess(
              action.langCode,
              action.informal
            )
          );
        }

        actions.push(
          new fromActions.LoadTranslation(
            action.appName,
            action.langCode,
            action.informal
          )
        );

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return actions;
      }),
      catchError(() => [new fromActions.LoadDefaultTranslation()])
    )
  );

  loadTranslation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LOAD_TRANSLATION),
      switchMap((action: fromActions.LoadTranslation) => {
        return this.apollo
          .query<TranslationsResponse>({
            query: translationQuery,
            variables: {
              appName: action.appName,
              langCode: action.langCode,
              informal: action.informal
            }
          })
          .pipe(
            map(response => {
              const translations = response.data && response.data.translations;

              if (translations && translations[action.langCode]) {
                this.i18nService.setTranslations(
                  action.langCode,
                  action.informal,
                  translations
                );
                return new fromActions.LoadTranslationSuccess(
                  action.langCode,
                  action.informal
                );
              }

              return new fromActions.LoadTranslationFail('UNEXPECTED_DATA');
            }),
            catchError(error => of(new fromActions.LoadTranslationFail(error)))
          );
      })
    )
  );

  loadTranslationFail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.LOAD_TRANSLATION_FAIL),
      switchMap((action: fromActions.LoadTranslationFail) => {
        console.error(`Error loading translation: ${action.error}`);
        return of(new fromActions.LoadDefaultTranslation());
      })
    )
  );

  removeTranslations$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromActions.REMOVE_TRANSLATIONS),
        tap(() => this.i18nService.removeTranslations())
      ),
    { dispatch: false }
  );
}
