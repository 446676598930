<app-modal-v2 *ngIf="showV2">
  <div class="modal-m">
    <app-modal-content>
      <div class="modal-body property-interest__body">
        <ng-container *ngIf="!isInterested && !fromEmail">
          <p class="title-m property-interest__title">
            {{ 'application.not_interested_modal_title' |
            translate:{propertyName: propertyName} }}
          </p>
          <p class="default-s property-interest__message mb30">
            {{
            'application.not_interested_modal_'+propertyType.toLowerCase()+'_message'
            | translate }}
          </p>

          <app-confirm-reason
            [(reasonTypeModel)]="reasonTypeModel"
            [(otherReasonText)]="otherReasonText"
            [reasons]="reasons"
          >
          </app-confirm-reason>
        </ng-container>

        <ng-container *ngIf="isInterested && !fromEmail">
          <p class="title-m property-interest__title">
            {{
            'application.interested_modal_'+propertyType.toLowerCase()+'_title'
            | translate:{propertyName: propertyName} }}
          </p>
          <p class="default-s property-interest__message">
            {{
            'application.interested_modal_'+propertyType.toLowerCase()+'_message'
            | translate }}
          </p>
          <app-info-box *ngIf="!isOfflineUser">
            {{ 'application.profile_incomplete_intent_message' | translate }}
          </app-info-box>

          <div *ngIf="askForViewingAppointmentAttendance">
            <p class="default-s property-interest__message mt10 mb10">
              <span
                >{{
                'application.interested_modal.appointment_'+propertyType.toLowerCase()+'_check_msg_l'
                | translate }}</span
              >
              <span class="required-marker">*&nbsp;</span>
            </p>

            <app-radio-group
              required
              [isUnselectable]="false"
              [(ngModel)]="hasVisitedViewingAppointment"
            >
              <app-radio-button class="radio-group mb4" [value]="'YES'">
                {{
                'application.interested_modal.appointment_'+propertyType.toLowerCase()+'_check_yes_l'
                | translate }}
              </app-radio-button>
              <app-radio-button class="radio-group mb4" [value]="'NO'">
                {{
                'application.interested_modal.appointment_'+propertyType.toLowerCase()+'_check_no_l'
                | translate }}
              </app-radio-button>
            </app-radio-group>
          </div>
        </ng-container>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer property-interest__footer">
        <ng-container *ngIf="!isInterested && !fromEmail">
          <app-button [type]="'light-bordered'" (clickEvent)="cancel()"
            >{{ 'general.cancel_a' | translate}}</app-button
          >
          <app-button
            [type]="'primary'"
            [disabled]="!reasonTypeModel"
            (clickEvent)="denyIntent()"
            >{{ 'application.not_interested_a' | translate}}</app-button
          >
        </ng-container>
        <ng-container *ngIf="isInterested && !fromEmail">
          <app-button [type]="'light-bordered'" (clickEvent)="cancel()"
            >{{ 'general.cancel_a' | translate}}</app-button
          >
          <app-button
            [type]="'positive'"
            [disabled]="askForViewingAppointmentAttendance && !hasVisitedViewingAppointment"
            [ngbTooltip]="!askForViewingAppointmentAttendance || !hasVisitedViewingAppointment ? ('application.interested_modal.appointment_check_tooltip_l' | translate) : ''"
            (clickEvent)="confirmIntent()"
          >
            {{ 'application.interested_a' | translate}}
          </app-button>
          <app-button
            *ngIf="!profileComplete && !isOfflineUser"
            [type]="'primary'"
            (clickEvent)="onEditProfile()"
          >
            {{ 'property.complete_profile_a' | translate}}
          </app-button>
        </ng-container>
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>

<app-modal *ngIf="!showV2">
  <div class="modal-m">
    <app-modal-content>
      <div class="modal-body property-interest__body">
        <ng-container *ngIf="!isInterested && !fromEmail">
          <p class="title-m property-interest__title">
            {{ 'application.not_interested_modal_title' |
            translate:{propertyName: propertyName} }}
          </p>
          <p class="default-s property-interest__message mb30">
            {{ 'application.not_interested_modal_message' | translate }}
          </p>

          <app-confirm-reason
            [(reasonTypeModel)]="reasonTypeModel"
            [(otherReasonText)]="otherReasonText"
            [reasons]="reasons"
          >
          </app-confirm-reason>
        </ng-container>

        <ng-container *ngIf="isInterested && !fromEmail">
          <p class="title-m property-interest__title">
            {{ 'application.interested_modal_title' | translate:{propertyName:
            propertyName} }}
          </p>
          <p class="default-s property-interest__message">
            {{ 'application.interested_modal_message' | translate }}
          </p>
          <app-info-box *ngIf="!isOfflineUser">
            {{ 'application.profile_incomplete_intent_message' | translate }}
          </app-info-box>

          <p class="default-s property-interest__message mt10 mb10">
            <span
              >{{ 'application.interested_modal.appointment_check_msg_l' |
              translate }}</span
            >
            <span class="required-marker">*&nbsp;</span>
          </p>

          <app-radio-group
            required
            [isUnselectable]="false"
            [(ngModel)]="hasVisitedViewingAppointment"
          >
            <app-radio-button class="radio-group mb4" [value]="'YES'">
              {{ 'application.interested_modal.appointment_check_yes_l' |
              translate }}
            </app-radio-button>
            <app-radio-button class="radio-group mb4" [value]="'NO'">
              {{ 'application.interested_modal.appointment_check_no_l' |
              translate }}
            </app-radio-button>
          </app-radio-group>
        </ng-container>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer property-interest__footer">
        <ng-container *ngIf="!isInterested && !fromEmail">
          <app-button [type]="'light-bordered'" (clickEvent)="cancel()"
            >{{ 'general.cancel_a' | translate}}</app-button
          >
          <app-button [disabled]="!reasonTypeModel" (clickEvent)="denyIntent()"
            >{{ 'application.not_interested_a' | translate}}</app-button
          >
        </ng-container>
        <ng-container *ngIf="isInterested && !fromEmail">
          <app-button [type]="'light-bordered'" (clickEvent)="cancel()"
            >{{ 'general.cancel_a' | translate}}</app-button
          >
          <app-button
            [disabled]="!hasVisitedViewingAppointment"
            [ngbTooltip]="(!hasVisitedViewingAppointment ? 'application.interested_modal.appointment_check_tooltip_l' : '') | translate"
            (clickEvent)="confirmIntent()"
          >
            {{ 'application.interested_a' | translate}}
          </app-button>
          <app-button
            *ngIf="!profileComplete && !isOfflineUser"
            (clickEvent)="onEditProfile()"
          >
            {{ 'property.complete_profile_a' | translate}}
          </app-button>
        </ng-container>
      </div>
    </app-modal-footer>
  </div>
</app-modal>
