import { InjectionToken } from '@angular/core';
import { Attachment } from '@ui/shared/models';

export const THEMES = new InjectionToken('THEMES');
export const ACTIVE_THEME = new InjectionToken('ACTIVE_THEME');

export enum ThemePropertyKeys {
  THEME_COLOR_APP_BACKGROUND = '--theme-color-app-background',
  THEME_COLOR_CARD_BACKGROUND = '--theme-color-card-background',
  THEME_COLOR_MENU_BACKGROUND = '--theme-color-menu-background',

  THEME_COLOR_PRIMARY_ACCENT = '--theme-color-primary-accent',
  THEME_COLOR_PRIMARY_ACCENT_BW_CONTRAST = '--theme-color-primary-accent-bw-contrast',
  THEME_COLOR_PRIMARY_ACCENT_LIGHT = '--theme-color-primary-accent-light',
  THEME_COLOR_PRIMARY_ACCENT_LIGHT_BW_CONTRAST = '--theme-color-primary-accent-light-bw-contrast',
  THEME_COLOR_PRIMARY_ACCENT_DARK = '--theme-color-primary-accent-dark',
  THEME_COLOR_PRIMARY_ACCENT_DARK_BW_CONTRAST = '--theme-color-primary-accent-dark-bw-contrast',

  THEME_COLOR_SECONDARY_ACCENT = '--theme-color-secondary-accent',
  THEME_COLOR_SECONDARY_ACCENT_BW_CONTRAST = '--theme-color-secondary-accent-bw-contrast',
  THEME_COLOR_SECONDARY_ACCENT_LIGHT = '--theme-color-secondary-accent-light',
  THEME_COLOR_SECONDARY_ACCENT_LIGHT_BW_CONTRAST = '--theme-color-secondary-accent-light-bw-contrast',
  THEME_COLOR_SECONDARY_ACCENT_LIGHTER = '--theme-color-secondary-accent-lighter',
  THEME_COLOR_SECONDARY_ACCENT_LIGHTER_BW_CONTRAST = '--theme-color-secondary-accent-lighter-bw-contrast',
  THEME_COLOR_SECONDARY_ACCENT_DARK = '--theme-color-secondary-accent-dark',
  THEME_COLOR_SECONDARY_ACCENT_DARK_BW_CONTRAST = '--theme-color-secondary-accent-dark-bw-contrast',

  THEME_COLOR_PRIMARY_TEXT = '--theme-color-primary-text',
  THEME_COLOR_SECONDARY_TEXT = '--theme-color-secondary-text',
  THEME_COLOR_BUTTON_TEXT = '--theme-color-button-text',

  THEME_COLOR_STATE_100_ULTRA_LIGHT = '--theme-color-state-100-ultra-light',
  THEME_COLOR_STATE_100_LIGHT = '--theme-color-state-100-light',
  THEME_COLOR_STATE_100 = '--theme-color-state-100',
  THEME_COLOR_STATE_100_DARK = '--theme-color-state-100-dark',
  THEME_COLOR_STATE_200_ULTRA_LIGHT = '--theme-color-state-200-ultra-light',
  THEME_COLOR_STATE_200_LIGHT = '--theme-color-state-200-light',
  THEME_COLOR_STATE_200 = '--theme-color-state-200',
  THEME_COLOR_STATE_200_DARK = '--theme-color-state-200-dark',
  THEME_COLOR_STATE_300 = '--theme-color-state-300',
  THEME_COLOR_STATE_500_ULTRA_LIGHT = '--theme-color-state-500-ultra-light',
  THEME_COLOR_STATE_500_LIGHT = '--theme-color-state-500-light',
  THEME_COLOR_STATE_500 = '--theme-color-state-500',
  THEME_COLOR_STATE_500_DARK = '--theme-color-state-500-dark',
  THEME_COLOR_STATE_700 = '--theme-color-state-700',
  THEME_COLOR_STATE_900_ULTRA_LIGHT = '--theme-color-state-900-ultra-light',
  THEME_COLOR_STATE_900_LIGHT = '--theme-color-state-900-light',
  THEME_COLOR_STATE_900 = '--theme-color-state-900',
  THEME_COLOR_STATE_900_DARK = '--theme-color-state-900-dark',

  THEME_COLOR_NEUTRAL = '--theme-color-neutral',
  THEME_COLOR_NEUTRAL_ALPHA_50 = '--theme-color-neutral-alpha-50'
}

enum ThemePropertyGroups {
  BACKGROUND = 'background',
  ACCENT = 'accent',
  TEXT = 'text',
  STATE = 'state',
  OTHER = 'other'
}

export interface ThemePropertyConfig {
  group: ThemePropertyGroups;
  isWhitelabelColor: boolean;
}

export const themePropertyConfig: Record<
  ThemePropertyKeys,
  ThemePropertyConfig
> = {
  [ThemePropertyKeys.THEME_COLOR_APP_BACKGROUND]: {
    group: ThemePropertyGroups.BACKGROUND,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_CARD_BACKGROUND]: {
    group: ThemePropertyGroups.BACKGROUND,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_MENU_BACKGROUND]: {
    group: ThemePropertyGroups.BACKGROUND,
    isWhitelabelColor: false
  },

  [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_BW_CONTRAST]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_LIGHT]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_LIGHT_BW_CONTRAST]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_DARK]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_PRIMARY_ACCENT_DARK_BW_CONTRAST]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },

  [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_BW_CONTRAST]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHT]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHT_BW_CONTRAST]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHTER]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_LIGHTER_BW_CONTRAST]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_DARK]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_SECONDARY_ACCENT_DARK_BW_CONTRAST]: {
    group: ThemePropertyGroups.ACCENT,
    isWhitelabelColor: true
  },

  [ThemePropertyKeys.THEME_COLOR_PRIMARY_TEXT]: {
    group: ThemePropertyGroups.TEXT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_SECONDARY_TEXT]: {
    group: ThemePropertyGroups.TEXT,
    isWhitelabelColor: true
  },
  [ThemePropertyKeys.THEME_COLOR_BUTTON_TEXT]: {
    group: ThemePropertyGroups.TEXT,
    isWhitelabelColor: true
  },

  [ThemePropertyKeys.THEME_COLOR_STATE_100]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_100_ULTRA_LIGHT]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_100_LIGHT]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_100_DARK]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_200]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_200_ULTRA_LIGHT]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_200_LIGHT]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_200_DARK]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_300]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_500]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_500_ULTRA_LIGHT]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_500_LIGHT]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_500_DARK]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_700]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_900]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_900_ULTRA_LIGHT]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_900_LIGHT]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_STATE_900_DARK]: {
    group: ThemePropertyGroups.STATE,
    isWhitelabelColor: false
  },

  [ThemePropertyKeys.THEME_COLOR_NEUTRAL]: {
    group: ThemePropertyGroups.OTHER,
    isWhitelabelColor: false
  },
  [ThemePropertyKeys.THEME_COLOR_NEUTRAL_ALPHA_50]: {
    group: ThemePropertyGroups.OTHER,
    isWhitelabelColor: false
  }
};

export type ThemeProperties = Record<ThemePropertyKeys, string>;

export interface Theme {
  name?: string;
  logo?: Attachment;
  iTPLogo?: Attachment;
  favicon?: Attachment;
  faviconBig?: Attachment;
  alternativeUrl?: string;
  properties?: ThemeProperties;
  pageTitle?: string;
}

export interface ThemeOptions {
  themes?: Theme[];
  active?: string;
}
