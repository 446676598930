<div class="banner__container">
  <div class="banner__message-container d-flex">
    <span class="banner__message d-flex" *ngIf="!emailResent && !emailVerified"
      >{{ 'user.verify_email_message' | translate }}&nbsp;</span
    >
    <span
      class="banner__resend-link d-flex"
      *ngIf="!emailResent && !emailVerified"
      (click)="onResend()"
      >{{ 'user.resend_verification_email_a' | translate }}</span
    >
    <span class="banner__message d-flex" *ngIf="emailResent && !emailVerified"
      >{{ 'user.confirm_email_message' | translate }}</span
    >
    <span class="banner__message d-flex" *ngIf="emailVerified"
      >{{ 'user.email_verified_message' | translate }}</span
    >
  </div>
</div>
