<div class="calendar">
  <div class="calendar__header">
    <span class="calendar__month-name"
      >{{ getCurrentMonthName() }} {{ getCurrentYear() }}</span
    >
    <div class="calendar__navigation">
      <i
        class="icon icon--chevron-left"
        (click)="onPreviousMonth()"
        [ngClass]="{ 'disabled': disablePreviousMonth }"
      ></i>
      <i
        class="icon icon--chevron-right"
        (click)="onNextMonth()"
        [ngClass]="{ 'disabled': disableNextMonth }"
      ></i>
    </div>
  </div>
  <div class="calendar__content">
    <div class="calendar__week-days-row">
      <span
        class="calendar__week-day"
        *ngFor="let dayLetter of daysLetters; let i = index"
        [ngClass]="{ 'weekend': (i >= 5) }"
      >
        {{ dayLetter }}</span
      >
    </div>
    <div class="calendar__days-table">
      <div
        class="calendar__days-row"
        *ngFor="let row of daysRows; let rowIndex = index"
        [ngClass]="{ 'highlight': highlightRow(row) }"
      >
        <span
          class="calendar__day"
          *ngFor="let day of row; let i = index"
          [ngClass]="{ 'previous-month': day.isPreviousMonth, 'next-month': day.isNextMonth, 'selected': day.selected, 'today': day.isToday }"
          (click)="onSelect(day)"
        >
          {{ day.day }}
          <span
            class="calendar__day-mark"
            *ngIf="day.marked && !day.selected"
            [ngClass]="{ 'before-today': day.isBeforeToday }"
          ></span>
        </span>
      </div>
    </div>
  </div>
</div>
