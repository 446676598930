import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';

import { PropertySearcher } from '@ui/shared/models';
import { ActionState } from 'libs/state-utils';

@Component({
  selector: 'app-chat-create-modal',
  templateUrl: './chat-create-modal.component.html',
  styleUrls: ['./chat-create-modal.component.scss']
})
export class ChatCreateModalComponent {
  @Input() userData: PropertySearcher;
  @Input() availableForNewConversation: Observable<PropertySearcher[]>;
  @Input() availableForNewConversationActionState: Observable<ActionState>;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public close(application: PropertySearcher) {
    this.ngbActiveModal.close(application);
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
