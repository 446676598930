import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';

import moment from 'moment';

import { DateTimeService } from 'libs/services';

export interface CalendarDate {
  day: number;
  selected: boolean;
  marked: boolean;
  isToday: boolean;
  isBeforeToday: boolean;
  isPreviousMonth: boolean;
  isNextMonth: boolean;
  date: moment.Moment;
}

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements OnInit, OnChanges {
  @Input() markedDays: string[] = [];
  @Input() initialDate: Date;
  @Input() updateBy: Date;
  @Input() selectOnMonthChange = false;
  @Input() disablePreviousMonth: boolean;
  @Input() disableNextMonth: boolean;

  @Output() selectDate = new EventEmitter<Date>();
  @Output() previousMonth = new EventEmitter<Date>();
  @Output() nextMonth = new EventEmitter<Date>();

  daysLetters: string[] = [];

  daysRows: CalendarDate[][];

  private date: moment.Moment;
  private currentMonth: moment.Moment;

  constructor(private dateTimeService: DateTimeService) {}

  ngOnInit() {
    this.daysLetters = this.dateTimeService.getDaysFirstLetters();
    this.date = this.initialDate ? moment(this.initialDate) : moment();
    this.currentMonth = moment(this.date).startOf('M');
    this.generate();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.updateBy && changes.updateBy.currentValue) {
      this.date = moment(this.updateBy);
      this.currentMonth = moment(this.date).startOf('M');
    }

    this.generate();
  }

  private generate() {
    this.daysRows = [];

    const days = [];
    const today = moment();
    const startOfMonth = moment(this.currentMonth).startOf('M');
    const endOfMonth = moment(this.currentMonth).endOf('M');

    const firstWeekDay = startOfMonth.day();
    const lastWeekDay = endOfMonth.day();

    const startOffset = (6 + firstWeekDay) % 7;
    const endOffset = (7 - lastWeekDay) % 7;

    for (let i = 0 - startOffset; i <= endOfMonth.date() + endOffset; i += 1) {
      const current =
        i < 0
          ? moment(startOfMonth).subtract(i * -1, 'd')
          : moment(startOfMonth).add(i, 'd');

      days.push({
        day: current.date(),
        selected: current.isSame(this.date, 'd'),
        marked: this.isMarked(current),
        isToday: current.isSame(today, 'd'),
        isBeforeToday: current.isBefore(today, 'd'),
        isPreviousMonth: i < 0,
        isNextMonth: i >= endOfMonth.date(),
        date: current
      });
    }

    for (let i = 0; i <= Math.round(days.length / 7) - 1; i += 1) {
      this.daysRows[i] = [];

      for (let j = 0; j <= 6; j += 1) {
        this.daysRows[i].push(days[j + 7 * i]);
      }
    }
  }

  private isMarked(date: moment.Moment) {
    return (
      this.markedDays.length &&
      this.markedDays.some(markedDay => date.isSame(markedDay, 'd'))
    );
  }

  onNextMonth() {
    if (this.disableNextMonth) return;

    this.currentMonth = moment(this.currentMonth).add(1, 'M');
    this.nextMonth.emit(this.currentMonth.toDate());

    if (this.selectOnMonthChange) {
      this.date = moment(this.date).add(1, 'M');
      this.selectDate.emit(this.date.toDate());
    }

    this.generate();
  }

  onPreviousMonth() {
    if (this.disablePreviousMonth) return;

    this.currentMonth = moment(this.currentMonth).subtract(1, 'M');
    this.previousMonth.emit(this.currentMonth.toDate());

    if (this.selectOnMonthChange) {
      this.date = moment(this.date).subtract(1, 'M');
      this.selectDate.emit(this.date.toDate());
    }

    this.generate();
  }

  onSelect(day: CalendarDate) {
    this.date = moment(day.date);
    this.currentMonth = moment(this.date).startOf('M');

    this.selectDate.emit(this.date.toDate());
    this.generate();
  }

  highlightRow(row: CalendarDate[]) {
    return this.date ? row.some(day => this.date.isSame(day.date, 'd')) : false;
  }

  getCurrentMonthName() {
    return this.dateTimeService.getMonthName(this.currentMonth.month());
  }

  getCurrentYear() {
    return this.currentMonth.year();
  }
}
