import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-page-size-selection',
  templateUrl: './page-size-selection.component.html',
  styleUrls: ['./page-size-selection.component.scss']
})
export class PageSizeSelectionComponent {
  @Input() pageSize: number;
  @Input() disabled: boolean;
  @Input() forceDisplay: boolean;
  @Input() selectableSizes: number[];

  @Output() sizeChange = new EventEmitter<number>();

  onSizeChange(pageNumber: number) {
    this.sizeChange.emit(pageNumber);
  }
}
