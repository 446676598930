import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Attachment } from '@ui/shared/models';

@Component({
  selector: 'app-chat-attachment',
  templateUrl: './chat-attachment.component.html',
  styleUrls: ['./chat-attachment.component.scss']
})
export class ChatAttachmentComponent {
  @Input() attachments: Attachment[];
  @Input() showDownload = true;
  @Input() disableDownload = false;
  @Input() blockDownload = false;
  @Input() isDocument = false;

  @Output() download = new EventEmitter<Attachment>();
  @Output() preview = new EventEmitter<Attachment>();

  public onDownload(attachment: Attachment) {
    this.download.emit(attachment);
  }

  public onPreview(attachment: Attachment) {
    this.preview.emit(attachment);
  }
}
