import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { of } from 'rxjs';
import { take } from 'rxjs/operators';

import { Attachment } from '@ui/shared/models';

import {
  AttachmentComponentHelperCtor,
  mixinAttachmentHelper
} from '../attachment-component-helper.mixin';

class EditAttachmentComponentBase {}

const _EditAttachmentComponentMixinBase: AttachmentComponentHelperCtor =
  mixinAttachmentHelper(EditAttachmentComponentBase);

const ROTATE_ANGLE = 90;

@Component({
  selector: 'app-edit-attachment',
  templateUrl: './edit-attachment.component.html',
  styleUrls: ['./edit-attachment.component.scss']
})
export class EditAttachmentComponent
  extends _EditAttachmentComponentMixinBase
  implements OnInit
{
  @Input() public attachment: Attachment;
  @Input() public removeFn: () => null;

  public imgSrc: SafeUrl | string;
  public loading = false;

  public form = new FormGroup({
    title: new FormControl('')
  });

  constructor(
    private sanitizer: DomSanitizer,
    private ngbActiveModal: NgbActiveModal,
    private http: HttpClient
  ) {
    super();
  }

  public ngOnInit() {
    this.attachment.rotate = this.attachment.rotate || 0;
    this.imgSrc = this.hasFile
      ? this.getSafeUrl(this.attachment.file)
      : this.attachment.url;

    this.form.patchValue({
      title: this.name
    });

    if (!this.hasFile) {
      this.loading = true;
      this.getBlob(this.attachment).subscribe(
        blob => {
          if (blob) {
            this.attachment.file = new File(
              [blob],
              `${this.attachment.title}.${this.attachment.extension}`
            );
          }

          this.loading = false;
        },
        err => {
          console.error(err);
          this.loading = false;
        }
      );
    }
  }

  public rotate() {
    this.attachment.rotate = this.attachment.rotate + ROTATE_ANGLE;

    if (this.attachment.rotate === 360) {
      this.attachment.rotate = 0;
    }
  }

  public remove() {
    this.removeFn();
    this.cancel();
  }

  public cancel() {
    this.ngbActiveModal.dismiss();
  }

  public save() {
    this.ngbActiveModal.close({
      attachment: this.attachment,
      formValue: this.form.value
    });
  }

  public getSafeUrl(file: Blob) {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  public getBlob(attachment: Attachment) {
    if (!attachment || !attachment.url) {
      return of(null);
    }

    /**
     * Forces browser to request given image once again,
     * instead of getting it from it's cache.
     */
    const refreshParam = new Date().getTime();
    const url = attachment.url;

    return this.http
      .get(`${url}?${refreshParam}`, {
        responseType: 'blob'
      })
      .pipe(take(1));
  }
}
