<app-modal>
  <div class="modal-m">
    <app-modal-content class="p15 d-block">
      <h4>{{'general.mobile_app_removal.title_l' | translate}}</h4>
      <p>{{'general.mobile_app_removal.description_l' | translate}}</p>
      <app-button class="mt5" [type]="'primary'" (clickEvent)="openStore()"
        >{{'general.mobile_app_removal.browser_url_l' | translate}}</app-button
      >
    </app-modal-content>
    <app-modal-footer *ngIf="isStillValid">
      <div class="modal-footer">
        <app-button (clickEvent)="close()"
          >{{ 'general.ok' | translate }}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal>
