<div
  ngbDropdown
  [placement]="placement"
  [autoClose]="'outside'"
  class="dropdown-multiselect__container"
  (openChange)="setShowFilters($event)"
>
  <button
    type="button"
    class="btn d-flex justify-content-between align-items-center"
    ngbDropdownToggle
    [disabled]="disabled"
  >
    <span class="dropdown-toggle__inner">
      <ng-container *ngIf="!selectedItems.length">
        {{ placeholder | translate }}
      </ng-container>
      <ng-container
        *ngIf="selectedItems.length > maxDisplayCountOfSelectedItems"
      >
        {{ itemsSelectedPlaceholder | translate: {count: selectedItems.length}
        }}
      </ng-container>
      <ng-container
        *ngIf="selectedItems.length > 0 && selectedItems.length <= maxDisplayCountOfSelectedItems"
      >
        <ng-container
          *ngFor="let selectedItem of selectedItems; index as selectedItemIndex"
        >
          <ng-container
            *ngIf="selectedItemIndex > 0 && textDelimiterForSelectedItems"
            >{{ ', ' }}</ng-container
          >
          <span
            [ngClass]="{ 'ms-1': selectedItemIndex > 0 && !textDelimiterForSelectedItems }"
          >
            <app-badge
              *ngIf="selectedItems[selectedItemIndex].badgeColor; else plainTextSelectedItem"
              [color]="selectedItems[selectedItemIndex].badgeColor"
              class="d-inline-block"
            >
              <ng-container
                *ngTemplateOutlet="plainTextSelectedItem"
              ></ng-container>
            </app-badge>

            <ng-template #plainTextSelectedItem
              >{{ selectedItems[selectedItemIndex].name | translate
              }}</ng-template
            >
          </span>
        </ng-container>
      </ng-container>
    </span>
    <div class="d-flex align-items-center">
      <i
        *ngIf="selectedItems.length || !form.pristine"
        class="icon icon--close ms-2"
        (click)="clear($event)"
      ></i>
      <i
        [ngClass]="isDropdownOpened ? 'chevron-rotated' : 'chevron-non-rotated'"
        class="icon icon--chevron-down ms-2"
      ></i>
    </div>
  </button>

  <div ngbDropdownMenu>
    <div class="dropdown__items" [formGroup]="form">
      <div
        *ngFor="let group of form.controls | appKeyValueMaintainOrder; index as i"
      >
        <ng-container
          *ngFor="let control of group.value.controls | appKeyValueMaintainOrder; index as j"
          [formGroup]="group.value"
        >
          <app-form-field>
            <div class="dropdown__item">
              <app-form-field-label>
                <app-badge
                  *ngIf="getBadgeColor(i, j); else plainTextItem"
                  [color]="getBadgeColor(i, j)"
                >
                  <ng-container
                    *ngTemplateOutlet="plainTextItem"
                  ></ng-container>
                </app-badge>

                <ng-template #plainTextItem
                  >{{getTranslationKey(i, j) | translate}}</ng-template
                >
              </app-form-field-label>
              <app-check
                class="dropdown__check"
                [formControlName]="control.key"
              ></app-check>
            </div>
          </app-form-field>
        </ng-container>

        <hr *ngIf="i < itemsLength - 1" />
      </div>
    </div>
    <div *ngIf="showApplyButton" class="dropdown__actions">
      <button class="btn btn-apply" [disabled]="!itemsLength" (click)="apply()">
        {{'APPLY_A' | translate}}
      </button>
    </div>
  </div>
</div>
