import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseControl } from '../base-control';

@UntilDestroy()
@Component({
  selector: 'app-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SlideToggleComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SlideToggleComponent)
    }
  ]
})
export class SlideToggleComponent
  extends BaseControl<boolean>
  implements OnInit
{
  @ViewChild(NgControl, { static: true }) ngControl: NgControl;

  ngOnInit() {
    this.ngControl.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
  }
}
