import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';

@Component({
  selector: 'app-application-confirmation',
  templateUrl: './application-confirmation.component.html',
  styleUrls: ['./application-confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationConfirmationComponent {
  @Input() header: string;
  @Input() description: string;
  @Input() buttonText: string;
  @Input() showButton = true;
  @Output() buttonClicked = new EventEmitter();

  public get logo(): string {
    return (
      this.themeService?.getActiveTheme?.logo?.url ||
      '/assets/images/logos/logo-immomio-main.svg'
    );
  }

  constructor(private themeService: ThemeService) {}
}
