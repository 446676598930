import { SearchProfileInfoComponent } from './search-profile-info/search-profile-info.component';
import { SearchProfileDetailsComponent } from './search-profile-details/search-profile-details.component';
import { SearchProfileAddressComponent } from './search-profile-address/search-profile-address.component';
import { SearchProfilePropertyDetailsComponent } from './search-profile-property-details/search-profile-property-details.component';
import { CityAutocompleteComponent } from './city-autocomplete/city-autocomplete.component';
import { SearchProfileLandlordDistrictsComponent } from './search-profile-landlord-districts/search-profile-landlord-districts.component';

export const searchProfileComponents = [
  SearchProfileInfoComponent,
  SearchProfileDetailsComponent,
  SearchProfileAddressComponent,
  SearchProfilePropertyDetailsComponent,
  CityAutocompleteComponent,
  SearchProfileLandlordDistrictsComponent
];
