<div class="cookie-banner__container" *ngIf="!isMobile">
  <div *ngIf="showCookieBanner" [@fadeOnEnterLeave] class="cookie-banner">
    <div class="d-flex row m0">
      <div class="cookies-banner__info col-12 col-lg-9 pl0 pe-lg-5">
        {{ 'general.cookies.info_l' | translate }}
        <a
          class="cookies-banner__link"
          (click)="dataPrivacyRedirect()"
          target="_blank"
          >{{ 'general.cookies.find_more_l' | translate }}</a
        >
      </div>

      <div
        class="cookies-banner__actions d-flex col-12 col-lg-3 p0 mt-2 mt-lg-0"
      >
        <div class="cookies-banner__button-container col-6 pl0 pr15">
          <app-button (clickEvent)="onAcceptAll()"
            >{{ 'general.cookies.accept_all_a' | translate }}</app-button
          >
        </div>
        <div class="cookies-banner__button-container col-6 pl15 pr0">
          <app-button
            [type]="'light-bordered'"
            (clickEvent)="onCustomSettings()"
            >{{ 'general.cookies.custom_settings_a' | translate }}</app-button
          >
        </div>
      </div>
    </div>
  </div>
</div>
