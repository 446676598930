import { DigitalContractSignerComponent } from './digital-contract-signer/digital-contract-signer.component';
import { QualifiedElectronicSignatureComponent } from './qualified-electronic-signature/qualified-electronic-signature.component';
import { QualifiedElectronicSignatureMethodModalComponent } from './qualified-electronic-signature/components/qualified-electronic-signature-method-modal/qualified-electronic-signature-method-modal.component';
import { QualifiedElectronicSignatureConfirmTermsAndConditionsComponent } from './qualified-electronic-signature/components/qualified-electronic-signature-confirm-terms-and-conditions/qualified-electronic-signature-confirm-terms-and-conditions.component';
import { MobileIdTutorialComponent } from './mobile-id-tutorial/mobile-id-tutorial.component';

export const digitalContractComponents = [
  DigitalContractSignerComponent,
  QualifiedElectronicSignatureComponent,
  QualifiedElectronicSignatureMethodModalComponent,
  QualifiedElectronicSignatureConfirmTermsAndConditionsComponent,
  MobileIdTutorialComponent
];
