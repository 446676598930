import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';

import { map } from 'rxjs/operators';
import * as fromBaseState from '../infrastructure/base-state';

@Injectable()
export class ConstantsTypeTranslatorService {
  constructor(
    private translate: TranslateService,
    private store: Store<fromBaseState.AppState>
  ) {}

  public getTranslation(value: string, constantType: string) {
    return this.store.select(fromBaseState.getConstants).pipe(
      map(constants => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return (
          (value &&
            constants?.[constantType].find(
              constantsValue => constantsValue.value === value
            ).name) ||
          this.translate.instant('NOT_AVAILABLE_L')
        );
      })
    );
  }
}
