<div class="list-controls d-flex flex-md-row flex-column col-12 p0">
  <app-pagination
    [pageSize]="pageSize"
    [collectionSize]="totalCount"
    [page]="page"
    (pageChange)="onPageChange($event)"
  >
  </app-pagination>
  <div
    class="size-selection ms-md-auto"
    *ngIf="totalCount > 10 && !hidePageSizeSelection"
  >
    <app-page-size-selection
      [pageSize]="pageSize"
      [selectableSizes]="selectableSizes"
      (sizeChange)="onSizeChange($event)"
    >
    </app-page-size-selection>
  </div>
</div>
