import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Host,
  Injector,
  Input,
  Optional,
  Output,
  SkipSelf
} from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';

import * as fromTypeGuards from 'libs/utils/type-guards';

import { AvailableLanguageCodesEnum, IconTypeEnum } from '@ui/shared/models';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from 'libs/utils';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';
import { SelectOption } from '../select/select-option/select-option';

@Component({
  selector: 'app-flat-select',
  templateUrl: './flat-select.component.html',
  styleUrls: ['./flat-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlatSelectComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => FlatSelectComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatSelectComponent extends BaseControl<string | string[]> {
  @Input() icon: IconTypeEnum;
  @Input() itemLabelKey = 'name';
  @Input() itemValueKey = 'value';
  @Input() multiple = false;
  @Input() readonly = false;
  @Input() showAddButton = false;
  @Input() addButtonText = 'ADD_NEW_A';
  @Input() allowUncheck = false;
  @Input() deletable = false;
  @Input() currentLanguage: AvailableLanguageCodesEnum;
  @Input() defaultLanguage: AvailableLanguageCodesEnum;
  @Input() stretch = true;
  @Input() wrapItems = false;

  @Output() remove: EventEmitter<any> = new EventEmitter<any>();
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onAdd: EventEmitter<any> = new EventEmitter<any>();

  private _touched = false;
  private _errors = null;

  get errors() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._errors;
  }
  set errors(errors: any) {
    this._errors = errors;
  }

  get touched() {
    return this._touched;
  }
  set touched(value: boolean) {
    this._touched = value;
  }

  options: SelectOption[];

  @Input()
  set items(items: any[]) {
    this.options = items?.length
      ? items
          .filter(item => !item.hide)
          .map(
            (item: any) =>
              new SelectOption(item, this.itemLabelKey, this.itemValueKey)
          )
      : [];
  }

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    protected injector: Injector,
    private cd: ChangeDetectorRef
  ) {
    super(injector);
  }

  public writeValue(value: string | string[]) {
    super.writeValue(value);
    this.cd.detectChanges();
  }

  selectOption(option: SelectOption) {
    if (option.disabled || this.readonly) return;
    if (this.multiple && !Array.isArray(this.value)) {
      throw new Error('"value" must be an array');
    }

    if (this.multiple && Array.isArray(this.value)) {
      const foundOption = this.value.find(element => element === option.value);

      this.value = foundOption
        ? this.value.filter(element => element !== option.value)
        : [...this.value, option.value];

      option.selected = !foundOption;
      this.touched = true;
    } else {
      if (
        fromTypeGuards.isObject(option.value) &&
        fromTypeGuards.isObject(this.value)
      ) {
        if (
          ((option.value as any) || {}).id === ((this.value as any) || {}).id &&
          !this.allowUncheck
        )
          return;
      }
      if (this.value === option.value && !this.allowUncheck) return;

      this.touched = true;

      option.selected = this.value !== option.value;
      this.value = option.selected ? option.value : '';
    }
  }

  isActive(option: SelectOption) {
    if (
      fromTypeGuards.isObject(option.value) &&
      fromTypeGuards.isObject(this.value)
    ) {
      return (
        ((option.value as any) || {}).id === ((this.value as any) || {}).id
      );
    }
    return (
      option.value === this.value ||
      (this.multiple && this.value && this.value.indexOf(option.value) !== -1)
    );
  }

  add() {
    this.onAdd.emit();
  }

  public onRemove(index: number) {
    this.remove.emit(this.options[index].value);
  }

  public returnValidLabel(option: SelectOption): string {
    if (
      option.label[this.currentLanguage] ||
      option.label[this.currentLanguage] === '' ||
      option.label[this.defaultLanguage] ||
      option.label[this.defaultLanguage] === ''
    ) {
      const languageAdjustedLabel: string =
        option.label[this.currentLanguage] ||
        option.label[this.defaultLanguage];
      return languageAdjustedLabel;
    } else {
      return option.label;
    }
  }

  public getIconClassName(iconType: IconTypeEnum): string {
    return buildBEMClassNamesByGivenBaseClassAndFlags('icon', {
      [iconType]: !!iconType
    });
  }
}
