<div
  class="d-flex align-items-center justify-content-between flex-wrap custom-question__details"
>
  <div *ngIf="isSelect" class="d-flex custom-question__options">
    <div class="mr6" *ngIf="isSingleSelect || isRanged">
      {{ 'custom_questions.single_answer_l' | translate }}:
    </div>
    <div class="mr6" *ngIf="isMultiSelect">
      {{ 'custom_questions.multiple_answer_l' | translate }}:
    </div>
    <div *ngIf="customQuestion?.options.length === 2">
      {{ customQuestion?.options[0].name }} / {{ customQuestion?.options[1].name
      }}
    </div>
    <div *ngIf="customQuestion?.options.length > 2">
      {{ customQuestion?.options.length }} {{ 'custom_questions.options_l' |
      translate }}
    </div>
  </div>
  <div *ngIf="isBoolean" class="d-flex custom-question__options">
    <div>
      {{ 'general.yes_l' | translate }} / {{ 'general.no_l' | translate }}
    </div>
  </div>

  <div
    class="d-flex custom-question__preferred"
    *ngIf="desiredAnswers.length === 1"
  >
    <div class="mr6">
      {{ 'custom_questions.preferred_answer_l' | translate }}:
    </div>
    <div *ngIf="isSelect">{{ desiredAnswers[0] }}</div>
    <div *ngIf="isBoolean">
      {{ (desiredAnswers[0] ? 'general.yes_l' : 'general.no_l') | translate }}
    </div>
  </div>
  <div
    class="d-flex custom-question__preferred"
    *ngIf="desiredAnswers.length > 1"
  >
    <div class="mr6">
      {{ 'custom_questions.preferred_answers_l' | translate }}:
    </div>
    <div>
      {{ desiredAnswers.length }} {{ 'custom_questions.answers_l' | translate }}
    </div>
  </div>
  <div class="d-flex custom-question__preferred" *ngIf="customQuestion?.global">
    <div class="mr6">{{ 'custom_questions.knockout_l' | translate }}:</div>
    <div>{{ customQuestion?.knockout | appHumanizeBoolean }}</div>
  </div>
</div>
