import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';

import { NG_VALUE_ACCESSOR, FormBuilder, FormGroup } from '@angular/forms';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

import { isFunction } from 'libs/utils/type-guards';

import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';

type Item = any;

@Component({
  selector: 'app-dropdown-multiselect',
  templateUrl: './dropdown-multiselect.component.html',
  styleUrls: ['./dropdown-multiselect.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownMultiselectComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => DropdownMultiselectComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownMultiselectComponent
  extends BaseControl<any[]>
  implements OnChanges
{
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  @Input() itemValueKey = 'value';
  @Input() items: Item;
  @Input() draggable: boolean;
  @Input() emptyPlaceholder: string;
  @Input() showToggleAll: boolean;
  @Input() showBadges: boolean;
  @Input() predicate = false;
  @Input() showAndOrFilter: boolean;
  @Input() numberOfBadgesToShow = 10;
  @Input() relativelyPositioned = false;
  @Input() showApplyButton = true;
  @Output() selectionChange = new EventEmitter<Item[]>();
  @Output() predicateChange = new EventEmitter<boolean>();
  public selectedItems: Item[] = [];
  public dropdownForm: FormGroup;
  public toggle = false;
  private _touched = false;
  private _errors = null;

  get errors() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._errors;
  }

  set errors(errors: any) {
    this._errors = errors;
  }

  get touched() {
    return this._touched;
  }

  set touched(value: boolean) {
    this._touched = value;
  }

  public isDropdownOpened = false;

  constructor(
    private fb: FormBuilder,
    protected injector: Injector
  ) {
    super(injector);
  }

  writeValue(value: any[]): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    const valueToWrite =
      typeof value === 'string'
        ? value
        : // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          value?.map(v => (typeof v === 'string' ? v : v?.id));
    super.writeValue(valueToWrite);
    const values = this.getFormGroupValues(item => this.isSelected(item));
    this.dropdownForm.patchValue(values);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.selectedItems = this.items.filter(item => this.isSelected(item));
    this.cdr.detectChanges();
  }

  ngOnChanges(): void {
    this.dropdownForm = this.fb.group(
      this.getFormGroupValues(
        this.dropdownForm ? item => this.isSelected(item) : false
      )
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.selectedItems = this.items.filter(item => this.isSelected(item));
  }

  onOptionClick(index: number): void {
    const key = this.items[index][this.itemValueKey];

    this.dropdownForm.patchValue({
      [key]: !this.dropdownForm.value[key]
    });

    if (!this.showApplyButton) this.applyValues();
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(
      event.container.data,
      event.previousIndex,
      event.currentIndex
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    this.dropdownForm = this.fb.group(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      this.getFormGroupValues(item => this.isSelectedDragAndDrop(item))
    );
  }

  clear(event?: Event): void {
    // When you don't have this, then the dropdown would be opened/closed
    // It stops the parent element from also receiving the click event
    if (event) event.stopPropagation();
    this.toggle = false;
    this.predicate = false;
    this.dropdownForm.patchValue(this.getFormGroupValues(false));

    // If the value is already the default value, don't reset it
    // That would cause another request being made.
    if (this.selectedItems.length) this.applyValues();
  }

  apply(): void {
    this.dropdown.close();
    this.applyValues();
  }

  applyValues(): void {
    this.value = Object.keys(this.dropdownForm.value).filter(
      key => this.dropdownForm.value[key]
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.selectedItems = this.items.filter(option => this.isSelected(option));
    this.predicateChange.emit(this.predicate);
    this.selectionChange.emit(this.selectedItems);
    this.cdr.detectChanges(); // fixes an issue where labels are not updated on manual reset
  }

  toggleAll(value: boolean) {
    this.toggle = value;
    this.dropdownForm.patchValue(this.getFormGroupValues(value));
  }

  onPredicateChange(value: boolean) {
    this.predicate = value;
    if (!this.showApplyButton) this.applyValues();
  }

  removeItem(event: MouseEvent, item: any) {
    this.dropdownForm.get(item[this.itemValueKey]).patchValue(false);
    this.value = Object.keys(this.dropdownForm.value).filter(
      key => this.dropdownForm.value[key]
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.selectedItems = this.items.filter(option => this.isSelected(option));
    event.stopPropagation();
  }

  removeAllItems(event: MouseEvent): void {
    this.clear();
    this.apply();
    event.stopPropagation();
  }

  private getFormGroupValues(value: ((item) => boolean) | boolean) {
    const groupValues = {};
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.items.forEach(option => {
      groupValues[option[this.itemValueKey]] = isFunction(value)
        ? value(option)
        : value;
    });

    return groupValues;
  }

  private isSelected(option: any) {
    if (!this.value) return false;

    const values = Array.isArray(this.value) ? this.value : [this.value];

    return values.findIndex(value => value === option[this.itemValueKey]) > -1;
  }

  private isSelectedDragAndDrop(option: any) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.dropdownForm.value[option.value];
  }

  public setShowFilters(event: boolean) {
    this.isDropdownOpened = event;
  }
}
