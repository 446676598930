<app-context-menu
  *ngIf="!selectNextLanguageOnClick; else button"
  [allowSideNavForMobileView]="false"
>
  <div menu-button>
    <ng-container *ngTemplateOutlet="button"></ng-container>
  </div>
  <div menu-content>
    <app-context-menu-item
      *ngFor="let lang of availableLanguages"
      [active]="selectedLanguage.code === lang.code"
      [iconRight]="selectedLanguage.code === lang.code ? 'check' : null"
      (clickEvent)="onLanguageChange(lang.code)"
    >
      <div class="d-flex align-items-center">
        {{ lang.nativeLabel | translate }}
      </div>
    </app-context-menu-item>
  </div>
</app-context-menu>

<ng-template #button>
  <app-button
    *ngIf="!customButton.children.length"
    [ghost]="ghostButton"
    [type]="'context-menu'"
    [iconRight]="!selectNextLanguageOnClick ? 'arrow-dropdown' : 'check'"
    [elevation]="1"
    (clickEvent)="onSelectNextLanguage(value)"
  >
    <div class="d-flex align-items-center" *ngIf="selectedLanguage">
      <app-locale-flag
        class="lang-picker__flag flex-shrink-0"
        [languageCode]="selectedLanguage.code"
      ></app-locale-flag>

      {{ showFullLanguageNames ? (selectedLanguage.nativeLabel | translate):
      (selectedLanguage.code | uppercase )}}
    </div>
  </app-button>

  <div #customButton (click)="onSelectNextLanguage(value)">
    <ng-content select="[custom-button]"></ng-content>
  </div>
</ng-template>
