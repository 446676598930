import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Attachment } from '@ui/shared/models';

@Component({
  selector: 'app-navigation-user-profile',
  templateUrl: './navigation-user-profile.component.html',
  styleUrls: ['./navigation-user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationUserProfileComponent {
  @Input() name: string;
  @Input() email: string;
  @Input() portrait: Attachment;
  @Input() dark: boolean;
}
