<div [ngClass]="[baseClass, 'row']">
  <div
    *ngIf="showOfferedBy"
    [ngClass]="[showInCooperationWithImmomio ? 'col-6' : 'col-7']"
  >
    <div [class]="baseClass + '__offered-by-title'">
      {{ (offeredByTitle || 'property.offered_by_l') | translate }}
    </div>
    <div [ngClass]="[baseClass + '__offered-by-name', 'semibold']">
      {{ name || ('general.private_landlord_l' | translate) }}
    </div>
  </div>
  <div
    [ngClass]="[baseClass + '__logo-wrapper', showInCooperationWithImmomio ? 'col-6' : 'col-5']"
  >
    <a
      *ngIf="withLinkedLogo; else customerLogoImage"
      [class]="baseClass + '__logo-link'"
      href="{{ logoRedirectUrl }}"
      target="_blank"
    >
      <ng-container *ngTemplateOutlet="customerLogoImage"></ng-container>
    </a>
    <ng-template #customerLogoImage>
      <app-image
        [class]="baseClass + '__logo-image'"
        [defaultSrc]="customerLogo"
        [alt]="'Logo' + (name ? ' ' + name : '')"
        maxHeightInPx="48"
      ></app-image>
    </ng-template>
    <div
      [ngClass]="[baseClass + '__in-cooperation-with', 'mt-2', 'tiny']"
      *ngIf="showInCooperationWith && showInCooperationWithImmomio"
    >
      <span>{{ 'general.in_cooperation_with' | translate }}</span>
      <app-image
        [ngClass]="[baseClass + '__immomio-logo', 'ms-1']"
        defaultSrc="/assets/images/logos/logo-immomio-main.svg"
        alt="{{ 'general.immomio_logo_alt' | translate }}"
      ></app-image>
    </div>
  </div>
</div>
