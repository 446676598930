<label
  [attr.for]="getId"
  class="radio-button__container m0"
  (click)="check($event)"
  [ngClass]="{
    'checked': isChecked,
    'disabled': isDisabled
    }"
>
  <ng-content select=".theme-card__body"></ng-content>
  <div
    [class.flex-row-reverse]="switchLabelCheck"
    class="radio-button__button-area d-flex align-items-center m0"
  >
    <input
      #input
      class="radio-button__input"
      type="radio"
      [id]="getId"
      [checked]="isChecked"
      [disabled]="isDisabled"
      [required]="required"
    />
    <div class="radio-button__button-container left col p0">
      <div class="radio-button__button">
        <div class="radio-button__inner-circle"></div>
      </div>
    </div>
    <div class="radio-button__label right d-flex col-11 p0">
      <ng-content></ng-content>
    </div>
  </div>
</label>
