import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NameValue } from '@ui/shared/models';

@Component({
  selector: 'app-confirm-reason-modal',
  templateUrl: './confirm-reason-modal.component.html',
  styleUrls: ['./confirm-reason-modal.component.scss']
})
export class ConfirmReasonModalComponent {
  @Input() reasons: NameValue[];
  @Input() message: string;
  @Input() titleMessage: string;
  @Input() okButtonMessage = 'general.ok';
  @Input() cancelButtonMessage = 'general.cancel_a';
  @Input() showV2 = true;

  public reasonTypeModel: string;
  public otherReasonText: string;

  public get isInvalid() {
    return (
      !this.reasonTypeModel ||
      (this.reasonTypeModel === 'OTHER_REASON' && !this.otherReasonText)
    );
  }

  public get showText() {
    return this.reasonTypeModel === 'OTHER_REASON';
  }

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public cancelAction() {
    this.ngbActiveModal.dismiss();
  }

  public okAction() {
    this.ngbActiveModal.close({
      reasonType: this.reasonTypeModel,
      otherReasonText: this.otherReasonText
    });
  }
}
