import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoContentComponent {
  @Input() headline: string;
  @Input() message: string;
  @Input() hideImage: boolean;
  @Input() transparent: boolean;
  @Input() withoutVerticalSpacing: false;
}
