import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appEllipsis' })
export class EllipsisPipe implements PipeTransform {
  transform(value: string, limit = 25, trail = '...') {
    if (!value) {
      return '';
    }

    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}
