import { Component, EventEmitter, Input, Output } from '@angular/core';

import { LandlordUser, PropertySearcherUser } from '@ui/shared/models';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() user: LandlordUser | PropertySearcherUser;
  @Input() navigationOpen: boolean;
  @Input() profileSettingsOpen: boolean;
  @Output() navigationToggleClick = new EventEmitter<Event>();
  @Output() profileSettingsToggleClick = new EventEmitter<Event>();

  public onNavigationToggleClick(event: Event) {
    this.navigationToggleClick.emit(event);
  }

  public onProfileSettingsToggleClick(event: Event) {
    this.profileSettingsToggleClick.emit(event);
  }
}
