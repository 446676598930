<div
  [ngClass]="{'atomic-tooltip':true, 'atomic-tooltip__right':outHorizontalBounds}"
  [ngStyle]="hostPaddingExists && {'top': hostPadding}"
  *ngIf="isVisible === true"
>
  <div [ngClass]="{'atomic-tooltip__flex-right':outHorizontalBounds}">
    <div *ngIf="!outVerticalBounds" class="atomic-tooltip__arrow-up"></div>
  </div>

  <div class="atomic-tooltip__content">
    <div
      class="atomic-tooltip__icon icon icon--close"
      (click)="onClose()"
    ></div>
    <div class="atomic-tooltip__tour-headline title-xs">
      {{tooltipTour?.title | translate}}
    </div>
    <div class="atomic-tooltip__headline title-m">
      {{tooltip?.title | translate}}
    </div>
    <div class="atomic-tooltip__text">{{tooltip?.description | translate}}</div>
    <div class="atomic-tooltip__buttons">
      <app-button
        *ngIf="showInfoButton"
        class="atomic-tooltip__button"
        type="empty-light"
        >info
      </app-button>
      <app-button
        class="atomic-tooltip__button"
        type="empty-light"
        (clickEvent)="onContinue()"
        >{{ (tooltipIsLastInTour ? 'tooltip.end_tour_a' : 'tooltip.continue_a')
        | translate}}</app-button
      >
    </div>
    <div class="atomic-tooltip__dots" *ngIf="tooltipTourLength > 1">
      <ng-container
        *ngFor="let tooltip of tooltipTour?.tourElements; index as i"
      >
        <div
          [ngClass]="{'atomic-tooltip__dot': true, 'atomic-tooltip__dot--selected': i === tooltipTourCurrentPosition}"
        ></div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="outVerticalBounds" class="atomic-tooltip__arrow-down"></div>
</div>
