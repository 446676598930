import {
  ApplicationRef,
  ComponentRef,
  Inject,
  Injectable,
  NgZone,
  ViewContainerRef
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import { InfrastructureConfig, LocalStorageService } from 'libs/infrastructure';
import { AdBlockInfoComponent } from 'libs/components/legacy/ad-block-info/ad-block-info.component';
import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import { Toast } from 'libs/components/legacy/toast';

@Injectable()
export class AdblockDetectorService {
  private _blockingAds: boolean;
  private toastContainer: ComponentRef<AdBlockInfoComponent>;
  private viewContainerRef: ViewContainerRef;
  private toastIndex = 0;

  constructor(
    private applicationRef: ApplicationRef,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    @Inject(DOCUMENT) private doc: HTMLDocument,
    @Inject(INFRASTRUCTURE_CONFIG) private config: InfrastructureConfig
  ) {
    this.init();
  }

  public init() {
    this._blockingAds = false;
  }

  public get blockingAds() {
    return this._blockingAds;
  }

  public checkForAdsJs(viewContainerRef: ViewContainerRef) {
    const alreadyShowed = this.localStorageService.getItem(
      this.config.storageKeys.adBlockerInfo
    );
    if (this.doc.getElementById('SW1tb21pb0FkRGV0ZWN0b3I=')) {
      this._blockingAds = false;
    } else if (!alreadyShowed) {
      this.viewContainerRef = viewContainerRef;
      this._blockingAds = true;
      this.localStorageService.setItem(
        this.config.storageKeys.adBlockerInfo,
        true
      );

      this.toastContainer =
        this.viewContainerRef.createComponent(AdBlockInfoComponent);
      const toast = new Toast(
        'info',
        this.translateService.instant('general.ad_block_detected'),
        {
          duration: 10000
        }
      );

      this.toastContainer.instance.onEmpty = () => this.destroyToastContainer();
      this.toastContainer.instance.onToastClick = (t: Toast) =>
        this.onToastClick(t);

      this.viewContainerRef.insert(this.toastContainer.hostView);

      return this.addToast(toast);
    }
  }

  private addToast(toast: Toast) {
    let timeoutId;
    toast.id = ++this.toastIndex;

    this.ngZone.runOutsideAngular(
      () =>
        (timeoutId = setTimeout(
          () => this.ngZone.run(() => this.clearToast(toast)),
          toast.duration
        ))
    );

    toast.timeoutId = timeoutId;
    this.toastContainer.instance.addToast(toast);

    return toast;
  }

  private clearToast(toast: Toast) {
    if (this.toastContainer) {
      clearTimeout(toast.timeoutId);
      this.toastContainer.instance.removeToast(toast);
    }
  }

  private destroyToastContainer() {
    if (this.toastContainer) {
      this.toastContainer.destroy();
      this.toastContainer = null;
    }
  }

  private onToastClick(toast: Toast) {
    if (toast.dismissOnClick) {
      this.clearToast(toast);
    }
  }
}
