import {
  AfterViewInit,
  Component,
  ElementRef,
  forwardRef,
  Injector,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import intlTelInput from 'intl-tel-input';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  NgControl
} from '@angular/forms';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { BaseControl } from 'libs/components/legacy/form';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

@UntilDestroy()
@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneInputComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => PhoneInputComponent)
    }
  ]
})
export class PhoneInputComponent
  extends BaseControl<string>
  implements OnInit, AfterViewInit, ControlValueAccessor
{
  @Input()
  preferredCountries = ['de', 'at', 'ch', 'nl', 'fr', 'pl', 'es', 'it', 'uk'];

  @Input()
  initialCountry = 'de';
  /**
   * Possibility to enter the phone number in national format
   * Input Example Germany: 0151 11223344
   * Output still E.164: +4915111223344
   */
  @Input()
  nationalMode = true;

  @ViewChild('phoneInput')
  phoneInput: ElementRef<HTMLInputElement>;

  @ViewChild(NgControl, { static: true }) ngControl: NgControl;

  private intlTelInput: intlTelInput.Plugin;

  private onChange = (value: string) => value;
  private onTouch = () => null;

  constructor(protected injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.ngControl.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();

    const { preferredCountries, initialCountry, nationalMode } = this;
    /**
     * The utilities script is a custom build of Google's libphonenumber which enables the following features:
     *
     * Formatting upon initialisation, as well as with getNumber and setNumber
     * Validation with isValidNumber, getNumberType and getValidationError methods
     * Placeholder set to an example number for the selected country - even
     * specify the type of number (e.g. mobile) using the placeholderNumberType option
     * Extract the standardised (E.164) international number with getNumber even when using the nationalMode option
     */
    const utilsScript =
      'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js';

    const options: intlTelInput.Options = {
      utilsScript,
      initialCountry,
      preferredCountries,
      nationalMode
    };

    this.intlTelInput = intlTelInput(this.phoneInput.nativeElement, options);

    this.phoneInput.nativeElement.addEventListener('countrychange', () =>
      this.onInput()
    );
  }

  onInput() {
    const noWhitespace = this.value?.replace(' ', '');
    const onlyNumbersAndPlus = noWhitespace?.replace(/[^0-9 +]/g, '');
    const only15Digits = onlyNumbersAndPlus?.substring(0, 15);
    setTimeout(() => {
      this.value = only15Digits;
    });
    const intlNumber: string = this.intlTelInput.getNumber();
    this.onChange(intlNumber);
  }

  registerOnChange(fn): void {
    this.onChange = fn;
  }

  registerOnTouched(fn): void {
    this.onTouch = fn;
  }

  writeValue(value: string): void {
    this.value = value ?? '';
    setTimeout(() => {
      this.intlTelInput.setNumber(this.value);
    });
  }

  onFocusOut(): void {
    if (this.ngControl?.control?.errors) {
      this.stateChanges.next();
    }
  }
}
