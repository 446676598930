<div
  class="list-navigation__container d-flex"
  [ngClass]="{'disabled': disabled}"
>
  <div
    *ngFor="let nav of navConfig; index as i"
    class="list-navigation__item d-flex align-items-center"
    [ngClass]="{'list-navigation__item--active': selectedNav === nav.value, 'list-navigation__item--enabled': !nav.disabled}"
    [ngbTooltip]="(nav.disabled ? nav.tooltip : null) | translate"
    (click)="navSelect(nav)"
  >
    <div class="list-navigation__item-name" [ngClass]="{'medium': medium}">
      {{ nav.label | translate}}
    </div>
    <div *ngIf="totals" class="list-navigation__item-count">
      {{ totals[i] }}
    </div>
  </div>
</div>
