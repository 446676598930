import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuid } from 'uuid';

import { isObject, isValueNotNullAndUndefined } from 'libs/utils';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { objectsEqual } from 'libs/utils';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';

@UntilDestroy()
@Component({
  selector: 'app-dropdown-select',
  templateUrl: './dropdown-select.component.html',
  styleUrls: ['./dropdown-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownSelectComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => DropdownSelectComponent)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownSelectComponent
  extends BaseControl<any>
  implements OnInit
{
  private _items: unknown[];
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ViewChild(NgControl, { static: true }) ngControl: NgControl;
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;
  @Input() itemValueKey = 'value';
  @Input() placement = 'bottom-left';

  @Input() get items(): unknown[] {
    return this._items;
  }

  set items(value) {
    this._items = value || [];
    this.setSelectedItem();
  }

  constructor(protected injector: Injector) {
    super(injector);
  }

  @Input() clearable = false;

  // useSelf should be used, when you want to have an object as value
  @Input() useSelf = false;
  @Input() showSeperator = false;
  @Input() container: 'body' | null = null;
  @Input() relativePositioned = false;
  // use `menuClass` when `container` is set to 'body'
  // and styles need to be applied to dropdown menu from outside:
  @Input() menuClass: string;
  @Input() toggleClass: string;
  @Input() isLoadingMenuItems = false;

  @Output() scrollBottom = new EventEmitter();

  public uniqueElementId: string;
  public selectedItem: any;

  public isDropdownOpened = false;

  public get isValue() {
    return (
      isValueNotNullAndUndefined(this.value) &&
      this.value !== '' &&
      !Array.isArray(this.value)
    );
  }

  ngOnInit() {
    this.uniqueElementId = `dropdown-select-${uuid()}`;
    this.ngControl.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
  }

  onItemClick(item: any) {
    this.dropdown.close();
    this.value =
      this.useSelf || !isObject(item) ? item : item[this.itemValueKey];
    this.setSelectedItem();
    this.stateChanges.next();
  }

  reset($event: Event) {
    // When you don't have this, then the dropdown would be opened/closed
    // It stops the parent element from also receiving the click event
    $event?.stopPropagation();
    this.dropdown.close();
    this.value = null;
    this.selectedItem = null;
  }

  public writeValue(value): void {
    this.value = value;
    this.setSelectedItem();
    this.stateChanges.next();
  }

  onScroll() {
    this.scrollBottom.emit();
  }

  close() {
    this.dropdown.close();
  }

  private setSelectedItem() {
    this.selectedItem = this.items?.find(item => {
      if (isObject(item)) {
        if (item[this.itemValueKey] === this.value) return true;

        // If we want to have an object as dropdown value
        if (this.useSelf && objectsEqual(item, this.value)) return true;
      }
      return item === this.value;
    });

    /* When values are updated multiple times during the form load, such as when patching a form,
       there can be an issue where a dropdown only considers the first value update, and subsequent
       updates are ignored. This can result in the label only being updated once, even though the
       underlying value is now different.
       To fix this issue, we manually trigger change detection using the ChangeDetectorRef.
       By manually detecting changes, we can ensure that the component's view reflects the
       current state of the form, even when multiple updates are made in quick succession.
    */

    this.cdr.detectChanges();
  }

  public setShowFilters(event: boolean) {
    this.isDropdownOpened = event;
  }
}
