import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from 'libs/services';

interface DatePipeOptions {
  withDate?: boolean;
  withTime?: boolean;
  withFullNameOfDay?: boolean;
  customPattern?: string;
  isCountdown?: boolean;
}

@Pipe({ name: 'appDateTime' })
export class DateTimePipe implements PipeTransform {
  constructor(private dateTimeService: DateTimeService) {}

  transform(date: Date | string | number, options: DatePipeOptions = {}) {
    if (!date) return '';
    const {
      withDate = true,
      withTime = false,
      withFullNameOfDay = false,
      customPattern = '',
      isCountdown = false
    } = options;

    let pattern: string = customPattern;
    if (!pattern) {
      const defaultPattern: string[] = [];
      if (withFullNameOfDay) defaultPattern.push('dddd');
      if (withDate) defaultPattern.push('DD.MM.YYYY');
      if (withTime) defaultPattern.push('HH:mm');
      pattern = defaultPattern.join(', ');
    }

    if (isCountdown && typeof date === 'number') {
      return this.dateTimeService.getTimeFromSeconds(date, pattern);
    }

    return this.dateTimeService.getDateInFormat(date, pattern);
  }
}
