import {
  Directive,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';

@Directive({
  selector: '[appClickDetector]'
})
export class ClickDetectorDirective {
  @Input() enabled = false;
  @Output() insideClick = new EventEmitter<void>();
  @Output() outsideClick = new EventEmitter<void>();

  constructor(private elementRef: ElementRef) {}

  @HostListener('document:click', ['$event.target'])
  public onClick(target: HTMLElement) {
    if (this.enabled) {
      const clickedInside = (
        this.elementRef.nativeElement as HTMLElement
      ).contains(target);

      if (!clickedInside) {
        this.outsideClick.emit();
      } else {
        this.insideClick.emit();
      }
    }
  }
}
