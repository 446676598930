import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { CustomQuestion } from '@ui/shared/models';

@Component({
  selector: 'app-custom-question-preview-modal',
  templateUrl: './custom-question-preview-modal.component.html',
  styleUrls: ['./custom-question-preview-modal.component.scss']
})
export class CustomQuestionPreviewModalComponent implements OnInit {
  public questionControl = new FormControl();
  public customQuestion: CustomQuestion;
  public global: boolean;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public ngOnInit() {
    if (this.customQuestion) {
      this.global = this.global ?? this.customQuestion.global;
      this.questionControl.patchValue(this.customQuestion);
    }
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
