import { ControlValueAccessor } from '@angular/forms';

export abstract class BaseValueAccessor<T> implements ControlValueAccessor {
  private _value: T;

  private changeCallbacks = new Array<(value: T) => void>();
  private touchCallbacks = new Array<() => void>();

  get value(): T {
    return this._value;
  }

  set value(value: T) {
    if (value !== undefined && this._value !== value) {
      this._value = value;
      this.changeCallbacks.forEach(fn => fn(value));
    }
  }

  touch() {
    this.touchCallbacks.forEach(fn => fn());
  }

  writeValue(value: T) {
    this._value = value;
  }

  registerOnChange(fn: (value: T) => void) {
    this.changeCallbacks.push(fn);
  }

  registerOnTouched(fn: () => void) {
    this.touchCallbacks.push(fn);
  }
}
