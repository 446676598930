import { Component, Input } from '@angular/core';
import { PropertyApplicationStatus } from '@ui/shared/models';
import { BANNER_CONFIGS } from './property-banner.config';

@Component({
  selector: 'app-property-banner',
  templateUrl: './property-banner.component.html',
  styleUrls: ['./property-banner.component.scss']
})
export class PropertyBannerComponent {
  @Input() applicantStatus: string;
  @Input() showExplanation = false;

  get bannerInfo() {
    return BANNER_CONFIGS[
      this.applicantStatus || PropertyApplicationStatus.NEW_MATCH
    ];
  }
}
