import { ModuleWithProviders, NgModule } from '@angular/core';
import { LayoutModule } from '@angular/cdk/layout';
import { DragDropModule } from '@angular/cdk/drag-drop';

import {
  NgbDropdownModule,
  NgbModule,
  NgbPopoverModule
} from '@ng-bootstrap/ng-bootstrap';

import { RouterModule } from '@angular/router';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule, KeyValuePipe } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import {
  provideHttpClient,
  withInterceptorsFromDi
} from '@angular/common/http';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { ColorPickerModule } from 'ngx-color-picker';
import { NgxEditorModule } from 'ngx-editor';
import { AngularSvgIconModule } from 'angular-svg-icon';

import {
  DateTimeService,
  GeoService,
  GOOGLE_MAPS_API_CONFIG
} from 'libs/services';
import { PipesModule } from 'libs/pipes';
import { DirectivesModule } from 'libs/directives';
import { MatSliderModule } from '@angular/material/slider';
import { IsValueSelectedInDropdownStepperPipe } from 'libs/components/atoms/multi-select-dropdown-stepper/is-value-selected.pipe';
import {
  legacyComponents,
  ModalService,
  ToastService,
  MessengerFilterService,
  MessengerNotificationService
} from './legacy';

import { atomsComponents } from './atoms';
import { moleculesComponents } from './molecules';
import { organismsComponents } from './organisms';
import { ComponentsModuleConfig } from './components.config';

const exportComponents = [
  ...legacyComponents,
  ...atomsComponents,
  ...moleculesComponents,
  ...organismsComponents
];

const declarations = [...exportComponents];

@NgModule({
  declarations,
  exports: exportComponents,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode'
    }),
    NgbPopoverModule,
    NgbDropdownModule,
    RouterModule,
    TranslateModule,
    NgbModule,
    LayoutModule,
    PipesModule,
    DirectivesModule,
    ColorPickerModule,
    NgScrollbarModule,
    AngularSvgIconModule,
    DragDropModule,
    LetDirective,
    NgxEditorModule,
    MatSliderModule,
    IsValueSelectedInDropdownStepperPipe
  ],
  providers: [
    ModalService,
    ToastService,
    MessengerFilterService,
    MessengerNotificationService,
    KeyValuePipe,
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class ComponentsModule {
  public static forRoot(
    config: ComponentsModuleConfig = {}
  ): ModuleWithProviders<ComponentsModule> {
    return {
      ngModule: ComponentsModule,
      providers: [
        {
          provide: GOOGLE_MAPS_API_CONFIG,
          useValue: config.googleApiConfig || {}
        },
        DateTimeService,
        GeoService,
        MessengerNotificationService
      ]
    };
  }
}
