<div [class]="getClassName()">
  <ol [class]="getStepListClassName(currentStatus?.pipeline?.showAsFullscreen)">
    <li
      *ngFor="let step of steps; first as isFirst; last as isLast;"
      [class]="getStepWrapperClassName(isFirst, isLast)"
      [ngbTooltip]="(step.isActive || narrowScreen) && !currentStatus?.pipeline?.showAsFullscreen ? (step.tooltip | translate) : false"
      [openDelay]="tooltipOpenDelay"
      [closeDelay]="tooltipCloseDelay"
      placement="bottom auto"
    >
      <div
        [class]="getStepItemClassName(isFirst, isLast, step.isActive, step.hasPassed)"
      >
        <div [class]="'icon icon--' + step.iconType"></div>
        <span [class]="baseClass + '__step-label'"
          >{{step.label | translate}}</span
        >
      </div>
    </li>
  </ol>
  <div
    *ngIf="currentStatus?.pipeline?.showAsFullscreen"
    [class]="getFullscreenStatusClassName(currentStatus.hide, currentStatus.isTenant)"
    [ngbTooltip]="currentStatus?.pipeline?.tooltip | translate"
    [openDelay]="tooltipOpenDelay"
    [closeDelay]="tooltipCloseDelay"
    placement="bottom auto"
  >
    <ng-container [ngSwitch]="propertyType">
      <ng-container *ngSwitchCase="'GARAGE'"
        >{{currentStatus?.pipeline?.garageLabel | translate}}</ng-container
      >
      <ng-container *ngSwitchCase="'COMMERCIAL'"
        >{{currentStatus?.pipeline?.commercialLabel | translate}}</ng-container
      >
      <ng-container *ngSwitchDefault
        >{{currentStatus?.pipeline?.label | translate}}</ng-container
      >
    </ng-container>
  </div>
</div>
