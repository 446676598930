import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'quotaTranslation' })
export class QuotaTranslationPipe implements PipeTransform {
  transform(
    amount: number,
    positive: string,
    neutral: string,
    negative: string
  ): string {
    if (amount > 0) {
      return positive;
    }

    if (amount === 0) {
      return neutral;
    }

    if (amount < 0) {
      return negative;
    }
  }
}
