import { Address } from './address.model';
import { Attachment } from './attachment.model';
import { ContactsDeletionConfig } from './branding.model';
import {
  CustomQuestionResponse,
  HierarchicalRootQuestionResponse,
  QuestionContainer
} from './custom-questions';
import { Pagination } from './data-access';
import { PropertyProposalStatus } from './enums';
import { AvailableLanguageCodesEnum } from './language.model';
import {
  PaymentCategory,
  PaymentStatus,
  SmartDepositStatus
} from './payments.model';
import { Profile } from './profile.model';
import {
  EditorBean,
  PropertySearcherHistory
} from './property-searcher-history.model';
import {
  CustomerBranding,
  DkApprovalLevel,
  PropertySearcherUserProfile
} from './property-searcher.model';
import { MarketingType } from './property.model';
import { RegistrationLinkObjectTypes } from './registration-link.model';
import { CustomerSpecificData } from './resident-attachment.model';
import { SearchPredicate } from './search-predicate.model';
import {
  LandlordDistrictGroupBean,
  SearchProfile
} from './search-profile.model';
import { SystemTag } from './system-tag.model';
import { ContactTag } from './tag.model';
import { ContactType, User } from './user.model';

export interface CustomerContact {
  id?: string;
  membershipNumber?: string;
  email?: string;
  data?: ContactProfileData;
  offlineProfileData?: PropertySearcherUserProfile;
  address?: Address;
  searchProfiles?: SearchProfile[];
  propertySearcherType?: ContactPropertySearcherType;
  type?: ContactType;
  sensitiveDataAccessible?: boolean;
  inviteToRegister?: boolean;
  verified?: boolean;
  tags?: ContactTag[];
  systemTags?: SystemTag[];
  numberOfApplications?: number;
  paymentStatuses: PaymentStatus[];
  smartDepositStatuses: SmartDepositStatus[];
  dkApprovalLevel?: DkApprovalLevel;
  ownershipType?: ContactOwnershipType;
  hasSearchProfiles?: boolean;
  residentData?: ResidentData;
  preferredLanguage?: AvailableLanguageCodesEnum;
  userProfileCount?: number;
  mergeCase?: boolean;
  rootProfileInformationId?: string;
  customerSpecificData?: CustomerSpecificData[];
}

export interface ResidentData {
  contracts: ResidentContract[];
  registrationDate?: Date;
  blocked: boolean;
  erpIdentifiers?: ErpIdentifiers;
}

interface ErpIdentifiers {
  businessPartnerNumber?: string;
  partnerNumber?: string;
  contractNumber?: string;
}

export interface ResidentContract {
  contractNumber?: number;
  administrationUnitId?: string;
  administrationUnitName?: string;
  propertyId?: string;
  propertyName?: string;
  contractStart?: Date;
  contractEnd?: string;
}

export interface LabelFilterTemplate {
  id?: string;
  name?: string;
  searchPredicate?: SearchPredicate;
  tags?: ContactTag[];
  systemTags?: SystemTag[];
}

export interface SavedLabelFilterTemplate {
  name?: string;
  searchPredicate?: SearchPredicate;
  tags?: string[];
}

export interface InternalListSearchOptions {
  filters?: InternalListFilters;
  page?: number;
  size?: number;
}

export interface Contacts {
  contacts: InternalListSearchOptions;
}

export interface ContactProfileData extends Profile {
  birthday?: Date | string;
  entryDate?: Date;
  firstname?: string;
  leavingDate?: Date;
  name?: string;
  phone?: string;
  mobile?: string;
  waitingSince?: Date;
}

export interface SendMailContactFormInput {
  contactIds?: string[];
  subject: string;
  body?: string;
  attachments?: Attachment[];
}

export interface ContactApplication {
  id?: string;
  propertyId?: string;
  externalId?: string;
  name?: string;
  applicationStatus?: string;
  address?: Address;
  titleImage?: Attachment;
  attendingViewing?: boolean;
}

export interface ContactProposals {
  nodes?: ContactProposal[];
  page?: Pagination;
}

export interface ContactSmartDepositExternalData {
  externalObjectId: string;
  externalContractId: string;
}

export interface ContactSmartDeposits {
  nodes?: ContactSmartDeposit[];
  page?: Pagination;
}

export interface ContactSmartDeposit {
  id: string;
  status: SmartDepositStatus;
  lastUpdated: Date;
  propertyAddress: Address;
  alreadyClaimedAmount: number;
  depositAmount: number;
  externalData: ContactSmartDepositExternalData;
}

export interface SmartDepositGenericInviteLink {
  url: string;
}

export interface ContactPayments {
  nodes?: ContactPayment[];
  page?: Pagination;
}

export interface ContactPayment {
  id?: string;
  status?: string;
  category?: PaymentCategory;
  iban?: string;
  value?: number;
  dueDate?: Date;
  subject?: string;
  lastReminderDate?: Date;
  name?: string;
  dueDays?: number;
  receiverUser?: User;
}

export interface ActivePayment {
  id?: string;
  category?: PaymentCategory;
  active?: boolean;
  data?: ContactPayment;
}

export interface RateAndCommentInput {
  id: string;
  rating?: number;
  comment?: string;
  isProspect?: boolean;
}

export interface ContactProposal {
  id?: string;
  name?: string;
  address?: Address;
  basePrice?: number;
  size?: number;
  totalRooms?: number;
  state?: PropertyProposalStatus;
  marketingType?: MarketingType;
  propertyId?: string;
  titleImage?: Attachment;
  purchasePrice?: number;
}

export interface ContactProposalSearchInput {
  contactId?: string;
  searchTerm?: string;
  searchInAllProperties?: boolean;
  page?: number;
}

export interface InviteContactToSmartDepositInput {
  landlordName?: string;
  landlordAddress?: Address;
  startOfContract?: string;
  propertyAddress?: Address;
  externalObjectId?: string;
  externalContractId?: string;
  depositAmount?: number;
}

export interface InviteContactsToSmartDepositInput {
  landlordName?: string;
  landlordAddress?: Address;
}

export interface SmartDepositClaim {
  claimAmount: number;
  guaranteeTerminated: boolean;
  reason: string;
  iban: string;
}

export interface SmartDepositClaimEvent {
  smartDepositId: string;
  smartDepositClaim: SmartDepositClaim;
}

export type SaveContactData = Omit<CustomerContact, 'offlineProfileData'> & {
  offlineData?: ContactOfflineData;
  token?: string;
};

export interface ContactOfflineData {
  userProfileData?: PropertySearcherUserProfile;
  customQuestionResponses?: CustomQuestionResponse[];
  questionResponses?: HierarchicalRootQuestionResponse[];
}

export interface InternalListFilterOptions {
  filters?: InternalListFilters;
  page?: number;
  size?: number;
}

export interface TagFilterInput {
  name: string;
  tags: string[];
  searchPredicate: boolean;
}

export interface InternalListFilters {
  searchTerm?: string;
  statusFilter?: string[];
  sort?: string[];
  tagFilter?: TagFilter;
  smartDepositFilter?: string[];
  paymentPositionStatusFilter?: string[];
}

export interface TagFilter {
  tags?: string[];
  searchPredicate?: SearchPredicate;
}

export interface MemberInspectMismatchValue {
  mismatched: boolean;
  value: string;
}

export interface SelfRegistrationConfig {
  landlordCustomerBranding: CustomerBranding;
  landlordCustomerContactsDeletionConfig: ContactsDeletionConfig;
  questionsContainer: QuestionContainer;
  dataPrivacyUrl: string;
  defaultObjectTypes?: RegistrationLinkObjectTypes;
  defaultDistrictGroup?: LandlordDistrictGroupBean;
}

export interface ContactTemplateData {
  name?: string;
  id?: string;
}

export interface MergedOfflineContactData {
  name?: string;
  firstName?: string;
}

export interface EmailEventData {
  subject?: string;
}

export interface ContactActivities {
  activities: ContactActivity[];
  searchAfter?: string[];
}

export interface ContactPSActivities {
  activities: PropertySearcherHistory[];
  searchAfter?: string[];
}

export interface ContactActivity {
  type: ContactActivityType;
  created: Date;
  editorBean: EditorBean;
  contactData: {
    id: string;
    /**
     * @deprecated Use {@link editorBean.name} or {@link editorBean.firstName} instead.
     * According to Mathis the creationType could probably also be removed
     * It is only used for the activities before the unified contacts migration
     */
    creationType: CreationType | null;
  };
  templateData: ContactTemplateData;
  mergedOfflineContactData: MergedOfflineContactData;
  emailEventData?: EmailEventData;
}

export interface ContactUserProfile {
  id: number;
  userProfileId: number;
  name?: string;
  firstName?: string;
  dateOfBirth?: Date;
  email?: string;
  membershipNumber?: string;
  resident: boolean;
  mainProfile: boolean;
}

export enum ContactPropertySearcherType {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE'
}

export enum ContactActivityType {
  CONTACT_CREATED = 'CONTACT_CREATED',
  PDF_PRINTED = 'PDF_PRINTED',
  MERGED_WITH_OFFLINE_CONTACT = 'MERGED_WITH_OFFLINE_CONTACT',
  CONTACT_CLEARED = 'CONTACT_CLEARED',
  CONTACT_RECREATED = 'CONTACT_RECREATED',
  PAYMENT_SENT = 'PAYMENT_SENT',
  PAYMENT_REMINDER_SENT = 'PAYMENT_REMINDER_SENT',
  PAYMENT_CANCELED = 'PAYMENT_CANCELED',
  OWNERSHIP_CONVERTED_TO_LANDLORD = 'OWNERSHIP_CONVERTED_TO_LANDLORD',
  AUTO_CONTACT_MERGE = 'AUTO_CONTACT_MERGE',
  MANUAL_CONTACT_MERGE = 'MANUAL_CONTACT_MERGE',
  USER_PROFILE_REMOVED = 'USER_PROFILE_REMOVED',
  MAIN_PROFILE_SWITCHED = 'MAIN_PROFILE_SWITCHED',
  EMAIL_SENT = 'EMAIL_SENT'
}

export enum InternalListType {
  CONTACT = 'CONTACT',
  RESIDENT = 'RESIDENT'
}

export enum ContactStatus {
  LANDLORD = 'LANDLORD',
  USER = 'USER',
  WITH_APPLICATIONS = 'WITH_APPLICATIONS',
  WITHOUT_APPLICATIONS = 'WITHOUT_APPLICATIONS',
  REGISTERED = 'REGISTERED',
  NOT_REGISTERED = 'NOT_REGISTERED'
}

/**
 * @deprecated Use {@link editorBean.name} or {@link editorBean.firstName} instead.
 * According to Mathis the creationType could probably also be removed
 * It is only used for the activities before the unified contacts migration
 */
export enum CreationType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC',
  SELF = 'SELF'
}

export enum ContactOwnershipType {
  LANDLORD = 'LANDLORD',
  USER = 'USER'
}

export enum CustomerPortalType {
  INVITATION = 'INVITATION',
  LEGAL = 'LEGAL',
  TASK_IMPORT = 'TASK-IMPORT',
  OTHER_RESIDENT_SETTINGS = 'OTHER-RESIDENT-SETTINGS'
}
