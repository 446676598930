<div class="wizard-footer">
  <div *ngIf="!hideRequiredHint" class="row">
    <div class="col-12 d-flex justify-content-end">
      <span class="wizard-footer__mandatory"
        >{{ 'rent_deposit.mandatory_field_hint_m' | translate }}</span
      >
    </div>
  </div>

  <div class="row wizard-footer__actions">
    <div class="col-12 d-flex justify-content-end">
      <app-button
        *ngIf="hasPrevious"
        [type]="'light-bordered'"
        (clickEvent)="onPreviousStep()"
        >{{ 'general.previous_a' | translate }}</app-button
      >
      <app-button
        *ngIf="hasNext"
        class="ml8"
        [type]="'primary'"
        [disabled]="disabled"
        (clickEvent)="onNextStep()"
        >{{ 'general.next_a' | translate }}</app-button
      >
      <app-button
        *ngIf="hasFinish"
        [disabled]="disabled"
        class="ml8"
        [type]="'primary'"
        (clickEvent)="onCompleteStep()"
        >{{ (completeActionText || 'general.complete_a') | translate
        }}</app-button
      >
    </div>
  </div>
</div>
