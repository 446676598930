import { Injectable, Inject } from '@angular/core';

import { WINDOW_REF } from './window-ref.token';

@Injectable()
export class ViewportSizeDetectorService {
  constructor(@Inject(WINDOW_REF) private windowRef: Window) {}

  public detectSize() {
    if (this.windowRef.innerWidth >= 768) {
      return 'L';
    }

    if (this.windowRef.innerWidth >= 479 && this.windowRef.innerWidth < 767) {
      return this.isRetinaDisplay() ? 'L' : 'M';
    }

    return this.isRetinaDisplay() ? 'M' : 'S';
  }

  // https://stackoverflow.com/a/19690464/2670060
  private isRetinaDisplay() {
    if (this.windowRef.matchMedia) {
      const mq = this.windowRef.matchMedia(
        `only screen and (min--moz-device-pixel-ratio: 1.3),
         only screen and (-o-min-device-pixel-ratio: 2.6 / 2),
         only screen and (-webkitmin-device-pixel-ratio: 1.3),
         only screen and (min-device-pixel-ratio: 1.3,
         only screen and (min-resolution: 1.3dppx)`
      );

      return (mq && mq.matches) || this.windowRef.devicePixelRatio > 1;
    }
  }
}
