import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { delay } from 'rxjs/operators';
import { HierarchicalQuestionService } from 'libs/services';
import {
  AvailableLanguageCodesEnum,
  HierarchicalQuestion,
  RootQuestion
} from '@ui/shared/models';

@UntilDestroy()
@Component({
  selector: 'app-hierarchical-root-question-form',
  templateUrl: './hierarchical-root-question-form.component.html',
  styleUrls: ['./hierarchical-root-question-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HierarchicalRootQuestionFormComponent),
      multi: true
    }
  ]
})
export class HierarchicalRootQuestionFormComponent
  implements OnInit, ControlValueAccessor
{
  @Input() hierarchicalRootQuestions: RootQuestion[];
  @Output() validityChange = new EventEmitter<boolean>();

  public form: FormGroup;
  public responseForm: FormGroup;
  public value: RootQuestion[];
  public currentLanguage$: Observable<AvailableLanguageCodesEnum>;
  public defaultLanguage$: Observable<AvailableLanguageCodesEnum>;

  public questionDisplayIndices: Map<number, string[]>;

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  public get hierarchicalRootQuestionsForm() {
    return this.form.get('hierarchicalRootQuestions') as FormArray;
  }

  private get rootQuestionControlConfig() {
    return {
      id: [null],
      mainQuestionId: [null],
      questions: this.fb.array([])
    };
  }

  constructor(
    private fb: FormBuilder,
    private hierarchicalQuestionService: HierarchicalQuestionService
  ) {}

  public ngOnInit() {
    this.questionDisplayIndices = new Map<number, string[]>();
    this.form = this.fb.group({
      hierarchicalRootQuestions: this.fb.array([])
    });

    this.hierarchicalRootQuestionsForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: RootQuestion[]) => {
        this.value = value;
        this.onChange(this.value);
        this.onTouch();
      });

    this.hierarchicalRootQuestionsForm.statusChanges
      .pipe(delay(0), untilDestroyed(this))
      .subscribe(value => this.validityChange.emit(value === 'VALID'));

    this.currentLanguage$ = this.hierarchicalQuestionService.getLanguageCode();
    this.defaultLanguage$ =
      this.hierarchicalQuestionService.getDefaultLanguageCode();
  }

  public onFormValidityChange(isValid: boolean) {
    this.hierarchicalRootQuestionsForm.setErrors(
      isValid ? null : { missingFields: true }
    );
  }

  public onAnswerChange(value: any, rootQuestionIndex: number) {
    // enable all controls, so all are included in the index updates
    this.getQuestionsControl(rootQuestionIndex).controls.forEach(control =>
      control.enable()
    );

    const allValidAnswersFromRootQuestion =
      this.hierarchicalQuestionService.getAllValidAnswersFromRootQuestion(
        this.getQuestionsControl(rootQuestionIndex).value,
        this.hierarchicalRootQuestions[rootQuestionIndex].mainQuestionId
      );

    const displayIndices = this.hierarchicalQuestionService.getDisplayIndices(
      allValidAnswersFromRootQuestion,
      this.getQuestionsControl(rootQuestionIndex).value,
      this.questionDisplayIndices.get(rootQuestionIndex)
    );
    this.questionDisplayIndices.set(rootQuestionIndex, displayIndices);
    // disable all question-controls that are not visible anymore, so the form is valid even when questions
    // are unanswered that are not shown.
    this.getQuestionsControl(rootQuestionIndex).controls.forEach(
      (control, index) =>
        index > 0 &&
        this.questionDisplayIndices.get(rootQuestionIndex)[index] === null
          ? control.disable()
          : control.enable()
    );
  }

  public getAllHierarchicalQuestions(rootQuestionIndex: number) {
    const rootQuestion = this.hierarchicalRootQuestions[rootQuestionIndex];
    return rootQuestion.questions;
  }

  public getQuestionsControl(rootQuestionIndex: number) {
    return this.hierarchicalRootQuestionsForm
      .get([rootQuestionIndex])
      .get('questions') as FormArray;
  }

  public writeValue(rootQuestions: RootQuestion[]): void {
    this.value = rootQuestions || ([] as RootQuestion[]);
    this.hierarchicalRootQuestionsForm.clear();
    this.hierarchicalRootQuestions?.forEach(
      (rootQuestion: RootQuestion, rootQuestionIndex: number) => {
        this.questionDisplayIndices.set(
          rootQuestionIndex,
          rootQuestion.questions.map(() => null)
        );
        const rootQuestionFormGroup = this.fb.group(
          this.rootQuestionControlConfig
        );

        rootQuestion.questions.forEach(
          (question: HierarchicalQuestion, questionIndex: number) => {
            const control = new FormControl();
            control.patchValue(
              rootQuestions[rootQuestionIndex].questions[questionIndex] ||
                question
            );
            (rootQuestionFormGroup.get('questions') as FormArray).push(control);
          }
        );
        rootQuestionFormGroup.patchValue(rootQuestions[rootQuestionIndex]);
        this.hierarchicalRootQuestionsForm.push(rootQuestionFormGroup);
      }
    );
    this.form.patchValue({ hierarchicalRootQuestions: this.value });

    /**
     * Workaround for now to trigger setting of subQuestions based on userResponse (patch should have done this)
     */
    rootQuestions.forEach((rootQuestion, index) => {
      const question = rootQuestion.questions[0];
      if (
        question?.userResponse?.response ||
        question?.userResponse?.answerIds
      ) {
        this.onAnswerChange(
          question.userResponse?.response ?? question.userResponse?.answerIds,
          index
        );
      }
    });
  }

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouch = fn;
  }
}
