import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {
  @Input() pageSize: number;
  @Input() disabled: boolean;
  @Input() collectionSize: number;
  @Input() page: number;
  @Input() forceDisplay: boolean;
  @Output() pageChange = new EventEmitter<number>();

  onPageChange(pageNumber: number) {
    this.pageChange.emit(pageNumber);
  }
}
