import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { TranslateService } from '@ngx-translate/core';

import {
  AvailableLanguageCodesEnum,
  MultiLanguageString,
  Translations
} from '@ui/shared/models';
import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import { InfrastructureConfig } from 'libs/infrastructure/infrastructure-config.model';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import moment from 'moment';
// You have to import the locales in order to be able to switch to them.
import 'moment/locale/de.js';
import 'moment/locale/en-gb.js';
import { LocalStorageService } from '../storage';

import { LocaleConfig } from './locale-config.model';
import { DEFAULT_TRANSLATIONS_PATH } from './default-translations-path.token';
import { LOCALE_CONFIG } from './locale-config.token';
import { languages } from './languages';

const TRANSLATIONS_STORAGE_KEY = 'translations';

@UntilDestroy()
@Injectable()
export class I18nService {
  public defaultLanguageCode: AvailableLanguageCodesEnum;

  constructor(
    private store: Store<fromBaseState.BaseState>,
    private translate: TranslateService,
    private http: HttpClient,
    private storage: LocalStorageService,
    @Inject(DEFAULT_TRANSLATIONS_PATH) private path: string,
    @Inject(LOCALE_CONFIG) private localeConfig: LocaleConfig,
    @Inject(INFRASTRUCTURE_CONFIG)
    private infrastructureConfig: InfrastructureConfig
  ) {
    this.store
      .select(fromBaseState.getDefaultLanguageCode)
      .pipe(untilDestroyed(this))
      .subscribe(defaultLanguageCode => {
        this.defaultLanguageCode = defaultLanguageCode;
      });
  }

  public loadDefaultTranslations() {
    this.http.get(this.path).subscribe(translations => {
      this.translate.setTranslation(
        this.localeConfig.currentLocale,
        translations
      );
      this.translate.setDefaultLang(this.localeConfig.currentLocale);
    });
  }

  public getTranslations() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.storage.getItem(TRANSLATIONS_STORAGE_KEY);
  }

  public translationExists(localeKey: string, informal: boolean) {
    const fromStorage = this.getTranslations();
    const key = informal ? `${localeKey}_informal` : localeKey;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return fromStorage && fromStorage[key];
  }

  public setTranslations(
    localeKey: string,
    informal: boolean,
    translations: Translations
  ) {
    const key = informal ? `${localeKey}_informal` : localeKey;
    this.translate.setTranslation(key, translations[localeKey], false);
    this.translate.setDefaultLang(key);
    moment.locale(localeKey);

    this.translate.use(key);

    this.saveTranslations(translations);
  }

  public saveTranslations(translations: Translations) {
    const value = {
      ...(this.storage.getItem(TRANSLATIONS_STORAGE_KEY) || {}),
      ...translations
    };

    this.storage.setItem(TRANSLATIONS_STORAGE_KEY, value);
  }

  public removeTranslations() {
    this.storage.removeItem(TRANSLATIONS_STORAGE_KEY);
  }

  public getExtendedLocale(locale: string) {
    return languages[locale] && languages[locale].extendedLocale;
  }

  public getInfrastructureLanguageConfig() {
    const { defaultLanguageCode, availableLanguagesByCode } =
      this.infrastructureConfig.languages;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return {
      defaultLanguageCode,
      availableLanguages: availableLanguagesByCode.map(code => languages[code])
    };
  }

  public getMultiLanguageStringValue(
    multiLangStr: MultiLanguageString,

    langCode = this.defaultLanguageCode
  ): string {
    const languageIsDefault = langCode === this.defaultLanguageCode;

    let value = multiLangStr?.[langCode];

    // use value in default app language as fallback:
    if (!value && !languageIsDefault) {
      value = multiLangStr?.[this.defaultLanguageCode];
    }

    return value;
  }
}
