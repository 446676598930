import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-knockout-criteria',
  templateUrl: './knockout-criteria.component.html',
  styleUrls: ['./knockout-criteria.component.scss']
})
export class KnockoutCriteriaComponent {
  @Input() formControlInput: FormControl;
  @Input() disabled: boolean;
}
