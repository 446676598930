<div
  ngbDropdown
  #dropdown="ngbDropdown"
  class="auto-complete-field"
  [ngClass]="{'show-clear-button': showClearButton}"
  autoClose="outside"
  (click)="hasUserInteractedWithComponent = true"
>
  <div (click)="!readonly && handleDropdownClick()">
    <ng-content></ng-content>

    <div
      class="auto-complete-field__dropdown-arrow-container dropdown-toggle--frameless align-items-center"
      [ngClass]="{'disabled': readonly}"
    >
      <app-loading-spinner
        class="auto-complete-field__spinner"
        *ngIf="loading"
      ></app-loading-spinner>

      <i
        *ngIf="!loading && showClearButton"
        class="auto-complete-field__dropdown-arrow icon icon--close me-2"
        (click)="clearValue.emit()"
      ></i>
      <i
        *ngIf="!loading"
        class="auto-complete-field__dropdown-arrow icon icon--arrow-dropdown"
      ></i>
    </div>

    <div
      class="auto-complete-field__dropdown-menu dropdown-menu"
      ngbDropdownMenu
    >
      <div
        class="auto-complete-field__dropdown-menu-inner"
        *ngIf="selector$ | async as selector"
        (scroll)="onScroll($event)"
      >
        <ng-container *ngIf="selector?.length > 0; else noResults">
          <div
            class="auto-complete-field__dropdown-menu-item"
            *ngFor="let value of selector; index as i"
            (click)="setFormControlValue(value)"
          >
            <ng-template
              [ngTemplateOutlet]="itemTemplate"
              [ngTemplateOutletContext]="{$implicit: value}"
            ></ng-template>
            <hr
              *ngIf="selector?.length > 0 && selector?.length - 1 !== i"
              class="mt10 mb0"
            />
          </div>
        </ng-container>
      </div>

      <ng-template #noResults>
        <div class="auto-complete-field__dropdown-menu-no-results">
          {{ 'general.no_search_results_m' | translate }}
        </div>
      </ng-template>
    </div>
  </div>
</div>
