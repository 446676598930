<div
  class="multi-select-dropdown"
  [class.disabled]="disabled"
  [placement]="relativelyPositioned ? 'bottom-start' : ['bottom-start', 'bottom-end', 'top-start', 'top-end']"
  ngbDropdown
>
  <div ngbDropdownAnchor>
    <input
      class="form-control"
      type="text"
      appInput
      [placeholder]="formGroupSelectedCount > 0 ? ('multi_select_dropdown.items_selected_l' | translate:{count: formGroupSelectedCount}) : ((placeholder || 'general.search_fulltext_l') | translate)"
      [formControl]="searchControl"
      [ngClass]="{'bordered-radius-bottom colored-border': dropdown?.isOpen(), 'disabled-search': disableSearch}"
      (focus)="dropdown.open()"
    />
    <ng-container *ngIf="!isLoadingMenuItems; else loading">
      <svg-icon
        *ngIf="!hideRemoveAllIcon && formGroupSelectedCount > 0"
        class="icon icon-remove"
        src="/assets/images/icons/icon-close.svg"
        [svgStyle]="{'width.px': 10, 'height.px': 10}"
        (click)="clearAndApply()"
      ></svg-icon>
      <svg-icon
        class="icon icon-dropdown"
        src="/assets/images/icons/icon-dropdown.svg"
        [class.rotate]="dropdown?.isOpen()"
        (click)="dropdown?.toggle()"
      ></svg-icon>
    </ng-container>

    <ng-template #loading>
      <div class="loading-area-input">
        <app-loading-spinner></app-loading-spinner>
      </div>
    </ng-template>
  </div>

  <div
    class="dropdown-menu"
    [class.relative-positioned]="relativelyPositioned"
    ngbDropdownMenu
  >
    <div class="dropdown__items" [class.d-none]="!dropdown?.isOpen()">
      <ng-container *ngIf="items?.length; else minCharacterRequirement">
        <app-infinite-scroll (scrolled)="onScroll()">
          <div
            class="dropdown__item"
            *ngFor="let item of items; index as i"
            [formGroup]="formRecord"
          >
            <ng-template
              [ngTemplateOutlet]="templateRef"
              [ngTemplateOutletContext]="{$implicit: item}"
            ></ng-template>
            <app-check
              class="dropdown__check"
              [squared]="true"
              [formControl]="formRecord.get(item[itemValueKey]) | appFormControlPipe"
            ></app-check>
          </div>
        </app-infinite-scroll>
      </ng-container>

      <ng-template #minCharacterRequirement>
        <ng-container
          *ngIf="searchCharactersLimit > 0 && searchControl.value.length < searchCharactersLimit; else noItems"
        >
          <div class="no-items-content">
            <span
              >{{ 'single_select_dropdown.minimum_character_l' |
              translate:{value: searchCharactersLimit} }}
            </span>
          </div>
        </ng-container>
      </ng-template>

      <ng-template #noItems>
        <div class="no-items-content">
          <ng-content select="[no-items-content]"></ng-content>
        </div>
      </ng-template>
    </div>

    <div *ngIf="items?.length" class="dropdown__actions">
      <button class="btn" (click)="clear()">{{'CLEAR_A' | translate}}</button>
      <button class="btn" (click)="apply()">{{'APPLY_A' | translate}}</button>
    </div>
  </div>
</div>
