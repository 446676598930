import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-form-field-label',
  templateUrl: './form-field-label.component.html',
  styleUrls: ['./form-field-label.component.scss']
})
export class FormFieldLabelComponent {
  @Input() showRequiredMarker = false;
  @Input() idFor: string;
  @Input() disabled = false;
  @Input() smallText = false;
  @Input() redText = false;
  @Input() noBottomMargin = false;
}
