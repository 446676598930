import { Component, ViewEncapsulation, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SystemTag } from '@ui/shared/models';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConfirmationDialogComponent {
  @Input() message: string;
  @Input() acknowledge = false;
  @Input() innerHTML = false;
  @Input() titleMessage: string;
  @Input() okButtonMessage = 'general.ok';
  @Input() cancelButtonMessage = 'general.cancel_a';
  @Input() titleValue = null;
  @Input() messageValue = null;
  @Input() systemTags: SystemTag[];

  constructor(private ngbActiveModal: NgbActiveModal) {}

  okAction() {
    this.ngbActiveModal.close();
  }

  cancelAction() {
    this.ngbActiveModal.dismiss();
  }

  public get isProspectOfflineSales() {
    return this.systemTags?.includes(
      SystemTag.OFFLINE && SystemTag.SALES_PROSPECT
    );
  }
}
