<li
  class="nav-item"
  routerLinkActive="active"
  [routerLinkActiveOptions]="{exact: exact}"
>
  <ng-container *ngIf="!item.redirectLink; else redirectLink">
    <a class="nav-link d-flex" routerLink="{{ item.link }}" r>
      {{item.label | translate}}
    </a>
  </ng-container>
  <ng-template #redirectLink>
    <a class="nav-link d-flex" href="{{item.redirectLink}}" target="_blank">
      {{item.label | translate}}
    </a>
  </ng-template>
</li>
