import { PortalCardComponent } from './portal-card/portal-card.component';
import { PropertyCardComponent } from './property-card/property-card.component';
import { ProjectCardComponent } from './project-card/project-card.component';
import { ServiceCardComponent } from './service-card/service-card.component';
import { PropertyGroupCardComponent } from './property-group-card/property-group-card.component';
import { SelectionCardComponent } from './selection-card/selection-card.component';
import { RegisterCardComponent } from './register-card/register-card.component';

export const cardComponents = [
  PropertyCardComponent,
  PortalCardComponent,
  ProjectCardComponent,
  ServiceCardComponent,
  PropertyGroupCardComponent,
  SelectionCardComponent,
  RegisterCardComponent
];
