import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { RootQuestionContainerModel, ContactTag } from '@ui/shared/models';

@Component({
  selector: 'app-available-hierarchical-question',
  templateUrl: './available-hierarchical-question.component.html',
  styleUrls: ['./available-hierarchical-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableHierarchicalQuestionComponent {
  @Input() hierarchicalQuestion: RootQuestionContainerModel;
  @Input() tags: ContactTag[];
  @Output() selectedChange = new EventEmitter<boolean>();
  public selected = false;

  public onSelectedChange(value: boolean) {
    this.selectedChange.emit(value);
  }
}
