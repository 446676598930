<div
  class="single-comment__container"
  *ngFor="let commentObj of editableComments; index as i"
>
  <div class="d-flex content__container">
    <div class="single-comment__image" *ngIf="commentObj?.property?.titleImage">
      <img
        src="{{commentObj?.property?.titleImage?.url}}"
        alt="{{commentObj?.property?.titleImage?.title}}"
      />
    </div>
    <div class="single-comment__details">
      <div class="row mr0 ml0">
        <div class="col-6 p0">
          <p class="default-m comment-details__name">
            {{ commentObj?.agentInfo?.firstName }}&nbsp;{{
            commentObj?.agentInfo?.name }}
          </p>
          <p class="default-s comment-details__excerpt">
            {{ commentObj?.comment }}
          </p>
        </div>
        <div
          *ngIf="(commentObj?.agentInfo?.id === userId) && allowCommentEdit"
          class="col-6 p0 d-flex justify-content-end"
        >
          <app-context-menu>
            <div menu-content>
              <app-context-menu-item (clickEvent)="editComment(i)">
                {{ 'comment.edit_a' | translate }}
              </app-context-menu-item>
              <app-context-menu-item
                (clickEvent)="onDeleteComment(commentObj.id)"
              >
                {{ 'comment.remove_a' | translate }}
              </app-context-menu-item>
            </div>
          </app-context-menu>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="commentObj.editMode" class="col-12 d-flex p12">
    <input
      appInput
      #editCommentInput
      type="text"
      placeholder="{{ 'comment.edit_placeholder_l' | translate }}"
      [value]="commentObj.comment"
      class="form-control"
    />
    <app-button
      class="secondary ms-2"
      (clickEvent)="cancelEdit(i)"
      [type]="'light-bordered'"
    >
      {{ 'CANCEL_A' | translate }}
    </app-button>
    <app-button
      class="default ms-2"
      [loading]="updatingComment"
      [disabled]="updatingComment || !editCommentInput.value"
      (clickEvent)="onUpdateComment(commentObj, editCommentInput.value)"
    >
      {{ 'comment.update_a' | translate }}
    </app-button>
  </div>
  <hr class="m0" />
  <div class="d-flex content__container">
    <div
      class="d-flex align-items-center single-comment__property_details"
      (click)="onShowProperty(commentObj?.property?.id)"
    >
      <p *ngIf="commentObj?.property" class="default-m property-details__name">
        {{ commentObj?.property?.data?.name }}
      </p>
      <p
        *ngIf="commentObj?.property?.externalId"
        class="default-s property-details__external-id"
      >
        {{ 'property.external_id_l' | translate }} {{
        commentObj?.property?.externalId }}
      </p>
      <p *ngIf="!commentObj?.property" class="default-m property-details__name">
        {{'comment.contact_list_l' | translate}}
      </p>
    </div>
    <div class="d-flex align-items-center">
      <span *ngIf="commentObj.updated" class="comment-updated"
        >{{ 'comment.updated_l' | translate }}</span
      >
      <p class="default-s comment-details__date">
        {{ (commentObj?.updated || commentObj?.created) | appDateTime }}
      </p>
    </div>
  </div>
</div>
