import { Component, ViewEncapsulation, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rejection-dialog',
  templateUrl: './rejection-dialog.component.html',
  styleUrls: ['./rejection-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RejectionDialogComponent {
  @Input() message: string;
  @Input() titleMessage: string;
  @Input() okButtonMessage = 'REJECT_DIALOG_OK_A';
  @Input() cancelButtonMessage = 'REJECT_DIALOG_CANCEL_A';

  public reason: string;
  public isReasonRequired = false;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  okAction() {
    if (this.isReasonRequired && !this.reason) return;

    this.ngbActiveModal.close(this.reason);
  }

  cancelAction() {
    this.ngbActiveModal.dismiss();
  }
}
