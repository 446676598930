<div class="list-navigation__container">
  <div class="list-navigation d-flex" [ngClass]="{'disabled': disabled}">
    <ng-container *ngFor="let nav of navConfig; index as i">
      <div
        *appHide="nav.invisible"
        class="nav-item"
        [ngClass]="{'active': selectedNav === nav.value, 'nav-item_enabled': !nav.disabled}"
        [ngbTooltip]="(nav.disabled ? nav.tooltip : null) | translate"
        (click)="navSelect(nav)"
      >
        <div class="nav-item__name" [ngClass]="{'medium': medium}">
          {{ nav.label | translate}}
        </div>
        <div
          *ngIf="totals?.length && totals[i] !== null"
          class="nav-item__count"
        >
          {{ totals[i] }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
