import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter, take } from 'rxjs/operators';

import {
  Conversation,
  ConversationDetails
} from 'libs/components/legacy/messenger/model/interface';
import { ActionState } from 'libs/state-utils';
import {
  CustomerSettings,
  LandlordUser,
  Pagination,
  Property
} from '@ui/shared/models';
import { MessengerFilterService } from 'libs/components/legacy/messenger/services/messenger-filter.service';

@UntilDestroy()
@Component({
  selector: 'app-chat-contact-list',
  templateUrl: './chat-contact-list.component.html',
  styleUrls: ['./chat-contact-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatContactListComponent implements OnInit {
  @Input() conversationList: Conversation[];
  @Input() conversationListPage: Pagination;
  @Input() contactListActionState: ActionState;
  @Input() messagesActionState: ActionState;
  @Input() isLandlord: boolean;
  @Input() isPropertySearcher: boolean;
  @Input() isAgent: boolean;
  @Input() totalMessageCount: number;
  @Input() activeConversation: Conversation;
  @Input() conversationDetails: ConversationDetails;
  @Input() agentsFilter: LandlordUser[] = [];
  @Input() customerSettings: CustomerSettings;
  @Input() loadArchivedConversationsToggle: boolean;
  @Input() searchedProperty: Property;
  @Output() selectConversation = new EventEmitter<Conversation>();
  @Output() reloadMessenger = new EventEmitter();
  @Output() openChatSettingsModal = new EventEmitter();
  @Output() addConversation = new EventEmitter();
  @Output() archivedConversations = new EventEmitter();
  @Output() activeConversations = new EventEmitter();
  @Output() removePropertyFilter = new EventEmitter();
  @ViewChild('contacts') contacts: ElementRef;

  public searchForm: FormGroup;
  public propertyId: string;
  public searched: boolean;
  public conversationExists: string;

  get agentsControl() {
    return this.searchForm.get('agents');
  }

  get canFilterAgents() {
    return (
      !this.customerSettings?.conversationConfig
        ?.agentsAccessOnlyOwnConversations || !this.isAgent
    );
  }

  get searchControl() {
    return this.searchForm.get('search');
  }

  constructor(
    private fb: FormBuilder,
    private messengerFilterService: MessengerFilterService
  ) {}

  public ngOnInit(): void {
    this.searchForm = this.fb.group({
      search: [''],
      agents: [[]]
    });

    this.messengerFilterService
      .getFilter()
      .pipe(
        filter(data => data && data.agents && data.agents.length > 0),
        take(1)
      )
      .subscribe(data => {
        this.agentsControl.patchValue(data.agents);
      });

    this.agentsControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(value => {
        this.messengerFilterService.updateFilter({ agents: value });
      });

    this.messengerFilterService
      .getFilter()
      .pipe(
        filter(value => !!value),
        untilDestroyed(this)
      )
      .subscribe(messengerFilter => {
        this.propertyId = messengerFilter.propertyId || null;
        this.conversationExists = messengerFilter.conversationExists;
      });

    if (this.conversationExists === 'true' && this.activeConversation) {
      const applicantName = `${this.activeConversation.mainConversationPartner.firstname} ${this.activeConversation.mainConversationPartner.name}`;
      const propertyName = `${this.activeConversation.subject}`;
      const searchData = `${applicantName} - ${propertyName}`;

      this.searchControl.patchValue(searchData, { emitEvent: false });
      this.searched = true;
    }
  }

  public onSelectConversation(conversation: Conversation) {
    this.selectConversation.emit(conversation);
  }

  public onReloadMessenger() {
    this.reloadMessenger.emit();
  }

  public onRemovePropertyFilter() {
    this.messengerFilterService.setPropertyId(null);
    this.removePropertyFilter.emit();
  }

  public onOpenSettings() {
    this.openChatSettingsModal.emit();
  }

  public onAddConversation() {
    this.addConversation.emit();
  }

  public onLoadMore() {
    if (!this.contactListActionState.pending) {
      this.messengerFilterService.setPage(this.messengerFilterService.page + 1);
    }
  }

  public onSearch() {
    this.searched = true;
    this.messengerFilterService.updateFilter({
      search: this.searchControl.value,
      page: 0
    });
  }

  public revertSearch() {
    this.searched = false;
    this.messengerFilterService.updateFilter({
      search: '',
      page: 0,
      conversationExists: undefined
    });
    this.searchControl.patchValue('');
  }

  public loadArchivedConversations() {
    this.archivedConversations.emit();
  }

  public loadActiveConversations() {
    this.activeConversations.emit();
  }
}
