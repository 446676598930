import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from 'libs/utils';
import { Elevation, ElevationType } from 'libs/directives';
import { IconTypeEnum } from '@ui/shared/models';
import {
  ButtonBorderRadiusEnum,
  ButtonSizeEnum,
  ButtonTypeEnum
} from './button.enum';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent {
  @Input() type: ButtonTypeEnum = ButtonTypeEnum.PRIMARY;
  @Input() size: ButtonSizeEnum = ButtonSizeEnum.LARGE;
  @Input() iconLeft: IconTypeEnum;
  @Input() iconRight: IconTypeEnum;
  @Input() spaceBetweenIcons: false;
  @Input() borderRadius: ButtonBorderRadiusEnum = ButtonBorderRadiusEnum.BIG;
  @Input() elevation: ElevationType = Elevation.ZERO;
  @Input() elevationHoverEffect = true;
  @Input() ghost = false;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() useFullContainerSize = false; // e.g. used in DataTable for taking whole cell size
  @Input() useDisplayFlex = false;
  @Input() zeroPadding: boolean;
  @Input() disableHover: boolean;
  @Input() useDefaultCursor: boolean;
  @Input() buttonType: 'button' | 'submit' = 'button'; // html button type
  @Input() ariaLabel: string;

  @Output() clickEvent = new EventEmitter<MouseEvent>();

  public baseClass = 'button';

  public isGhost(): boolean {
    return this.type === ButtonTypeEnum.PRIMARY ? false : this.ghost;
  }

  public isDisabled(): boolean {
    return this.disabled || this.loading;
  }

  public getClassName(): string {
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      [`type-${this.type}`]: !!this.type,
      [`border-radius-${this.borderRadius}`]: !!this.borderRadius,
      [`ghost`]: this.isGhost(),
      [`space-between-icons`]: !!this.spaceBetweenIcons,
      [`size-${this.size}`]: !!this.size,
      ['use-default-cursor']: !!this.useDefaultCursor
    });
  }

  public getIconClassName(iconType: IconTypeEnum): string {
    return buildBEMClassNamesByGivenBaseClassAndFlags('icon', {
      [iconType]: !!iconType
    });
  }

  public getElevation(): ElevationType {
    return this.isGhost() || this.type === ButtonTypeEnum.LINK
      ? Elevation.ZERO
      : this.elevation;
  }

  public click(event: MouseEvent): void {
    if (!this.isDisabled()) {
      this.clickEvent.emit(event);
    }
  }
}
