import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { CookiePreference } from '@ui/shared/models';
import { fadeOnEnterLeaveAnimation } from 'libs/utils';
import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import { InfrastructureConfig } from 'libs/infrastructure';

@Component({
  animations: [fadeOnEnterLeaveAnimation],
  selector: 'app-cookie-banner',
  templateUrl: './cookie-banner.component.html',
  styleUrls: ['./cookie-banner.component.scss']
})
export class CookieBannerComponent {
  public get showCookieBanner() {
    return (
      !this.cookiePreference ||
      (new Date().getTime() - this.cookiePreference?.lastUpdated) / 1000 >
        this.ONE_YEAR_IN_SEC
    );
  }

  private get isTenantApp() {
    return this.config.environment.app_name === 'tenant';
  }

  public get isMobile() {
    return this.config?.environment?.mobile;
  }

  constructor(
    @Inject(INFRASTRUCTURE_CONFIG) private config: InfrastructureConfig
  ) {}

  @Input() cookiePreference: CookiePreference;
  @Output() acceptAll = new EventEmitter<CookiePreference>();
  @Output() customSettings = new EventEmitter<CookiePreference>();

  private ONE_YEAR_IN_SEC = 31557600;

  private DEFAULT_PREFERENCE = {
    allowRequired: true,
    allowPerformance: false,
    allowFunctional: false,
    lastUpdated: new Date().getTime()
  };

  onAcceptAll() {
    this.acceptAll.emit({
      allowRequired: true,
      allowPerformance: true,
      allowFunctional: true,
      lastUpdated: new Date().getTime()
    });
  }

  onCustomSettings() {
    this.customSettings.emit(this.cookiePreference || this.DEFAULT_PREFERENCE);
  }

  dataPrivacyRedirect() {
    this.isTenantApp
      ? window.open('https://www.mieter.immomio.com/datenschutz', '_blank')
      : window.open('https://www.vermieter.immomio.com/datenschutz', '_blank');
  }
}
