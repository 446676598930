<div class="logo" (click)="onClick()" [class.c-pointer]="!preventClick">
  <ng-container *ngIf="!logoExist; else fallback">
    <img
      *ngIf="!landlordLogo && logo?.url; else landlordLogoImg"
      class="logo__image"
      [src]="logo?.url"
      [alt]="logo?.title"
    />
    <ng-template #landlordLogoImg>
      <img
        *ngIf="landlordLogo?.url"
        class="logo__image"
        [src]="landlordLogo?.url"
        [alt]="landlordLogo?.title"
      />
    </ng-template>
  </ng-container>
  <ng-template #fallback>
    <img
      src="/assets/images/logos/logo-immomio-main.svg"
      class="logo__image"
      alt="Immomio"
    />
  </ng-template>
</div>
