import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-new-update-banner',
  templateUrl: './new-update-banner.component.html',
  styleUrls: ['./new-update-banner.component.scss']
})
export class NewUpdateBannerComponent {
  constructor(private ngbActiveModal: NgbActiveModal) {}

  onUpdate() {
    this.ngbActiveModal.close();
  }
}
