<app-card
  [class]="'portal-card'"
  [clickable]="!isDisabled"
  [padding]="'none'"
  [elevation]="elevation"
  [borderStyle]="cardBorderStyle"
  [borderRadius]="cardBorderRadius"
>
  <div class="row flex-nowrap" (click)="onBoxClick()">
    <div class="col-3 d-flex right-divider">
      <div class="portal-card__image">
        <app-image defaultSrc="{{imgUrl}}" maxHeightInPx="72"></app-image>
      </div>
    </div>

    <div class="col portal-card__info">
      <span class="semibold text-ellipsis"> {{ title | translate }} </span>
      <ng-content select="[description]"></ng-content>
    </div>

    <div
      class="col-auto d-flex justify-content-end align-items-center flex-shrink-0 p-static"
    >
      <div
        *ngIf="selectable || isSelected"
        class="portal-card__checkbox"
        [ngbTooltip]="showDeselectionDisabledTooltip ? deselectionDisabledTooltip : null"
      >
        <app-check
          [formControl]="checkControl"
          [forceDisabled]="isDisabled"
        ></app-check>
      </div>
      <div class="portal-card__context-menu">
        <ng-content select="[context-menu]"></ng-content>
      </div>
    </div>
  </div>
</app-card>

<ng-template #deselectionDisabledTooltip>
  <span>{{ deselectDisabledText | translate }}</span>
</ng-template>
