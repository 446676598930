import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'libs/utils';

@Pipe({ name: 'appAlphaIndex' })
export class AlphaIndexPipe implements PipeTransform {
  transform(index: number): string {
    if (!isNumber(index)) return '';

    return (index + 10).toString(36).toUpperCase();
  }
}
