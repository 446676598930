<app-modal *ngIf="!showV2">
  <div class="modal-s">
    <app-modal-content>
      <div class="modal-body">
        <p class="title-s sub-headline">
          {{ 'appointment.slot_available_l' | translate:{value:
          numberOfAppointments} }}
        </p>
        <div *ngIf="hasAppointments">
          <p class="title-m modal-headline">
            {{'appointment.select_slot_l' | translate}}
          </p>
          <p class="default-s confirmation-dialog__message">
            {{'appointment.select_description_l' | translate}}
          </p>
        </div>
        <div *ngIf="!hasAppointments">
          <p class="title-m modal-headline">
            {{'appointment.no_slot_l' | translate}}
          </p>
          <p class="default-s confirmation-dialog__message">
            {{'appointment.no_slot_description_l' | translate}}
          </p>
        </div>
        <div class="property-info">
          <div>
            <img
              *ngIf="picture"
              src="{{ picture | appImageSize:'S' }}"
              alt="{{ property?.name }}"
            />
          </div>
          <div>
            <div class="property-name">{{ property?.name }}</div>
            <div class="property-address">
              {{ property?.address | appAddress }}
            </div>
          </div>
        </div>
        <app-appointment-selection
          [appointments]="appointments"
          [exclusiveAppointments]="exclusiveAppointments"
          [(ngModel)]="newActiveAppointmentId"
          [showNotFittingOption]="showNotFittingOption"
        >
        </app-appointment-selection>
      </div>
    </app-modal-content>
    <app-modal-footer>
      <div class="modal-footer confirmation-dialog__footer">
        <app-button [type]="'light-bordered'" (clickEvent)="dismiss()">
          {{ 'general.close_a' | translate}}
        </app-button>

        <app-button
          *ngIf="hasAppointments"
          [disabled]="!newActiveAppointmentId || newActiveAppointmentId === currentActiveAppointmentId"
          (clickEvent)="close()"
        >
          {{ okButtonMessage | translate}}
        </app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal>

<app-modal-v2 *ngIf="showV2">
  <div class="modal-s">
    <app-modal-content>
      <div class="modal-body">
        <p class="title-s sub-headline">
          {{ 'appointment.slot_available_l' | translate:{value:
          numberOfAppointments} }}
        </p>
        <div *ngIf="hasAppointments">
          <p class="title-m modal-headline">
            {{'appointment.select_slot_l' | translate}}
          </p>
          <p class="default-s confirmation-dialog__message">
            {{'appointment.select_description_l' | translate}}
          </p>
        </div>
        <div *ngIf="!hasAppointments">
          <p class="title-m modal-headline">
            {{'appointment.no_slot_l' | translate}}
          </p>
          <p class="default-s confirmation-dialog__message">
            {{'appointment.no_slot_description_l' | translate}}
          </p>
        </div>
        <div class="property-info">
          <div>
            <img
              *ngIf="picture"
              src="{{ picture | appImageSize:'S' }}"
              alt="{{ property?.name }}"
            />
          </div>
          <div>
            <div class="property-name">{{ property?.name }}</div>
            <div class="property-address">
              {{ property?.address | appAddress }}
            </div>
          </div>
        </div>
        <app-appointment-selection
          [appointments]="appointments"
          [applicationStatus]="applicationStatus"
          [exclusiveAppointments]="exclusiveAppointments"
          [showNotFittingOption]="showNotFittingOption"
          [(ngModel)]="newActiveAppointmentId"
        >
        </app-appointment-selection>
      </div>
    </app-modal-content>
    <app-modal-footer>
      <div class="modal-footer confirmation-dialog__footer">
        <app-button [type]="'light-bordered'" (clickEvent)="dismiss()">
          {{ 'general.close_a' | translate}}
        </app-button>

        <app-button
          *ngIf="hasAppointments"
          [disabled]="!newActiveAppointmentId || newActiveAppointmentId === currentActiveAppointmentId"
          [type]="'primary'"
          (clickEvent)="close()"
        >
          <ng-container
            *ngIf="newActiveAppointmentId !== 'non_fitting';else non_fitting "
          >
            {{ okButtonMessage | translate}}
          </ng-container>
          <ng-template #non_fitting>
            {{ 'appointment.not_fitting_a_l' | translate }}
          </ng-template>
        </app-button>
      </div>
    </app-modal-footer>
  </div>
</app-modal-v2>
