<div
  class="flat-select"
  [ngClass]="{ 'flex-wrap': options?.length > 4 || wrapItems }"
>
  <div
    class="flat-select__item"
    [ngClass]="{
      'flat-select__item--no-stretch': options?.length > 3 || !stretch,
      'active': isActive(option),
      'readOnly': (readonly && isActive(option)), 'disabled': disabled || (readonly && !isActive(option)) || option.disabled, 'd-none': option?.value?.id === 'default'
      }"
    *ngFor="let option of options; index as i"
  >
    <div class="flat-select__label-container">
      <span
        class="flat-select__item-label"
        [ngClass]="{'can-be-deleted': deletable}"
        (click)="selectOption(option)"
      >
        <div class="d-flex align-items-center">
          <div
            *ngIf="icon"
            [class]="getIconClassName(icon)"
            class="flat-select__svg-icon"
          ></div>
          <div>{{ returnValidLabel(option) | translate}}</div>
        </div>
      </span>
      <app-button
        *ngIf="deletable && (option?.value?.template || option?.value?.name)"
        (clickEvent)="onRemove(i)"
        [elevationHoverEffect]="false"
        [type]="'light-bordered'"
        [borderRadius]="'none'"
        [elevationHoverEffect]="true"
      >
        <div class="icon icon--delete"></div>
      </app-button>
    </div>
  </div>
  <app-button
    class="ghost flat-select__add-button"
    *ngIf="showAddButton"
    [iconLeft]="'add'"
    [type]="'light-bordered'"
    [disabled]="readonly"
    (clickEvent)="add()"
  >
    {{addButtonText | translate}}
  </app-button>
</div>
