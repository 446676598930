import { Component, Input } from '@angular/core';
import { QuotaStatistic } from '@ui/shared/models';

@Component({
  selector: 'app-quota-info-badge',
  templateUrl: './quota-info-badge.component.html',
  styleUrls: ['./quota-info-badge.component.scss']
})
export class QuotaInfoBadgeComponent {
  @Input() quotaStatistic: QuotaStatistic = {
    totalQuota: null,
    availableQuota: null
  };

  @Input() isAdmin: boolean;
  @Input() text = 'general.overview.contingent_l';
  @Input() showUnlimited = false;
}
