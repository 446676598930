<div class="card">
  <div class="card-body">
    <div class="profile__top-section">
      <div
        class="profile-address"
        [class.transparent]="!isProfileActiveControl.value || isProfileOutdated"
      >
        <div class="profile-name__header d-flex flex-wrap">
          <app-badge
            class="mr5"
            [color]="badgeColor.PRIMARY_ACCENT_DARK"
            *ngIf="isProjectSearchProfile"
          >
            {{ 'project_l' | translate }}
          </app-badge>
          <app-badge class="mr5" [color]="badgeColor.NEUTRAL">
            {{ 'FLATTYPES_' + searchProfile?.propertyType | translate }}
          </app-badge>
          <app-badge
            *ngIf="searchProfile?.createdByPS"
            class="mr5"
            [color]="badgeColor.PRIMARY_ACCENT_LIGHT"
          >
            {{ 'internal_list.sp_created_by_ps.l' | translate }}
          </app-badge>
          <app-badge
            *ngIf="showMarketingTypeBadge"
            class="mr5"
            [color]="badgeColor.SECONDARY_ACCENT"
          >
            {{ 'MARKETINGTYPES_' + searchProfile?.marketingType | translate }}
          </app-badge>
          <app-badge
            *ngIf="searchProfile?.searchingSince"
            [color]="badgeColor.LIGHT_BLUE"
          >
            {{ 'general.search_profile_searching_since_date_l' | translate}} {{
            searchProfile?.searchingSince | appDateTime }}
          </app-badge>
        </div>

        {{ searchProfile.name || ('search_profile.name_l' | translate:{ city:
        searchProfileCities }) }}

        <div
          *ngIf="searchProfile.type === typeRadius && !small"
          class="profile-address__details"
        >
          {{ searchProfile.address | appAddress }}
        </div>
        <div
          *ngIf="searchProfile.type === typeDistrict && !small"
          class="profile-address__details"
        >
          <div
            class="profile-address__details--cities"
            *ngFor="let district of districtsMap | keyvalue"
          >
            <span>{{district.key}}</span>
            <span>
              ({{getMappedDistrictNames(district.value | appSort:'name')}})
            </span>
          </div>
        </div>
        <div
          *ngIf="searchProfile?.projectLandingPageUrl"
          class="d-flex align-items-center"
        >
          <div class="icon icon--export me-2"></div>
          <a
            rel="noopener noreferrer"
            [href]="searchProfile?.projectLandingPageUrl"
            target="_blank"
          >
            {{ 'search_profile.project_landing_page_url_l' | translate}}
          </a>
        </div>
      </div>

      <div *ngIf="!hideMenu" class="profile-menu">
        <app-slide-toggle
          class="mt5"
          *ngIf="showToggleOption && !isProfileOutdated"
          [formControl]="isProfileActiveControl"
          (change)="onToggleProfile()"
          ngbTooltip="{{(isProfileActiveControl.value ? 'search_profile.active_tooltip_l' : 'search_profile.inactive_tooltip_l') | translate}}"
          tooltipClass="hide-on-mobile"
        ></app-slide-toggle>
        <span class="outdated-profile" *ngIf="isProfileOutdated"
          >{{'search_profile.outdated_l' | translate}}</span
        >
        <app-context-menu *ngIf="!searchProfile?.createdByPS" class="ms-1">
          <div menu-content>
            <app-context-menu-item (clickEvent)="onEditProfile()"
              >{{ 'search_profile.edit_a' | translate }}</app-context-menu-item
            >
            <app-context-menu-item (clickEvent)="onDeleteProfile()"
              >{{ 'search_profile.delete_a' | translate
              }}</app-context-menu-item
            >
          </div>
        </app-context-menu>
      </div>
    </div>

    <div class="section__subdivider"></div>

    <div
      class="profile-details search-profile__details d-flex"
      [class.transparent]="!isProfileActiveControl.value || isProfileOutdated"
    >
      <div
        class="profile-details__info rent d-flex align-items-center"
        [class.order-1]="!small"
        [class.order-4]="small"
      >
        <ng-container *ngIf="isInfinity; else normal">
          {{'general.infinity_value_l' | translate}}
        </ng-container>
        <ng-template #normal>
          <span *ngIf="!small">max&nbsp;</span>
          {{((!isSalesObject ? upperBoundRent : priceUpperBoundSales) |
          currency:'EUR': 'symbol' : '1.0-0') || '-' }}
        </ng-template>
        <span *ngIf="small && !isSalesObject">
          / {{'general.month_l' | translate}}</span
        >
      </div>

      <div
        *ngIf="isFlat || isCommercial"
        class="profile-details__info d-flex align-items-center order-2 pe-3 pe-md-0"
      >
        <svg-icon
          src="/assets/images/icons/icon-area.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color rect line polyline path mr5"
        ></svg-icon>
        <span *ngIf="!small">min&nbsp;</span> {{ searchProfile.lowerBoundSize |
        number }} m<sup>2</sup>
      </div>

      <div
        *ngIf="isFlat"
        class="profile-details__info d-flex align-items-center order-3"
      >
        <svg-icon
          src="/assets/images/icons/icon-rooms.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color rect line polyline path mr5"
        ></svg-icon>
        <span *ngIf="!small"> min&nbsp; </span>
        {{ searchProfile.lowerBoundRooms }}
        <span *ngIf="!small">
          &nbsp;{{ (searchProfile.lowerBoundRooms === 1 ? 'general.room_l' :
          'general.rooms_l') | translate}}
        </span>
      </div>
    </div>
  </div>
</div>
