<div class="color-picker__container d-flex flex-wrap">
  <div class="color-picker-input col-3 p0">
    <input
      [style.background]="value"
      [(colorPicker)]="value"
      [cpOKButton]="true"
      [disabled]="isDisabled"
      [required]="required"
      readonly
    />
  </div>
  <div class="color-text col-9 p0">
    <input
      type="text"
      appAutofocus="{{autoFocus}}"
      [(ngModel)]="value"
      (ngModelChange)="valueChange($event)"
      class="form-control"
      [placeholder]="'forms.pick_a_color' | translate"
      [disabled]="isDisabled"
      [required]="required"
    />
  </div>
</div>
<div
  class="default-s color-picker-description"
  *ngIf="description"
  [innerHTML]="description"
></div>
