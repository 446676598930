<form [formGroup]="form" class="custom-questions__container">
  <div formArrayName="customQuestions" class="custom-questions__list card">
    <div
      class="custom-questions__item mb16"
      *ngFor="let customQuestion of customQuestionsForm.controls; index as i"
    >
      <div class="custom-questions__item-content" [formGroupName]="i">
        <app-custom-questions-field
          formControlName="answer"
          [customQuestion]="customQuestions[i]"
          (ngModelChange)="addSelectedRangeOption(i)"
        ></app-custom-questions-field>

        <div
          class="custom-question-field__range-content"
          *ngIf="SelectedAnswerIsDesired(i)"
        >
          <app-form-field *ngIf="isRangeDate(i)">
            <app-date formControlName="selectedRange"> </app-date>
          </app-form-field>
          <app-form-field *ngIf="isRangeValue(i)">
            <input
              type="number"
              appInput
              [placeholder]="'custom_questions.type_answer_l' | translate"
              formControlName="selectedRange"
              class="form-control"
            />
          </app-form-field>
        </div>

        <div *ngIf="showHint(i)">
          <app-form-field>
            <app-form-field-label
              >{{ customQuestions[i].commentHint | translate
              }}</app-form-field-label
            >
            <textarea
              appInput
              count="true"
              maxValue="1000"
              formControlName="comment"
              class="form-control"
            ></textarea>
          </app-form-field>
        </div>
      </div>
    </div>
  </div>
</form>
