import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-list-navigation-v2',
  templateUrl: './list-navigation-v2.component.html',
  styleUrls: ['./list-navigation-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListNavigationV2Component {
  @Input() navConfig: any[];
  @Input() totals: number[];
  @Input() selectedNav: string;
  @Input() disabled: boolean;
  @Input() medium: boolean;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onNavSelect = new EventEmitter<any>();

  navSelect(tab: any) {
    if (this.disabled || tab.disabled) return;

    this.onNavSelect.emit(tab.value);
  }
}
