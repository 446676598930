<app-attachments-list
  [showDownload]="showDownload"
  [disableDownload]="disableDownload"
  [blockDownload]="blockDownload"
  [showRemove]="showRemove"
  [showPreview]="showPreview"
  [isDocument]="isDocument"
  [attachments]="attachmentsArray"
  [orderable]="orderable"
  [editable]="editable"
  (remove)="onRemove($event)"
  (download)="onDownload($event)"
  (preview)="onPreview($event)"
  (moveUp)="onMoveUp($event)"
  (moveDown)="onMoveDown($event)"
  (updateAttachment)="onUpdateAttachment($event)"
></app-attachments-list>
<app-file-upload
  *ngIf="!hideUpload"
  [disabled]="disabled"
  [required]="required"
  [multiple]="multiple"
  [size]="size"
  [accept]="accept"
  [subInformation]="subInformation"
  [showButton]="showAddButton"
  (changeFileUpload)="onChange($event)"
></app-file-upload>
