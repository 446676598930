<div
  ngbTooltip="{{bannerInfo?.tooltip | translate}}"
  class="property-banner__content"
  [ngClass]="bannerInfo?.color"
>
  <i class="icon icon--check-framed" *ngIf="bannerInfo?.icon === 'check'"></i>
  {{ bannerInfo?.label | translate }}
</div>
<!--<div *ngIf="showExplanation">-->
<!--  <span class="badge badge-explanation">{{ bannerInfo?.explanation | translate }}</span>-->
<!--</div>-->
