<div
  ngbDropdown
  [ngModel]="value"
  [container]="container"
  [required]="required"
  ngDefaultControl
  autoClose="outside"
  class="dropdown-select__container"
  (openChange)="setShowFilters($event)"
>
  <button
    [attr.id]="this.uniqueElementId"
    class="btn"
    type="button"
    [class]="toggleClass"
    ngbDropdownToggle
    [disabled]="disabled"
  >
    <div class="d-flex justify-content-between">
      <div
        class="dropdown-select__inner"
        *ngIf="!isValue; else optionSelectedButton"
      >
        <ng-content select="[dropdown-button]"></ng-content>
      </div>

      <div class="d-flex align-items-center">
        <i
          *ngIf="clearable && value"
          class="icon icon--close ms-2"
          (click)="reset($event)"
        ></i>
        <i
          [ngClass]="isDropdownOpened ? 'chevron-rotated' : 'chevron-non-rotated'"
          class="icon icon--chevron-down ms-2"
        ></i>
      </div>
    </div>

    <ng-template #optionSelectedButton>
      <span class="dropdown-toggle__inner">
        <ng-template
          [ngTemplateOutlet]="templateRef"
          [ngTemplateOutletContext]="{$implicit: selectedItem || {}}"
        ></ng-template>
      </span>
    </ng-template>
  </button>

  <div
    [ngClass]="{menuClass: !!menuClass, 'dropdown-menu': true}"
    ngbDropdownMenu
    [attr.aria-labelledby]="this.uniqueElementId"
    [class.relative-positioned]="relativePositioned"
  >
    <div class="dropdown__items">
      <ng-container *ngIf="items?.length">
        <app-infinite-scroll (scrolled)="onScroll()">
          <div
            class="dropdown__item"
            [ngClass]="{'active': (item?.value === value)}"
            *ngFor="let item of items; index as i"
            (click)="onItemClick(item)"
          >
            <ng-template
              [ngTemplateOutlet]="templateRef"
              [ngTemplateOutletContext]="{$implicit: item}"
            ></ng-template>
            <hr
              *ngIf="showSeperator && items?.length > 0 && items?.length - 1 !== i"
            />
          </div>
        </app-infinite-scroll>
      </ng-container>
    </div>
  </div>
</div>
