<div
  class="circle__container"
  [ngStyle]="{height: outerRadius + 'px', width: outerRadius + 'px'}"
>
  <div class="circle-wrapper" [ngClass]="!isAnonymous ? circleClass : null">
    <svg
      *ngIf="path"
      class="svg-element circle-svg"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xml:space="preserve"
      [attr.viewBox]="viewBox"
    >
      <path [attr.d]="path"></path>
      <path class="circle-path" *ngIf="hasRestPath" [attr.d]="restPath"></path>
    </svg>
    <ng-container *ngIf="!isAnonymous; else showSandClock">
      <ng-container *ngIf="!scoreObscured; else showScoreObscured">
        <div *ngIf="!showPercentage" class="circle-number">
          {{displayedValue | number:'1.1-1'}}
        </div>
        <div
          *ngIf="showPercentage"
          class="circle-number cirlce-number--percentage"
        >
          {{value | number:'1.0-0'}} %
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #showSandClock>
  <svg-icon
    src="/assets/images/icons/sanduhr.svg"
    [applyClass]="true"
    [svgStyle]="{'width.px': 30}"
    class="default-svg-color rect path unlock-icon"
  ></svg-icon>
</ng-template>

<ng-template #showScoreObscured>
  <div class="circle-number">{{ scoreObscured }}</div>
</ng-template>
