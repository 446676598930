import { AddonDiscount } from './addon-discount.model';
import { CalculatedPrices } from './product.model';

export enum AddonType {
  EMAILEDITOR = 'EMAILEDITOR',
  DATAINSIGHTS = 'DATAINSIGHTS',
  PORTALPUBLISH = 'PORTALPUBLISH',
  SHORTLIST = 'SHORTLIST',
  BRANDING = 'BRANDING',
  IMPORT = 'IMPORT',
  AGENT = 'AGENT',
  SCHUFA = 'SCHUFA',
  CUSTOM_QUESTIONS = 'CUSTOM_QUESTIONS',
  SELF_DISCLOSURE = 'SELF_DISCLOSURE',
  REPORTING = 'REPORTING',
  DIGITAL_CONTRACT = 'DIGITAL_CONTRACT',
  INTERNAL_TENANT_POOL = 'INTERNAL_TENANT_POOL',
  OFFLINE_USER = 'OFFLINE_USER',
  AUTOMATED_RENTAL_PROCESS = 'AUTOMATED_RENTAL_PROCESS',
  HAUFE_API = 'HAUFE_API',
  HPMODULE = 'HPMODULE',
  HOMEPAGE_MODULE_REST = 'HOMEPAGE_MODULE_REST',
  SALES_OBJECTS_MODULE = 'SALES_OBJECTS_MODULE',
  RESIDENT_APP = 'RESIDENT_APP',
  TICKETING = 'TICKETING',
  PAYMENT = 'PAYMENT',
  EMAIL_SENDER = 'EMAIL_SENDER',
  DOCUMENT_MANAGEMENT = 'DOCUMENT_MANAGEMENT',
  CUSTOMER_COOPERATION = 'CUSTOMER_COOPERATION',
  HAUFE_BACKCHANNEL = 'HAUFE_BACKCHANNEL',
  FREE_AGENT = 'FREE_AGENT',
  WHITELABEL_CUSTOMER_PORTAL = 'WHITELABEL_CUSTOMER_PORTAL',
  CONSUMPTION_DATA_DOCUMENTS = 'CONSUMPTION_DATA_DOCUMENTS',
  CONSUMPTION_DATA_VISUALISATION = 'CONSUMPTION_DATA_VISUALISATION',
  COOPERATIVE = 'COOPERATIVE'
}

export enum AddonPriceType {
  FIX = 'FIX',
  CUSTOM = 'CUSTOM',
  MINIMUM = 'MINIMUM'
}

// TODO clean fix -> used in workaround in admin-addon.component.ts / subsription-details.component.ts
export interface AddonSelected {
  addon: Addon;
  selected: boolean;
}

export interface Addon {
  id?: string;
  name?: string;
  description?: string;
  type?: AddonType;
  discount?: AddonDiscount;
  amounts?: AddonAmount;
  price?: CalculatedPrices;
  nextPrice?: CalculatedPrices;
  renew?: boolean;
  dueDate?: boolean;
  booked?: boolean;
  priceType?: AddonPriceType;
}

export interface AddonAmount {
  renew: number;
  expire: number;
}
