import { MultiSelectGroupedIntermediateDropdownComponent } from 'libs/components/atoms/multi-select-grouped-intermediate-dropdown/multi-select-grouped-intermediate-dropdown.component';
import { SingleSelectDropdownStepperComponent } from 'libs/components/atoms/single-select-dropdown-stepper/single-select-dropdown-stepper.component';
import { MultiSelectDropdownStepperComponent } from 'libs/components/atoms/multi-select-dropdown-stepper/multi-select-dropdown-stepper.component';
import { BadgeComponent } from './badge/badge.component';
import { CardComponent } from './card/card.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { ImageComponent } from './image/image.component';
import { LocaleFlagComponent } from './image/locale-flag/locale-flag.component';
import { TextFieldComponent } from './input-fields/text-field/text-field.component';
import { MultiSelectGroupedDropdownComponent } from './multi-select-grouped-dropdown/multi-select-grouped-dropdown.component';
import { SalesLegalCheckboxesPreviewComponent } from './sales-legal-checkboxes-preview/sales-legal-checkboxes-preview.component';
import { ButtonComponent } from './button/button.component';
import { InfoCollapseComponent } from './info-collapse/info-collapse.component';
import { ApplicationConfirmationComponent } from './application-confirmation/application-confirmation.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { GroupedDistrictsDetailComponent } from './grouped-districts-detail/grouped-districts-detail.component';
import { GroupedCityMapComponent } from './grouped-city-map/grouped-city-map.component';
import { InformationBoxComponent } from './information-box/information-box.component';
import { QuotaInfoBadgeComponent } from './quota-info-badge/quota-info-badge.component';
import { MultiSelectDropdownV2Component } from './multi-select-dropdown-v2/multi-select-dropdown-v2.component';
import { SingleSelectDropdownComponent } from './single-select-dropdown/single-select-dropdown.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { LoadMoreComponent } from './load-more/load-more.component';

export const atomsComponents = [
  TextFieldComponent,
  ImageComponent,
  BadgeComponent,
  CardComponent,
  ButtonComponent,
  BreadcrumbsComponent,
  MultiSelectGroupedDropdownComponent,
  SalesLegalCheckboxesPreviewComponent,
  InfoCollapseComponent,
  ApplicationConfirmationComponent,
  InfiniteScrollComponent,
  GroupedDistrictsDetailComponent,
  GroupedCityMapComponent,
  InformationBoxComponent,
  LocaleFlagComponent,
  QuotaInfoBadgeComponent,
  MultiSelectDropdownV2Component,
  SingleSelectDropdownComponent,
  TextEditorComponent,
  LoadMoreComponent,
  MultiSelectGroupedIntermediateDropdownComponent,
  SingleSelectDropdownStepperComponent,
  MultiSelectDropdownStepperComponent
];
