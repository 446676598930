import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { PropertyPortalStates } from '@ui/shared/models';

const stateToLabelMapping = {
  [PropertyPortalStates.ACTIVE]: 'success',
  [PropertyPortalStates.ERROR]: 'error',
  [PropertyPortalStates.PENDING]: 'warning',
  [PropertyPortalStates.DEACTIVATED]: 'default'
};

@Directive({
  selector: '[appPortalStatusClass]'
})
export class PortalStatusClassDirective implements OnChanges {
  @Input() status: string;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.status.firstChange) {
      this.renderer.removeClass(
        this.el.nativeElement,
        stateToLabelMapping[changes.status.previousValue]
      );
    }
    this.renderer.addClass(
      this.el.nativeElement,
      stateToLabelMapping[changes.status.currentValue]
    );
  }
}
