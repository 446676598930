<ng-container *ngIf="!isLoading; else loading">
  <ng-container *ngIf="data?.length; else noContent">
    <div class="position-relative">
      <ng-scrollbar
        class="data-table"
        [ngClass]="{'data-table--with-sticky-last-cell': withStickyLastCell}"
        trackClass="data-table__scrollbar"
        [track]="'horizontal'"
        [position]="scrollbarPosition"
        [visibility]="'native'"
        [autoHeightDisabled]="false"
        (scroll)="onScroll()"
        *ngIf="columns?.length"
      >
        <table
          class="data-table__table"
          [class.data-table__no-border-spacing]="removeBorderSpacing"
        >
          <thead [hidden]="hideHeader">
            <tr>
              <th
                *ngFor="let column of columns"
                class="data-table__head-cell"
                [class.same-background]="sameBackgroundHeader"
              >
                <ng-container
                  *ngIf="headerTemplatesMap[column.name]; else defaultHeaderTemplate"
                >
                  <div
                    [class]="column.alignment ? 'data-table__head-cell--align-' + column.alignment : 'data-table__head-cell--align-left'"
                    class="data-table__head-cell--inserted-label"
                  >
                    <ng-container
                      [ngTemplateOutlet]="headerTemplatesMap[column.name]"
                    ></ng-container>
                  </div>
                </ng-container>
                <ng-template #defaultHeaderTemplate>
                  <span
                    [class]="column.alignment ? 'data-table__head-cell--align-' + column.alignment : 'data-table__head-cell--align-left'"
                    class="data-table__head-cell--label"
                    >{{ column?.label | translate }}<span
                      *ngIf="column.required"
                      class="required-marker ng-star-inserted"
                      >&nbsp;*</span
                    >
                  </span>
                </ng-template>
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let item of data">
              <tr
                class="data-table__row"
                (click)="onRowClick(item)"
                [ngClass]="{'data-table__row--specific': highlightKey && item[highlightKey]}"
              >
                <td
                  class="data-table__cell"
                  *ngFor="let column of columns"
                  [attr.data-qa-column-name]="column.name"
                >
                  <ng-container
                    *ngIf="cellTemplatesMap[column.name]; else defaultTemplate"
                  >
                    <ng-container
                      [ngTemplateOutlet]="cellTemplatesMap[column.name]"
                      [ngTemplateOutletContext]="{ $implicit: item }"
                    ></ng-container>
                  </ng-container>

                  <ng-template #defaultTemplate>
                    {{ item[column.name] || '-' }}
                  </ng-template>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </ng-scrollbar>
    </div>
  </ng-container>
</ng-container>
<div
  #tableAnchor
  class="data-table__anchor"
  *ngIf="enableVariableScrollbar"
></div>

<ng-template #noContent>
  <div class="container-fluid">
    <div class="row mt-5">
      <div class="col-12">
        <app-no-data-disclaimer
          [title]="noDataHeader"
          [description]="noDataMessage"
          [showImage]="noDataShowImage"
        ></app-no-data-disclaimer>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #loading>
  <div class="data-table__skeleton-row mb-2"></div>
  <div
    *ngFor="let item of createRange(skeletonRowsAmount); let i = index"
    class="data-table__skeleton-row skeleton mb-2"
    [style]="{ opacity: (i/skeletonRowsAmount * -1) + 1 }"
  ></div>
</ng-template>
