import { Component, Input, OnChanges } from '@angular/core';

import { ProjectStatus } from '@ui/shared/models';
import { PROJECT_STATUS_CONFIGS } from 'libs/config/status-pipeline.config';

import {
  CurrentStatusConfig,
  PipelineSteps
} from 'libs/components/molecules/status-pipelines/status-pipeline-base.config';

import { projectSteps } from './project-status-pipeline.config';

@Component({
  selector: 'app-project-status-pipeline',
  templateUrl: './project-status-pipeline.component.html'
})
export class ProjectStatusPipelineComponent implements OnChanges {
  @Input() status: ProjectStatus;
  @Input() pageView: boolean;

  public steps: PipelineSteps;
  public currentStatus: CurrentStatusConfig;

  private getCurrentStatus(): CurrentStatusConfig {
    this.steps = projectSteps;
    const config = PROJECT_STATUS_CONFIGS[this.status];
    return {
      ...config,
      pipeline: {
        ...config.pipeline,
        showAsFullscreen: typeof config.pipeline.stepId === 'undefined'
      }
    };
  }

  ngOnChanges(): void {
    this.currentStatus = this.getCurrentStatus();
  }
}
