<div
  class="single-select-dropdown"
  ngbDropdown
  ngDefaultControl
  [required]="required"
  [ngModel]="value"
  [class.disabled]="disabled || readOnly"
>
  <div
    ngbDropdownAnchor
    class="form-control d-flex align-items-center"
    [ngClass]="{'bordered-radius-bottom colored-border': dropdown?.isOpen()}"
  >
    <input
      appInput
      class="col-12"
      type="text"
      [placeholder]="selectedItem?.[placeholderSelectedItemKey] || ((placeholder || 'general.search_fulltext_l') | translate)"
      [formControl]="searchControl"
      [class.search-disabled]="disableSearch"
      [class.selected-item-placeholder]="selectedItem && !searchControl.value && dropdown?.isOpen()"
      (focus)="dropdown.open()"
      (focusout)="touch()"
    />
    <ng-container *ngIf="!isLoadingMenuItems; else loading">
      <svg-icon
        *ngIf="selectedItem"
        class="icon icon-remove"
        src="/assets/images/icons/icon-close.svg"
        [svgStyle]="{'width.px': 10, 'height.px': 10}"
        (click)="removeSelectedItem()"
      ></svg-icon>
      <svg-icon
        class="icon icon-dropdown"
        src="/assets/images/icons/icon-dropdown.svg"
        [class.rotate]="dropdown?.isOpen()"
        (click)="dropdown.toggle()"
      ></svg-icon>
    </ng-container>

    <ng-template #loading>
      <div class="loading-area-input">
        <app-loading-spinner></app-loading-spinner>
      </div>
    </ng-template>
  </div>

  <div
    class="dropdown-menu"
    [class.relative-positioned]="relativelyPositioned"
    ngbDropdownMenu
  >
    <div class="dropdown__items" [class.d-none]="!dropdown?.isOpen()">
      <ng-container *ngIf="items?.length; else minCharacterRequirement">
        <app-infinite-scroll (scrolled)="onScroll()">
          <div
            class="dropdown__item"
            *ngFor="let item of items"
            (click)="apply(item)"
          >
            <ng-template
              [ngTemplateOutlet]="templateRef"
              [ngTemplateOutletContext]="{$implicit: item}"
            ></ng-template>
          </div>
        </app-infinite-scroll>
      </ng-container>

      <ng-template #minCharacterRequirement>
        <ng-container
          *ngIf="searchCharactersLimit > 0 && searchControl.value.length < searchCharactersLimit; else noItems"
        >
          <div class="no-items-content">
            <span
              >{{ 'single_select_dropdown.minimum_character_l' |
              translate:{value: searchCharactersLimit} }}
            </span>
          </div>
        </ng-container>
      </ng-template>

      <ng-template #noItems>
        <div class="no-items-content">
          <ng-content select="[no-items-content]"></ng-content>
        </div>
      </ng-template>
    </div>
  </div>
</div>
