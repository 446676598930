import {
  Component,
  Input,
  ChangeDetectionStrategy,
  Output,
  EventEmitter
} from '@angular/core';
import { Step } from '../models';

@Component({
  selector: 'app-wizard-progress',
  templateUrl: './wizard-progress.component.html',
  styleUrls: ['./wizard-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WizardProgressComponent {
  @Input() steps: Step[];
  @Input() currentStepNumber: number;
  @Output() selectStep = new EventEmitter<Step>();

  public onSelectStep(step: Step) {
    return this.selectStep.emit(step);
  }
}
