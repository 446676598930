import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { delay, take } from 'rxjs/operators';

import {
  CustomQuestion,
  CustomQuestionOption,
  CustomQuestionSubType,
  CustomQuestionType
} from '@ui/shared/models';
import { isBoolean } from 'libs/utils';
import {
  dateRangeValidator,
  equalValidator,
  numberRangeValidator,
  optionsValidator
} from 'libs/components/legacy/form/controls/validation/validators';

interface CustomQuestionValue extends CustomQuestion {
  desiredBooleanAnswer: boolean;
  policies?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-custom-question-form',
  templateUrl: './custom-question-form.component.html',
  styleUrls: ['./custom-question-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomQuestionFormComponent),
      multi: true
    }
  ]
})
export class CustomQuestionFormComponent
  implements OnInit, ControlValueAccessor
{
  @Input() readonly: boolean;
  @Input() global: boolean;
  @Output() validityChange = new EventEmitter<boolean>();

  public form: FormGroup;
  public value: CustomQuestion;

  public types = [
    { name: 'CUSTOMQUESTIONSTYPE_BOOLEAN', value: CustomQuestionType.BOOLEAN },
    { name: 'CUSTOMQUESTIONSTYPE_SELECT', value: CustomQuestionType.SELECT },
    {
      name: 'CUSTOMQUESTIONSTYPE_MULTISELECT',
      value: CustomQuestionType.MULTISELECT
    },
    { name: 'CUSTOMQUESTIONSTYPE_RANGE', value: CustomQuestionType.RANGE }
  ];

  public rangeTypes = [
    {
      name: 'CUSTOMQUESTIONSTYPE_RANGE_DATE',
      value: CustomQuestionSubType.RANGE_DATE
    },
    {
      name: 'CUSTOMQUESTIONSTYPE_RANGE_VALUE',
      value: CustomQuestionSubType.RANGE_VALUE
    }
  ];

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  public get getKnockoutInput() {
    return this.form.get('knockout');
  }

  constructor(private fb: FormBuilder) {}

  public ngOnInit() {
    const initImportance = this.global ? 5 : null;
    this.form = this.fb.group({
      title: ['', Validators.required],
      type: [null, Validators.required],
      subType: [null],
      policies: [null, Validators.requiredTrue],
      commentAllowed: [false],
      commentHint: [],
      options: this.fb.array([], optionsValidator),
      desiredBooleanAnswer: [true, Validators.required],
      scoring: [true],
      importance: [initImportance],
      knockout: [false],
      global: [this.global]
    });

    if (!this.readonly) {
      this.registerListeners();
    }

    this.typeControl.valueChanges.pipe(untilDestroyed(this)).subscribe(type => {
      const options = this.form.controls['options'] as FormArray;
      if (type !== CustomQuestionType.RANGE) {
        options.controls.forEach((group: FormGroup) => {
          group.removeControl('rangedOptions');
        });
        this.subTypeControl.patchValue(null);
        return;
      }
      this.subTypeControl.patchValue(CustomQuestionSubType.RANGE_DATE);
      return options.controls.forEach((group: FormGroup) => {
        if (group.get('desired').value) {
          group.addControl('rangedOptions', this.addRangedOptions());
        }
      });
    });
  }

  public addOption() {
    const option = this.fb.group({
      name: ['', Validators.required],
      value: [this.options.controls.length],
      desired: [false]
    });
    this.options.push(option);
  }

  public removeOption(index: number) {
    this.options.removeAt(index);
  }

  public writeValue(value: CustomQuestion) {
    this.value = value || {};

    this.handleOptionControls(null, this.value.type, this.value.options);

    const desiredBooleanAnswer = this.optionToBoolean(this.value.options);
    const valueToPatch = {
      ...(value || {}),
      desiredBooleanAnswer
    };

    this.form.patchValue(valueToPatch);
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public get typeControl() {
    return this.form.get('type');
  }

  public get subTypeControl() {
    return this.form.get('subType');
  }

  public get desiredBooleanAnswer() {
    return this.form.get('desiredBooleanAnswer');
  }

  public get options() {
    return this.form.get('options') as FormArray;
  }

  public get showCommentHint() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.form.value.commentAllowed;
  }

  public get isBoolean() {
    return this.form.value.type === CustomQuestionType.BOOLEAN;
  }

  public get isSingleSelect() {
    return this.form.value.type === CustomQuestionType.SELECT;
  }

  public get isMultiSelect() {
    return this.form.value.type === CustomQuestionType.MULTISELECT;
  }

  public get isRange() {
    return this.form.value.type === CustomQuestionType.RANGE;
  }

  public get isRangeDate() {
    return this.form.value.subType === CustomQuestionSubType.RANGE_DATE;
  }
  public get isRangeValue() {
    return this.form.value.subType === CustomQuestionSubType.RANGE_VALUE;
  }

  public hasRangedOptions(group: AbstractControl) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return Object.prototype.hasOwnProperty.call(group.value, 'rangedOptions');
  }

  public get isSelect() {
    return this.isSingleSelect || this.isMultiSelect || this.isRange;
  }

  private isTypeSelect(type: CustomQuestionType) {
    return (
      type === CustomQuestionType.SELECT ||
      type === CustomQuestionType.MULTISELECT ||
      type === CustomQuestionType.RANGE
    );
  }

  public preferredAnswerClick(option: AbstractControl, subType?: string) {
    if (this.readonly) return;
    const group = option as FormGroup;
    if (subType) {
      const rangedOptions = group.get('rangedOptions') as FormGroup;
      Object.keys(rangedOptions.controls).forEach(key => {
        const subTypeControl = rangedOptions.get(key);
        if (key === subType) {
          return subTypeControl
            .get('desired')
            .patchValue(!subTypeControl.get('desired').value);
        }
        return subTypeControl.get('desired').patchValue(false);
      });
      return;
    }
    const desiredControl = group.get('desired');
    desiredControl.valueChanges.pipe(take(1)).subscribe(value => {
      if (value && this.isRange) {
        group.addControl('rangedOptions', this.addRangedOptions());
      } else {
        group.removeControl('rangedOptions');
      }
    });
    desiredControl.patchValue(!desiredControl.value);
  }

  public isDesiredOption(index: number) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.options.get([index, 'desired']).value;
  }

  updateForm(group: AbstractControl) {
    const rangedOptions = group.get('rangedOptions');
    const minControl = rangedOptions.get('min');
    const maxControl = rangedOptions.get('max');
    minControl.get('value').updateValueAndValidity();
    maxControl.get('value').updateValueAndValidity();
  }

  private addRangedOptions() {
    const rangedOptions = this.fb.group(
      {
        min: this.fb.group({
          value: [null, Validators.required],
          desired: [false]
        }),
        max: this.fb.group({
          value: [null, Validators.required],
          desired: [false]
        })
      },
      {
        validator: optionsValidator
      }
    );
    return this.setMinMaxValidator(rangedOptions);
  }

  private setMinMaxValidator(group: FormGroup) {
    const minControl = group.get('min');
    const maxControl = group.get('max');
    group.valueChanges.subscribe(() => {
      if (this.isRangeValue) {
        minControl
          .get('value')
          .setValidators([
            numberRangeValidator(maxControl.get('value'), true),
            equalValidator(maxControl.get('value')),
            Validators.required
          ]);
        maxControl
          .get('value')
          .setValidators([
            numberRangeValidator(minControl.get('value')),
            equalValidator(minControl.get('value')),
            Validators.required
          ]);
      }
      if (this.isRangeDate) {
        minControl
          .get('value')
          .setValidators([
            dateRangeValidator(maxControl.get('value'), true),
            equalValidator(maxControl.get('value')),
            Validators.required
          ]);
        maxControl
          .get('value')
          .setValidators([
            dateRangeValidator(minControl.get('value')),
            equalValidator(minControl.get('value')),
            Validators.required
          ]);
      }
    });
    return group;
  }

  private handleOptionControls(
    previousType: CustomQuestionType,
    currentType: CustomQuestionType,
    optionsValue: CustomQuestionOption[] = null
  ) {
    if (previousType === currentType) return;

    const options = optionsValue || [{}, {}];

    if (!options) return;

    const groups = options.map((option, index) =>
      this.fb.group({
        name: [option.name, Validators.required],
        value: [option.value || option.value === false ? option.value : index],
        desired: [option.desired || false]
      })
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.options.value.forEach(() => this.removeOption(0));
    groups.forEach(group => this.options.push(group));
  }

  private registerListeners() {
    this.form.valueChanges.subscribe((value: CustomQuestionValue) => {
      this.value = value;

      const { desiredBooleanAnswer, policies, ...customQuestion } = value;
      const { commentHint, commentAllowed } = customQuestion;

      const options =
        value.type === CustomQuestionType.BOOLEAN
          ? this.booleanToOption(value.desiredBooleanAnswer)
          : value.options;

      const valueToSend = {
        ...customQuestion,
        commentHint: commentAllowed ? commentHint : null,
        options
      };

      this.onChange(valueToSend);
      this.onTouch();
    });

    this.form.statusChanges
      .pipe(delay(0))
      .subscribe(value => this.validityChange.emit(value === 'VALID'));

    this.typeControl.valueChanges.subscribe(type => {
      if (this.isTypeSelect(type)) {
        this.options.enable();
        this.desiredBooleanAnswer.disable();
      } else {
        this.options.disable();
        this.desiredBooleanAnswer.enable();
      }
    });
  }

  private optionToBoolean(options: CustomQuestionOption[]) {
    const desiredOption = options.find(option => option.desired);

    if (!desiredOption) return null;

    return desiredOption.value;
  }

  private booleanToOption(value: boolean) {
    return [
      { value: true, desired: isBoolean(value) && !!value },
      { value: false, desired: isBoolean(value) && !value }
    ];
  }
}
