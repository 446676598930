import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import {
  CityPacket,
  Constants,
  InternationalizationSettings,
  MarketingType,
  NameValue,
  ObjectType,
  Project,
  PropertyType,
  RegistrationLinkObjectTypes,
  SearchProfile,
  SearchProfileType
} from '@ui/shared/models';
import moment from 'moment';
import { SearchProfileService } from 'libs/services';
import {
  isPropertyTypeCommercial,
  isPropertyTypeFlat,
  isPropertyTypeGarage,
  isSalesObject
} from 'libs/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { startWith } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-search-profile-details',
  templateUrl: './search-profile-details.component.html',
  styleUrls: ['./search-profile-details.component.scss'],
  providers: [SearchProfileService]
})
export class SearchProfileDetailsComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() searchProfile: SearchProfile;
  @Input() constants: Constants;
  @Input() landlordCityPacket: CityPacket;
  @Input() onlyShowConfiguredCityPartsToUser: boolean;
  @Input() showMarketingType: boolean;
  @Input() project: Project;
  @Input() inInternalPool: boolean;
  @Input() internalizationSettings: InternationalizationSettings;
  @Input() enableEditingOfSearchingSinceDate = false;
  @Input() isRegistration = false;
  @Input() defaultObjectTypes?: RegistrationLinkObjectTypes;

  public locationError: string;
  public garageTypesConstants: NameValue<string>[];
  public commercialSubTypes: NameValue<string>[];
  public objectTypeConstants: NameValue<string>[];
  public propertyTypesConstants: NameValue<string>[];
  public flatTypesConstants: NameValue<string>[];
  public commercialTypesConstants: NameValue<string>[];
  public houseTypesConstants: NameValue<string>[];

  public dateLimit: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };
  public maxUpperBoundRent = 0;
  public minUpperBoundRent = 0;
  public stepsForUpperBoundRent = 0;

  public get isNewSearchProfile() {
    return !this.searchProfile?.id;
  }

  private get searchProfileTypeControl() {
    return this.form.get('type');
  }

  public get addressForm() {
    return this.form.get('address') as FormGroup;
  }

  public get propertyTypeControl() {
    return this.form.get('propertyType') as FormControl;
  }

  public get garageTypesControl() {
    return this.form.get('garageTypes') as FormControl;
  }

  public get commercialDataGroup() {
    return this.form.get('commercialData') as FormGroup;
  }

  public get commercialTypeForm() {
    return this.commercialDataGroup.get('commercialType');
  }

  public get isProjectSearchProfile() {
    return (
      this.searchProfileTypeControl?.value === SearchProfileType.PROJECT ||
      this.project?.id
    );
  }

  public get flatDataGroup() {
    return this.form.get('flatData') as FormGroup;
  }

  public get commercialSubTypesForm() {
    return this.commercialDataGroup.get('commercialSubTypes') as FormGroup;
  }

  public get districtsFormArray() {
    return this.form.get('districts') as FormArray;
  }

  public get isFlat() {
    return isPropertyTypeFlat(this.propertyTypeControl.value);
  }

  public get flatDataObjectTypesControl() {
    return this.flatDataGroup.get('objectTypes') as FormControl;
  }

  public get isObjectTypeFlat() {
    return (
      this.isFlat &&
      (this.flatDataObjectTypesControl?.value as string)?.includes(
        ObjectType.FLAT
      )
    );
  }

  public get isObjectTypeHouse() {
    return (
      this.isFlat &&
      (this.flatDataObjectTypesControl?.value as string)?.includes(
        ObjectType.HOUSE
      )
    );
  }

  public get isGarage() {
    return isPropertyTypeGarage(this.propertyTypeControl.value);
  }

  public get isCommercial() {
    return isPropertyTypeCommercial(this.propertyTypeControl.value);
  }

  public get isSalesObject() {
    return isSalesObject(this.marketingTypeControl.value as MarketingType);
  }

  private get radiusControl() {
    return this.form.get('radius');
  }

  private get marketingTypeControl() {
    return this.form.get('marketingType');
  }

  constructor(private searchProfileService: SearchProfileService) {}

  /**
   *
   * @param loadDefaultConstants Load constants from selfRegistrationConfig
   */
  loadGarageTypesConstants(loadDefaultConstants: boolean) {
    if (loadDefaultConstants) {
      this.garageTypesConstants = this.constants.garageTypes.filter(
        garageType =>
          this.defaultObjectTypes.garageTypes.find(
            defaultType => defaultType === garageType.value
          )
      );
    } else {
      this.garageTypesConstants = this.constants?.garageTypes?.filter(
        c => c?.value !== 'NO_INFORMATION'
      );
    }
  }

  /**
   *
   * @param loadDefaultConstants Load constants from selfRegistrationConfig
   */
  loadObjectTypeConstants(loadDefaultConstants: boolean) {
    if (loadDefaultConstants) {
      this.objectTypeConstants = this.constants.objectTypes.filter(
        objectTypes =>
          this.defaultObjectTypes.objectTypes.find(
            defaultType => defaultType === objectTypes.value
          )
      );
    } else {
      this.objectTypeConstants = this.constants?.objectTypes?.filter(
        type =>
          type.value === ObjectType.HOUSE || type.value === ObjectType.FLAT
      );
    }
  }

  /**
   *
   * @param loadDefaultConstants Load constants from selfRegistrationConfig
   */
  loadPropertyTypesConstants(loadDefaultConstants: boolean) {
    if (loadDefaultConstants) {
      this.propertyTypesConstants = this.constants.propertyTypes.filter(
        propertyType =>
          this.defaultObjectTypes.propertyTypes.find(
            defaultType => defaultType === propertyType.value
          )
      );
    } else {
      this.propertyTypesConstants = this.constants.propertyTypes;
    }
  }

  /**
   *
   * @param loadDefaultConstants Load constants from selfRegistrationConfig
   */
  loadFlatTypesConstants(loadDefaultConstants: boolean) {
    if (loadDefaultConstants) {
      this.flatTypesConstants = this.constants.flatTypes.filter(flatType =>
        this.defaultObjectTypes.flatTypes.find(
          defaultType => defaultType === flatType.value
        )
      );
    } else {
      this.flatTypesConstants = this.constants.flatTypes;
    }
  }

  /**
   *
   * @param loadDefaultConstants Load constants from selfRegistrationConfig
   */
  loadHouseTypesConstants(loadDefaultConstants: boolean) {
    if (loadDefaultConstants) {
      this.houseTypesConstants = this.constants.houseTypes.filter(flatType =>
        this.defaultObjectTypes.houseTypes.find(
          defaultType => defaultType === flatType.value
        )
      );
    } else {
      this.houseTypesConstants = this.constants.houseTypes;
    }
  }

  loadDropdownConstants() {
    this.loadGarageTypesConstants(
      this.defaultObjectTypes?.garageTypes?.length > 0
    );
    this.loadObjectTypeConstants(
      this.defaultObjectTypes?.objectTypes?.length > 0
    );
    this.loadPropertyTypesConstants(
      this.defaultObjectTypes?.propertyTypes?.length > 0
    );
    this.loadFlatTypesConstants(this.defaultObjectTypes?.flatTypes?.length > 0);
    this.loadHouseTypesConstants(
      this.defaultObjectTypes?.houseTypes?.length > 0
    );
  }

  checkCommercialConstants() {
    if (this.defaultObjectTypes?.commercialType) {
      this.commercialTypesConstants = this.constants?.commercialTypes.filter(
        item => item.value === this.defaultObjectTypes?.commercialType
      );
    } else {
      this.commercialTypesConstants = this.constants?.commercialTypes;
    }
  }

  public ngOnInit() {
    this.loadDropdownConstants();
    this.checkCommercialConstants();

    if (this.commercialTypeForm.value) {
      this.updateCommercialSubTypes(this.commercialTypeForm.value);
    }

    this.commercialTypeForm.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(commercialType => {
        this.commercialSubTypesForm.reset();
        this.updateCommercialSubTypes(commercialType);
      });

    this.propertyTypeControl.valueChanges
      .pipe(
        startWith(this.form.get('propertyType').value),
        untilDestroyed(this)
      )
      .subscribe((type: PropertyType) => {
        // When a default commercialType is set use this one.
        if (this.defaultObjectTypes?.commercialType) {
          this.commercialTypeForm.patchValue(
            this.defaultObjectTypes.commercialType
          );
        }

        this.minUpperBoundRent =
          this.searchProfileService.getMinUpperBoundRent(type);

        this.maxUpperBoundRent =
          this.searchProfileService.getMaxUpperBoundRent(type);

        this.stepsForUpperBoundRent =
          this.searchProfileService.getStepsForUpperBoundRent(type);
      });

    this.searchProfileService
      .listenAndApplyDefaultFormChanges(this.form)
      .pipe(untilDestroyed(this))
      .subscribe();

    if (!this.isNewSearchProfile) {
      this.form.patchValue(
        this.searchProfileService.patchForm(
          this.searchProfile,
          this.districtsFormArray
        )
      );
    } else {
      this.radiusControl.patchValue(this.isFlat ? 2000 : 200);
    }

    if (this.isProjectSearchProfile) {
      this.form.get('projectId').patchValue(this.project?.id);
      this.searchProfileTypeControl.patchValue(SearchProfileType.PROJECT);
      this.searchProfileService.disableNonProjectRelatedFormFields(this.form);
    }
  }

  public updateCommercialSubTypes(commercialType: string) {
    this.commercialSubTypes = this.constants?.commercialSubTypes?.reduce(
      (array: NameValue<string>[], subType) => {
        const modifiedSubType =
          subType.value.commercialType === commercialType
            ? [{ value: subType.value.commercialSubType, name: subType.name }]
            : [];

        return [...array, ...modifiedSubType];
      },
      []
    );

    // If there are default commercialSubTypes, then only use them
    if (this.defaultObjectTypes?.commercialSubTypes?.length > 0) {
      this.commercialSubTypes = this.commercialSubTypes.filter(
        commercialSubType =>
          this.defaultObjectTypes.commercialSubTypes.find(
            defaultType => defaultType === commercialSubType.value
          )
      );
    }
  }

  public addCityGroup() {
    this.districtsFormArray.push(this.searchProfileService.districtsFormGroup);
  }
}
