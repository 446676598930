import { Component, Input, Output, EventEmitter } from '@angular/core';

import {
  trigger,
  state,
  animate,
  transition,
  style
} from '@angular/animations';

export type FooterDisplayMode = 'simple' | 'advanced';
export type FooterLinkAppearance = 'default' | 'button';

export interface FooterLink {
  url: string;
  name: string;
  appearance?: FooterLinkAppearance;
  hideOnMobile?: boolean;
}

@Component({
  selector: 'app-footer-v2',
  templateUrl: './footer-v2.component.html',
  styleUrls: ['./footer-v2.component.scss'],
  animations: [
    trigger('toggleState', [
      state('true', style({ maxHeight: '300px' })),
      state('false', style({ maxHeight: 0, display: 'none' })),
      transition('* => *', animate('300ms ease-in-out'))
    ])
  ]
})
export class FooterV2Component {
  @Input() currentYear = new Date().getFullYear();
  @Input() links: FooterLink[] = [];

  @Output() languageChange = new EventEmitter<string>();

  public showLinks = true;

  public toggleLinks() {
    this.showLinks = !this.showLinks;
  }

  public onLanguageChange(value: string) {
    this.languageChange.emit(value);
  }
}
