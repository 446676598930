import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output
} from '@angular/core';
import { BaseStep } from 'libs/components/legacy/wizard/wizard-step/base-step';
import { ModalService } from 'libs/components/legacy/modal/modal.service';
import { QualifiedElectronicSignatureMethodModalComponent } from 'libs/components/legacy/digital-contract/qualified-electronic-signature/components/qualified-electronic-signature-method-modal/qualified-electronic-signature-method-modal.component';
import { BadgeColorEnum } from 'libs/components/atoms/badge/badge.enum';
import {
  QesMethods,
  QESMethodsBadgeText
} from 'libs/components/legacy/digital-contract/qualified-electronic-signature/model';
import { ActionState } from 'libs/state-utils';
import { WINDOW_REF } from 'libs/infrastructure';
import { QesCheckPagesEnum } from '@ui/shared/models';

@Component({
  selector: 'app-qualified-electronic-signature',
  templateUrl: './qualified-electronic-signature.component.html',
  styleUrls: ['./qualified-electronic-signature.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualifiedElectronicSignatureComponent extends BaseStep {
  @Input() set qesActionState(actionState: ActionState) {
    this.actionState = actionState;
    this.handleActionStateChange(actionState);
  }

  @Input() qesLink: string;
  @Output() startQesCheck = new EventEmitter<{
    provider: QesMethods;
    dateOfBirth?: string;
  }>();

  qesCheckPagesEnum = QesCheckPagesEnum;
  public actionState: ActionState;
  public qesCheckPagesState: QesCheckPagesEnum;

  public methods = [
    {
      translationKey: 'digital_contract.qes_method_selfie_l',
      imageSrc: QesMethods.ROBO_IDENT,
      label: {
        color: BadgeColorEnum.DIGITAL_CONTRACT_NECT,
        text: QESMethodsBadgeText.ROBO_IDENT
      }
    },
    {
      translationKey: 'digital_contract.qes_method_video_l',
      imageSrc: QesMethods.VIDEO,
      label: {
        color: BadgeColorEnum.DIGITAL_CONTRACT_IDENTITY,
        text: QESMethodsBadgeText.VIDEO
      }
    },
    {
      translationKey: 'digital_contract.qes_method_id_l',
      imageSrc: QesMethods.E_ID,
      label: {
        color: BadgeColorEnum.DIGITAL_CONTRACT_IDENTITY,
        text: QESMethodsBadgeText.E_ID
      }
    }
  ];

  constructor(
    private modalService: ModalService,
    @Inject(WINDOW_REF) private windowRef: Window
  ) {
    super();
  }

  public openQesMethodModal(index: number) {
    this.modalService
      .open<QualifiedElectronicSignatureMethodModalComponent>(
        QualifiedElectronicSignatureMethodModalComponent,
        {
          size: 'lg',
          data: { qesMethod: this.methods[index].imageSrc }
        }
      )
      .onClose()
      .subscribe(payload => this.startQesCheck.emit(payload));
  }

  public openLink(): void {
    this.windowRef.open(this.qesLink, '_blank');
    this.qesCheckPagesState = QesCheckPagesEnum.AUTHENTIFICATION_STARTED_PAGE;
  }

  public handleBackClicked(): void {
    this.qesCheckPagesState = QesCheckPagesEnum.AUTH_SELECTION_PAGE;
  }

  private handleActionStateChange(actionState: ActionState) {
    if (actionState.done) {
      this.qesCheckPagesState = QesCheckPagesEnum.START_AUTHENTIFICATION_PAGE;
    } else if (!actionState.error && !actionState.pending) {
      this.qesCheckPagesState = QesCheckPagesEnum.AUTH_SELECTION_PAGE;
    }
  }
}
