<div #content>
  <ng-content></ng-content>
</div>

<div *ngIf="content.children.length === 0">
  <app-attachment-item
    *ngFor="let attachment of attachments; index as i"
    [attachment]="attachment"
    [index]="i"
    [isDocument]="isDocument"
    [showDownload]="showDownload"
    [showPreview]="showPreview"
    [disableDownload]="disableDownload"
    [blockDownload]="blockDownload"
    [showRemove]="showRemove"
    [orderable]="orderable"
    [editable]="editable"
    (remove)="onRemove($event)"
    (download)="onDownload($event)"
    (preview)="onPreview($event)"
    (moveUp)="onMoveUp($event)"
    (moveDown)="onMoveDown($event)"
    (updateAttachment)="onUpdateAttachment($event, i)"
  >
  </app-attachment-item>
</div>
