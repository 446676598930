import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { BaseControl } from 'libs/components/legacy/form';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  arrayHasPrimitiveValues,
  removeArrayDuplicates,
  removeArrayDuplicatesPrimitive
} from 'libs/utils';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@UntilDestroy()
@Component({
  selector: 'app-multi-select-dropdown-v2',
  templateUrl: './multi-select-dropdown-v2.component.html',
  styleUrls: ['./multi-select-dropdown-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectDropdownV2Component),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => MultiSelectDropdownV2Component)
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectDropdownV2Component
  extends BaseControl<unknown[]>
  implements OnInit, OnChanges
{
  @Input() items: unknown[];
  @Input() isLoadingMenuItems: boolean;
  @Input() relativelyPositioned: boolean;
  @Input() itemValueKey = 'value';
  @Input() searchCharactersLimit = 3;
  @Input() useValue: boolean;
  @Input() disableSearch: boolean;
  @Input() hideRemoveAllIcon: boolean;
  @Output() searchChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() scrollChange = new EventEmitter();
  @Output() selectionChange = new EventEmitter<any[]>();
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @ContentChild(TemplateRef) templateRef: TemplateRef<any>;

  constructor(
    private fb: FormBuilder,
    protected injector: Injector
  ) {
    super(injector);
  }

  public formRecord = this.fb.record<boolean>({});
  public searchControl: FormControl<string> = new FormControl<string>('');
  public formGroupSelectedCount: number;
  private allItems: unknown[];

  ngOnInit() {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(value => value.length >= this.searchCharactersLimit),
        untilDestroyed(this)
      )
      .subscribe(value => this.searchChange.emit(value));
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.items) {
      this.items = changes.items.currentValue;
      const concatenatedItems: unknown[] = (this.allItems || []).concat(
        this.items
      );
      this.allItems = arrayHasPrimitiveValues(this.items)
        ? removeArrayDuplicatesPrimitive(concatenatedItems)
        : removeArrayDuplicates(concatenatedItems, this.itemValueKey);
      this.adjustFormGroupControls();
    }
  }

  writeValue(value: unknown[]): void {
    if (!value) return;
    const valueToWrite = value?.map(v =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      this.useValue || typeof v === 'string' ? v : v[this.itemValueKey]
    );
    super.writeValue(valueToWrite);
    this.patchFormRecord();
  }

  public clear() {
    this.items.forEach((item, index) =>
      this.formRecord
        .get(item[this.itemValueKey])
        .patchValue(false, { emitEvent: index === this.items.length - 1 })
    );
    this.applyValues();
  }

  public apply() {
    this.dropdown.close();
    this.applyValues();
  }

  applyValues() {
    const selectedValues = Object.keys(this.formRecord.value).filter(
      key => this.formRecord.value[key]
    );
    this.value = !this.useValue
      ? selectedValues
      : this.allItems.filter(
          item => this.formRecord.value[item[this.itemValueKey]]
        );
    this.formGroupSelectedCount = Object.values(this.formRecord.value).filter(
      v => v
    ).length;
    this.updateItems();
    this.selectionChange.emit(this.value);
    this.searchControl.patchValue('');
  }

  public onScroll() {
    this.scrollChange.emit();
  }

  public clearAndApply() {
    const groupValues: Record<string, boolean> = this.formRecord.value;
    Object.keys(groupValues).forEach(key => (groupValues[key] = false));
    this.formRecord.patchValue(groupValues);
    this.apply();
  }

  private updateItems() {
    if (this.searchCharactersLimit > 0) {
      this.items = [];
    }
  }

  private adjustFormGroupControls() {
    this.items.forEach((item, index) => {
      const key: string = item[this.itemValueKey];
      if (!this.formRecord.contains(key)) {
        this.formRecord.addControl(key, this.fb.control(false), {
          emitEvent: index === this.items.length - 1
        });
      }
    });
  }

  private patchFormRecord() {
    const falseFormRecordValues = Object.keys(this.formRecord.controls).reduce<
      Record<string, boolean>
    >((previousValue, key) => {
      previousValue[key] = false;
      return previousValue;
    }, {});
    const valuesToPatch: Record<string, boolean> = {
      ...falseFormRecordValues
    };
    if (this.value.length > 0) {
      this.value.forEach(value => {
        const key: string = value[this.itemValueKey];
        if (this.formRecord.contains(key)) {
          valuesToPatch[key] = true;
        } else {
          this.formRecord.addControl(key, this.fb.control(true), {
            emitEvent: false
          });
        }
      });
    } else {
      Object.keys(this.formRecord.controls).forEach(
        key => (valuesToPatch[key] = false)
      );
    }
    this.formRecord.patchValue(valuesToPatch);
    this.formGroupSelectedCount = Object.values(this.formRecord.value).filter(
      v => v
    ).length;
    this.cdr.detectChanges();
  }
}
