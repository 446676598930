import { Injectable, Inject } from '@angular/core';
import { WINDOW_REF } from '../browser/window-ref.token';
import { StorageService } from './storage.service';

@Injectable()
export class SessionStorageService extends StorageService {
  constructor(@Inject(WINDOW_REF) private windowRef: Window) {
    super(windowRef.sessionStorage);
  }
}
