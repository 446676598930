import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControl } from 'libs/components/legacy/form';
import {
  AvailableLanguageCodesEnum,
  HierarchicalQuestion,
  HierarchicalQuestionSelectionAnswerData
} from '@ui/shared/models';
import { HierarchicalQuestionService } from 'libs/services';

@Component({
  selector: 'app-hierarchical-question-selection',
  templateUrl: './hierarchical-question-selection.component.html',
  styleUrls: ['./hierarchical-question-selection.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HierarchicalQuestionSelectionComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchicalQuestionSelectionComponent extends BaseControl<any> {
  @Input() hierarchicalQuestion: HierarchicalQuestion;
  @Input() currentLanguage: AvailableLanguageCodesEnum =
    AvailableLanguageCodesEnum.DE;
  @Input() defaultLanguage: AvailableLanguageCodesEnum;

  constructor(
    private hierarchicalQuestionService: HierarchicalQuestionService,
    protected injector: Injector
  ) {
    super(injector);
  }

  public writeValue(value: any) {
    if (this.hierarchicalQuestion.data?.maxAnswers > 1) {
      this.value = value ? value : [];
      return;
    }

    this.value = value;
  }

  public get options() {
    return (
      [...this.hierarchicalQuestion.answers]
        .sort(this.hierarchicalQuestionService.sort)
        .map(answer => ({
          name: (answer.data as HierarchicalQuestionSelectionAnswerData).title,
          value: answer.id
        })) || []
    );
  }
}
