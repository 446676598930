<app-modal>
  <div class="modal-xs">
    <app-modal-content>
      <div class="modal-body chat-create__body">
        <p class="title-m chat-create__title">
          {{'messenger.chat_create_title_l' | translate}}
        </p>
        <p class="default-s chat-create__message">
          {{'messenger.chat_create_description_l' | translate}}
        </p>
        <div
          class="chat-create__container"
          *ngIf="!(availableForNewConversationActionState | async).pending;else loading"
        >
          <ng-container
            *ngIf="(availableForNewConversation | async)?.length > 0;else noContent"
          >
            <div
              class="application-item d-flex justify-content-center align-content-center"
              (click)="close(application)"
              *ngFor="let application of (availableForNewConversation | async)"
            >
              <div
                class="col-2 m-auto p0 flat_image"
                *ngIf="application?.property?.data?.attachments[0]?.url"
              >
                <img
                  [src]="application?.property?.data?.attachments[0]?.url"
                  alt=""
                />
              </div>
              <div class="col pr0 flat__information">
                <p class="m0">{{application?.property?.data?.name}}</p>
                <span class="secondary"
                  >{{application?.property?.data?.totalRentGross |
                  currency:'EUR'}} / {{ 'general.total_rent_l' | translate
                  }}</span
                >
              </div>
            </div>
          </ng-container>
        </div>
        <ng-template #loading>
          <div class="loading-area">
            <app-loading-spinner></app-loading-spinner>
          </div>
        </ng-template>
        <ng-template #noContent>
          <div class="no_conversation">
            {{'messenger.no_object_for_chat_l' | translate}}
          </div>
        </ng-template>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer chat-create__footer">
        <app-button (clickEvent)="dismiss()" [type]="'light-bordered'"
          >{{ 'general.cancel_a' | translate}}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal>
