import { Pipe, PipeTransform } from '@angular/core';
import { Attachment, ImageSizes } from '@ui/shared/models';

// Converted UrlSize Pipe from old Landlord App
// Optional to add an own size to define the size to everytime the same like  url | imageSize:'S'
@Pipe({ name: 'appImageSize' })
export class ImageSizePipe implements PipeTransform {
  transform(source: Attachment, size = 'L'): string {
    if (!source) return '';
    const result = this.resizeUrl(source, size);
    if (result) return result;
    if (source && source.url) return source.url;
    return '';
  }

  /**
   * adds the API-valid resize parameters to an url
   */
  private resizeUrl = (source: Attachment, size: string) => {
    const url = source.url || '';
    const fileExtension = url.split('.').pop().split(/#|\?/)[0];
    return url.replace(
      /\.\w+$/,
      `-${String(ImageSizes[`${size}`])}.${fileExtension}`
    );
  };
}
