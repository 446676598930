<app-card class="mb-2">
  <app-hierarchical-questions-display-question
    [question]="mainQuestion"
    [questions]="subQuestions"
    [tags]="tags"
    [isMainQuestion]="true"
    [appCheckTemplateRef]="appCheckTemplateRef"
    [editTaskType]="editTaskType"
    [currentLanguage]="languageCode$ | async"
    (edit)="onEdit()"
    (delete)="onDelete()"
  >
  </app-hierarchical-questions-display-question>

  <hr />
  <div class="hierarchical-question-container__details">
    <div class="row hierarchical-question-container__details-overview">
      <div class="col-6 details">
        {{ 'hierarchical_questions.display.details_number_of_sub_questions_l' |
        translate }} {{subQuestions.length}}
      </div>
      <div
        *ngIf="subQuestions.length > 0"
        class="col-6 d-flex justify-content-end"
      >
        <span (click)="toggleShowDetails()" class="details-toggle">
          {{(!showDetails ? 'hierarchical_questions.display.details_show_more_l'
          : 'hierarchical_questions.display.details_show_less_l') | translate}}
        </span>
      </div>
    </div>

    <div
      *ngIf="showDetails"
      class="hierarchical-question-container__details-sub-questions"
    >
      <p class="semibold mt20">
        {{ 'hierarchical_questions.display.sub_questions_header_l' | translate
        }}
      </p>

      <div *ngFor="let question of subQuestions; index as i">
        <div class="mt30">
          <app-hierarchical-questions-display-question
            [question]="question"
            [subQuestionIndex]="i + 1"
            [questions]="subQuestions"
            [tags]="tags"
            [currentLanguage]="languageCode$ | async"
          >
          </app-hierarchical-questions-display-question>
        </div>
      </div>
    </div>
  </div>
</app-card>
