import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef
} from '@angular/core';
import { DataTableColumnDefaultConfig } from '../data-table.model';

@Component({
  selector: 'app-data-table-cell',
  templateUrl: './data-table-cell.component.html',
  styleUrls: ['./data-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableCellComponent {
  @Input() icon: string;
  @Input() hover = false;
  @Input() alignment = DataTableColumnDefaultConfig.alignment;
  @Input() withoutPaddingLeft = DataTableColumnDefaultConfig.withoutPaddingLeft;
  @Input() clickable = DataTableColumnDefaultConfig.clickable;
  @Input() withoutPadding = false;
  @ContentChild('cellData')
  cellData: TemplateRef<any>;
}
