<div
  class="side-sheet"
  [ngClass]="{'side-sheet__layer': isLayer}"
  [@slide]="isLayer ? slideAnimationStateEnum.OPEN : slideAnimationStateEnum.CLOSED_RIGHT"
  [elevation]="isLayer ? 2 : 0"
  appElevation
>
  <div
    class="side-sheet__header"
    [ngClass]="{'side-sheet__header--bordered': !contents.length}"
  >
    <div class="side-sheet__header-content">
      <div class="d-flex justify-content-between">
        <div>
          <ng-content select="[control-panel]"></ng-content>
        </div>
        <div class="d-flex">
          <div
            *ngIf="showEditButton"
            class="icon icon--edit app-click-area mb-0 ps-3"
            (click)="editEntryClicked()"
          ></div>
          <div
            class="icon icon--close app-click-area mb-0 ps-3"
            (click)="dismiss()"
          ></div>
        </div>
      </div>
      <div class="d-flex flex-nowrap justify-content-between">
        <ng-content select="[header-title]"></ng-content>
      </div>
      <ng-content select="[header-subtitle]"></ng-content>
    </div>
    <div class="side-sheet__header-nav">
      <nav
        [keyboard]="'changeWithArrows'"
        ngbNav
        #navigation
        #nav="ngbNav"
        [(activeId)]="activeNav"
        class="nav-pills"
        [ngClass]="{'side-sheet__header-nav--fade-left': leftFade, 'side-sheet__header-nav--fade-right': rightFade}"
        (scroll)="onHorizontalScroll()"
        (wheel)="onWheelScroll($event)"
      >
        <ng-container *ngFor="let content of contents; let i = index">
          <ng-container
            ngbNavItem="nav-{{i}}"
            [disabled]="content?.disabled || (!isFormValid && currentFormIndex() < i) || (disableActions && currentFormIndex() !== i)"
          >
            <a draggable="false" ngbNavLink (click)="scrollIntoView(i)">
              {{ content?.label | translate }}
              <span *ngIf="content?.required">*</span>
            </a>
          </ng-container>
        </ng-container>
      </nav>
    </div>
  </div>

  <div class="side-sheet__content" *ngIf="contents?.length">
    <ng-template
      [ngTemplateOutlet]="contents?.toArray()[currentFormIndex()]?.template"
    ></ng-template>
  </div>

  <div class="side-sheet__footer" [ngClass]="{ 'w-100' : useFullWidth }">
    <div
      class="side-sheet__footer-button-container"
      [ngClass]="{ 'w-100' : useFullWidth }"
    >
      <ng-container *ngIf="showDefaultFooterButtons">
        <ng-container *ngIf="!singleSubmitButton">
          <app-button
            [type]="'light-bordered'"
            *ngIf="currentFormIndex() !== 0"
            [disabled]="isPending || disableActions"
            (clickEvent)="switchToTab(-1)"
          >
            {{ 'general.previous_a' | translate }}
          </app-button>
          <app-button
            [loading]="isPending"
            [disabled]="isNextStepDisabled"
            (clickEvent)="currentFormIndex() >= contents.length - 1 ? save() : switchToTab(1)"
          >
            {{ (currentFormIndex() >= contents.length - 1 ? 'general.complete_a'
            : 'general.next_a') | translate }}
          </app-button>
        </ng-container>
        <app-button
          *ngIf="singleSubmitButton"
          [loading]="isPending"
          [disabled]="!isFormValid || isPending || disableActions"
          (clickEvent)="save()"
        >
          {{singleSubmitButton | translate}}
        </app-button>
      </ng-container>
      <ng-container *ngIf="showCustomFooterButtons">
        <ng-content select="[footer-buttons]"></ng-content>
      </ng-container>
    </div>
  </div>
</div>
