import { ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { SvgService } from 'libs/services/svg.service';
import { first } from 'rxjs';
import { StatusInfoImageReplaceConfig } from './status-info.model';

@Component({
  selector: 'app-status-info',
  templateUrl: './status-info.component.html',
  styleUrls: ['./status-info.component.scss']
})
export class StatusInfoComponent {
  private changeDetectorRef = inject(ChangeDetectorRef);

  public svgXML: SafeHtml;

  @Input() loading = false;
  @Input() subject: string;
  @Input() imageSrc: string;
  @Input() imageSize: string;
  @Input() title: string;
  @Input() set whitelabelSvg(value: StatusInfoImageReplaceConfig) {
    if (value) {
      this.svgService
        .loadSvgAsXML(value.imageSrc)
        .pipe(first())
        .subscribe(svg => {
          this.svgXML = this.svgService.replaceColorAndFinalize(
            svg,
            value.colorHex,
            value.replaceVar
          );
          this.changeDetectorRef.detectChanges();
        });
    }
  }

  constructor(private svgService: SvgService) {}
}
