import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';

@Component({
  selector: 'app-tab-navigation',
  templateUrl: './tab-navigation.component.html',
  styleUrls: ['./tab-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabNavigationComponent implements AfterViewInit {
  @Input() navigationItems: NavigationItem[];
  @Input() activeItem: NavigationItem;
  @Output() linkClicked = new EventEmitter<string>();
  @ViewChild('tabNavigation') el: ElementRef;

  public leftFade = false;
  public rightFade = true;

  public onClick(link: string, index: number) {
    this.linkClicked.emit(link);
    this.scrollIntoView(index);
  }

  public ngAfterViewInit(): void {
    const target = this.el.nativeElement as HTMLElement;
    if (target.scrollWidth > target.offsetWidth) {
      this.rightFade = true;
      this.leftFade = false;
    } else {
      this.leftFade = false;
      this.rightFade = false;
    }
  }

  public scrollIntoView(index: number): void {
    const target = this.el.nativeElement.children[index] as HTMLElement;
    // Fix for ART-10375: added delay of 1ms fixes programmatic scrolling in Chrome somehow
    setTimeout(() => {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }, 1);
  }

  public onHorizontalScroll(): void {
    const target = this.el.nativeElement as HTMLElement;
    const edgeTolerance = 10;

    const scrollLeft = target.scrollLeft; // Scroll amount in the X-direction
    const maxScrollLeft = target.scrollWidth - target.clientWidth; // Maximum scroll amount in the X-direction

    if (maxScrollLeft <= edgeTolerance) {
      this.leftFade = false;
      this.rightFade = false;
      return;
    }

    if (scrollLeft < edgeTolerance) {
      this.leftFade = false;
      if (maxScrollLeft >= edgeTolerance) {
        this.rightFade = true;
      }
    } else {
      this.leftFade = true;
      if (scrollLeft >= maxScrollLeft) {
        this.rightFade = false;
      }
    }
  }

  public onWheelScroll(event: WheelEvent): void {
    const target = this.el.nativeElement as HTMLElement;

    if (!event.deltaY) {
      return; // keep horizontal scrolling same
    }

    // transform vertical scroll to horizontal scroll
    target.scrollLeft += event.deltaY + event.deltaX;
  }
}
