<div class="info-box">
  <div class="info-box__message-container">
    <div class="info-box__icon-container">
      <svg-icon
        src="/assets/images/icons/icon-info.svg"
        [applyClass]="true"
        [svgStyle]="{'width.px': 15}"
        class="secondary-svg-color circle circle-fill"
      ></svg-icon>
    </div>

    <div class="info-box__message">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="info-box__actions-container">
    <ng-content select="[info-box-actions]"></ng-content>
  </div>
</div>
