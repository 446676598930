import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { InfoCollapseTypeEnum } from './info-collapse.enum';

@Component({
  selector: 'app-info-collapse',
  templateUrl: './info-collapse.component.html',
  styleUrls: ['./info-collapse.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoCollapseComponent {
  @Input() type: InfoCollapseTypeEnum = InfoCollapseTypeEnum.DEFAULT;
  @Input() collapsed: boolean;

  public getStatusIcon(type: InfoCollapseTypeEnum): string {
    switch (type) {
      case InfoCollapseTypeEnum.INFO:
        return 'info';
      case InfoCollapseTypeEnum.POSITIVE:
        return 'check-framed';
      case InfoCollapseTypeEnum.DEFAULT:
        return 'question';
      default:
        return 'attention';
    }
  }
}
