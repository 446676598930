import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR, FormControl, FormGroup } from '@angular/forms';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { BaseControl } from 'libs/components/legacy/form';
import {
  MultiSelectGroupedIntermediateItem,
  MultiSelectGroupedItemsWithTitle
} from '@ui/shared/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

interface Value {
  [key: string]: boolean | null;
}

@UntilDestroy()
@Component({
  selector: 'app-multi-select-grouped-intermediate-dropdown',
  templateUrl: './multi-select-grouped-intermediate-dropdown.component.html',
  styleUrls: ['./multi-select-grouped-intermediate-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(
        () => MultiSelectGroupedIntermediateDropdownComponent
      ),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(
        () => MultiSelectGroupedIntermediateDropdownComponent
      )
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MultiSelectGroupedIntermediateDropdownComponent
  extends BaseControl<Value>
  implements OnInit, OnChanges
{
  @ViewChild(NgbDropdown) dropdown: NgbDropdown;
  @Input() items: MultiSelectGroupedItemsWithTitle;
  @Input() extendedItemsConfig: MultiSelectGroupedItemsWithTitle;
  @Input() itemsSelectedPlaceholder = 'multi_select_dropdown.items_selected_l';
  @Input() maxDisplayCountOfSelectedItems = 2;
  @Input() textDelimiterForSelectedItems = true;
  @Input() placement = 'bottom-left';
  @Input() showApplyButton = true;

  public selectedItems: MultiSelectGroupedIntermediateItem[] = [];
  public groupItemsLength = 0;

  public isDropdownOpened = false;

  /**
   * The returned items are display as placeholder in the input, when the dropdown is closed.
   * @param value
   */
  public getSelectedItems(value: Value): MultiSelectGroupedIntermediateItem[] {
    if (!value) return [];

    const selectedKeys = new Set(
      Object.keys(value).filter(
        key => value[key] === true || value[key] === false
      )
    );

    const selectedItems: MultiSelectGroupedIntermediateItem[] = [];
    Object.values(this.items).forEach(group => {
      group.items.forEach(item => {
        if (selectedKeys.has(item.value)) {
          selectedItems.push({
            ...item,
            value: value[item.value]
          });
        }
      });
    });

    // Return MultiSelectGroupedItem as this contains the translations of the items
    return selectedItems;
  }

  constructor(protected injector: Injector) {
    super(injector);
  }

  public form = new FormGroup({});

  public writeValue(value?: Value): void {
    super.writeValue(value);
    this.updateForm(value);
    this.updateSelection(value);
    this.cdr.detectChanges();
  }

  set value(value: Value) {
    super.value = value;
    this.updateSelection(value);
    this.cdr.detectChanges();
  }

  public ngOnInit(): void {
    this.initializeForm();
  }

  public ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.items) {
      this.groupItemsLength = Object.values(this.items).length;
    }
  }

  clear(event?: Event): void {
    // When you don't have this, then the dropdown would be opened/closed
    // It stops the parent element from also receiving the click event
    if (event) event.stopPropagation();
    this.form.reset();

    // If the value is already the default value, don't reset it
    // That would cause another request being made.
    if (this.selectedItems.length) this.applyValues();
  }

  public apply(): void {
    this.dropdown.close();
    this.applyValues();
  }

  public applyValues(): void {
    this.value = this.form.value;
    this.cdr.detectChanges(); // fixes an issue where labels are not updated on manual reset
  }

  private initializeForm(): void {
    Object.entries(this.items).forEach(([, item]) => {
      item.items.forEach(v =>
        this.form.addControl(v.value, new FormControl(null))
      );
    });

    if (!this.showApplyButton)
      this.form.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(() => this.applyValues());
  }

  private updateForm(value?: Value): void {
    if (!value) return;

    this.form.patchValue(value);
  }

  public updateSelection(value: Value) {
    this.selectedItems = this.getSelectedItems(value);
  }

  public setShowFilters(event: boolean) {
    this.isDropdownOpened = event;
  }
}
