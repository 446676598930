import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectionCardDefaultConfig } from 'libs/components/molecules/cards/selection-card/selection-card.model';
import { IconTypeEnum } from '@ui/shared/models';

@Component({
  selector: 'app-selection-card',
  templateUrl: './selection-card.component.html',
  styleUrls: ['./selection-card.component.scss']
})
export class SelectionCardComponent {
  @Input() imageUrl = SelectionCardDefaultConfig.imageUrl;
  @Input() title = SelectionCardDefaultConfig.title;
  @Input() subTitle = SelectionCardDefaultConfig.subTitle;
  @Input() icon: IconTypeEnum | '';
  @Input() selected = SelectionCardDefaultConfig.selected; // you can control the initial display
  @Input() selectable = SelectionCardDefaultConfig.selectable; // controls display of check
  @Output() selectEvent = new EventEmitter<boolean>();

  get imageUrlWithFallback(): string {
    return this.imageUrl || '/assets/images/object-image-placeholder.svg';
  }

  public toggleSelection(): void {
    if (this.selectable) {
      this.selected = !this.selected;
      this.selection();
    }
  }

  public selection(): void {
    this.selectEvent.emit(this.selected);
  }
}
