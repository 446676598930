import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ContentChild,
  QueryList,
  HostBinding,
  ViewEncapsulation,
  AfterContentInit,
  ChangeDetectionStrategy
} from '@angular/core';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppFormFieldControl } from './form-field-control/form-field-control';

import { FormFieldLabelComponent } from './form-field-label/form-field-label.component';
import { FormFieldErrorComponent } from './form-field-error/form-field-error.component';

import { getErrorMessage } from './form-field-error/error-message';

@UntilDestroy()
@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldComponent implements OnInit, AfterContentInit {
  @Input() label = '';
  @Input() labelBelowInput = false;
  @Input() markCompleted = false;
  @Input() disableErrorMessages = false;
  @Input() disabled = false;
  @Input() disabledClickArea = false;

  @ContentChild(AppFormFieldControl, { static: true })
  fieldControl: AppFormFieldControl<any>;
  @ContentChild(FormFieldLabelComponent, { static: true })
  projectedLabelChild: FormFieldLabelComponent;
  @ContentChild(FormFieldErrorComponent, { static: true })
  projectedErrorChildren: QueryList<FormFieldErrorComponent>;
  @HostBinding('class.app-form-field') private mainClass = true;
  public errors: string[] = [];
  public showRequiredMarker: boolean;
  public controlId: string;

  public get controlTouched() {
    return this.fieldControl?.touched;
  }

  public get disableClickArea() {
    return this.disabled || this.disabledClickArea;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.validateInputChild();
    this.fieldControl.stateChanges.pipe(untilDestroyed(this)).subscribe(() => {
      if (!this.hasProjectedErrorChildren()) {
        const errorsMap = this.fieldControl.errors;
        this.errors = errorsMap
          ? // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            Object.keys(errorsMap).map(key => getErrorMessage(key))
          : [];
      }
      this.changeDetectorRef.markForCheck();
    });
  }

  ngAfterContentInit() {
    this.showRequiredMarker =
      this.fieldControl?.required ||
      this.projectedLabelChild?.showRequiredMarker;
    this.controlId = this.fieldControl?.id;

    if (this.hasProjectedLabelChild()) {
      this.projectedLabelChild.showRequiredMarker = this.showRequiredMarker;
      this.projectedLabelChild.idFor = this.controlId;
    }
  }

  hasProjectedLabelChild() {
    return !!this.projectedLabelChild;
  }

  hasProjectedErrorChildren() {
    return this.projectedErrorChildren?.length > 0;
  }

  validateInputChild() {
    if (!this.fieldControl)
      throw Error('fieldControl missing in app-form-field');
  }
}
