import { Pipe, PipeTransform } from '@angular/core';

import { Address } from '@ui/shared/models';

@Pipe({ name: 'appAddressVertical' })
export class AddressVerticalPipe implements PipeTransform {
  transform(source: Address): string {
    if (!source) return '';

    const streetPart =
      source.street && source.houseNumber
        ? `${source.street} ${source.houseNumber}`
        : source.street;
    const cityPart =
      source.zipCode && source.city
        ? `${source.zipCode} ${source.city}`
        : source.zipCode || source.city;

    return [streetPart, cityPart, source.district, source.countryName]
      .filter(it => !!it)
      .join(' <br/> ');
  }
}
