import { Component, Input, OnChanges } from '@angular/core';

import {
  APPLICATION_STATUS_CONFIGS,
  unknownApplicationStatusConfig
} from 'libs/config/status-pipeline.config';

import {
  CurrentStatusConfig,
  PipelineSteps
} from 'libs/components/molecules/status-pipelines/status-pipeline-base.config';

import { PropertyType, ApplicantStatus } from '@ui/shared/models';
import { applicationSteps } from './application-status-pipeline.config';

@Component({
  selector: 'app-application-status-pipeline',
  templateUrl: './application-status-pipeline.component.html'
})
export class ApplicationStatusPipelineComponent implements OnChanges {
  @Input() status: ApplicantStatus | null;
  @Input() pageView: boolean;
  @Input() propertyType: PropertyType;

  public steps: PipelineSteps;
  public currentStatus: CurrentStatusConfig;

  private getCurrentStatus(): CurrentStatusConfig {
    this.steps = applicationSteps;
    const config =
      APPLICATION_STATUS_CONFIGS[this.status] || unknownApplicationStatusConfig;
    return {
      ...config,
      pipeline: {
        ...config.pipeline,
        showAsFullscreen: typeof config.pipeline.stepId === 'undefined'
      }
    };
  }

  ngOnChanges(): void {
    this.currentStatus = this.getCurrentStatus();
  }
}
