<form [formGroup]="dropdownForm">
  <div class="toggle-all-item">
    {{ 'dropdown_multi-select.select_all_l' | translate }}

    <app-check
      class="dropdown__check"
      [ngModel]="toggle()"
      (ngModelChange)="toggleAll($event)"
      [ngModelOptions]="{standalone: true}"
      [squared]="true"
    >
    </app-check>
  </div>
  <div
    class="dropdown__item"
    *ngFor="let item of items(); index as i"
    (click)="onOptionClick(i)"
  >
    <ng-template
      [ngTemplateOutlet]="templateRef"
      [ngTemplateOutletContext]="{$implicit: item}"
    ></ng-template>
    <app-check
      class="dropdown__check"
      formControlName="{{item[itemValueKey]}}"
      [squared]="true"
    ></app-check>
  </div>
  <div
    *ngIf="items().length === 0 && emptyPlaceholder"
    class="dropdown__item dropdown__item--disabled"
  >
    {{emptyPlaceholder | translate}}
  </div>
</form>
