<div class="hierarchical-question-container">
  <div *ngIf="!isMainQuestion" class="row d-flex align-items-center">
    <div class="col-12">
      <span
        class="hierarchical-question-container__sub-question-header uppercase-m"
        >{{ 'hierarchical_questions.display.sub_question_title_l' | translate:
        {index: subQuestionIndex} }}</span
      >
    </div>
  </div>

  <div class="row d-flex align-items-center">
    <div class="col-8">
      <span *ngIf="question.data.title && currentLanguage">
        {{question.data.title[currentLanguage]}}
      </span>
    </div>

    <div
      *ngIf="isMainQuestion"
      class="col-4 d-flex justify-content-end align-items-center"
    >
      <app-badge
        *ngIf="editTaskType"
        class="mr5"
        [color]="badgeType"
        [uppercase]="false"
        [tooltipText]="'hierarchical_questions.display_badge_' + editTaskType.toLowerCase() + '_tooltip_l' | translate"
        >{{
        'hierarchical_questions.display_badge_'+editTaskType.toLowerCase()+'_l'
        | translate }}</app-badge
      >
      <app-context-menu *ngIf="!appCheckTemplateRef">
        <div menu-content>
          <app-context-menu-item
            (clickEvent)="onEdit()"
            [disabled]="isEditTaskTypeDelete"
          >
            {{ 'custom_questions.edit_a' | translate }}
          </app-context-menu-item>
          <app-context-menu-item
            *ngIf="!isEditTaskTypeDelete"
            (clickEvent)="onDelete()"
          >
            {{ 'custom_questions.delete_a' | translate }}
          </app-context-menu-item>
        </div>
      </app-context-menu>
      <ng-template [ngTemplateOutlet]="appCheckTemplateRef"></ng-template>
    </div>
  </div>

  <hr />
  <div class="hierarchical-question-container__body">
    <ng-container [ngSwitch]="true">
      <ng-container *ngSwitchCase="isSelection">
        <div class="row">
          <div class="col-12 hierarchical-question-container__body-title">
            <span
              >{{ 'hierarchical_questions.display.answers_selection_title_l' |
              translate }}</span
            >
          </div>
        </div>

        <div
          *ngFor="let answer of sortedAnswers; index as i"
          class="hierarchical-question-container__body-answers"
        >
          <div class="row">
            <div class="col-12">
              <span class="answer-option"
                >{{ 'hierarchical_questions.display.answers_selection_option_l'
                | translate }}</span
              >
              <span class="answer-option">&nbsp;{{ i | appAlphaIndex }}: </span>
              <span
                class="answer-title"
                *ngIf="answer.data.title && currentLanguage"
                >{{ answer.data.title[currentLanguage] | translate }}</span
              >
              <svg-icon
                *ngIf="answer.preferredAnswer"
                src="/assets/images/icons/star.svg"
                [applyClass]="true"
                class="orange-svg-color polygon polygon-fill svg-star"
              ></svg-icon>
            </div>
          </div>
          <div *ngIf="answer.questionIds?.length > 0" class="row pl12">
            <div class="col-11">
              <span class="answer-follow-question-label"
                >{{ 'hierarchical_questions.display.answers_follow_questions_l'
                | translate }}</span
              >
              <span class="answer-follow-question-value"
                >&nbsp;{{ getFollowQuestionTitles(answer.questionIds) }}</span
              >
            </div>
          </div>
          <div *ngIf="answer.actions?.length > 0" class="d-flex flex-wrap">
            <app-label-list
              [assignedTags]="getTags(answer.actions)"
              [isSystemTag]="false"
              [readonly]="true"
              [amountBeforeTruncation]="3"
              [truncateLabels]="true"
              [multilineMode]="true"
            ></app-label-list>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="isRanged">
        <div class="row">
          <div class="col-12 hierarchical-question-container__body-title">
            <span
              >{{ 'hierarchical_questions.display.answers_ranged_title_l' |
              translate }}</span
            >
          </div>
        </div>

        <div
          *ngFor="let answer of sortedAnswers; index as i"
          class="hierarchical-question-container__body-answers"
        >
          <div
            *ngIf="!isRangedMain(answer.data.answerId) || answer.questionIds?.length > 0"
            class="row"
          >
            <div class="col-12">
              <span class="answer-option"
                >{{ 'hierarchical_questions.display.answers_ranged_option_' +
                answer.data.answerId.toLowerCase() + '_l' | translate }}</span
              >
              <span *ngIf="isRangedNumber" class="answer-title"
                >{{ getBound(answer.data) }}</span
              >
              <span *ngIf="isRangedDate" class="answer-title"
                >{{ getBound(answer.data).toString() | appDateTime }}</span
              >
              <svg-icon
                *ngIf="answer.preferredAnswer"
                src="/assets/images/icons/star.svg"
                [applyClass]="true"
                class="orange-svg-color polygon polygon-fill svg-star"
              ></svg-icon>
            </div>
          </div>
          <div *ngIf="answer.questionIds?.length > 0" class="row pl12">
            <div class="col-11">
              <span class="answer-follow-question-label"
                >{{ 'hierarchical_questions.display.answers_follow_questions_l'
                | translate }}</span
              >
              <span class="answer-follow-question-value"
                >&nbsp;{{ getFollowQuestionTitles(answer.questionIds) }}</span
              >
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
