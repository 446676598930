import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef
} from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { documentsTypes, imagesTypes } from 'libs/config';

import { Attachment } from '@ui/shared/models';
import { arrayMove } from 'libs/utils';

import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AttachmentsComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => AttachmentsComponent)
    }
  ]
})
export class AttachmentsComponent extends BaseControl<
  Attachment | Attachment[]
> {
  @Input() multiple = false;

  // List Inputs
  @Input() showDownload = true;
  @Input() showPreview = true;
  @Input() disableDownload = false;
  @Input() blockDownload = false;
  @Input() showRemove = false;
  @Input() isDocument = false;
  @Input() orderable = false;
  @Input() editable = false;

  // FileUpload Inputs
  @Input() hideFileUpload = false;
  @Input() size = 1024 * 1024 * 10;
  @Input() accept: string;
  @Input() subInformation: string;

  @Input() attachmentType: string;

  @Output() download = new EventEmitter<Attachment>();
  @Output() preview = new EventEmitter<Attachment>();
  @Output() boundaryMoveUp = new EventEmitter<Attachment>();
  @Output() boundaryMoveDown = new EventEmitter<Attachment>();
  @Output() remove = new EventEmitter<Attachment>();

  private _touched = false;
  private _errors = null;

  get errors() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._errors;
  }

  set errors(errors: any) {
    this._errors = errors;
  }

  get touched() {
    return this._touched;
  }

  set touched(value: boolean) {
    this._touched = value;
  }

  get attachmentsArray() {
    return this.value instanceof Array
      ? this.value
      : this.value
        ? [this.value]
        : [];
  }

  get showAddButton() {
    return Array.isArray(this.value) && this.value.length > 0;
  }

  public onDownload(attachment: Attachment) {
    this.download.emit(attachment);
  }

  public onPreview(attachment: Attachment) {
    this.preview.emit(attachment);
  }

  public onRemove(indexToRemove: number) {
    if (this.multiple) {
      const removed = ((this.value as Attachment[]) || []).find(
        (_, i) => i === indexToRemove
      );
      const attachments = ((this.value as Attachment[]) || []).filter(
        (_, i) => i !== indexToRemove
      );
      this.value = [...attachments];

      this.remove.emit(removed);
    }

    if (!this.multiple) {
      const removed = { ...this.value };
      this.value = null;

      /**
       * The order here is important!
       * This event musts emit after value is set to null,
       * otherwise when we want to replace it with something else,
       * value will be nulled after callback's execution.
       */
      this.remove.emit(removed as Attachment);
    }

    this.markAsTouched();
  }

  private getFileType(type: string): string {
    if (this.attachmentType) return this.attachmentType;
    if (documentsTypes.includes(type)) return 'PDF';
    if (imagesTypes.includes(type)) return 'IMG';
    return undefined;
  }

  public onChange(files: File[]) {
    if (!files || !files[0]) return;

    const newAttachments = files.map(
      file =>
        ({
          title: file.name,
          name: file.name,
          size: file.size,
          file: file as Blob,
          type: this.getFileType(file.type)
        }) as Attachment
    );

    if (this.multiple) {
      const currentFiles = (this.value as Attachment[]) || [];
      this.value = [...currentFiles, ...newAttachments];
    }

    if (!this.multiple) {
      this.value = newAttachments && newAttachments[0];
    }

    this.markAsTouched();
  }

  public onMoveUp(attachment: Attachment) {
    const index = this.attachmentsArray.findIndex(a => a === attachment);

    if (index <= 0) {
      this.boundaryMoveUp.emit(attachment);

      /**
       * If the event's callback alters the value somehow,
       * we want to be sure that indexes are still correct.
       */
      if (!this.multiple) {
        this.value = { ...this.value, index: 0 };
      } else {
        this.value = this.updateIndexes(this.attachmentsArray);
      }
      return;
    }

    this.value = this.updateIndexes(
      arrayMove(this.attachmentsArray, index, index - 1)
    );
  }

  public onMoveDown(attachment: Attachment) {
    const index = this.attachmentsArray.findIndex(a => a === attachment);
    const length = this.attachmentsArray.length;

    if (index >= length - 1) {
      this.boundaryMoveDown.emit(attachment);

      /**
       * If the event's callback alters the value somehow,
       * we want to be sure that indexes are still correct.
       */
      if (!this.multiple) {
        this.value = { ...this.value, index: 0 };
      } else {
        this.value = this.updateIndexes(this.attachmentsArray);
      }
      return;
    }

    this.value = this.updateIndexes(
      arrayMove(this.attachmentsArray, index, index + 1)
    );
  }

  public get hideUpload() {
    const hasValue =
      (Array.isArray(this.value) && this.value.length) ||
      (!Array.isArray(this.value) && this.value);

    return this.hideFileUpload || (!this.multiple && hasValue);
  }

  private markAsTouched() {
    this.touched = true;
  }

  private updateIndexes(attachments: Attachment[]) {
    return attachments.map((attachment, index) => ({ ...attachment, index }));
  }

  public onUpdateAttachment(input: { attachment: Attachment; index: number }) {
    const isArray = Array.isArray(this.value);

    isArray
      ? (this.value[input.index] = {
          ...this.value[input.index],
          ...input.attachment
        })
      : (this.value = { ...this.value, ...input.attachment });
  }
}
