import {
  Component,
  Input,
  forwardRef,
  ViewChild,
  OnInit,
  Optional,
  Host,
  SkipSelf,
  Injector
} from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR, ControlContainer } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';

import { SelectOption } from './select-option/select-option';

@UntilDestroy()
@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => SelectComponent)
    }
  ]
})
export class SelectComponent
  extends BaseControl<string | number | { [key: string]: any }>
  implements OnInit
{
  @ViewChild(NgControl, { static: true }) ngControl: NgControl;
  @Input() itemLabelKey = 'name';
  @Input() itemValueKey = 'value';
  @Input() multiple = false;
  @Input() sharedModel: string;
  @Input() readonly = false;
  @Input() showAdditionalItem = false;

  get valueNotAItem() {
    return !this.options.some(option => option.value === this.value);
  }

  get additionalItem() {
    return (
      this.valueNotAItem &&
      new SelectOption(this.value, this.itemLabelKey, this.itemValueKey)
    );
  }

  options: SelectOption[];

  @Input()
  set items(items: any[]) {
    this.options =
      items && items.length
        ? items.map(
            (item: any) =>
              new SelectOption(item, this.itemLabelKey, this.itemValueKey)
          )
        : [];
  }

  constructor(
    @Optional() @Host() @SkipSelf() private controlContainer: ControlContainer,
    protected injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    if (this.sharedModel && this.controlContainer) {
      this.controlContainer.control
        .get(this.sharedModel)
        .valueChanges.pipe(untilDestroyed(this))
        .subscribe(value => {
          this.value = value;
        });
    }
    this.ngControl.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
  }

  shouldAddSharedModelActiveClass() {
    return (
      this.sharedModel &&
      this.options.find(option => option.value === this.value)
    );
  }
}
