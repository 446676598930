import { Pipe, PipeTransform } from '@angular/core';

export type SortOptions = 'ASC' | 'DESC';

@Pipe({ name: 'appSort' })
export class SortPipe implements PipeTransform {
  transform(items: any[], field: string, sort: SortOptions = 'ASC') {
    if (!Array.isArray(items) || !field) {
      return;
    }

    const sortDirection = sort === 'ASC' ? [1, -1] : [-1, 1];

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return [...items].sort((a, b) =>
      a[field] > b[field]
        ? sortDirection[0]
        : b[field] > a[field]
          ? sortDirection[1]
          : 0
    );
  }
}
