import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '@ui/shared/models';

@Injectable()
export class ConstantsParserService {
  constructor(private translateService: TranslateService) {}

  parse(data): Constants {
    return Object.keys(data).reduce((previous, key) => {
      previous[key] = Array.isArray(data[key])
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          data[key].map(item => {
            if (key === 'conversationMessageTemplateSubstitutionTags') {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              return {
                ...item,
                label: this.translateService.instant(item.label)
              };
            }
            return {
              value: item,
              name: this.translateService.instant(
                `${key.toUpperCase()}_${String(this.returnObjectName(item))}`
              )
            };
          })
        : [];

      return previous;
    }, {});
  }

  returnObjectName(item: any): any {
    if (item instanceof Object) {
      if ('commercialSubType' in item) {
        return item.commercialSubType;
      }
    }
    return item;
  }
}
