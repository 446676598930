import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output,
  Input
} from '@angular/core';
import { SocialLoginProvider } from '@ui/shared/models';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialLoginComponent {
  @Input() horizontalLayout = false;
  @Input() showText = true;
  @Output() login = new EventEmitter<string>();

  public socialLoginProvider = SocialLoginProvider;

  public onSocialLogin(loginMethod: string) {
    this.login.emit(loginMethod);
  }
}
