<label class="checkbox__container" [class.d-flex]="innerHTML">
  <input
    type="checkbox"
    class="checkbox"
    [class.checkbox--hide-active-value]="hideActiveValue"
    [ngClass]="{'checkbox--large': showLargerCheckboxes}"
    [disabled]="disabled"
    [required]="required"
    [(ngModel)]="value"
  />

  <span
    (click)="toggleValue()"
    class="checkbox__label default-s d-flex"
    [ngClass]="{'checkbox__label--large': showLargerCheckboxes}"
  >
    <span *ngIf="required" class="required-marker">*&nbsp;</span>
    <span *ngIf="!innerHTML; else useInnerHTML" class="normal"
      >{{ label }}</span
    >
    <ng-template #useInnerHTML>
      <span [innerHTML]="innerHTML" class="inner-HTML"></span>
    </ng-template>
    <span class="checkbox__custom"></span>
  </span>
</label>
