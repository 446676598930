import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSliderDragEvent } from '@angular/material/slider';

@UntilDestroy()
@Component({
  selector: 'app-search-profile-property-details',
  templateUrl: './search-profile-property-details.component.html',
  styleUrls: ['./search-profile-property-details.component.scss']
})
export class SearchProfilePropertyDetailsComponent {
  @Input() showSeniorApartment = true;
  @Input() considerElevator = true;
  @Input() form: FormGroup;

  public elevatorSelected = 0;
  public balconyTerraceSelected = 0;
  public barrierFreeSelected = 0;
  public seniorApartmentSet = 0;

  public lowerBoundFloorSet = 0;
  public upperBoundFloorSet = 10;

  public yesNoOptions = [
    { name: 'general.yes_l', value: true },
    { name: 'general.no_l', value: false },
    { name: 'general.no_matter_l', value: null }
  ];

  public booleanOptions = [
    { name: 'general.yes_l', value: false },
    { name: 'general.no_l', value: true }
  ];

  public ngOnInit() {
    this.elevatorSelected = this.resolveValue(this.form.get('elevator').value);
    this.balconyTerraceSelected = this.resolveValue(
      this.form.get('balconyTerrace').value
    );
    this.barrierFreeSelected = this.resolveValue(
      this.form.get('barrierFree').value
    );
    this.seniorApartmentSet = this.resolveValue(
      this.form.get('seniorApartment').value
    );
    this.lowerBoundFloorSet = this.form.get('lowerBoundFloor').value;
    this.upperBoundFloorSet = this.form.get('upperBoundFloor').value;

    this.form
      .get('elevator')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(val => (this.elevatorSelected = this.resolveValue(val)));

    this.form
      .get('balconyTerrace')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(val => (this.balconyTerraceSelected = this.resolveValue(val)));

    this.form
      .get('barrierFree')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(val => (this.barrierFreeSelected = this.resolveValue(val)));

    this.form
      .get('seniorApartment')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(val => (this.seniorApartmentSet = this.resolveValue(val)));

    this.form
      .get('lowerBoundFloor')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((val: number) => (this.lowerBoundFloorSet = val));

    this.form
      .get('upperBoundFloor')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((val: number) => (this.upperBoundFloorSet = val));
  }

  private resolveValue(value: boolean) {
    if (value !== null) return 1;
    else return 0;
  }

  public displayFloorLabel(value: number) {
    return value >= 10 ? `${value}+` : `${value}`;
  }

  /**
   * When the user stops dragging and leaves the nobs with the cursor,
   * the number bubbles should be hidden
   * @param event
   */
  dragEnd(event: MatSliderDragEvent) {
    event.source._isFocused = false;
  }
}
