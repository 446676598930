<div [className]="baseClass + '__column'">
  <span [className]="getLabelClassName()">
    {{label}}
    <span [className]="baseClass + '__asterisk'" *ngIf="required && !disabled">
      *
    </span>
  </span>
</div>
<div [className]="baseClass + '__column'">
  <ng-content></ng-content>
  <span
    [className]="baseClass + '__error-message'"
    *ngIf="errorMessage.length && !disabled"
    >{{errorMessage}}</span
  >
</div>
