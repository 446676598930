import { Component, Input } from '@angular/core';
import { isValueNotNullAndUndefined } from 'libs/utils';
@Component({
  selector: 'app-property-group-card',
  templateUrl: './property-group-card.component.html',
  styleUrls: ['./property-group-card.component.scss']
})
export class PropertyGroupCardComponent {
  @Input() imageUrl = '';
  @Input() title = '';
  @Input() subTitle = '';
  @Input() propertiesCount = 0;
  @Input() propertiesCountTotal = 0;
  @Input() propertiesCountAdded = 0;
  @Input() propertiesCountTooltipText: string | null = null;

  public baseClass = 'property-group-card';

  get imageUrlWithFallback(): string {
    return this.imageUrlHasBeenSet()
      ? this.imageUrl
      : '/assets/images/object-image-placeholder.svg';
  }

  public imageUrlHasBeenSet(): boolean {
    return this.imageUrl !== '' && isValueNotNullAndUndefined(this.imageUrl);
  }
}
