import { Action } from '@ngrx/store';
import {
  Language,
  CookiePreference,
  User,
  AvailableLanguageCodesEnum
} from '@ui/shared/models';

export const GET_SYSTEM_DOWNTIME = '[App] Set System Time';
export const SET_CURRENT_LOCALE = '[App] Set Current Locale';
export const CHANGE_LOCALE = '[App] Change Locale';
export const SET_AVAILABLE_LANGUAGES = '[App] Set Available Languages';
export const SET_DEFAULT_LANGUAGE_CODE = '[App] Set Default Language Code';
export const UPDATE_APPLICATION = '[App] Update Application';
export const UPDATE_APPLICATION_SUCCESS = '[App] Update Application Success';
export const UPDATE_URL = '[App] Update URL';
export const SET_REDIRECTED = '[App] Set Redirected';
export const GET_VERSION = '[App] Get Version';
export const GET_VERSION_SUCCESS = '[App] Get Version Success';

export class GetSystemDowntime implements Action {
  readonly type = GET_SYSTEM_DOWNTIME;
}

export class SetCurrentLocale implements Action {
  readonly type = SET_CURRENT_LOCALE;

  constructor(public locale: AvailableLanguageCodesEnum) {}
}

export class SetAvailableLanguages implements Action {
  readonly type = SET_AVAILABLE_LANGUAGES;

  constructor(public languages: Language[]) {}
}

export class SetDefaultLanguageCode implements Action {
  readonly type = SET_DEFAULT_LANGUAGE_CODE;

  constructor(public languageCode: AvailableLanguageCodesEnum) {}
}

export class ChangeLocale implements Action {
  readonly type = CHANGE_LOCALE;

  constructor(public locale: AvailableLanguageCodesEnum) {}
}

export const SET_VIEWPORT_SIZE = '[App] Set Image Size';

export class SetViewportSize implements Action {
  readonly type = SET_VIEWPORT_SIZE;
  constructor(public viewportSize: string) {}
}

export const SET_COOKIES_PREFERENCE = '[App] Set Cookies Preference';
export const SET_COOKIES_PREFERENCE_SUCCESS =
  '[App] Set Cookies Preference Success';

export class SetCookiesPreference implements Action {
  readonly type = SET_COOKIES_PREFERENCE;
  constructor(
    public cookiePreference: CookiePreference,
    public user?: User
  ) {}
}

export class SetCookiesPreferenceSuccess implements Action {
  readonly type = SET_COOKIES_PREFERENCE_SUCCESS;
  constructor(public cookiePreference: CookiePreference) {}
}

export const INIT_COOKIES_PREFERENCE = '[App] Init Cookies Preference';

export class InitCookiesPreference implements Action {
  readonly type = INIT_COOKIES_PREFERENCE;
  constructor(public user?: User) {}
}

export const OPEN_COOKIE_SETTINGS_MODAL = '[App] Open Cookies Settings Modal';

export class OpenCookieSettingsModal implements Action {
  readonly type = OPEN_COOKIE_SETTINGS_MODAL;
  constructor(
    public cookiePreference: CookiePreference,
    public isTenant: boolean,
    public user?: User
  ) {}
}

export const UPDATE_COOKIE_TRACKING = '[App] Update Cookie Tracking';

export class UpdateCookieTracking implements Action {
  readonly type = UPDATE_COOKIE_TRACKING;
  constructor(
    public cookiePreference: CookiePreference,
    public user?: User
  ) {}
}

export const GET_PERFORMANCE_WARNING_CONFIRMATION =
  '[App] Get Performance Warning Confirmation';

export class GetPerformanceWarningConfirmation implements Action {
  readonly type = GET_PERFORMANCE_WARNING_CONFIRMATION;
}

export const SET_PERFORMANCE_WARNING_CONFIRMATION =
  '[App] Set Performance Warning Confirmation';

export class SetPerformanceWarningConfirmation implements Action {
  readonly type = SET_PERFORMANCE_WARNING_CONFIRMATION;
  constructor(public confirmation: boolean) {}
}

export const TRACK_GOOGLE_EVENT = '[App] Track Google Event';

export class TrackGoogleEvent implements Action {
  readonly type = TRACK_GOOGLE_EVENT;
  constructor(
    public trackingId: string,
    public category?: string,
    public sendPageView = false
  ) {}
}

export class UpdateApplication implements Action {
  readonly type = UPDATE_APPLICATION;
}

export class UpdateApplicationSuccess implements Action {
  readonly type = UPDATE_APPLICATION_SUCCESS;
}

export class SetRedirected implements Action {
  readonly type = SET_REDIRECTED;
}

export class GetVersion implements Action {
  readonly type = GET_VERSION;
}

export class GetVersionSuccess implements Action {
  readonly type = GET_VERSION_SUCCESS;
  constructor(public version: { version: string; prevVersion: string }) {}
}

export type AppAction =
  | GetSystemDowntime
  | SetCurrentLocale
  | ChangeLocale
  | SetAvailableLanguages
  | SetDefaultLanguageCode
  | SetViewportSize
  | SetCookiesPreferenceSuccess
  | SetCookiesPreference
  | UpdateCookieTracking
  | InitCookiesPreference
  | OpenCookieSettingsModal
  | GetPerformanceWarningConfirmation
  | SetPerformanceWarningConfirmation
  | TrackGoogleEvent
  | UpdateApplication
  | UpdateApplicationSuccess
  | SetRedirected
  | GetVersion
  | GetVersionSuccess;
