import { NgModule } from '@angular/core';

import {
  FormArrayPipe,
  FormControlPipe,
  FormGroupPipe
} from './form-control.pipe';

const pipes = [FormArrayPipe, FormControlPipe, FormGroupPipe];

@NgModule({
  declarations: pipes,
  exports: pipes
})
export class FormControlPipeModule {}
