<form [formGroup]="form" class="digital-contract-signer">
  <div
    *ngFor="let digitalContractSignerFormGroup of getDigitalContractSignerControls; index as index"
  >
    <div [formGroup]="digitalContractSignerFormGroup">
      <div class="row pb12 digital-contract-signer__remove">
        <div
          *ngIf="headerTitle"
          class="col-9 digital-contract-signer__remove digital-contract-signer__remove--text"
        >
          {{ headerTitle | translate }}
        </div>
        <div
          *ngIf="!readOnly"
          class="col-3 digital-contract-signer__remove digital-contract-signer__remove--icon"
        >
          <svg-icon
            src="/assets/images/icons/icon-bin.svg"
            [applyClass]="true"
            [svgStyle]="{'width.px': 15}"
            class="secondary-svg-color path rect"
            (click)="removeDigitalContractSigner(index)"
          ></svg-icon>
        </div>
      </div>

      <div *ngIf="templates" class="row pt8">
        <div class="col-12 col-md-6 mb20">
          <app-dropdown-select
            [items]="templates"
            formControlName="selectedTemplate"
            [disabled]="templates?.length === 0"
            itemValueKey="id"
          >
            <div dropdown-button>{{'CHOOSE_TEMPLATE_L' | translate}}</div>
            <ng-template let-item>
              <div class="template-item">
                <app-avatar
                  class="template-item__image"
                  size="tiny"
                  [imgSrc]="item?.profile?.portrait | appImageSize:'S'"
                  name="{{ item?.profile | appFullName }}"
                >
                </app-avatar>
                <div class="template-item__name">
                  {{ item.profile | appFullName }}
                </div>
              </div>
            </ng-template>
          </app-dropdown-select>
        </div>
      </div>
      <div *ngIf="genderTypes" class="row pt8">
        <div class="col-12 mb20">
          <app-form-field *ngIf="!readOnly">
            <app-form-field-label
              >{{'general.gender_l' | translate}}</app-form-field-label
            >
            <app-select
              [required]="!readOnly && isGenderRequired"
              [items]="genderTypes"
              [placeholder]="'general.select_l' | translate"
              formControlName="gender"
            >
            </app-select>
          </app-form-field>
          <div *ngIf="readOnly && getGender(index)">
            <label class="app-label">{{'general.gender_l' | translate}}</label>
            <div class="app-text">
              {{ 'GENDERTYPES_' + getGender(index) | translate}}
            </div>
          </div>
        </div>
      </div>

      <div class="row pt8">
        <div class="col-12 col-md-6 mb20">
          <app-form-field>
            <app-form-field-label
              >{{'general.first_name_l' | translate}}</app-form-field-label
            >
            <input
              appInput
              type="text"
              (keyup)="onKeyUp(digitalContractSignerFormGroup)"
              [readonly]="readOnly"
              [required]="!readOnly"
              [placeholder]="'forms.type_name' | translate"
              formControlName="firstname"
              class="form-control"
            />
          </app-form-field>
        </div>

        <div class="col-12 col-md-6 mb20">
          <app-form-field>
            <app-form-field-label
              >{{'general.last_name_l' | translate}}</app-form-field-label
            >
            <input
              appInput
              type="text"
              (keyup)="onKeyUp(digitalContractSignerFormGroup)"
              [readonly]="readOnly"
              [required]="!readOnly"
              [placeholder]="'forms.type_name' | translate"
              formControlName="lastname"
              class="form-control"
            />
          </app-form-field>
        </div>

        <div class="col-12 mb20">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.email_address_l' | translate}}</app-form-field-label
            >
            <input
              appInput
              type="email"
              (keyup)="onKeyUp(digitalContractSignerFormGroup)"
              [readonly]="readOnly"
              [required]="!readOnly"
              placeholder="{{ 'forms.type_email_address' | translate}}"
              formControlName="email"
              class="form-control"
            />
          </app-form-field>
        </div>

        <div class="col-12 mb20" *ngIf="isPhoneRequired">
          <app-form-field>
            <!--
            We need to use the appInput directive here in order to show the validations for phone
            Otherwise the validation isn't shown, as the the component splits the country code and the phone number
            -->
            <app-international-phone
              appInput
              [readonly]="readOnly"
              [required]="!readOnly"
              [internationalOptions]="internationalPhoneOptions"
              (keyup)="onKeyUp(digitalContractSignerFormGroup)"
              formControlName="phone"
            >
            </app-international-phone>
          </app-form-field>
        </div>

        <div class="col-12 mb0">
          <app-address-form
            [formGroup]="getControlAt(index)"
            [required]="isAddressRequired"
            [config]="addressFormConfig"
            [readOnly]="readOnly"
            [showRegion]="false"
            [showDistricts]="false"
            [customerSettings]="customerSettings"
          >
          </app-address-form>
        </div>
      </div>
    </div>

    <!-- add some space between entries -->
    <div
      *ngIf="index < getDigitalContractSignerControls.length - 1"
      class="pb8"
    >
      <hr class="mb30" />
    </div>
  </div>

  <div *ngIf="getDigitalContractSignerControls?.length > 0" class="mt40"></div>

  <div *ngIf="!readOnly">
    <app-button
      [type]="!getDigitalContractSignerControls?.length && parentFormTouched ? 'negative' : 'light-bordered'"
      [ghost]="!getDigitalContractSignerControls?.length && parentFormTouched"
      (clickEvent)="addDigitalContractSigner()"
    >
      {{ 'general.add_a' | translate }}
    </app-button>
  </div>
</form>
