<div class="header-mobile">
  <div class="header-mobile__title title-m">{{title | translate}}</div>
  <div class="header-mobile__back">
    <app-button
      *ngIf="showBack"
      type="context-menu"
      iconLeft="arrow-left"
      ghost="true"
      (clickEvent)="onBack($event)"
    ></app-button>
  </div>
  <div class="header-mobile__close">
    <app-button
      *ngIf="showClose"
      type="context-menu"
      iconLeft="close"
      ghost="true"
      (clickEvent)="onClose($event)"
    ></app-button>
  </div>
</div>
