<div class="mb10" *ngIf="introduction" [innerHTML]="introduction.text"></div>
<ng-container *ngFor="let checkbox of checkboxes">
  <app-checkbox
    *ngIf="checkbox.text"
    (change)="checkboxValueChanged()"
    class="d-block"
    [innerHTML]="checkbox.text"
    [ngModel]="false"
  ></app-checkbox>
</ng-container>
<div *ngIf="conclusion" [innerHTML]="conclusion.text"></div>
