<app-infinite-scroll
  *ngIf="scrollable; else tableTemplate"
  [style.max-height]="maxScrollableHeight"
  (scrolled)="onScrollChange()"
>
  <ng-container [ngTemplateOutlet]="tableTemplate"></ng-container>
</app-infinite-scroll>

<ng-template #tableTemplate>
  <table
    class="table"
    [ngClass]="{
    'table-bordered': borderedTable,
    'table-dark': darkTable,
    'table-striped': stripedTable,
    'table-hover': hoveredTable,
    'table-sm': smallTable,
    'table-responsive': responsiveTable
  }"
  >
    <thead
      [ngClass]="{
      'thead-dark': darkHeader,
      'thead-light': lightHeader
    }"
    >
      <tr>
        <th *ngIf="showIndex" scope="col">#</th>
        <th *ngFor="let headColumn of headColumns" scope="col">
          {{ headColumn.label | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngIf="data?.length; else noContent">
        <ng-container [ngTemplateOutlet]="rowTemplate"></ng-container>
      </ng-container>

      <ng-template #noContent>
        <tr class="no-content-row">
          <td
            class="text-center default-grey-s"
            [attr.colspan]="headColumns?.length"
          >
            {{ noDataMessage | translate }}
          </td>
        </tr>
      </ng-template>
    </tbody>

    <tfoot *ngIf="data?.length">
      <tr>
        <td [attr.colspan]="headColumns?.length"></td>
      </tr>
    </tfoot>

    <ng-container *ngIf="isLoading" [ngTemplateOutlet]="loading"></ng-container>
  </table>
</ng-template>

<ng-template #rowTemplate>
  <tr *ngFor="let item of data; let i = index">
    <td *ngIf="showIndex">{{ i + 1}}</td>
    <td *ngFor="let headColumn of headColumns">
      <ng-container
        *ngIf="cellTemplatesMap[headColumn.name]; else defaultTemplate"
      >
        <ng-container
          [ngTemplateOutlet]="cellTemplatesMap[headColumn.name]"
          [ngTemplateOutletContext]="{ $implicit: item }"
        ></ng-container>
      </ng-container>

      <ng-template #defaultTemplate>
        {{ item[headColumn.name] || '-' }}
      </ng-template>
    </td>
  </tr>
</ng-template>

<ng-template #loading>
  <div class="loading-spinner">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>
