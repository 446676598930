<form [formGroup]="form">
  <ng-content></ng-content>

  <app-form-field class="mb10 d-block">
    <div class="checkbox-group d-flex">
      <app-check
        [disableUncheck]="true"
        formControlName="forbidden"
        class="chat-settings m-auto"
      ></app-check>
      <app-form-field-label class="d-block col p0 mt5"
        >{{'messenger.setting_forbidden_l' | translate}}</app-form-field-label
      >
    </div>
  </app-form-field>
  <div>
    <span> {{'messenger.chat_settings_pre_text_l' | translate}} </span>
    <app-form-field>
      <div class="checkbox-group d-flex">
        <app-check
          [disableUncheck]="true"
          formControlName="allowed"
          class="chat-settings m-auto"
        ></app-check>
        <app-form-field-label class="d-block col p0 mt5"
          >{{'messenger.setting_allowed_l' | translate}}</app-form-field-label
        >
      </div>
    </app-form-field>
    <app-form-field>
      <div class="checkbox-group d-flex">
        <app-check
          [disableUncheck]="true"
          formControlName="replyOnly"
          class="chat-settings m-auto"
        ></app-check>
        <app-form-field-label class="d-block col p0 mt5"
          >{{'messenger.setting_reply_only_l' |
          translate}}</app-form-field-label
        >
      </div>
    </app-form-field>
    <app-form-field *ngIf="!isGarage">
      <div class="checkbox-group d-flex">
        <app-check
          [disableUncheck]="true"
          formControlName="whenInvited"
          class="chat-settings m-auto"
        ></app-check>
        <app-form-field-label class="d-block col p0 mt5"
          >{{'messenger.setting_when_invited_l' |
          translate}}</app-form-field-label
        >
      </div>
    </app-form-field>
  </div>
</form>
