<div *ngIf="!confirmed" class="banner__container">
  <div class="banner__message-container d-flex">
    <div class="banner__header">{{ config.header | translate }}</div>
    <div class="banner__message" [innerHTML]="config.message | translate"></div>
    <div class="banner__close">
      <img
        src="/assets/images/icons/icon-close-white.svg"
        (click)="onClose()"
      />
    </div>
  </div>
</div>
