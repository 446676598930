<div
  *ngIf="!isPdf(attachment) && !isDocument"
  (click)="togglePreview()"
  (mouseleave)="closePreview()"
>
  <div class="image-container">
    <img
      [src]="imgSrc || ''"
      alt=""
      class="attachment__thumbnail"
      [ngClass]="'rotate-' + attachment.rotate"
    />
  </div>

  <div *ngIf="previewOpen" class="attachment-preview">
    <img
      [src]="imgSrc || ''"
      alt=""
      [ngClass]="'rotate-' + attachment.rotate"
    />
  </div>
</div>

<div *ngIf="isPdf(attachment) || isDocument" class="d-flex">
  <img
    *ngIf="isPdf(attachment) && isDocument"
    (click)="clickOnPdf()"
    class="attachment-icon"
    src="/assets/images/icons/icon-pdf-red.svg"
  />
  <i
    *ngIf="isDocument && !isPdf(attachment)"
    (click)="clickOnImage()"
    class="attachment-icon icon icon--document"
  ></i>
</div>
