<form [formGroup]="form" class="hierarchical-questions card">
  <div class="hierarchical-questions__sub-questions card-body">
    <ng-container [ngSwitch]="true">
      <app-hierarchical-question-selection
        *ngSwitchCase="hierarchicalQuestion.data.type === 'SELECTION'"
        [formControl]="answerIdsControl"
        [hierarchicalQuestion]="hierarchicalQuestion"
        [currentLanguage]="currentLanguage"
        [defaultLanguage]="defaultLanguage"
        (ngModelChange)="onAnswerChanged($event)"
      ></app-hierarchical-question-selection>
      <app-hierarchical-question-input
        *ngSwitchCase="
        hierarchicalQuestion.data.type === 'RANGE_DATE' ||
        hierarchicalQuestion.data.type === 'RANGE_NUMBER' ||
        hierarchicalQuestion.data.type === 'INPUT_DATE' ||
        hierarchicalQuestion.data.type === 'INPUT_TEXT'"
        [disabled]="isProcessing"
        [hierarchicalQuestion]="hierarchicalQuestion"
        [formControl]="responseControl"
        [currentLanguage]="currentLanguage"
        [defaultLanguage]="defaultLanguage"
        (ngModelChange)="onAnswerChanged($event)"
      ></app-hierarchical-question-input>

      <div
        *ngIf="hierarchicalQuestion.data.idType === 'MEMBERSHIP'"
        class="dk-info__container"
      >
        <app-badge
          [color]="BadgeColorEnum.PRIMARY_ACCENT"
          [icon]="IconTypeEnum.Info"
        >
          {{ 'general.data_privacy_notice_l' | translate }}
        </app-badge>
        <p class="mt10 mb0">
          {{ 'question.membership.privacy_notice_l' | translate }}
        </p>
      </div>

      <div
        *ngIf="hierarchicalQuestion.data.commentAllowed"
        formGroupName="userResponse"
      >
        <app-form-field>
          <app-form-field-label>
            {{ hierarchicalQuestion.data.commentHint[currentLanguage] ||
            hierarchicalQuestion.data.commentHint[defaultLanguage] }}
          </app-form-field-label>
          <textarea
            appInput
            count="true"
            maxValue="1000"
            formControlName="comment"
            class="form-control"
          ></textarea>
        </app-form-field>
      </div>
    </ng-container>
  </div>
</form>
