import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';
import { Observable } from 'rxjs';
import {
  AvailableLanguageCodesEnum,
  EditTaskType,
  HierarchicalQuestionModel,
  RootQuestionContainerModel,
  ContactTag
} from '@ui/shared/models';
import { HierarchicalQuestionService } from 'libs/services';

@Component({
  selector: 'app-hierarchical-questions-display-root',
  templateUrl: './hierarchical-questions-display-root.component.html',
  styleUrls: ['./hierarchical-questions-display-root.component.scss']
})
export class HierarchicalQuestionsDisplayRootComponent implements OnInit {
  @Input() hierarchicalQuestionContainer: RootQuestionContainerModel;
  @Input() tags: ContactTag[];
  @Input() isProcessing: boolean;
  @Input() appCheckTemplateRef: TemplateRef<any>;
  @Input() editTaskType: EditTaskType;

  @Output() edit = new EventEmitter<RootQuestionContainerModel>();
  @Output() delete = new EventEmitter<RootQuestionContainerModel>();

  public mainQuestion: HierarchicalQuestionModel;
  public showDetails = false;
  public languageCode$: Observable<AvailableLanguageCodesEnum>;

  public get questions() {
    return this.hierarchicalQuestionContainer.rootQuestion.questions;
  }

  public get subQuestions() {
    return this.questions
      .filter(question => question.id !== this.mainQuestion.id)
      .sort(this.hierarchicalQuestionService.sort);
  }

  constructor(
    private hierarchicalQuestionService: HierarchicalQuestionService
  ) {}

  ngOnInit(): void {
    this.mainQuestion = this.questions.find(
      question =>
        question.id ===
        this.hierarchicalQuestionContainer.rootQuestion.mainQuestionId
    );
    this.languageCode$ = this.hierarchicalQuestionService.getLanguageCode();
  }

  public toggleShowDetails() {
    this.showDetails = !this.showDetails;
  }

  public onEdit() {
    this.edit.emit(this.hierarchicalQuestionContainer);
  }

  public onDelete() {
    this.delete.emit(this.hierarchicalQuestionContainer);
  }
}
