import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';

import { NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';

@UntilDestroy()
@Component({
  selector: 'app-check',
  templateUrl: './check.component.html',
  styleUrls: ['./check.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => CheckComponent)
    }
  ]
})
export class CheckComponent extends BaseControl<boolean> implements OnInit {
  @ViewChild(NgControl, { static: true }) ngControl: NgControl;
  @Input() disableUncheck = false;
  @Input() squared = false;
  @Input() tooltip = '';

  public ngOnInit() {
    this.ngControl.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
  }

  public onClick(event: Event, value: boolean) {
    if (this.disableUncheck && value) {
      event.preventDefault();
    }
  }
}
