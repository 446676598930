import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { RegexTypes } from 'libs/utils';

@Component({
  selector: 'app-landlord-info',
  templateUrl: './landlord-info.component.html',
  styleUrls: ['./landlord-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LandlordInfoComponent {
  @Input() name: string;
  @Input() logo: string;
  @Input() logoRedirectUrl: string;
  @Input() showOfferedBy = true;
  @Input() offeredByTitle: string;
  @Input() showInCooperationWith = true;

  public baseClass = 'landlord-info';

  public get withLinkedLogo(): boolean {
    return !!RegexTypes.URL.exec(this.logoRedirectUrl);
  }

  public get showInCooperationWithImmomio(): boolean {
    return !!this.logo;
  }

  public get customerLogo(): string {
    return (
      this.logo ||
      this.themeService?.getTheme(this.themeService?.getDefaultTheme)?.logo?.url
    );
  }

  constructor(private themeService: ThemeService) {}
}
