<div class="card card-body">
  <div class="mb20">
    <img [src]="logo" alt="customer-logo" />
  </div>

  <p class="uppercase-m semibold mb20">{{ header | translate }}</p>

  <p class="mb20">{{ description | translate }}</p>

  <div *ngIf="showButton" class="d-flex justify-content-center">
    <app-button [type]="'primary'" (clickEvent)="buttonClicked.emit()">
      {{ buttonText | translate }}
    </app-button>
  </div>
</div>
