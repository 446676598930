<form [formGroup]="form" class="hierarchical-root-questions">
  <div formArrayName="hierarchicalRootQuestions">
    <div
      class="mb16"
      *ngFor="let hierarchicalQuestion of hierarchicalRootQuestionsForm.controls; index as i"
    >
      <div [formGroupName]="i">
        <div formGroupName="questions">
          <div
            *ngFor="let subQuestion of getQuestionsControl(i).controls; index as j"
          >
            <app-hierarchical-question-form
              *ngIf="j === 0 || questionDisplayIndices.get(i)[j]"
              [formControl]="subQuestion"
              [hierarchicalQuestion]="subQuestion.value"
              [hierarchicalQuestions]="getAllHierarchicalQuestions(i)"
              [currentLanguage]="currentLanguage$ | async"
              [defaultLanguage]="defaultLanguage$ | async"
              (answerChange)="onAnswerChange($event, i)"
              (validityChange)="onFormValidityChange($event)"
            ></app-hierarchical-question-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
