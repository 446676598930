import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Attachment } from '@ui/shared/models';

const isDragEvent = (event: any): event is DragEvent => !!event.dataTransfer;

export type FileUploadChange = Attachment[] | { error: any; message?: string };

@Component({
  selector: 'app-file-upload-input',
  templateUrl: './file-upload-input.component.html',
  styleUrls: ['./file-upload-input.component.scss']
})
export class FileUploadInputComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() disabled = false;
  @Input() required = false;
  @Input() multiple = false;
  @Input() showButton = false;
  @Input() size = 1024 * 1024 * 10;
  @Input() accept: string;
  @Input() subInformation: string;

  @Output() changeFileUpload: EventEmitter<FileUploadChange> =
    new EventEmitter();

  public dragHover = false;
  private uploadArea: Element;
  public error: string;

  public get acceptImages() {
    return (this.accept || '').match(/png|jpg|jpeg|gif/g);
  }

  constructor(private elementRef: ElementRef) {}

  public ngOnInit() {
    this.uploadArea =
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      this.elementRef.nativeElement.querySelector('.file-upload__area');
    this.uploadArea.addEventListener(
      'dragover',
      this.fileDragHover.bind(this),
      true
    );
    this.uploadArea.addEventListener(
      'dragleave',
      this.fileDragHover.bind(this),
      true
    );
    this.uploadArea.addEventListener(
      'drop',
      this.fileSelectHandler.bind(this),
      false
    );
  }

  private fileDragHover(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.dragHover = event.type === 'dragover';
  }

  private extractFileList(event: Event) {
    if (isDragEvent(event)) {
      return event.dataTransfer.files;
    }
    return (event.target as HTMLInputElement).files;
  }

  public fileSelectHandler(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.disabled) {
      return;
    }

    this.error = null;
    this.dragHover = false;
    const files = Array.from(this.extractFileList(event));

    return this.validateFiles(files);
  }

  public handleButtonClick() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.fileInput.nativeElement.dispatchEvent('click');
  }

  private castToType(fileType: string) {
    return fileType ? fileType.split('/')[1] : '';
  }

  private getFileType() {
    if (this.accept && this.accept.includes(',')) {
      return this.accept.split(',').map(this.castToType);
    }
    return [this.castToType(this.accept)];
  }

  private emitFiles(files: File[]) {
    this.changeFileUpload.emit(files);
    this.fileInput.nativeElement.value = '';
  }

  private validateFiles(files: File[]) {
    // IE fix
    if (!files.length) return false;

    const fileTypes: string[] = this.getFileType();
    const areAllSizesValid = files.every(file => this.isFileSizeValid(file));

    if ((!this.accept || fileTypes.includes('*')) && areAllSizesValid) {
      return this.emitFiles(files);
    }

    const validFiles = files.filter(
      file =>
        (!fileTypes[0] || fileTypes.includes(this.castToType(file.type))) &&
        this.isFileSizeValid(file)
    );

    if (!validFiles.length) {
      this.error = !areAllSizesValid
        ? 'NOT_VALID_FILE_SIZE_L'
        : 'NOT_VALID_FILE_TYPE_L';

      return this.changeFileUpload.emit({
        error: true,
        message: this.error
      });
    }

    this.emitFiles(validFiles);
  }

  private isFileSizeValid(file: File) {
    return file.size <= this.size;
  }
}
