<app-modal>
  <div class="modal-xs">
    <app-modal-content>
      <div class="modal-body confirmation-dialog__body">
        <p class="title-m confirmation-dialog__title">
          {{titleMessage | translate:{value: titleValue} }}
        </p>
        <p
          class="default-s confirmation-dialog__message"
          *ngIf="!innerHTML; else useInnerHTML"
        >
          {{message | translate:{value: messageValue} }}
        </p>
        <ng-template #useInnerHTML>
          <p
            class="default-s confirmation-dialog__message"
            [innerHTML]="message | translate:{value: messageValue}"
          ></p>
        </ng-template>
        <p
          *ngIf="isProspectOfflineSales"
          class="default-s confirmation-dialog__message mt-4"
        >
          {{ 'offline_sales_prospect.confirmation.terms_and_condition.message' |
          translate }}
        </p>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer confirmation-dialog__footer">
        <app-button
          *ngIf="!acknowledge"
          (clickEvent)="cancelAction()"
          [type]="'light-bordered'"
          >{{ cancelButtonMessage | translate}}</app-button
        >
        <app-button (clickEvent)="okAction()"
          >{{ okButtonMessage | translate}}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal>
