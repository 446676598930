<app-card
  [class]="getClassName()"
  [elevation]="getElevation()"
  [clickable]="!pageView"
  [padding]="'none'"
  [borderStyle]="cardBorderStyle"
  [borderRadius]="cardBorderRadius"
  [overflowVisible]="!pageView"
>
  <div
    *ngIf="highlightCardAsActionNeeded"
    [class]="baseClass + '__notification-bell'"
    (click)="notificationBellClick()"
  >
    <div [class]="'icon icon--bell'"></div>
  </div>

  <ng-container *ngIf="!isRented; else rented">
    <app-context-menu
      *ngIf="showContextMenu"
      [class]="getContextMenuClassName()"
      [tooltipPlacement]="'bottom'"
      [buttonElevation]="getElevation() + 1"
    >
      <div menu-content>
        <app-context-menu-item
          *ngIf="hasCustomQuestions"
          (clickEvent)="answerCustomQuestionsClick()"
          [iconLeft]="'question'"
        >
          {{ 'property.edit_custom_questions_a' | translate }}
        </app-context-menu-item>

        <app-context-menu-item
          *ngIf="hasDeniedIntention"
          (clickEvent)="declareIntentClick()"
          [iconLeft]="'check-framed'"
        >
          {{ 'property.declare_intent_a' | translate }}
        </app-context-menu-item>

        <app-context-menu-item
          *ngIf="hasDeclaredIntention"
          (clickEvent)="denyIntentClick()"
          [iconLeft]="'remove'"
          [iconSpacing]="true"
        >
          {{ 'property.declare_no_intent_a' | translate }}
        </app-context-menu-item>
        <!-- TODO: replace attention icon with future 'no' icon and remove iconSpacing
            Before:
            <svg-icon
              [src]="'/assets/images/icons/icon-no.svg'"
              [applyClass]="true"
              [svgStyle]="{'width.px': 15}"
              class="negative-svg-color path rect circle"
            ></svg-icon>
           -->

        <app-context-menu-item
          *ngIf="enableExportAppointmentToCalendar"
          (clickEvent)="exportAppointmentToCalendarClick()"
          [iconLeft]="'calendar'"
        >
          {{ 'property.export_appointment_to_calendar_a' | translate }}
        </app-context-menu-item>

        <app-context-menu-item
          *ngIf="enableDownloadPDF"
          (clickEvent)="downloadPDFClick()"
          [iconLeft]="'pdf'"
        >
          {{ 'property.export_pdf_a' | translate }}
        </app-context-menu-item>

        <app-context-menu-item
          (clickEvent)="removePropertyClick()"
          [disabled]="isProcessing"
          [iconLeft]="'delete'"
        >
          {{ (isProposal ? 'property.remove_property_proposal_a' :
          'property.remove_property_application_a') | translate }}
        </app-context-menu-item>

        <app-context-menu-item
          *ngIf="isShowSelfDisclosure && !isProposal && isNotBlocked"
          (clickEvent)="showSelfDisclosureClick()"
          [disabled]="isProcessing"
          [iconSpacing]="true"
          [iconLeft]="'attention'"
        >
          {{ 'self_disclosure.answers.open_dialog_menu_a' | translate}}
        </app-context-menu-item>

        <app-context-menu-item
          *ngIf="enableAppointmentSelection"
          (clickEvent)="selectAppointmentClick()"
          [iconLeft]="'calendar'"
        >
          {{ 'appointment.select_appointment_a' | translate }}
        </app-context-menu-item>
      </div>
    </app-context-menu>
  </ng-container>

  <ng-template #rented>
    <app-button
      *ngIf="enableDownloadPDF"
      [class]="getDownloadPDFButtonClassName()"
      [type]="'context-menu'"
      [iconLeft]="'download'"
      [elevation]="getElevation() + 1"
      (clickEvent)="downloadPDFClick()"
    >
    </app-button>
  </ng-template>

  <div [class]="getBodyClassName()" (click)="showDetailsClick()">
    <div [class]="baseClass + '__image-wrapper'">
      <app-image
        [class]="getImageClassName()"
        [defaultSrc]="hasImages ? images[0]?.url : '/assets/images/object-image-placeholder.svg'"
        alt="{{ hasImages && ('property.fallback_image_l' | translate) }}"
        (click)="showImageGalleryClick()"
      ></app-image>

      <app-button
        *ngIf="showImageGallery"
        [class]="baseClass + '__lightbox-opener'"
        [type]="'context-menu'"
        [iconLeft]="'gallery'"
        [elevation]="getElevation() + 1"
        (clickEvent)="showImageGalleryClick()"
      >
        {{ images.length }} {{ galleryButtonLabel }}
      </app-button>
      <div
        *ngIf="hasImages"
        [class]="baseClass + '__image-background'"
        [style.background-image]="getBackgroundImage"
      ></div>
    </div>
    <app-application-status-pipeline
      *ngIf="showApplicationStatusPipeline"
      class="d-block"
      [status]="applicationStatus"
      [pageView]="pageView"
      [propertyType]="propertyData?.type"
    ></app-application-status-pipeline>
    <div [class]="baseClass + '__content'" class="d-flex flex-column">
      <div
        [class]="baseClass + '__badges'"
        class="d-flex flex-row flex-wrap"
        [class.mb-3]="pageView"
      >
        <app-badge *ngIf="propertyData.objectType || propertyData.type">
          {{ 'FLATTYPES_' + (propertyData.objectType || propertyData.type) |
          translate }}
        </app-badge>
        <app-badge
          *ngIf="propertyData.housingPermissionNeeded"
          [color]="'primary-accent'"
        >
          {{ 'property.wbs_badge_l' | translate }}
        </app-badge>
        <app-badge *ngIf="actionBadgeContent" [color]="'state-900'">
          {{ actionBadgeContent }}
        </app-badge>
        <app-badge *ngIf="highlightCardAsUserIsTenant" [color]="'state-100'">
          {{ 'general.congratulations_l' | translate }}
        </app-badge>
        <app-badge
          *ngIf="hasUnansweredQuestions && !isRented"
          [color]="'state-900'"
        >
          {{ 'property.unanswered_questions_l' | translate }}
        </app-badge>
      </div>
      <div [class]="baseClass + '__details'">
        <div
          *ngIf="showExternalId && isNotNullAndUndefined(propertyData.externalId)"
          [class]="baseClass + '__external-id'"
          class="mb-1"
        >
          {{ 'property.external_id_l' | translate }} {{ propertyData.externalId
          }}
        </div>

        <!-- Name -->
        <h1
          *ngIf="pageView; else cardTitle"
          class="title-xl d-inline-block mb-0"
          [class]="baseClass + '__title'"
        >
          {{ propertyData.name }}
        </h1>
        <ng-template #cardTitle>
          <h3
            class="title-m d-inline-block mb-0"
            [class]="baseClass + '__title'"
          >
            {{ propertyData?.name }}
          </h3>
        </ng-template>

        <span
          class="d-block mt-1"
          *ngIf="showAddress || pageView"
          [ngClass]="showAddress && pageView ? 'title-m' : 'title-s'"
        >
          <ng-container *ngIf="showAddress; else noAddress"
            >{{ propertyData?.address | appAddress }}</ng-container
          >
          <ng-template #noAddress>
            {{ 'property.no_map_allowed_l' | translate }}
          </ng-template>
        </span>
      </div>
      <div class="mt-auto" *ngIf="!pageView"></div>
      <div
        [class]="baseClass + '__available-from-date'"
        class="mt-3"
        *ngIf="showAvailableFromDate && checkDateAndStringValidity(propertyData?.availableFrom)"
      >
        {{ 'AVAILABLE_FROM_L' | translate }}
        <span class="ms-1"
          >{{(propertyData?.availableFrom?.dateAvailable | appDateTime) ||
          propertyData?.availableFrom?.stringAvailable}}</span
        >
      </div>
      <app-landlord-info
        *ngIf="showLandlordInfo"
        [name]="landlordInfoData?.name"
        [logo]="landlordInfoData?.logo"
        [showInCooperationWith]="pageView"
        [logoRedirectUrl]="pageView ? landlordInfoData?.logoRedirectUrl : null"
        [class.order-3]="pageView"
        [class.mt-3]="!pageView"
      ></app-landlord-info>
      <hr [class.order-2]="pageView" />
      <div
        [class]="baseClass + '__stats'"
        class="d-flex"
        [class.order-1]="pageView"
        [class.mt-4]="pageView"
      >
        <div [class]="baseClass + '__stats-left-group'">
          <!-- Room Size -->
          <div
            [class]="baseClass + '__stats-item'"
            class="d-flex align-items-center me-3"
          >
            <div [class]="'icon icon--area-size'"></div>
            {{ propertyData?.size | number }} m<sup>2</sup>
          </div>

          <!-- Amount Rooms -->
          <div
            *ngIf="propertyData?.rooms"
            [class]="baseClass + '__stats-item'"
            class="d-flex align-items-center me-3"
          >
            <div
              [class]="'icon icon--rooms'"
              [ngbTooltip]="(propertyData?.halfRooms ? 'NUMBER_OF_WHOLE_ROOMS' : '') | translate"
            ></div>
            {{ propertyData?.rooms | number }}
            <span *ngIf="pageView" class="ms-1">
              {{ (!propertyData?.halfRooms ? 'general.rooms_l' :
              propertyData?.rooms === 1 ? 'general.whole_room_l' :
              'general.whole_rooms_l') | translate }}
            </span>
          </div>

          <!-- Amount Half Rooms -->
          <div
            *ngIf="propertyData?.halfRooms"
            [class]="baseClass + '__stats-item'"
            class="d-flex align-items-center"
          >
            <div
              [class]="'icon icon--half-rooms'"
              [ngbTooltip]="'NUMBER_OF_HALF_ROOMS_L' | translate"
            ></div>
            {{ propertyData?.halfRooms | number }}
            <span *ngIf="pageView" class="ms-1">
              {{ (propertyData?.halfRooms === 1 ? 'general.half_room_l' :
              'general.half_rooms_l') | translate }}
            </span>
          </div>
        </div>

        <!-- Price -->
        <div [class]="baseClass + '__stats-item'" class="my-0 ms-auto">
          <ng-container *ngIf="isRentalObject; else salesPriceTemplate">
            {{ propertyData?.totalRentGross | currency:'EUR' }} {{
            'general.monthly_short_l' | translate }}
          </ng-container>
          <ng-template #salesPriceTemplate>
            {{ propertyData?.salesData?.price | currency:'EUR' }}
          </ng-template>
          <ng-container
            *ngIf="propertyData?.commercialData?.vatNotIncludedInPrice"
          >
            {{ 'general.plus_vat_l' | translate }}
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</app-card>
