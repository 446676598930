<select
  class="form-control styled-select"
  [ngClass]="{ 'shared-model-active': shouldAddSharedModelActiveClass() }"
  [disabled]="disabled || readonly"
  [required]="required"
  [(ngModel)]="value"
>
  <option *ngIf="valueNotAItem && showAdditionalItem">
    {{ additionalItem.label | translate }}
  </option>
  <option [ngValue]="null" selected *ngIf="placeholder">
    {{ placeholder }}
  </option>
  <option *ngFor="let o of options" [ngValue]="o.value">
    {{ o.label | translate }}
  </option>
</select>
