import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Elevation, ElevationType } from 'libs/directives';
import { Project, ProjectTotalRange } from '@ui/shared/models';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from 'libs/utils';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss']
})
export class ProjectCardComponent implements OnInit {
  @Input() projectData: Project;
  @Input() pageView = false;
  @Input() limitInformation = false;
  @Input() elevation: ElevationType = Elevation.ZERO;
  @Input() enableContextMenu = true;
  @Output() clickEvent = new EventEmitter();
  @Output() deleteClickEvent = new EventEmitter<number>();
  @Output() editClickEvent = new EventEmitter<number>();
  @Output() copyClickEvent = new EventEmitter<number>();
  @Output() copyProjectRegistrationLinkEvent = new EventEmitter<string>();
  @Input() allowedToDeleteProjects: boolean;
  public baseClass = 'project-card';
  public imagePlaceholder = '/assets/images/object-image-placeholder.svg';
  public titleFontSize: string;

  constructor(
    private sanitizer: DomSanitizer,
    private currencyPipe: CurrencyPipe,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit(): void {
    this.titleFontSize = this.getTitleFontSize();
  }

  public getTitleFontSize(): string {
    const title = this.projectData?.data?.name;
    if (!this.pageView) {
      if (title?.length >= 15) {
        return '25px';
      } else if (title?.length >= 20) {
        return '20px';
      } else if (title?.length >= 25) {
        return '18px';
      }
    }
    return '30px';
  }

  public getClassName(): string {
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      ['page-view']: this.pageView
    });
  }

  public get getBackgroundImage(): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(
      `url(${
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        this.projectData?.data?.titleImage?.url
          ? this.projectData?.data?.titleImage.url
          : this.imagePlaceholder
      })`
    );
  }

  public get hasPictures(): boolean {
    return !!this.projectData?.data?.titleImage?.url;
  }

  public click(event: Event): void {
    event.stopPropagation();
    this.clickEvent.emit();
  }

  public deleteClick(id: number): void {
    this.deleteClickEvent.emit(id);
  }

  public editClick(id: number): void {
    this.editClickEvent.emit(id);
  }

  public copyClick(id: number): void {
    this.copyClickEvent.emit(id);
  }

  public copyProjectRegistrationLinkClick(url: string): void {
    this.copyProjectRegistrationLinkEvent.emit(url);
  }

  public getFromToRange(range: ProjectTotalRange): number | string {
    return range?.from === range?.to
      ? range?.from ?? 0
      : `${range?.from} - ${range?.to}`;
  }

  public formatCurrencyRange(range: ProjectTotalRange): number | string {
    const from = this.currencyPipe.transform(range.from, 'EUR', '');
    const to = this.currencyPipe.transform(range.to, 'EUR', '');
    return from === to ? from ?? 0 : `${from} - ${to}`;
  }

  public formatAreaRange(range: ProjectTotalRange): number | string {
    const from = this.decimalPipe.transform(range.from, '1.0-2');
    const to = this.decimalPipe.transform(range.to, '1.0-2');
    return from === to ? from ?? 0 : `${from} - ${to}`;
  }
}
