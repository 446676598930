<div class="contact-list ios-padding-left ios-padding-right">
  <div class="contact-list__header d-flex">
    <div class="contact-list__title d-flex align-items-center">
      <app-button
        *ngIf="isLandlord"
        (clickEvent)="onOpenSettings()"
        [type]="'context-menu'"
      >
        <div class="icon icon--settings"></div>
      </app-button>
      <h3 class="title-xl">
        {{'messenger.title_l' | translate}}
        <span
          class="messenger__total-message-count"
          *ngIf="totalMessageCount > 0"
          >{{totalMessageCount}}</span
        >
      </h3>
      <div class="contact-list__title-actions ms-auto">
        <app-button (clickEvent)="onReloadMessenger()" [type]="'context-menu'">
          <div
            [class.rotate-center]="contactListActionState.pending || messagesActionState.pending"
            class="icon icon--refresh"
          ></div>
        </app-button>
      </div>
    </div>
    <form [formGroup]="searchForm" (keydown.enter)="$event.preventDefault()">
      <div class="agent-filter mb10" *ngIf="isLandlord && canFilterAgents">
        <app-dropdown-multiselect
          [itemValueKey]="'id'"
          [items]="agentsFilter"
          formControlName="agents"
        >
          <div dropdown-button>{{ 'ALL_AGENTS_L' | translate }}</div>
          <div dropdown-button-multi>
            {{ agentsControl.value?.length}} {{'AGENTS_SELECTED_NUMBER_L' |
            translate }}
          </div>
          <ng-template let-item>
            <div class="agent__item d-flex">
              <app-avatar
                class="agent-item__image pr5"
                size="tiny"
                [imgSrc]="item?.profile?.portrait | appImageSize:'S'"
                name="{{item?.profile | appFullName}}"
              ></app-avatar>
              <div class="agent-item__name">
                {{ item?.profile?.firstname }} {{ item?.profile?.name }}
              </div>
            </div>
          </ng-template>
        </app-dropdown-multiselect>
      </div>
      <div
        class="contact-list__search col-12 p0"
        *ngIf="!searched; else showSearchedValue"
      >
        <img class="search__icon" src="/assets/images/icons/icon-search.svg" />
        <app-form-field>
          <input
            appInput
            type="text"
            placeholder="{{'general.search_l' | translate}}"
            formControlName="search"
            class="form-control"
            ngbTooltip="{{ 'messenger.search.hint_l' | translate }}"
            (keyup.enter)="onSearch()"
          />
        </app-form-field>
      </div>
      <ng-template #showSearchedValue>
        <div
          class="contact-list-search col-12 d-flex justify-content-between searched"
        >
          {{this.searchControl.value}}
          <div
            (click)="revertSearch()"
            class="d-flex align-items-center c-pointer icon icon--close"
          ></div>
        </div>
      </ng-template>
    </form>
  </div>
  <ng-container *ngIf="isLandlord">
    <div class="row m-0 mb-3">
      <div class="col-6 pe-0">
        <app-button
          (clickEvent)="loadActiveConversations()"
          [type]="loadArchivedConversationsToggle ? '' : 'primary'"
          [borderRadius]="'none'"
          [elevation]="2"
          [size]="'small'"
          >{{'messenger.show-active-conversations.button-text_l' | translate}}
        </app-button>
      </div>
      <div class="col-6 ps-0">
        <app-button
          (clickEvent)="loadArchivedConversations()"
          [elevation]="2"
          [size]="'small'"
          [borderRadius]="'none'"
          [type]="loadArchivedConversationsToggle ? 'primary' : ''"
          >{{'messenger.show-archived-conversations.button-text_l' | translate}}
        </app-button>
      </div>
    </div>
    <div class="search_by_property" *ngIf="searchedProperty?.id || propertyId">
      <ng-container
        *ngIf="searchedProperty?.id; else generalSelectedPropertyRef"
      >
        <img
          class="search_by_property__property_img"
          *ngIf="searchedProperty?.titleImage?.url"
          [src]="searchedProperty?.titleImage?.url"
        />
        <span class="text-ellipsis"> {{searchedProperty?.name}} </span>
      </ng-container>
      <img
        class="c-pointer"
        src="/assets/images/icons/icon-close.svg"
        (click)="onRemovePropertyFilter()"
      />

      <ng-template #generalSelectedPropertyRef>
        <span class="text-ellipsis"
          >{{'messenger.selected_property_filter_l' | translate}}</span
        >
      </ng-template>
    </div>
  </ng-container>

  <div
    #contacts
    class="contact-list__contacts"
    [class.contact-list__search_by_property]="propertyId"
    [class.property-searcher__contacts]="isPropertySearcher"
  >
    <ng-scrollbar
      class="contact-list__contacts-content"
      [track]="'vertical'"
      [visibility]="'hover'"
    >
      <app-chat-contact-item
        *ngFor="let conversation of conversationList"
        [conversation]="conversation"
        [isLandlord]="isLandlord"
        [isPropertySearcher]="isPropertySearcher"
        [activeConversation]="activeConversation"
        (selectConversation)="onSelectConversation($event)"
      ></app-chat-contact-item>
      <app-button
        *ngIf="conversationListPage.hasNext && !contactListActionState.pending"
        class="mt10 more__btn"
        (clickEvent)="onLoadMore()"
        [type]="'context-menu'"
      >
        {{'messenger.load_more_l' | translate}}
      </app-button>
    </ng-scrollbar>
  </div>
  <div class="add_conversation" *ngIf="isPropertySearcher">
    <app-button
      [size]="'large'"
      [borderRadius]="'big'"
      [elevation]="10"
      (clickEvent)="onAddConversation()"
      [type]="'primary'"
      [elevationHoverEffect]="false"
      [iconLeft]="'plus'"
    >
    </app-button>
  </div>
</div>
