import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ViewEncapsulation
} from '@angular/core';
import {
  animate,
  AnimationEvent,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Toast } from 'libs/components/legacy/toast';

@Component({
  selector: 'app-ad-block-info',
  templateUrl: './ad-block-info.component.html',
  styleUrls: ['./ad-block-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('inOut', [
      state('fade', style({ opacity: 1 })),
      transition(':enter', [
        style({
          opacity: 0
        }),
        animate('0.3s ease-in')
      ]),
      transition(':leave', [
        animate(
          '0.3s ease-out',
          style({
            opacity: 0
          })
        )
      ])
    ])
  ]
})
export class AdBlockInfoComponent {
  animate = 'fade';
  toasts: Toast[] = [];

  onEmpty: () => void;
  onToastClick: (toast: Toast) => void;

  constructor(private cdr: ChangeDetectorRef) {}

  public addToast(toast: Toast) {
    this.toasts.push(toast);
    this.cdr.detectChanges();
  }

  public removeToast(toast: Toast) {
    this.toasts = this.toasts.filter(t => t.id !== toast.id);

    this.cdr.detectChanges();
  }

  public toastClick(toast: Toast) {
    if (this.onToastClick) {
      this.onToastClick(toast);
    }
  }

  public onAnimationEnd(event: AnimationEvent) {
    if (event.toState === 'void' && !this.toasts.length && this.onEmpty) {
      this.onEmpty();
    }
  }
}
