import { Action } from '@ngrx/store';
import {
  BlockInput,
  BulkSendMessageInput,
  ConversationDetails,
  ConversationInput,
  ConversationMessage,
  ConversationMessageInput,
  Conversations,
  LoadParsedTemplate,
  MessageTemplate,
  MessageTemplates,
  SendMessageInput
} from 'libs/components/legacy/messenger/model/interface';
import {
  AgentInfo,
  CustomerSettings,
  Pagination,
  Property,
  PropertySearcher,
  SelectedTicket
} from '@ui/shared/models';
import { ConversationSenderTypes } from 'libs/components/legacy/messenger/model/enum';

export const SET_SENDER = '[Messenger] Set Sender';

export class SetSender implements Action {
  readonly type = SET_SENDER;

  constructor(public sender: ConversationSenderTypes) {}
}

export const SEND_MESSAGE = '[Messenger] Send Message';
export const SEND_MESSAGE_FAIL = '[Messenger] Send Message Fail';
export const SEND_MESSAGE_SUCCESS = '[Messenger] Send Message Success';

export class SendMessage implements Action {
  readonly type = SEND_MESSAGE;

  constructor(
    public data: SendMessageInput,
    public unarchive?: boolean
  ) {}
}

export class SendMessageFail implements Action {
  readonly type = SEND_MESSAGE_FAIL;

  constructor(public error: any) {}
}

export class SendMessageSuccess implements Action {
  readonly type = SEND_MESSAGE_SUCCESS;

  constructor(public conversationMessage: ConversationMessage) {}
}

export const SEND_MESSAGE_IN_NEW_CONVERSATION =
  '[Messenger] Send Message In New Conversation';
export const SEND_MESSAGE_IN_NEW_CONVERSATION_FAIL =
  '[Messenger] Send Message In New Conversation Fail';
export const SEND_MESSAGE_IN_NEW_CONVERSATION_SUCCESS =
  '[Messenger] Send Message In New Conversation Success';

export class SendMessageInNewConversation implements Action {
  readonly type = SEND_MESSAGE_IN_NEW_CONVERSATION;

  constructor(public data: SendMessageInput) {}
}

export class SendMessageInNewConversationFail implements Action {
  readonly type = SEND_MESSAGE_IN_NEW_CONVERSATION_FAIL;

  constructor(public error: any) {}
}

export class SendMessageInNewConversationSuccess implements Action {
  readonly type = SEND_MESSAGE_IN_NEW_CONVERSATION_SUCCESS;

  constructor(
    public conversation: ConversationMessage,
    public isLandlordApp: boolean
  ) {}
}

export const BULK_SEND_MESSAGE = '[Messenger] Bulk Send Message';
export const BULK_SEND_MESSAGE_FAIL = '[Messenger] Bulk Send Message Fail';
export const BULK_SEND_MESSAGE_SUCCESS =
  '[Messenger] Bulk Send Message Success';

export class BulkSendMessage implements Action {
  readonly type = BULK_SEND_MESSAGE;

  constructor(public data: BulkSendMessageInput) {}
}

export class BulkSendMessageFail implements Action {
  readonly type = BULK_SEND_MESSAGE_FAIL;

  constructor(public error: Error) {}
}

export class BulkSendMessageSuccess implements Action {
  readonly type = BULK_SEND_MESSAGE_SUCCESS;
}

export const LOAD_CONVERSATIONS = '[Messenger] Load Conversations';
export const LOAD_CONVERSATIONS_FAIL = '[Messenger] Load Conversations Fail';
export const LOAD_CONVERSATIONS_SUCCESS =
  '[Messenger] Load Conversations Success';

export class LoadConversations implements Action {
  readonly type = LOAD_CONVERSATIONS;

  constructor(public data?: ConversationInput) {}
}

export class LoadConversationsFail implements Action {
  readonly type = LOAD_CONVERSATIONS_FAIL;

  constructor(public error: any) {}
}

export class LoadConversationsSuccess implements Action {
  readonly type = LOAD_CONVERSATIONS_SUCCESS;

  constructor(
    public conversations: Conversations,
    public activeConversationId?: string,
    public archivedByCustomer?: boolean
  ) {}
}

export const BLOCK_CONVERSATIONS = '[Messenger] Block Conversations';
export const BLOCK_CONVERSATIONS_FAIL = '[Messenger] Block Conversations Fail';
export const BLOCK_CONVERSATIONS_SUCCESS =
  '[Messenger] Block Conversations Success';

export class BlockConversation implements Action {
  readonly type = BLOCK_CONVERSATIONS;

  constructor(public data: BlockInput) {}
}

export class BlockConversationFail implements Action {
  readonly type = BLOCK_CONVERSATIONS_FAIL;

  constructor(public error: any) {}
}

export class BlockConversationSuccess implements Action {
  readonly type = BLOCK_CONVERSATIONS_SUCCESS;

  constructor(public blocked: boolean) {}
}

export const LOAD_CONVERSATION_MESSAGES =
  '[Messenger] Load Conversations Messages';
export const LOAD_CONVERSATION_MESSAGES_FAIL =
  '[Messenger] Load Conversations Messages Fail';
export const LOAD_CONVERSATION_MESSAGES_SUCCESS =
  '[Messenger] Load Conversations Messages Success';

export class LoadConversationsMessages implements Action {
  readonly type = LOAD_CONVERSATION_MESSAGES;

  constructor(public input: ConversationMessageInput) {}
}

export class LoadConversationsMessagesFail implements Action {
  readonly type = LOAD_CONVERSATION_MESSAGES_FAIL;

  constructor(public error: Error) {}
}

export class LoadConversationsMessagesSuccess implements Action {
  readonly type = LOAD_CONVERSATION_MESSAGES_SUCCESS;

  constructor(
    public conversationsMessages: ConversationMessage[],
    public page: Pagination
  ) {}
}

export const LOAD_CONVERSATION_MESSAGES_AND_DETAILS =
  '[Messenger] Load Conversation Messages And Details';
export const LOAD_CONVERSATION_MESSAGES_AND_DETAILS_FAIL =
  '[Messenger] Load Conversation Messages And Details Fail';
export const LOAD_CONVERSATION_MESSAGES_AND_DETAILS_SUCCESS =
  '[Messenger] Load Conversation Messages And Details Success';

export class LoadConversationMessagesAndDetails implements Action {
  readonly type = LOAD_CONVERSATION_MESSAGES_AND_DETAILS;

  constructor(public input: ConversationMessageInput) {}
}

export class LoadConversationMessagesAndDetailsFail implements Action {
  readonly type = LOAD_CONVERSATION_MESSAGES_AND_DETAILS_FAIL;

  constructor(public error: Error) {}
}

export class LoadConversationMessagesAndDetailsSuccess implements Action {
  readonly type = LOAD_CONVERSATION_MESSAGES_AND_DETAILS_SUCCESS;

  constructor(
    public conversationMessagesAndDetails: {
      nodes: ConversationMessage[];
      page: Pagination;
      details: ConversationDetails;
    }
  ) {}
}

export const LOAD_PARTICIPATED_AGENTS = '[Messenger] Load Participated Agents';
export const LOAD_PARTICIPATED_AGENTS_SUCCESS =
  '[Messenger] Load Participated Agents Success';
export const LOAD_PARTICIPATED_AGENTS_FAIL =
  '[Messenger] Load Participated Agents Fail';

export class LoadParticipatedAgents implements Action {
  readonly type = LOAD_PARTICIPATED_AGENTS;

  constructor(public conversationId: string) {}
}

export class LoadParticipatedAgentsSuccess implements Action {
  readonly type = LOAD_PARTICIPATED_AGENTS_SUCCESS;

  constructor(public participatedAgents: AgentInfo[]) {}
}

export class LoadParticipatedAgentsFail implements Action {
  readonly type = LOAD_PARTICIPATED_AGENTS_FAIL;

  constructor(public error: any) {}
}

export const CHECK_FOR_CONVERSATION = '[Messenger] Check for Conversation';

export class CheckForConversation implements Action {
  readonly type = CHECK_FOR_CONVERSATION;

  constructor(
    public data: {
      propertySearcher?: PropertySearcher;
      property?: Property;
      ticketDetails?: SelectedTicket;
    },
    public agentIds?: string[]
  ) {}
}

export const CREATE_CONVERSATION = '[Messenger] Create Conversation';

export class CreateConversation implements Action {
  readonly type = CREATE_CONVERSATION;

  constructor(
    public data: {
      property?: Property;
      propertySearcher?: PropertySearcher;
      applicationId?: string;
      ticketDetails?: SelectedTicket;
    }
  ) {}
}

export const REMOVE_CONVERSATION = '[Messenger] Remove Conversation';

export class RemoveConversation implements Action {
  readonly type = REMOVE_CONVERSATION;

  constructor(public conversationId: string) {}
}

export const SELECT_CONVERSATION = '[Messenger] Select Conversation';

export class SelectConversation implements Action {
  readonly type = SELECT_CONVERSATION;

  constructor(public conversationId: string) {}
}

export const DESELECT_CONVERSATION = '[Messenger] Deselect Conversation';

export class DeselectConversation implements Action {
  readonly type = DESELECT_CONVERSATION;
}

export const INCREASE_CHAT_PAGE = '[Messenger] Increase Chat Page';

export class IncreaseChatPage implements Action {
  readonly type = INCREASE_CHAT_PAGE;

  constructor(public customerSettings: CustomerSettings) {}
}

export const INCREASE_CONTACT_LIST_PAGE =
  '[Messenger] Increase Contact List Page';

export class IncreaseContactListPage implements Action {
  readonly type = INCREASE_CONTACT_LIST_PAGE;
}

export const STOP_COUNT_UNREAD_POLLING =
  '[Messenger] Stop Polling Conversations';

export class StopCountUnreadPolling implements Action {
  readonly type = STOP_COUNT_UNREAD_POLLING;
}

export const START_FIND_UNREAD_MESSAGES_POLLING =
  '[Messenger] Start Find Unread Messages';

export class StartFindUnreadMessagesPolling implements Action {
  readonly type = START_FIND_UNREAD_MESSAGES_POLLING;

  constructor(public pollingInterval: number) {}
}

export const STOP_FIND_UNREAD_MESSAGES_POLLING =
  '[Messenger] Stop Find Unread Messages';

export class StopFindUnreadMessagesPolling implements Action {
  readonly type = STOP_FIND_UNREAD_MESSAGES_POLLING;
}

export const FIND_UNREAD_MESSAGES = '[Messenger] Find Unread Messages';

export class FindUnreadMessages implements Action {
  readonly type = FIND_UNREAD_MESSAGES;

  constructor(
    public input: ConversationMessageInput,
    public pollInterval: number
  ) {}
}

export const FIND_UNREAD_MESSAGES_SUCCESS =
  '[Messenger] Find Unread Messages Success';

export class FindUnreadMessagesSuccess implements Action {
  readonly type = FIND_UNREAD_MESSAGES_SUCCESS;

  constructor(public conversationMessages: ConversationMessage[]) {}
}

export const COUNT_UNREAD = '[Messenger] Count Unread Messages';

export class CountUnread implements Action {
  readonly type = COUNT_UNREAD;

  constructor(public pollInterval: number) {}
}

export const COUNT_UNREAD_SUCCESS = '[Messenger] Count Unread Messages Success';

export class CountUnreadSuccess implements Action {
  readonly type = COUNT_UNREAD_SUCCESS;

  constructor(public count: number) {}
}

export const REFETCH_COUNT_UNREAD = '[Messenger] Refetch Count Unread Messages';

export class RefetchCountUnread implements Action {
  readonly type = REFETCH_COUNT_UNREAD;
}

export const REFETCH_COUNT_UNREAD_SUCCESS =
  '[Messenger] Refetch Count Unread Messages Success';

export class RefetchCountUnreadSuccess implements Action {
  readonly type = REFETCH_COUNT_UNREAD_SUCCESS;

  constructor(public count: number) {}
}

export const LOAD_TEMPLATE = '[Messenger] Load Template';
export const LOAD_TEMPLATE_FAIL = '[Messenger] Load Template Fail';
export const LOAD_TEMPLATE_SUCCESS = '[Messenger] LoadTemplate Success';

export class LoadTemplate implements Action {
  readonly type = LOAD_TEMPLATE;

  constructor(public page: Pagination) {}
}

export class LoadTemplateFail implements Action {
  readonly type = LOAD_TEMPLATE_FAIL;

  constructor(public error: any) {}
}

export class LoadTemplateSuccess implements Action {
  readonly type = LOAD_TEMPLATE_SUCCESS;

  constructor(public templates: MessageTemplates) {}
}

export const LOAD_PARSED_TEMPLATES = '[Messenger] Load Parsed Templates';
export const LOAD_PARSED_TEMPLATES_FAIL =
  '[Messenger] Load Parsed Templates Fail';
export const LOAD_PARSED_TEMPLATES_SUCCESS =
  '[Messenger] Load Parsed Templates Success';

export class LoadParsedTemplates implements Action {
  readonly type = LOAD_PARSED_TEMPLATES;

  constructor(public input: LoadParsedTemplate) {}
}

export class LoadParsedTemplatesFail implements Action {
  readonly type = LOAD_PARSED_TEMPLATES_FAIL;

  constructor(public error: any) {}
}

export class LoadParsedTemplatesSuccess implements Action {
  readonly type = LOAD_PARSED_TEMPLATES_SUCCESS;

  constructor(public templates: MessageTemplates) {}
}

export const CREATE_TEMPLATE = '[Messenger] Create Template';
export const CREATE_TEMPLATE_FAIL = '[Messenger] Create Template Fail';
export const CREATE_TEMPLATE_SUCCESS = '[Messenger] Create Template Success';

export class CreateTemplate implements Action {
  readonly type = CREATE_TEMPLATE;

  constructor(public template: MessageTemplate) {}
}

export class CreateTemplateFail implements Action {
  readonly type = CREATE_TEMPLATE_FAIL;

  constructor(public error: any) {}
}

export class CreateTemplateSuccess implements Action {
  readonly type = CREATE_TEMPLATE_SUCCESS;

  constructor(public template: MessageTemplate) {}
}

export const UPDATE_TEMPLATE = '[Messenger] Update Template';
export const UPDATE_TEMPLATE_FAIL = '[Messenger] Update Template Fail';
export const UPDATE_TEMPLATE_SUCCESS = '[Messenger] Update Template Success';

export class UpdateTemplate implements Action {
  readonly type = UPDATE_TEMPLATE;

  constructor(public template: MessageTemplate) {}
}

export class UpdateTemplateFail implements Action {
  readonly type = UPDATE_TEMPLATE_FAIL;

  constructor(public error: any) {}
}

export class UpdateTemplateSuccess implements Action {
  readonly type = UPDATE_TEMPLATE_SUCCESS;

  constructor(public template: MessageTemplate) {}
}

export const DELETE_TEMPLATE = '[Messenger] Delete Template';
export const DELETE_TEMPLATE_FAIL = '[Messenger] Delete Template Fail';
export const DELETE_TEMPLATE_SUCCESS = '[Messenger] Delete Template Success';

export class DeleteTemplate implements Action {
  readonly type = DELETE_TEMPLATE;

  constructor(public templateId: string) {}
}

export class DeleteTemplateFail implements Action {
  readonly type = DELETE_TEMPLATE_FAIL;

  constructor(public error: any) {}
}

export class DeleteTemplateSuccess implements Action {
  readonly type = DELETE_TEMPLATE_SUCCESS;

  constructor(public templateId: string) {}
}

export const ARCHIVE_CONVERSATION = '[Property Searchers] Archive Coversation';
export const ARCHIVE_CONVERSATION_FAIL =
  '[Property Searchers] Archive Coversation Fail';
export const ARCHIVE_CONVERSATION_SUCCESS =
  '[Property Searchers] Archive Coversation Success';

export const UN_ARCHIVE_CONVERSATION =
  '[Property Searchers] Unarchive Coversation';
export const UN_ARCHIVE_CONVERSATION_FAIL =
  '[Property Searchers] Unarchive Coversation Fail';
export const UN_ARCHIVE_CONVERSATION_SUCCESS =
  '[Property Searchers] Unarchive Coversation Success';

export class ArchiveConversation implements Action {
  readonly type = ARCHIVE_CONVERSATION;

  constructor(public conversationId: string) {}
}

export class ArchiveConversationFail implements Action {
  readonly type = ARCHIVE_CONVERSATION_FAIL;

  constructor(public error: any) {}
}

export class ArchiveConversationSuccess implements Action {
  readonly type = ARCHIVE_CONVERSATION_SUCCESS;

  constructor(public conversationId: string) {}
}

export class UnarchiveConversation implements Action {
  readonly type = UN_ARCHIVE_CONVERSATION;

  constructor(public conversationId: string) {}
}

export class UnarchiveConversationFail implements Action {
  readonly type = UN_ARCHIVE_CONVERSATION_FAIL;

  constructor(public error: any) {}
}

export class UnarchiveConversationSuccess implements Action {
  readonly type = UN_ARCHIVE_CONVERSATION_SUCCESS;

  constructor(public conversationId: string) {}
}

export const MARK_CONVERSATION_AS_UNREAD =
  '[Messenger] Mark Conversation As Unread';
export const MARK_CONVERSATION_AS_UNREAD_SUCCESS =
  '[Messenger] Mark Conversation As Unread Success';

export class MarkConversationAsUnread implements Action {
  readonly type = MARK_CONVERSATION_AS_UNREAD;

  constructor(public id: string) {}
}

export class MarkConversationAsUnreadSuccess implements Action {
  readonly type = MARK_CONVERSATION_AS_UNREAD_SUCCESS;

  constructor(public id: string) {}
}

export const SEARCHED_PROPERTY = '[Messenger] Searched Property';

export class SearchedProperty implements Action {
  readonly type = SEARCHED_PROPERTY;

  constructor(public property: Property) {}
}

export type MessengerActions =
  | SetSender
  | SendMessage
  | SendMessageFail
  | SendMessageSuccess
  | SendMessageInNewConversation
  | SendMessageInNewConversationFail
  | SendMessageInNewConversationSuccess
  | BulkSendMessage
  | BulkSendMessageFail
  | BulkSendMessageSuccess
  | LoadConversations
  | LoadConversationsFail
  | LoadConversationsSuccess
  | BlockConversation
  | BlockConversationFail
  | BlockConversationSuccess
  | LoadConversationsMessages
  | LoadConversationsMessagesFail
  | LoadConversationsMessagesSuccess
  | LoadConversationMessagesAndDetails
  | LoadConversationMessagesAndDetailsFail
  | LoadConversationMessagesAndDetailsSuccess
  | LoadParticipatedAgents
  | LoadParticipatedAgentsSuccess
  | LoadParticipatedAgentsFail
  | CheckForConversation
  | CreateConversation
  | SelectConversation
  | DeselectConversation
  | RemoveConversation
  | IncreaseChatPage
  | IncreaseContactListPage
  | StopCountUnreadPolling
  | StartFindUnreadMessagesPolling
  | StopFindUnreadMessagesPolling
  | FindUnreadMessages
  | FindUnreadMessagesSuccess
  | CountUnread
  | CountUnreadSuccess
  | RefetchCountUnread
  | RefetchCountUnreadSuccess
  | LoadTemplate
  | LoadTemplateFail
  | LoadTemplateSuccess
  | LoadParsedTemplates
  | LoadParsedTemplatesFail
  | LoadParsedTemplatesSuccess
  | CreateTemplate
  | CreateTemplateFail
  | CreateTemplateSuccess
  | UpdateTemplate
  | UpdateTemplateFail
  | UpdateTemplateSuccess
  | DeleteTemplate
  | DeleteTemplateFail
  | DeleteTemplateSuccess
  | ArchiveConversation
  | ArchiveConversationFail
  | ArchiveConversationSuccess
  | UnarchiveConversation
  | UnarchiveConversationFail
  | UnarchiveConversationSuccess
  | MarkConversationAsUnread
  | MarkConversationAsUnreadSuccess
  | SearchedProperty;
