import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

const CIRCLE_MIN_VAL = 0.0001;
const CIRCLE_MAX_VAL = 0.9999;

interface Point {
  x: number;
  y: number;
}
interface Radius {
  inner: number;
  outer: number;
}

const svg = {
  build: (
    percentage: number,
    center: Point,
    outerRadius: number,
    innerRadius: number
  ) =>
    svg.buildPath(
      center,
      percentage > 0.5,
      svg.toRad(0),
      svg.toRad(percentage),
      outerRadius,
      innerRadius
    ),
  buildArcPath: (
    radius: number,
    sweep: boolean,
    reverse: boolean,
    coords: Point
  ) =>
    [
      'A',
      radius,
      radius,
      0,
      svg.to10(sweep),
      svg.to10(reverse),
      coords.x,
      coords.y
    ].join(' '),
  buildLine: (coords: Point) => ['L', coords.x, coords.y].join(' '),
  buildMove: (coords: Point) => ['M', coords.x, coords.y].join(' '),
  buildPath: (
    center: Point,
    sweep: boolean,
    fromRad: number,
    toRad: number,
    outerRadius: number,
    innerRadius: number
  ) =>
    [
      svg.buildMove(svg.getArcCoords(center, outerRadius, fromRad)),
      svg.buildArcPath(
        outerRadius,
        sweep,
        true,
        svg.getArcCoords(center, outerRadius, toRad)
      ),
      svg.buildLine(svg.getArcCoords(center, innerRadius, toRad)),
      svg.buildArcPath(
        innerRadius,
        sweep,
        false,
        svg.getArcCoords(center, innerRadius, fromRad)
      ),
      'z'
    ].join(' '),
  // calculate the path parameters
  buildRest: (
    percentage: number,
    center: Point,
    outerRadius: number,
    innerRadius: number
  ) => {
    const maxPercentage = Math.max(CIRCLE_MIN_VAL, percentage);
    return svg.buildPath(
      center,
      maxPercentage < 0.5,
      svg.toRad(maxPercentage),
      svg.toRad(1),
      outerRadius,
      innerRadius
    );
  },
  getArcCoords: (center: Point, radius: number, rad: number): Point => {
    const coords = svg.toCoords(rad);
    return {
      x: coords.x * radius + center.x,
      y: coords.y * radius + center.y
    };
  },
  to10: (bool: boolean) => (bool ? 1 : 0),
  toCoords: (rad: number): Point => ({
    x: Math.cos(rad),
    y: -Math.sin(rad)
  }),
  toRad: (deg: number) => -deg * 2 * Math.PI + 0.5 * Math.PI
};

const SCORE_OBSCURED_CONFIG = {
  A: 'green',
  B: 'lightgreen',
  C: 'yellow',
  D: 'orange',
  E: 'red'
};

@Component({
  selector: 'app-score-circle',
  templateUrl: './score-circle.component.html',
  styleUrls: ['./score-circle.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScoreCircleComponent implements OnChanges {
  @Input() max = 10;
  @Input() min = 0;
  @Input() thickness = 15;
  @Input() value = 0;
  @Input() showPercentage = false;
  @Input() outerRadius = 50;
  @Input() isAnonymous: boolean;
  @Input() scoreObscured: string;

  public hasRestPath = false;
  public radius: Radius;
  public path = '';
  public restPath = '';
  public viewBox: string;
  public circleClass: string;

  public displayedValue = 0;

  private center: Point;

  ngOnChanges(changes: SimpleChanges) {
    const value = this.scoreObscured ? { currentValue: 10 } : changes.value;
    this.value = this.showPercentage
      ? parseInt(value.currentValue || 0, 10)
      : // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        (value.currentValue || 0).toFixed(2);
    this.displayedValue =
      this.value / (this.max / 10) >= 10
        ? 10
        : this.value / (this.max / 10) <= 0
          ? 0
          : this.value / (this.max / 10);
    this.onChanges();
  }

  private onChanges() {
    const min = this.min || 0;
    const max = this.max || 100;
    const maxMin = max - min;
    const thickness = Math.max(0, Math.min(100, this.thickness || 25)) / 100;

    this.viewBox = `0 0 ${this.outerRadius * 2} ${this.outerRadius * 2}`;
    this.radius = {
      inner: this.outerRadius * (1 - thickness),
      outer: this.outerRadius
    };

    this.center = {
      x: this.outerRadius,
      y: this.outerRadius
    };

    if (this.value === undefined) {
      this.path = '';
      return;
    }

    const percentage = Math.max(
      0,
      Math.min(CIRCLE_MAX_VAL, (this.value - min) / maxMin)
    );

    this.path = svg.build(
      percentage,
      this.center,
      this.radius.outer,
      this.radius.inner
    );
    this.hasRestPath = CIRCLE_MAX_VAL > percentage;
    this.restPath = svg.buildRest(
      percentage,
      this.center,
      this.radius.outer,
      this.radius.inner
    );

    this.circleClass = this.assignCircleClass();
  }

  private assignCircleClass() {
    if (this.scoreObscured) {
      return SCORE_OBSCURED_CONFIG[this.scoreObscured] as string;
    } else {
      switch (true) {
        case this.displayedValue <= 2:
          return 'red';
        case this.displayedValue <= 4:
          return 'orange';
        case this.displayedValue <= 6:
          return 'yellow';
        case this.displayedValue <= 8:
          return 'lightgreen';
        case this.displayedValue <= 10:
          return 'green';
      }
    }
  }
}
