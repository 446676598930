<div
  class="navigation"
  [class.navigation--open]="navigation.open$ | async"
  [class.navigation--left]="(navigation.alignment$ | async) !== 'right'"
  [class.navigation--right]="(navigation.alignment$ | async) === 'right'"
  [class.navigation--overlay]="navigation.overlay$ | async"
  [class.navigation--backdrop-visible]="backdropVisible$ | async"
>
  <ng-container *ngIf="(navigation.alignment$ | async) === 'right'">
    <ng-container *ngTemplateOutlet="subMenu"></ng-container>
  </ng-container>

  <app-navigation-drawer
    class="navigation__main"
    [narrow]="navigation.narrow$ | async"
    [items]="navigation.navigationItems$ | async"
  >
    <ng-content select="[navigationHeader]" ngProjectAs="header"></ng-content>
    <ng-content select="[navigationFooter]" ngProjectAs="footer"></ng-content>
  </app-navigation-drawer>

  <ng-container *ngIf="(navigation.alignment$ | async) !== 'right'">
    <ng-container *ngTemplateOutlet="subMenu"></ng-container>
  </ng-container>

  <ng-template #subMenu>
    <app-navigation-drawer
      *ngrxLet="navigation.floatingSubMenuParentItem$ as floatingSubMenuParentItem"
      class="navigation__sub"
      [items]="floatingSubMenuParentItem?.children"
      [isFloatingSubMenu]="true"
    ></app-navigation-drawer>
  </ng-template>

  <div
    class="navigation__backdrop"
    [class.navigation__backdrop--visible]="(backdropVisible$ | async)"
    (click)="onBackdropClick()"
    [@fade]="(backdropVisible$ | async) ? 'show' : 'hide'"
    (@fade.done)="fadeDone($event)"
  ></div>
</div>
