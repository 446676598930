import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTableCell]'
})
export class DataTableCellDirective {
  @Input('appTableCell') name: string;

  constructor(public template: TemplateRef<HTMLDivElement>) {}
}
