import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Tooltip, TooltipProgress, TooltipTour } from '@ui/shared/models';
import { filter } from 'rxjs/operators';
import { TooltipPosition } from './tooltip.enum';

@UntilDestroy()
@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
  @Input() tooltipAnchor: string;
  @Input() anchorElementRef: ElementRef;
  @Input() outHorizontalBounds: boolean;
  @Input() outVerticalBounds: boolean;
  @Input() tooltipPosition: TooltipPosition;

  @Input() set tooltipProgress(val: TooltipProgress) {
    this._tooltipProgress = val;
    this.cdf.detectChanges();
  }
  get tooltipProgress() {
    return this._tooltipProgress;
  }

  // return last tooltip
  @Output() continueEvent = new EventEmitter<Tooltip>();
  @Output() closeEvent = new EventEmitter<TooltipTour>();

  private _tooltipProgress: TooltipProgress;
  private currentRoute = '';

  get tooltipTour(): TooltipTour {
    return this.tooltipProgress?.currentTour;
  }

  get tooltip(): Tooltip {
    return this.tooltipProgress?.currentTourElement?.tooltip;
  }

  get tooltipTourLength(): number {
    return this.tooltipTour?.tourElements?.length;
  }

  get tooltipTourCurrentPosition(): number {
    return this.tooltipProgress.currentTourElement.step;
  }

  get tooltipIsLastInTour(): boolean {
    return (
      this.tooltipTour?.tourElements?.length - 1 ===
      this.tooltipTour?.tourElements.findIndex(
        tourElement =>
          tourElement.id === this.tooltipProgress.currentTourElement.id
      )
    );
  }

  get isVisible(): boolean {
    return (
      this.tooltip?.anchorPoint?.anchorPoint === this.tooltipAnchor &&
      this.tooltip?.anchorPoint?.path === this.currentRoute
    );
  }

  get showInfoButton(): boolean {
    return false;
  }

  get hostPaddingExists(): boolean {
    const padding = this.anchorPadding;
    return parseInt(padding) !== 0;
  }

  get hostPadding(): string {
    const padding = this.anchorPadding;
    return padding;
  }

  constructor(
    private router: Router,
    private cdf: ChangeDetectorRef
  ) {
    this.currentRoute = this.router.url;

    this.router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.urlAfterRedirects;
      });
  }

  public onClose(): void {
    this.closeEvent.emit(this.tooltipTour);
  }

  public onContinue(): void {
    this.continueEvent.emit(this.tooltip);
  }

  private get anchorPadding(): string {
    let padding = '0';
    if (this.tooltipPosition === TooltipPosition.BELOW) {
      padding = getComputedStyle(
        this.anchorElementRef.nativeElement
      ).paddingBottom;
    }
    return padding;
  }
}
