import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Apollo } from 'apollo-angular';

import { CustomCookieSettingsModalComponent } from 'libs/components/legacy/cookie-banner';
import { ModalService } from 'libs/components/legacy/modal';
import {
  systemDowntimeQuery,
  versionQuery
} from 'libs/infrastructure/base-state/gql-queries';
import { WINDOW_REF } from 'libs/infrastructure/browser/window-ref.token';
import { InfrastructureConfig } from 'libs/infrastructure/infrastructure-config.model';
import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import {
  CookieService,
  LocalStorageService
} from 'libs/infrastructure/storage';
import { CookiePreference, User } from '@ui/shared/models';
import { map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { UserConversionService } from '../../../services/user-conversion.service';
import { FreshchatWidgetService } from '../../browser/freshworks';

import { GoogleAnalyticsService } from '../../data-privacy/google-analytics.service';
import * as fromReducers from '../reducers';
import * as fromActions from './app.actions';
import * as fromSelectors from './app.selectors';

@Injectable()
export class AppEffects {
  constructor(
    private apollo: Apollo,
    private actions$: Actions,
    private storage: LocalStorageService,
    private store: Store<fromReducers.BaseState>,
    private modalService: ModalService,
    private cookieService: CookieService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private freshchatWidgetService: FreshchatWidgetService,
    private userConversionService: UserConversionService,
    @Inject(INFRASTRUCTURE_CONFIG)
    private infrastructureConfig: InfrastructureConfig,
    @Inject(WINDOW_REF) private windowRef: Window
  ) {}

  getSystemDowntime = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.GetSystemDowntime>(fromActions.GET_SYSTEM_DOWNTIME),
        switchMap(() =>
          this.apollo
            .query<{ systemDowntime: { isSystemDown: boolean } }>({
              query: systemDowntimeQuery,
              fetchPolicy: 'no-cache'
            })
            .pipe(
              map(response => {
                if (response.data.systemDowntime.isSystemDown)
                  this.windowRef.open(
                    'https://www.mieter.immomio.com/wartungsarbeiten',
                    '_self'
                  );
              })
            )
        )
      ),
    { dispatch: false }
  );

  initCookiesPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.InitCookiesPreference>(
        fromActions.INIT_COOKIES_PREFERENCE
      ),
      map(({ user }) => ({
        cookiePreference: this.storage.getItem<CookiePreference>(
          this.infrastructureConfig.storageKeys.cookiePreference
        ),
        user
      })),
      map(
        ({ cookiePreference, user }) =>
          new fromActions.SetCookiesPreference(cookiePreference, user)
      )
    )
  );

  setCookiesPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.SetCookiesPreference>(
        fromActions.SET_COOKIES_PREFERENCE
      ),
      mergeMap(({ cookiePreference, user }) => [
        new fromActions.UpdateCookieTracking(cookiePreference, user),
        new fromActions.SetCookiesPreferenceSuccess(cookiePreference)
      ])
    )
  );

  openCustomCookieSettingsModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.OpenCookieSettingsModal>(
          fromActions.OPEN_COOKIE_SETTINGS_MODAL
        ),
        tap(({ cookiePreference, isTenant, user }) => {
          this.modalService
            .open<CustomCookieSettingsModalComponent>(
              CustomCookieSettingsModalComponent,
              {
                backdrop: 'static',
                keyboard: false,
                data: { cookiePreference, isTenant }
              }
            )
            .onClose()
            .subscribe((payload: CookiePreference) =>
              this.store.dispatch(
                new fromActions.SetCookiesPreference(payload, user)
              )
            );
        })
      ),
    { dispatch: false }
  );

  getPerformanceWarningConfirmation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.GetPerformanceWarningConfirmation>(
        fromActions.GET_PERFORMANCE_WARNING_CONFIRMATION
      ),
      map(() => {
        const preference =
          this.storage.getItem<boolean>(
            this.infrastructureConfig.storageKeys
              .hasAcceptedPerformanceWarningConfirmation
          ) || false;

        return new fromActions.SetPerformanceWarningConfirmation(preference);
      })
    )
  );

  setPerformanceWarningConfirmation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.SetPerformanceWarningConfirmation>(
          fromActions.SET_PERFORMANCE_WARNING_CONFIRMATION
        ),
        map(action =>
          this.storage.setItem(
            this.infrastructureConfig.storageKeys
              .hasAcceptedPerformanceWarningConfirmation,
            action.confirmation
          )
        )
      ),
    { dispatch: false }
  );

  changeLocale$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.ChangeLocale>(fromActions.CHANGE_LOCALE),
        withLatestFrom(this.store.select(fromSelectors.getCurrentLocale)),
        map(([{ locale }, currentLocale]) => {
          const href = this.windowRef.location.href.replace(
            `/${currentLocale}/`,
            `/${locale}/`
          );
          this.store.dispatch(new fromActions.SetCurrentLocale(locale));

          this.windowRef.location.href = href;
        })
      ),
    { dispatch: false }
  );

  trackGoogleEvent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.TrackGoogleEvent>(fromActions.TRACK_GOOGLE_EVENT),
        tap(({ trackingId, category, sendPageView }) =>
          this.googleAnalyticsService.track(trackingId, category, sendPageView)
        )
      ),
    { dispatch: false }
  );

  updateApplicationSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.UpdateApplicationSuccess>(
          fromActions.UPDATE_APPLICATION_SUCCESS
        ),
        tap(() => this.windowRef.location.reload())
      ),
    { dispatch: false }
  );

  updateCookieTracking$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromActions.UpdateCookieTracking>(
          fromActions.UPDATE_COOKIE_TRACKING
        ),
        tap(({ cookiePreference, user }) =>
          this.updateTracking(cookiePreference, user)
        )
      ),
    { dispatch: false }
  );

  getVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromActions.GetVersion>(fromActions.GET_VERSION),
      switchMap(() =>
        this.apollo
          .query<{ version: { version: string; prevVersion: string } }>({
            query: versionQuery,
            fetchPolicy: 'no-cache'
          })
          .pipe(
            map(
              response =>
                new fromActions.GetVersionSuccess(response.data.version)
            )
          )
      )
    )
  );

  private updateTracking(payload: CookiePreference, user: User) {
    this.cookieService.savePreference(payload);
    if (payload?.allowPerformance && this.isTenantApp) {
      this.googleAnalyticsService.setGtagPixelManager();
      this.googleAnalyticsService.setGTagManager();
    }
    if (payload?.allowFunctional && !this.isTenantApp) {
      this.freshchatWidgetService.init(user);
    }
  }

  private get isTenantApp() {
    return this.infrastructureConfig.environment.app_name === 'tenant';
  }
}
