import { gql } from 'apollo-angular';
import { multiLanguageStringQuery } from 'libs/queries/multi-language-string.query';
import { courtageDataFields } from 'libs/queries/property-data.queries';
import { customerDefinedValues } from 'libs/queries/customer-defined-fields.queries';
import { DistrictForAddress } from '@ui/shared/models';

export const addressContentGQL = `
  street
  houseNumber
  zipCode
  city
  district
  region
  country
  coordinates {
    lon
    lat
  }
`;

export const addressGQL = `
  address {
    ${addressContentGQL}
  }
`;

export const commentsGQL = `
  comments {
    id
    author
    comment
    created
    updated
    property {
      externalId
      id
      data {
        name
      }
      titleImage {
        type
        url
        identifier
        name
        extension
      }
    }
    agentInfo {
      id
      firstName
      name
    }
  }
`;

export const commercialDataGQL = `
  commercialData {
    commercialType
    commercialSubTypes
  }
`;

export const searchProfilesGQL = `
  id
  ${addressGQL}
  lowerBoundRooms
  lowerBoundSize
  upperBoundRent
  upperBoundBaseRent
  type
  marketingType
  districts {
    id
    name
    cityName
    cityId
  }
  radius
  flatData {
    objectTypes
    flatTypes
    houseTypes
  }
  name
  garageTypes
  propertyType
  ${commercialDataGQL}
  durationEndDate
  balconyTerrace
  barrierFree
  seniorApartment
  createdByPS
  managedByLandlord
  salesData {
    priceUpperBound
  }
  deactivated
  projectId
  projectLandingPageUrl
  searchingSince
  elevator
  ignoreFloorIfElevatorExists
  lowerBoundFloor
  upperBoundFloor
`;

export const systemDowntimeQuery = gql`
  query systemDowntime {
    systemDowntime {
      isSystemDown
    }
  }
`;

export const versionQuery = gql`
  query version {
    version {
      version
      prevVersion
    }
  }
`;

const landlordDistrict = `
  id
  name
  districts {
    id
    name
    cityName
    cityId
  }
`;

export const landlordDistrictGroup = `
  ${landlordDistrict}
  children {
    ${landlordDistrict}
  }
  country
`;

export const landlordObjectTypes = `
  propertyTypes
  garageTypes
  commercialType
  commercialSubTypes
  houseTypes
  flatTypes
  objectTypes
  countries
`;

export const attachmentFields = `
  url
  title
  type
  identifier
  extension
  encrypted
  index
  visibleOnPortals
  documentFileType
  documentType
  name
`;

export const attachments = `
  attachments {
    ${attachmentFields}
  }
`;

export const expose = `
  expose {
    ${attachmentFields}
  }
`;

export const documents = `
  documents {
    ${attachmentFields}
  }
`;

export const garageDataPsSide = `
  garageData {
    garageType
    availableUntil
    length
    width
    height
    freeUntil
  }
`;

export const commercialDataPsSide = `
  commercialData {
    commercialType
    commercialSubType
    flooringType
    hasCanteen
    lanCables
    highVoltage
    airConditioning
    distanceToTrainStation
    distanceToHighway
    distanceToPublicTransport
    distanceToAirport
    pricePerSquareMeter
    vatNotIncludedInPrice
  }
`;

export const salesDataPsSide = `
  salesData {
    price
    serviceCharge
  }
`;

export const propertyDataPsSide = `
  referenceId
  name
  address {
    city
    country
    region
    street
    houseNumber
    zipCode
  }
  showAddress
  energyCertificate {
    energyCertificateType
    creationDate
    primaryEnergyProvider
    primaryEnergyConsumption
    usageCertificate {
      energyConsumption
      energyEfficiencyClass
    }
    demandCertificate {
      endEnergyConsumption
      energyEfficiencyClass
    }
    austrianEnergyCertificate {
      dateOfExpiry
      heatingEnergyDemand
      heatingEnergyDemandClass
      overallEnergyEfficiencyFactor
      overallEnergyEfficiencyFactorClass
    }
  }
  basePrice
  availableFrom {
    dateAvailable
    stringAvailable
  }
  heatingCostIncluded
  flatShare
  size
  documents {
    url
    title
    type
    identifier
    extension
    encrypted
  }
  parkingPriceIncludedToAdditionalCosts
  totalRentGross
  otherCosts
  parkingPrice
  previousTenantAppointmentEnabled
  shortTermAccommodation
  buildingCondition
  attachments {
    url
    title
    type
    identifier
    extension
    encrypted
  }
  constructionYear
  heater
  objectType
  numberOfFloors
  bathRooms
  guestToilette
  kitchenette
  landArea
  storeRoom
  washDryRoom
  garden
  gardenUse
  attic
  ground
  bicycleRoom
  seniors
  barrierFree
  fireplace
  parkingSpaces {
    type
    price
    count
    purchasePrice
  }
  totalParkingPrice
  totalParkingPurchasePrice
  historicBuilding
  rooms
  halfRooms
  basePrice
  elevator
  tvSatCable
  flatType
  floor
  heatingCost
  heatingCostIncluded
  serviceCharge
  bailment
  showContact
  objectDescription {
    ${multiLanguageStringQuery}
  }
  objectLocationText {
    ${multiLanguageStringQuery}
  }
  objectMiscellaneousText {
    ${multiLanguageStringQuery}
  }
  furnishingDescription {
    ${multiLanguageStringQuery}
  }
  customerName
  customerLogo
  numberOfBalconies
  numberOfTerraces
  numberOfLoggias
  balconyTerraceArea
  numberOfBedrooms
  washingMachineConnection
  shutter
  intercomType
  furnishingType
  basementAvailable
  basementSize
  wheelchairAccessible
  bathroomEquipment {
    shower
    bathtub
    window
    bidet
    urinal
  }
  ${garageDataPsSide}
  ${commercialDataPsSide}
  ${salesDataPsSide}
  ${courtageDataFields}
`;
export const propertyPsSide = `
  property {
    id
    status
    externalId
    runtimeInDays
    selfDisclosureId
    type
    applyLink
    entryPrice
    downloadExposeAllowed
    downloadExposeUrl
    wbs
    marketingType
    customer {
      logo
      name
    }
    data {
      ${propertyDataPsSide}
    }
    ${customerDefinedValues}
  }
`;
export const rentDepositInfoFields = `
  oneTimeDeposit
  selectedDepositType
  ruvDepositAvailable
  ruvData {
    yearlyAmount
    monthlyAmount
    applianceStatus
    areaOfOperation
    insuranceNumber
  }
  landlordAddress {
    city
    country
    street
    houseNumber
    zipCode
  }
  sendDocumentsToLandlord
`;

export interface FindDistrictByAddressResult {
  districtForAddress: DistrictForAddress[];
}

export const findDistrictByAddressQuery = gql`
  query findDistrictByAddress($address: AddressInput) {
    districtForAddress(address: $address) {
      id
      name
      cityId
      cityName
      stateName
    }
  }
`;
