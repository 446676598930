<div
  class="attachment-item d-flex col-12 p0 flex-row"
  (click)="action()"
  [class.preview]="!disableDownload && attachment.url"
  [class.attachment-item--clickable]="!disableDownload && attachment.url"
>
  <div class="attachment-item__info col-9 p0">
    <div class="attachment-item__order-menu" *ngIf="orderable">
      <i class="icon icon--chevron-up" (click)="onMoveUp()"></i>
      <i class="icon icon--chevron-down" (click)="onMoveDown()"></i>
    </div>
    <div class="attachment-item__preview mr10" *ngIf="getFileExtension">
      {{getFileExtension}}
    </div>
    <div class="attachment-item__details">
      <app-simple-text-input
        [formControl]="nameControl"
        *ngIf="editable"
        [hideIcon]="hideIcons"
      ></app-simple-text-input>
      <p class="default-s attachment-details__name" *ngIf="!editable">
        {{ name }}
      </p>
      <p class="default-s attachment-details__size">
        <span *ngIf="attachment?.size">{{attachment?.size | appFileSize}}</span>
        <span *ngIf="hasExifMetadata" class="exif-metadata-info"
          >{{ 'attachments.exif_metadata_info' | translate }}</span
        >
      </p>
    </div>
  </div>
  <div class="attachment-item__actions upload-progress col-3 p0">
    <div
      *ngIf="showDownload && !hasFile"
      class="download-attachment-button"
      [ngClass]="{ 'mr12': showRemove }"
    >
      <a
        *ngIf="!blockDownload && !disableDownload"
        class="download-icon"
        (click)="onDownload()"
      >
        <!-- {{attachment?.title || attachment?.name}} -->
        <svg-icon
          src="/assets/images/icons/icon-download.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="white-svg-color path rect download"
        ></svg-icon>
      </a>
      <span class="download-icon disabled">
        <svg-icon
          *ngIf="!blockDownload && disableDownload"
          src="/assets/images/icons/icon-download.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="default-svg-color path rect"
        ></svg-icon>
        <svg-icon
          *ngIf="blockDownload"
          src="/assets/images/icons/lock-icon.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="default-svg-color path rect"
        ></svg-icon>
      </span>
    </div>

    <div *ngIf="showRemove" class="remove-attachment-button">
      <svg-icon
        src="/assets/images/icons/icon-bin.svg"
        [applyClass]="true"
        [svgStyle]="{'width.px': 15}"
        class="secondary-svg-color path rect"
        (click)="onRemove()"
      ></svg-icon>
    </div>
    <div *ngIf="editable">
      <app-context-menu>
        <div menu-content>
          <app-context-menu-item (clickEvent)="editAttachment()"
            >{{'general.edit_attachment_a' | translate}}</app-context-menu-item
          >
        </div>
      </app-context-menu>
    </div>
  </div>
</div>
