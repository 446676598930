import { navigationComponents } from './navigation';
import { DataTableComponent } from './data-table/data-table.component';
import { DataTableCellDirective } from './data-table/data-table-cell/data-table-cell.directive';
import { DataTableCellComponent } from './data-table/data-table-cell/data-table-cell.component';
import { BasicTableComponent } from './basic-table/basic-table.component';
import { QuotaTableComponent } from './quota-table/quota-table.component';
import { DataTableHeaderDirective } from './data-table/data-table-header.directive';

export const organismsComponents = [
  ...navigationComponents,
  DataTableCellDirective,
  DataTableHeaderDirective,
  DataTableComponent,
  DataTableCellComponent,
  BasicTableComponent,
  QuotaTableComponent
];
