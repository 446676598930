<div class="search-profile-details">
  <p class="uppercase-m semibold modal-header__title mb0">
    {{ (isProjectSearchProfile ? 'profile.project_search_profile_l' :
    'profile.property_search_profile_l')| translate }}
  </p>
  <h3 class="title-xl modal-header__subtitle mb32">
    {{ (isNewSearchProfile ? 'search_profile.add_l' : 'search_profile.edit_l') |
    translate }}
  </h3>
  <div class="uppercase-m semibold mb20" *ngIf="!isProjectSearchProfile">
    {{ 'search_profile.property_info_l' | translate }}
  </div>
  <form class="row" [formGroup]="form">
    <div *ngIf="isProjectSearchProfile" class="mb32">
      <app-project-card
        [projectData]="project"
        [pageView]="true"
        [limitInformation]="true"
        [enableContextMenu]="false"
      ></app-project-card>
    </div>

    <div *ngIf="!isProjectSearchProfile">
      <div class="form__section mb10">
        <app-form-field class="inline">
          <app-form-field-label [showRequiredMarker]="true"
            >{{'general.search_profile_title_l' |
            translate}}</app-form-field-label
          >
          <input
            appInput
            [placeholder]="'forms.type_search_profile_title' | translate"
            class="form-control"
            formControlName="name"
          />
        </app-form-field>
      </div>
    </div>
    <app-form-field *ngIf="showMarketingType" class="mb20">
      <app-form-field-label
        >{{'property.marketing_type_selection_l' |
        translate}}</app-form-field-label
      >
      <app-dropdown-select
        [items]="constants?.marketingTypes"
        formControlName="marketingType"
      >
        <div dropdown-button>
          {{ 'search_profiles.select-marketing-type' | translate }}
        </div>
        <ng-template let-item>{{ item.name | translate }}</ng-template>
      </app-dropdown-select>
    </app-form-field>

    <app-form-field class="mb20">
      <app-form-field-label
        >{{'property.property_type_selection_l' |
        translate}}</app-form-field-label
      >
      <app-dropdown-select
        [items]="propertyTypesConstants"
        formControlName="propertyType"
      >
        <div dropdown-button>
          {{ 'search_profiles.select-property-type' | translate }}
        </div>
        <ng-template let-item>{{ item.name | translate }}</ng-template>
      </app-dropdown-select>
    </app-form-field>

    <ng-container formGroupName="flatData">
      <app-form-field [disabledClickArea]="true" class="mb20" *ngIf="isFlat">
        <app-form-field-label [showRequiredMarker]="true"
          >{{'property.object_type_selection_l' |
          translate}}</app-form-field-label
        >
        <app-dropdown-multiselect
          [items]="objectTypeConstants"
          [showToggleAll]="true"
          formControlName="objectTypes"
        >
          <div dropdown-button>
            {{ 'property.object_type_select_l' | translate }}
          </div>
          <div dropdown-button-multi>
            {{ (form.get('flatData.objectTypes') |
            appFormControlPipe)?.value?.length }} {{
            'property.object_type_selected_l' | translate }}
          </div>
          <ng-template let-item>{{ item.name }}</ng-template>
        </app-dropdown-multiselect>
      </app-form-field>

      <app-form-field
        [disabledClickArea]="true"
        class="mb20"
        *ngIf="isObjectTypeFlat"
      >
        <app-form-field-label [showRequiredMarker]="true"
          >{{'property.flat_type_selection_l' |
          translate}}</app-form-field-label
        >
        <app-dropdown-multiselect
          [items]="flatTypesConstants"
          [showToggleAll]="true"
          formControlName="flatTypes"
        >
          <div dropdown-button>
            {{ 'property.flat_type_select_l' | translate }}
          </div>
          <div dropdown-button-multi>
            {{ (form.get('flatData.flatTypes') |
            appFormControlPipe)?.value?.length }} {{
            'property.flat_type_selected_l' | translate }}
          </div>
          <ng-template let-item>{{ item.name }}</ng-template>
        </app-dropdown-multiselect>
      </app-form-field>

      <app-form-field
        [disabledClickArea]="true"
        class="mb20"
        *ngIf="isObjectTypeHouse"
      >
        <app-form-field-label [showRequiredMarker]="true"
          >{{'property.hause_type_selection_l' |
          translate}}</app-form-field-label
        >
        <app-dropdown-multiselect
          [items]="houseTypesConstants"
          [showToggleAll]="true"
          formControlName="houseTypes"
        >
          <div dropdown-button>
            {{ 'property.hause_type_select_l' | translate }}
          </div>
          <div dropdown-button-multi>
            {{ (form.get('flatData.houseTypes') |
            appFormControlPipe)?.value?.length }} {{
            'property.hause_type_selected_l' | translate }}
          </div>
          <ng-template let-item>{{ item.name }}</ng-template>
        </app-dropdown-multiselect>
      </app-form-field>
    </ng-container>

    <app-form-field [disabledClickArea]="true" *ngIf="isGarage" class="mb20">
      <app-form-field-label
        >{{'property.garage_type_selection_l' |
        translate}}</app-form-field-label
      >
      <app-dropdown-multiselect
        class="mb20"
        required
        [items]="garageTypesConstants"
        [showToggleAll]="true"
        formControlName="garageTypes"
      >
        <div dropdown-button>{{ 'general.select_l' | translate }}</div>
        <div dropdown-button-multi>
          {{ garageTypesControl?.value?.length }} {{
          'general.selected_garage_types_l' | translate }}
        </div>
        <ng-template let-item
          ><span>{{ item.name | translate }}</span></ng-template
        >
      </app-dropdown-multiselect>
    </app-form-field>

    <ng-container *ngIf="isCommercial" formGroupName="commercialData">
      <app-form-field class="mb20">
        <app-form-field-label [showRequiredMarker]="true"
          >{{'property.commercial_type_selection_l' |
          translate}}</app-form-field-label
        >
        <app-dropdown-select
          [items]="commercialTypesConstants"
          formControlName="commercialType"
        >
          <div dropdown-button>
            {{'property.commercial_type_selection_placeholder_l' | translate}}
          </div>
          <ng-template let-item>{{ item.name | translate }}</ng-template>
        </app-dropdown-select>
      </app-form-field>

      <app-form-field
        *ngIf="commercialSubTypes?.length"
        [disabledClickArea]="true"
        class="mb20"
      >
        <app-form-field-label [showRequiredMarker]="true"
          >{{'property.commercial_subtype_selection_l' |
          translate}}</app-form-field-label
        >
        <app-dropdown-multiselect
          class="mb20"
          [items]="commercialSubTypes"
          formControlName="commercialSubTypes"
        >
          <div dropdown-button>{{ 'general.select_l' | translate }}</div>
          <div dropdown-button-multi>
            {{ commercialSubTypesForm?.value?.length }} {{
            'general.selected_commercial_subtypes_l' | translate }}
          </div>
          <ng-template let-item
            ><span>{{ item.name | translate }}</span></ng-template
          >
        </app-dropdown-multiselect>
      </app-form-field>
    </ng-container>

    <app-search-profile-address
      [form]="form"
      [locationError]="locationError"
      [landlordCityPacket]="landlordCityPacket"
      [inInternalPool]="inInternalPool"
      [onlyShowConfiguredCityPartsToUser]="onlyShowConfiguredCityPartsToUser"
      [isRegistration]="isRegistration"
      [countries]="internalizationSettings?.countries ? internalizationSettings?.countries : internalizationSettings?.itpCountries"
      (addCity)="addCityGroup()"
      *ngIf="!isProjectSearchProfile"
    >
    </app-search-profile-address>

    <div>
      <div class="form__section">
        <app-form-field *ngIf="!isSalesObject; else salesObjectPrice">
          <app-form-field-label
            >{{ 'general.max_rental_price_l' | translate
            }}</app-form-field-label
          >
          <app-slider
            [min]="minUpperBoundRent"
            [max]="maxUpperBoundRent"
            [unit]="'€'"
            [unitMax]="'€ +'"
            [step]="stepsForUpperBoundRent"
            [required]
            formControlName="upperBoundRent"
          >
          </app-slider>
        </app-form-field>

        <ng-template #salesObjectPrice [formGroup]="form.get('salesData')">
          <app-form-field>
            <app-form-field-label
              >{{ 'general.max_price_l' | translate }}</app-form-field-label
            >
            <app-slider
              [min]="5000"
              [max]="5000000"
              [unit]="'€'"
              [unitMax]="'€ +'"
              [step]="5000"
              [required]
              formControlName="priceUpperBound"
            >
            </app-slider>
          </app-form-field>
        </ng-template>

        <app-form-field *ngIf="isFlat || isCommercial">
          <app-form-field-label
            >{{ 'general.flat_area_l' | translate }}</app-form-field-label
          >
          <app-slider
            [min]="10"
            [max]="200"
            [unit]="'&#13217;'"
            [step]="5"
            [required]
            formControlName="lowerBoundSize"
          >
          </app-slider>
        </app-form-field>

        <app-form-field *ngIf="isFlat">
          <app-form-field-label
            >{{ 'general.number_of_rooms_l' | translate }}</app-form-field-label
          >
          <app-slider
            [min]="1"
            [max]="9"
            [step]="1"
            formControlName="lowerBoundRooms"
          >
          </app-slider>
        </app-form-field>
      </div>

      <div class="form__section" *ngIf="isFlat || isCommercial">
        <app-search-profile-property-details
          [form]="form"
          [showSeniorApartment]="isFlat"
          [considerElevator]="!isSalesObject || isCommercial"
        >
        </app-search-profile-property-details>
      </div>

      <div class="form__section">
        <app-form-field>
          <app-form-field-label
            >{{ 'search_profile.duration_end_date_l' | translate
            }}</app-form-field-label
          >
          <app-date
            [minDate]="dateLimit"
            formControlName="durationEndDate"
          ></app-date>
        </app-form-field>
      </div>

      <div class="form__section" *ngIf="enableEditingOfSearchingSinceDate">
        <app-form-field>
          <app-form-field-label [showRequiredMarker]="true"
            >{{ 'general.search_profile_creation_date_l' | translate
            }}</app-form-field-label
          >
          <app-date
            [maxDate]="dateLimit"
            formControlName="searchingSince"
          ></app-date>
        </app-form-field>
      </div>
    </div>
  </form>
</div>
