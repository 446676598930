import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseControl } from 'libs/components/legacy/form/controls/base-control';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { INTERNATIONAL_OPTIONS, NameValue } from '@ui/shared/models';
import { RegexTypes } from 'libs/utils';

@UntilDestroy()
@Component({
  selector: 'app-international-phone',
  templateUrl: './international-phone.component.html',
  styleUrls: ['./international-phone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InternationalPhoneComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => InternationalPhoneComponent)
    }
  ]
})
export class InternationalPhoneComponent
  extends BaseControl<string>
  implements OnInit, ControlValueAccessor
{
  @Input() readonly = false;
  public form: FormGroup;
  @Input() internationalOptions: NameValue[] = INTERNATIONAL_OPTIONS;

  private _errors: any;
  private _touched = false;

  get errors() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this._errors;
  }
  set errors(errors: any) {
    this._errors = errors;
  }

  get touched() {
    return this._touched;
  }
  set touched(value: boolean) {
    this._touched = value;
  }

  constructor(
    private fb: FormBuilder,
    protected injector: Injector
  ) {
    super(injector);
  }

  private splitNumber(value: string) {
    // This regex is used to split the country code and phone number
    const regex = new RegExp(RegexTypes.INTERNATIONAL_PHONE);
    const payload = {
      international: '+49',
      number: ''
    };
    if (value) {
      // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
      const regexResults = value.match(regex);
      payload.international = regexResults[0];
      payload.number = regexResults[1];
    }

    if (value?.charAt(0) === '0') {
      payload.international = payload.international || '+49';
      payload.number = value.substring(1);
    }
    return payload;
  }

  /*
   * Cut first Zero and remove special characters
   */
  private static parseNumber(number: string) {
    let n = number;
    if (n.length === 0) {
      return n;
    }

    n = n.replace(RegexTypes.SPECIAL_CHARACTERS, '');

    if (n.charAt(0) !== '0') {
      return n;
    }

    while (n?.charAt(0) === '0') {
      n = n.substring(1);
    }
    return n;
  }

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  public ngOnInit() {
    this.form = this.fb.group({
      international: '',
      number: ''
    });

    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: { international: string; number: string }) => {
        if (!value.international || !value.number) {
          // Change the formcontrol value to en empty string in order to trigger validations
          this.onChange('');
          return;
        }

        // Combine both values again and set them as value for the formcontrol
        this.onChange(`${String(value.international)}${value.number}`);
        this.onTouch();
      });
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public writeValue(value: string) {
    this.form.patchValue(this.splitNumber(value));
  }
}
