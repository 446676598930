export { NavigationService } from './navigation.service';

import { navigationDrawerComponents } from './navigation-drawer';
import { NavigationComponent } from './navigation.component';
import { NavigationUserProfileComponent } from './navigation-user-profile/navigation-user-profile.component';

export const navigationComponents = [
  ...navigationDrawerComponents,
  NavigationUserProfileComponent,
  NavigationComponent
];
