import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  NameValue,
  PropertyApplicationStatus,
  PropertyType
} from '@ui/shared/models';

@Component({
  selector: 'app-property-interest',
  templateUrl: './property-interest.component.html',
  styleUrls: ['./property-interest.component.scss']
})
export class PropertyInterestComponent {
  @Input() propertyName: string;
  @Input() intent: string;
  @Input() fromEmail: boolean;
  @Input() profileComplete: boolean;
  @Input() reasons: NameValue[];
  @Input() editProfile: () => null;
  @Input() isOfflineUser: boolean;
  @Input() propertyType: PropertyType;
  @Input() showV2 = true;
  @Input() askForViewingAppointmentAttendance: boolean;

  public hasVisitedViewingAppointment: string;
  public reasonTypeModel: string;
  public otherReasonText: string;
  public applicationStates = PropertyApplicationStatus;

  public get isInterested() {
    return this.intent === PropertyApplicationStatus.INTENT;
  }

  constructor(private ngbActiveModal: NgbActiveModal) {}

  close() {
    this.ngbActiveModal.close();
  }

  public onEditProfile() {
    this.ngbActiveModal.dismiss(true);
    return this.editProfile();
  }

  public cancel() {
    this.ngbActiveModal.dismiss();
  }

  public confirmIntent() {
    this.ngbActiveModal.close({
      intent: true
    });
  }

  public denyIntent() {
    this.ngbActiveModal.close({
      intent: false,
      reasonType: this.reasonTypeModel,
      otherReasonText: this.otherReasonText
    });
  }
}
