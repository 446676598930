export { NavigationDrawerService } from './navigation-drawer.service';

import { NavigationDrawerComponent } from './navigation-drawer.component';
import { NavigationDrawerListComponent } from './navigation-drawer-list/navigation-drawer-list.component';
import { NavigationDrawerItemComponent } from './navigation-drawer-item/navigation-drawer-item.component';

export const navigationDrawerComponents = [
  NavigationDrawerComponent,
  NavigationDrawerListComponent,
  NavigationDrawerItemComponent
];
