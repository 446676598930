import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApolloError } from '@apollo/client/core';

import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { from } from 'rxjs';

import { MessengerFacade } from 'libs/components/legacy/messenger/services/messenger.facade';
import { messengerNotificationConfig } from 'libs/components/legacy/messenger/model/messengerNotificationConfig';
import {
  POLLING_TIME_NEW_MESSAGES_LL,
  POLLING_TIME_NEW_MESSAGES_PS
} from 'libs/components/legacy/messenger/model/interface';
import { ConversationSenderTypes } from 'libs/components/legacy/messenger/model/enum';
import { errorMessageParser } from 'libs/infrastructure/gql-client/error-message-parser';

import * as fromReducers from '../reducers';
import * as fromNotification from '../notification/notification.actions';
import { Go } from '../router/router.actions';
import * as fromMessageAction from './messenger.actions';
import * as fromMessageSelector from './messenger.selector';

@Injectable()
export class MessengerEffects {
  constructor(
    private actions$: Actions,
    private messengerRepository: MessengerFacade,
    private store: Store<fromReducers.BaseState>
  ) {}

  sendMessageInNewConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.SendMessageInNewConversation>(
        fromMessageAction.SEND_MESSAGE_IN_NEW_CONVERSATION
      ),
      withLatestFrom(this.store.select(fromMessageSelector.isSenderLandlord)),
      switchMap(([{ data }, isSenderLandlord]) =>
        this.messengerRepository
          .sendMessageInNewConversation(data, isSenderLandlord)
          .pipe(
            map(
              res =>
                new fromMessageAction.SendMessageInNewConversationSuccess(
                  res,
                  isSenderLandlord
                )
            ),
            catchError((error: ApolloError) => {
              return [
                new fromMessageAction.SendMessageInNewConversationFail(error),
                new fromNotification.ShowError(
                  errorMessageParser(
                    error,
                    messengerNotificationConfig.messenger.sendMessage.error
                  )
                )
              ];
            })
          )
      )
    )
  );

  sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.SendMessage>(fromMessageAction.SEND_MESSAGE),
      switchMap(({ data, unarchive }) =>
        this.messengerRepository.sendMessage(data).pipe(
          mergeMap(res => {
            const actions: Action[] = [
              new fromMessageAction.SendMessageSuccess(res)
            ];
            if (unarchive) {
              actions.push(
                new fromMessageAction.UnarchiveConversation(data.conversationId)
              );
            }
            return actions;
          }),
          catchError((error: ApolloError) => {
            return [
              new fromMessageAction.SendMessageFail(error),
              new fromNotification.ShowError(
                errorMessageParser(
                  error,
                  messengerNotificationConfig.messenger.sendMessage.error
                )
              )
            ];
          })
        )
      )
    )
  );

  bulkSendMessage$ = createEffect(() =>
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.actions$.pipe(
      ofType<fromMessageAction.BulkSendMessage>(
        fromMessageAction.BULK_SEND_MESSAGE
      ),
      switchMap(({ data }) =>
        this.messengerRepository.bulkSendMessage(data).pipe(
          mergeMap(() => [
            new fromMessageAction.BulkSendMessageSuccess(),
            new fromNotification.ShowInfo(
              messengerNotificationConfig.messenger.bulkSendMessage.success
            )
          ]),
          catchError((error: ApolloError) => [
            new fromMessageAction.BulkSendMessageFail(error),
            new fromNotification.ShowError(
              messengerNotificationConfig.messenger.bulkSendMessage.error
            )
          ])
        )
      )
    )
  );

  loadConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.LoadConversations>(
        fromMessageAction.LOAD_CONVERSATIONS
      ),
      withLatestFrom(this.store.select(fromMessageSelector.isSenderLandlord)),
      switchMap(([action, isSenderLandlord]) =>
        this.messengerRepository
          .searchConversation(action.data, isSenderLandlord)
          .pipe(
            map(
              conversations =>
                new fromMessageAction.LoadConversationsSuccess(
                  conversations,
                  action.data?.conversationId,
                  action.data?.archivedByCustomerOnly
                )
            ),
            catchError(error => [
              new fromMessageAction.LoadConversationsFail(error),
              new fromNotification.ShowError(
                messengerNotificationConfig.messenger.loadConversations.error
              )
            ])
          )
      )
    )
  );

  blockConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.BlockConversation>(
        fromMessageAction.BLOCK_CONVERSATIONS
      ),
      switchMap(({ data }) =>
        this.messengerRepository.blockConversation(data).pipe(
          map(
            () => new fromMessageAction.BlockConversationSuccess(data.blocked)
          ),
          catchError(error => [
            new fromMessageAction.BlockConversationFail(error),
            new fromNotification.ShowError(
              messengerNotificationConfig.messenger.loadConversations.error
            )
          ])
        )
      )
    )
  );

  loadConversationsMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.LoadConversationsMessages>(
        fromMessageAction.LOAD_CONVERSATION_MESSAGES
      ),
      switchMap(action =>
        this.messengerRepository
          .searchConversationMessagesByConversationId(action.input)
          .pipe(
            withLatestFrom(this.store.select(fromMessageSelector.getSender)),
            mergeMap(([res, sender]) => {
              const POLLING_TIME =
                sender === ConversationSenderTypes.LANDLORD
                  ? POLLING_TIME_NEW_MESSAGES_LL
                  : POLLING_TIME_NEW_MESSAGES_PS;
              return [
                new fromMessageAction.LoadConversationsMessagesSuccess(
                  res.nodes,
                  res.page
                ),
                new fromMessageAction.FindUnreadMessages(
                  action.input,
                  POLLING_TIME
                )
              ];
            }),
            catchError(error => [
              new fromMessageAction.LoadConversationsMessagesFail(error),
              new fromNotification.ShowError(
                messengerNotificationConfig.messenger.loadConversationMessages.error
              )
            ])
          )
      )
    )
  );

  loadConversationsMessagesAndDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.LoadConversationMessagesAndDetails>(
        fromMessageAction.LOAD_CONVERSATION_MESSAGES_AND_DETAILS
      ),
      withLatestFrom(this.store.select(fromMessageSelector.getSender)),
      switchMap(([action, sender]) =>
        this.messengerRepository
          .conversationMessagesAndDetails(action.input, sender)
          .pipe(
            mergeMap(res => {
              const POLLING_TIME =
                sender === ConversationSenderTypes.LANDLORD
                  ? POLLING_TIME_NEW_MESSAGES_LL
                  : POLLING_TIME_NEW_MESSAGES_PS;
              return [
                new fromMessageAction.LoadConversationMessagesAndDetailsSuccess(
                  {
                    nodes: res.searchConversationMessagesByConversationId.nodes,
                    page: res.searchConversationMessagesByConversationId.page,
                    details: res.conversationDetails
                  }
                ),
                new fromMessageAction.FindUnreadMessages(
                  action.input,
                  POLLING_TIME
                )
              ];
            }),
            catchError(error => [
              new fromMessageAction.LoadConversationMessagesAndDetailsFail(
                error
              ),
              new fromNotification.ShowError(
                messengerNotificationConfig.messenger.loadConversationMessages.error
              )
            ])
          )
      )
    )
  );

  archiveConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.ArchiveConversation>(
        fromMessageAction.ARCHIVE_CONVERSATION
      ),
      switchMap(({ conversationId }) =>
        this.messengerRepository.archiveConversation(conversationId).pipe(
          mergeMap(() => [
            new fromMessageAction.ArchiveConversationSuccess(conversationId),
            new fromNotification.ShowInfo(
              messengerNotificationConfig.messenger.archiveConversation.success
            )
          ]),
          catchError((error: ApolloError) => [
            new fromMessageAction.ArchiveConversationFail(error),
            new fromNotification.ShowError(
              messengerNotificationConfig.messenger.archiveConversation.error
            )
          ])
        )
      )
    )
  );

  unarchiveConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.UnarchiveConversation>(
        fromMessageAction.UN_ARCHIVE_CONVERSATION
      ),
      switchMap(({ conversationId }) =>
        this.messengerRepository.unarchiveConversation(conversationId).pipe(
          mergeMap(() => [
            new fromMessageAction.UnarchiveConversationSuccess(conversationId),
            new fromNotification.ShowInfo(
              messengerNotificationConfig.messenger.unarchiveConversation.success
            )
          ]),
          catchError((error: ApolloError) => [
            new fromMessageAction.UnarchiveConversationFail(error),
            new fromNotification.ShowError(
              messengerNotificationConfig.messenger.unarchiveConversation.error
            )
          ])
        )
      )
    )
  );

  loadParticipatedAgents$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.LoadParticipatedAgents>(
        fromMessageAction.LOAD_PARTICIPATED_AGENTS
      ),
      switchMap(({ conversationId }) =>
        this.messengerRepository.loadParticipatedAgents(conversationId).pipe(
          map(res => new fromMessageAction.LoadParticipatedAgentsSuccess(res)),
          catchError(error => [
            new fromMessageAction.LoadParticipatedAgentsFail(error)
          ])
        )
      )
    )
  );

  setPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.IncreaseChatPage>(
        fromMessageAction.INCREASE_CHAT_PAGE
      ),
      withLatestFrom(
        this.store.select(fromMessageSelector.getActiveConversationId),
        this.store.select(fromMessageSelector.getConversationMessagesPage)
      ),
      map(
        ([{ customerSettings }, activeConversation, { page }]) =>
          new fromMessageAction.LoadConversationsMessages({
            conversationId: activeConversation,
            page,
            customerSettings
          })
      )
    )
  );

  findUnreadMessages$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.FindUnreadMessages>(
        fromMessageAction.FIND_UNREAD_MESSAGES
      ),
      switchMap(({ input, pollInterval }) =>
        this.messengerRepository.findUnreadMessages(input, pollInterval).pipe(
          map(
            ({ nodes }) =>
              new fromMessageAction.FindUnreadMessagesSuccess(nodes)
          ),
          catchError(() => [
            new fromNotification.ShowError(
              messengerNotificationConfig.messenger.findUnreadMessages.error
            )
          ])
        )
      )
    )
  );

  countUnread$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.CountUnread>(fromMessageAction.COUNT_UNREAD),
      switchMap(({ pollInterval }) =>
        this.messengerRepository
          .countUnread(pollInterval)
          .pipe(
            map(
              ({ messages }) =>
                new fromMessageAction.CountUnreadSuccess(messages)
            )
          )
      )
    )
  );

  refetchCountUnread$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.RefetchCountUnread>(
        fromMessageAction.REFETCH_COUNT_UNREAD
      ),
      switchMap(() =>
        this.messengerRepository
          .refetchCountUnread()
          .pipe(
            map(
              ({ messages }) =>
                new fromMessageAction.RefetchCountUnreadSuccess(messages)
            )
          )
      )
    )
  );

  checkForConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.CheckForConversation>(
        fromMessageAction.CHECK_FOR_CONVERSATION
      ),
      withLatestFrom(this.store.select(fromMessageSelector.isSenderLandlord)),
      switchMap(
        ([
          {
            data: { propertySearcher, property, ticketDetails },
            agentIds
          },
          isSenderLandlord
        ]) => {
          if (propertySearcher.conversationId) {
            return this.messengerRepository
              .searchConversation(
                {
                  conversationId: propertySearcher?.conversationId,
                  agents: agentIds,
                  archivedByCustomerOnly: propertySearcher?.archivedByCustomer
                },
                isSenderLandlord
              )
              .pipe(
                mergeMap(
                  conversations =>
                    [
                      new fromMessageAction.LoadConversationsSuccess(
                        conversations,
                        conversations.nodes[0]?.id,
                        propertySearcher?.archivedByCustomer
                      ),
                      new Go({
                        path: ['messenger'],
                        extras: { queryParams: { conversationExists: true } }
                      })
                    ] as Action[]
                )
              );
          } else {
            const actions = [
              new fromMessageAction.CreateConversation({
                property,
                propertySearcher,
                ticketDetails,
                applicationId: propertySearcher?.id
              }),
              new Go({
                path: ['messenger'],
                extras: {
                  queryParams: {
                    conversationExists: false,
                    applicationId: propertySearcher?.id,
                    ticketId: ticketDetails?.id
                  }
                }
              })
            ] as Action[];

            return from(actions);
          }
        }
      )
    )
  );

  stopCountUnreadPolling = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromMessageAction.StopCountUnreadPolling>(
          fromMessageAction.STOP_COUNT_UNREAD_POLLING
        ),
        tap(() => this.messengerRepository.stopCountUnreadPolling())
      ),
    { dispatch: false }
  );

  startFindUnreadMessagesPolling = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromMessageAction.StartFindUnreadMessagesPolling>(
          fromMessageAction.START_FIND_UNREAD_MESSAGES_POLLING
        ),
        map(action => action.pollingInterval),
        tap(pollingInterval =>
          this.messengerRepository.startFindUnreadMessagesPolling(
            pollingInterval
          )
        )
      ),
    { dispatch: false }
  );

  stopFindUnreadMessagesPolling = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromMessageAction.StopFindUnreadMessagesPolling>(
          fromMessageAction.STOP_FIND_UNREAD_MESSAGES_POLLING
        ),
        tap(() => this.messengerRepository.stopFindUnreadMessagesPolling())
      ),
    { dispatch: false }
  );

  loadTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.LoadTemplate>(fromMessageAction.LOAD_TEMPLATE),
      switchMap(({ page }) =>
        this.messengerRepository.loadTemplate(page).pipe(
          map(
            templates => new fromMessageAction.LoadTemplateSuccess(templates)
          ),
          catchError(err => [new fromMessageAction.LoadTemplateFail(err)])
        )
      )
    )
  );

  loadParsedTemplates$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.LoadParsedTemplates>(
        fromMessageAction.LOAD_PARSED_TEMPLATES
      ),
      switchMap(({ input }) =>
        this.messengerRepository.loadParsedTemplates(input).pipe(
          map(
            templates =>
              new fromMessageAction.LoadParsedTemplatesSuccess(templates)
          ),
          catchError(err => [
            new fromMessageAction.LoadParsedTemplatesFail(err)
          ])
        )
      )
    )
  );

  createTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.CreateTemplate>(
        fromMessageAction.CREATE_TEMPLATE
      ),
      switchMap(({ template }) =>
        this.messengerRepository.createTemplate(template).pipe(
          mergeMap(({ id }) => [
            new fromMessageAction.CreateTemplateSuccess({ ...template, id }),
            new fromNotification.ShowInfo(
              messengerNotificationConfig.template.create.success
            )
          ]),
          catchError(err => [new fromMessageAction.CreateTemplateFail(err)])
        )
      )
    )
  );

  updateTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.UpdateTemplate>(
        fromMessageAction.UPDATE_TEMPLATE
      ),
      switchMap(({ template }) =>
        this.messengerRepository.updateTemplate(template).pipe(
          mergeMap(() => [
            new fromMessageAction.UpdateTemplateSuccess(template),
            new fromNotification.ShowInfo(
              messengerNotificationConfig.template.update.success
            )
          ]),
          catchError(err => [new fromMessageAction.UpdateTemplateFail(err)])
        )
      )
    )
  );

  deleteTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<fromMessageAction.DeleteTemplate>(
        fromMessageAction.DELETE_TEMPLATE
      ),
      switchMap(({ templateId }) =>
        this.messengerRepository.deleteTemplate(templateId).pipe(
          mergeMap(() => [
            new fromMessageAction.DeleteTemplateSuccess(templateId),
            new fromNotification.ShowInfo(
              messengerNotificationConfig.template.delete.success
            )
          ]),
          catchError(err => [new fromMessageAction.DeleteTemplateFail(err)])
        )
      )
    )
  );

  markConversationAsUnread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromMessageAction.MARK_CONVERSATION_AS_UNREAD),
      switchMap(({ id }) =>
        this.messengerRepository.markConversationAsUnread(id).pipe(
          map(() => new fromMessageAction.MarkConversationAsUnreadSuccess(id)),
          catchError(error => [
            new fromNotification.ShowError(errorMessageParser(error))
          ])
        )
      )
    )
  );
}
