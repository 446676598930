<span class="contingent-header" *ngIf="quotaStatistic">
  <img
    *ngIf="quotaStatistic.availableQuota >= 10"
    src="/assets/images/icons/icon-checkbox-green.svg"
  />
  <img
    *ngIf="quotaStatistic.availableQuota < 10 && quotaStatistic.availableQuota > 0"
    src="/assets/images/icons/icon-info.svg"
  />
  <img
    *ngIf="quotaStatistic.availableQuota === 0"
    src="/assets/images/icons/icon-no.svg"
  />
  <ng-container *ngIf="showUnlimited; else showQuota">
    <span>{{text | translate}} {{'quota_info.unlimited_l' | translate}}</span>
  </ng-container>
  <ng-template #showQuota>
    <a
      *ngIf="isAdmin"
      class="nav p-0"
      [routerLink]="['/manage/digital-contracts', 'booking']"
      >{{text | translate}} {{quotaStatistic.availableQuota}}</a
    >
    <span *ngIf="!isAdmin"
      >{{text | translate}} {{quotaStatistic.availableQuota}}</span
    >
  </ng-template>
</span>
