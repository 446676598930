import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-quota-button',
  templateUrl: './quota-button.component.html',
  styleUrls: ['./quota-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuotaButtonComponent {
  @Input() currentAmount: number;
  @Input() useDefaultCursor: boolean;

  @Input() positiveQuota: string;
  @Input() emptyQuota: string;
  @Input() negativeQuota: string;

  @Input() alwaysShowNumber: boolean;

  @Output() clickEvent = new EventEmitter();

  public onClick() {
    this.clickEvent.emit();
  }
}
