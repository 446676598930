import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'quotaNumber' })
export class QuotaNumberPipe implements PipeTransform {
  transform(amount: number, numberAlwaysVisible: boolean): string {
    if (amount > 0 || numberAlwaysVisible) {
      return `: ${amount}`;
    }
    return '';
  }
}
