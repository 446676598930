import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CityPacket } from '@ui/shared/models';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-search-profile-landlord-districts',
  templateUrl: './search-profile-landlord-districts.component.html',
  styleUrls: ['./search-profile-landlord-districts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchProfileLandlordDistrictsComponent {
  @Input() landlordCityPacket: CityPacket;
  @Input() form: FormGroup;
  @Input() onlyShowConfiguredCityPartsToUser: boolean;
  @Input() isNewSP: boolean;
}
