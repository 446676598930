import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';

import { combineLatest, skip } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { fadeAnimation, FadeAnimationStateEnum } from 'libs/utils';
import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';

import { AnimationEvent } from '@angular/animations';
import { NavigationService } from './navigation.service';
import { NavigationAlignment } from './navigation.model';

@UntilDestroy()
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  animations: [fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NavigationService]
})
export class NavigationComponent implements OnInit, OnDestroy {
  /**
   * Activates dark mode.
   */
  @Input() dark?: boolean;

  /**
   * Defines whether the whole navigation should always overlay the main content instead of displacing it.
   */
  @Input() overlay?: boolean;

  /**
   * Defines the screen side the navigation should appear at.
   */
  @Input() alignment?: NavigationAlignment;

  /**
   * Defines whether the main navigation should appear narrow initially.
   */
  @Input() narrow?: boolean;
  @Output() narrowManualChange = new EventEmitter<boolean>();

  /**
   * Defines whether the main navigation should be open initially.
   */
  @Input() open?: boolean;
  @Output() openChange = new EventEmitter<boolean>(true);
  @Output() closedNavigationAnimationEnded = new EventEmitter<void>();

  /**
   * All navigation items starting from root level with nested children.
   */
  @Input() navigationItems?: NavigationItem[];

  public get backdropVisible$() {
    return combineLatest(
      [
        this.navigation.open$,
        this.navigation.overlay$,
        this.navigation.mobileView$
      ],
      (open, overlay, mobileView) => open && (overlay || mobileView)
    );
  }

  private _closeOnOutsideClick: boolean;
  private _closeFloatingSubMenuOnOutsideClick: boolean;
  private _clickedInside = false;

  @HostListener('click')
  public clickInside() {
    this._clickedInside = true;
  }

  @HostListener('document:click')
  public clickout() {
    if (!this._clickedInside) {
      if (this._closeOnOutsideClick) {
        this.navigation.close();
      } else if (this._closeFloatingSubMenuOnOutsideClick) {
        this.navigation.closeFloatingSubMenu();
      }
    }
    this._clickedInside = false;
  }

  constructor(public navigation: NavigationService) {}

  public ngOnInit() {
    const { dark, overlay, alignment, narrow, open, navigationItems } = this;

    this.navigation.init({
      dark,
      overlay,
      alignment,
      narrow,
      open,
      navigationItems
    });

    this.navigation.open$.pipe(untilDestroyed(this)).subscribe(open => {
      this.openChange.emit(open);
    });

    this.navigation.openFloatingSubMenu$
      .pipe(untilDestroyed(this))
      .subscribe(
        openFloatingSubMenu =>
          (this._closeFloatingSubMenuOnOutsideClick = openFloatingSubMenu)
      );

    this.navigation.narrowManualChange$
      .pipe(skip(1), untilDestroyed(this))
      .subscribe(narrow => this.narrowManualChange.emit(narrow));

    combineLatest([
      this.navigation.open$,
      this.navigation.overlay$,
      this.navigation.mobileView$
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([open, overlay, mobileView]) => {
        this._closeOnOutsideClick = open && (overlay || mobileView);
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.navigation.initialised) return;

    if (changes.open) {
      if (this.open) {
        this.navigation.open();
      } else {
        this.navigation.close();
      }
    }

    if (changes.navigationItems) {
      this.navigation.setNavigationItems(this.navigationItems);
    }

    if (changes.dark) {
      this.navigation.setDark(this.dark);
    }

    if (changes.overlay) {
      this.navigation.setOverlay(this.overlay);
    }

    if (changes.alignment) {
      this.navigation.setAlignment(this.alignment);
    }

    if (changes.narrow) {
      this.navigation.setNarrow(this.narrow);
    }
  }

  public ngOnDestroy() {
    this.navigation.complete();
  }

  public onBackdropClick(): void {
    this.navigation.close();
  }

  public fadeDone(e: AnimationEvent) {
    if (e.toState === FadeAnimationStateEnum.HIDE) {
      this.closedNavigationAnimationEnded.emit();
    }
  }
}
