<div class="toasts-container">
  <div
    class="toast"
    *ngFor="let toast of toasts"
    [id]="'toast-' + toast.id"
    [@inOut]="animate"
    (@inOut.done)="onAnimationEnd($event)"
    [ngClass]="toast.type"
    (click)="toastClick(toast)"
  >
    <div class="toast__content">
      <div class="toast__message-container">
        <span class="toast__message" [innerHTML]="toast.message"></span>
      </div>
    </div>
  </div>
</div>
