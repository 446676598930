function t(t) {
  return t.split("-")[1];
}
function e(t) {
  return "y" === t ? "height" : "width";
}
function n(t) {
  return t.split("-")[0];
}
function o(t) {
  return ["top", "bottom"].includes(n(t)) ? "x" : "y";
}
function r(r, i, a) {
  let {
    reference: l,
    floating: s
  } = r;
  const c = l.x + l.width / 2 - s.width / 2,
    f = l.y + l.height / 2 - s.height / 2,
    u = o(i),
    m = e(u),
    g = l[m] / 2 - s[m] / 2,
    d = "x" === u;
  let p;
  switch (n(i)) {
    case "top":
      p = {
        x: c,
        y: l.y - s.height
      };
      break;
    case "bottom":
      p = {
        x: c,
        y: l.y + l.height
      };
      break;
    case "right":
      p = {
        x: l.x + l.width,
        y: f
      };
      break;
    case "left":
      p = {
        x: l.x - s.width,
        y: f
      };
      break;
    default:
      p = {
        x: l.x,
        y: l.y
      };
  }
  switch (t(i)) {
    case "start":
      p[u] -= g * (a && d ? -1 : 1);
      break;
    case "end":
      p[u] += g * (a && d ? -1 : 1);
  }
  return p;
}
const i = async (t, e, n) => {
  const {
      placement: o = "bottom",
      strategy: i = "absolute",
      middleware: a = [],
      platform: l
    } = n,
    s = a.filter(Boolean),
    c = await (null == l.isRTL ? void 0 : l.isRTL(e));
  let f = await l.getElementRects({
      reference: t,
      floating: e,
      strategy: i
    }),
    {
      x: u,
      y: m
    } = r(f, o, c),
    g = o,
    d = {},
    p = 0;
  for (let n = 0; n < s.length; n++) {
    const {
        name: a,
        fn: h
      } = s[n],
      {
        x: y,
        y: x,
        data: w,
        reset: v
      } = await h({
        x: u,
        y: m,
        initialPlacement: o,
        placement: g,
        strategy: i,
        middlewareData: d,
        rects: f,
        platform: l,
        elements: {
          reference: t,
          floating: e
        }
      });
    u = null != y ? y : u, m = null != x ? x : m, d = {
      ...d,
      [a]: {
        ...d[a],
        ...w
      }
    }, v && p <= 50 && (p++, "object" == typeof v && (v.placement && (g = v.placement), v.rects && (f = !0 === v.rects ? await l.getElementRects({
      reference: t,
      floating: e,
      strategy: i
    }) : v.rects), ({
      x: u,
      y: m
    } = r(f, g, c))), n = -1);
  }
  return {
    x: u,
    y: m,
    placement: g,
    strategy: i,
    middlewareData: d
  };
};
function a(t) {
  return "number" != typeof t ? function (t) {
    return {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      ...t
    };
  }(t) : {
    top: t,
    right: t,
    bottom: t,
    left: t
  };
}
function l(t) {
  return {
    ...t,
    top: t.y,
    left: t.x,
    right: t.x + t.width,
    bottom: t.y + t.height
  };
}
async function s(t, e) {
  var n;
  void 0 === e && (e = {});
  const {
      x: o,
      y: r,
      platform: i,
      rects: s,
      elements: c,
      strategy: f
    } = t,
    {
      boundary: u = "clippingAncestors",
      rootBoundary: m = "viewport",
      elementContext: g = "floating",
      altBoundary: d = !1,
      padding: p = 0
    } = e,
    h = a(p),
    y = c[d ? "floating" === g ? "reference" : "floating" : g],
    x = l(await i.getClippingRect({
      element: null == (n = await (null == i.isElement ? void 0 : i.isElement(y))) || n ? y : y.contextElement || (await (null == i.getDocumentElement ? void 0 : i.getDocumentElement(c.floating))),
      boundary: u,
      rootBoundary: m,
      strategy: f
    })),
    w = "floating" === g ? {
      ...s.floating,
      x: o,
      y: r
    } : s.reference,
    v = await (null == i.getOffsetParent ? void 0 : i.getOffsetParent(c.floating)),
    b = (await (null == i.isElement ? void 0 : i.isElement(v))) && (await (null == i.getScale ? void 0 : i.getScale(v))) || {
      x: 1,
      y: 1
    },
    R = l(i.convertOffsetParentRelativeRectToViewportRelativeRect ? await i.convertOffsetParentRelativeRectToViewportRelativeRect({
      rect: w,
      offsetParent: v,
      strategy: f
    }) : w);
  return {
    top: (x.top - R.top + h.top) / b.y,
    bottom: (R.bottom - x.bottom + h.bottom) / b.y,
    left: (x.left - R.left + h.left) / b.x,
    right: (R.right - x.right + h.right) / b.x
  };
}
const c = Math.min,
  f = Math.max;
function u(t, e, n) {
  return f(t, c(e, n));
}
const m = n => ({
    name: "arrow",
    options: n,
    async fn(r) {
      const {
          element: i,
          padding: l = 0
        } = n || {},
        {
          x: s,
          y: c,
          placement: f,
          rects: m,
          platform: g
        } = r;
      if (null == i) return {};
      const d = a(l),
        p = {
          x: s,
          y: c
        },
        h = o(f),
        y = e(h),
        x = await g.getDimensions(i),
        w = "y" === h ? "top" : "left",
        v = "y" === h ? "bottom" : "right",
        b = m.reference[y] + m.reference[h] - p[h] - m.floating[y],
        R = p[h] - m.reference[h],
        A = await (null == g.getOffsetParent ? void 0 : g.getOffsetParent(i));
      let P = A ? "y" === h ? A.clientHeight || 0 : A.clientWidth || 0 : 0;
      0 === P && (P = m.floating[y]);
      const T = b / 2 - R / 2,
        O = d[w],
        D = P - x[y] - d[v],
        E = P / 2 - x[y] / 2 + T,
        L = u(O, E, D),
        k = null != t(f) && E != L && m.reference[y] / 2 - (E < O ? d[w] : d[v]) - x[y] / 2 < 0;
      return {
        [h]: p[h] - (k ? E < O ? O - E : D - E : 0),
        data: {
          [h]: L,
          centerOffset: E - L
        }
      };
    }
  }),
  g = ["top", "right", "bottom", "left"],
  d = g.reduce((t, e) => t.concat(e, e + "-start", e + "-end"), []),
  p = {
    left: "right",
    right: "left",
    bottom: "top",
    top: "bottom"
  };
function h(t) {
  return t.replace(/left|right|bottom|top/g, t => p[t]);
}
function y(n, r, i) {
  void 0 === i && (i = !1);
  const a = t(n),
    l = o(n),
    s = e(l);
  let c = "x" === l ? a === (i ? "end" : "start") ? "right" : "left" : "start" === a ? "bottom" : "top";
  return r.reference[s] > r.floating[s] && (c = h(c)), {
    main: c,
    cross: h(c)
  };
}
const x = {
  start: "end",
  end: "start"
};
function w(t) {
  return t.replace(/start|end/g, t => x[t]);
}
const v = function (e) {
  return void 0 === e && (e = {}), {
    name: "autoPlacement",
    options: e,
    async fn(o) {
      var r, i, a;
      const {
          rects: l,
          middlewareData: c,
          placement: f,
          platform: u,
          elements: m
        } = o,
        {
          crossAxis: g = !1,
          alignment: p,
          allowedPlacements: h = d,
          autoAlignment: x = !0,
          ...v
        } = e,
        b = void 0 !== p || h === d ? function (e, o, r) {
          return (e ? [...r.filter(n => t(n) === e), ...r.filter(n => t(n) !== e)] : r.filter(t => n(t) === t)).filter(n => !e || t(n) === e || !!o && w(n) !== n);
        }(p || null, x, h) : h,
        R = await s(o, v),
        A = (null == (r = c.autoPlacement) ? void 0 : r.index) || 0,
        P = b[A];
      if (null == P) return {};
      const {
        main: T,
        cross: O
      } = y(P, l, await (null == u.isRTL ? void 0 : u.isRTL(m.floating)));
      if (f !== P) return {
        reset: {
          placement: b[0]
        }
      };
      const D = [R[n(P)], R[T], R[O]],
        E = [...((null == (i = c.autoPlacement) ? void 0 : i.overflows) || []), {
          placement: P,
          overflows: D
        }],
        L = b[A + 1];
      if (L) return {
        data: {
          index: A + 1,
          overflows: E
        },
        reset: {
          placement: L
        }
      };
      const k = E.map(e => {
          const n = t(e.placement);
          return [e.placement, n && g ? e.overflows.slice(0, 2).reduce((t, e) => t + e, 0) : e.overflows[0], e.overflows];
        }).sort((t, e) => t[1] - e[1]),
        B = (null == (a = k.filter(e => e[2].slice(0, t(e[0]) ? 2 : 3).every(t => t <= 0))[0]) ? void 0 : a[0]) || k[0][0];
      return B !== f ? {
        data: {
          index: A + 1,
          overflows: E
        },
        reset: {
          placement: B
        }
      } : {};
    }
  };
};
const b = function (e) {
  return void 0 === e && (e = {}), {
    name: "flip",
    options: e,
    async fn(o) {
      var r;
      const {
          placement: i,
          middlewareData: a,
          rects: l,
          initialPlacement: c,
          platform: f,
          elements: u
        } = o,
        {
          mainAxis: m = !0,
          crossAxis: g = !0,
          fallbackPlacements: d,
          fallbackStrategy: p = "bestFit",
          fallbackAxisSideDirection: x = "none",
          flipAlignment: v = !0,
          ...b
        } = e,
        R = n(i),
        A = n(c) === c,
        P = await (null == f.isRTL ? void 0 : f.isRTL(u.floating)),
        T = d || (A || !v ? [h(c)] : function (t) {
          const e = h(t);
          return [w(t), e, w(e)];
        }(c));
      d || "none" === x || T.push(...function (e, o, r, i) {
        const a = t(e);
        let l = function (t, e, n) {
          const o = ["left", "right"],
            r = ["right", "left"],
            i = ["top", "bottom"],
            a = ["bottom", "top"];
          switch (t) {
            case "top":
            case "bottom":
              return n ? e ? r : o : e ? o : r;
            case "left":
            case "right":
              return e ? i : a;
            default:
              return [];
          }
        }(n(e), "start" === r, i);
        return a && (l = l.map(t => t + "-" + a), o && (l = l.concat(l.map(w)))), l;
      }(c, v, x, P));
      const O = [c, ...T],
        D = await s(o, b),
        E = [];
      let L = (null == (r = a.flip) ? void 0 : r.overflows) || [];
      if (m && E.push(D[R]), g) {
        const {
          main: t,
          cross: e
        } = y(i, l, P);
        E.push(D[t], D[e]);
      }
      if (L = [...L, {
        placement: i,
        overflows: E
      }], !E.every(t => t <= 0)) {
        var k, B;
        const t = ((null == (k = a.flip) ? void 0 : k.index) || 0) + 1,
          e = O[t];
        if (e) return {
          data: {
            index: t,
            overflows: L
          },
          reset: {
            placement: e
          }
        };
        let n = null == (B = L.filter(t => t.overflows[0] <= 0).sort((t, e) => t.overflows[1] - e.overflows[1])[0]) ? void 0 : B.placement;
        if (!n) switch (p) {
          case "bestFit":
            {
              var C;
              const t = null == (C = L.map(t => [t.placement, t.overflows.filter(t => t > 0).reduce((t, e) => t + e, 0)]).sort((t, e) => t[1] - e[1])[0]) ? void 0 : C[0];
              t && (n = t);
              break;
            }
          case "initialPlacement":
            n = c;
        }
        if (i !== n) return {
          reset: {
            placement: n
          }
        };
      }
      return {};
    }
  };
};
function R(t, e) {
  return {
    top: t.top - e.height,
    right: t.right - e.width,
    bottom: t.bottom - e.height,
    left: t.left - e.width
  };
}
function A(t) {
  return g.some(e => t[e] >= 0);
}
const P = function (t) {
    return void 0 === t && (t = {}), {
      name: "hide",
      options: t,
      async fn(e) {
        const {
            strategy: n = "referenceHidden",
            ...o
          } = t,
          {
            rects: r
          } = e;
        switch (n) {
          case "referenceHidden":
            {
              const t = R(await s(e, {
                ...o,
                elementContext: "reference"
              }), r.reference);
              return {
                data: {
                  referenceHiddenOffsets: t,
                  referenceHidden: A(t)
                }
              };
            }
          case "escaped":
            {
              const t = R(await s(e, {
                ...o,
                altBoundary: !0
              }), r.floating);
              return {
                data: {
                  escapedOffsets: t,
                  escaped: A(t)
                }
              };
            }
          default:
            return {};
        }
      }
    };
  },
  T = function (t) {
    return void 0 === t && (t = {}), {
      name: "inline",
      options: t,
      async fn(e) {
        const {
            placement: r,
            elements: i,
            rects: s,
            platform: u,
            strategy: m
          } = e,
          {
            padding: g = 2,
            x: d,
            y: p
          } = t,
          h = l(u.convertOffsetParentRelativeRectToViewportRelativeRect ? await u.convertOffsetParentRelativeRectToViewportRelativeRect({
            rect: s.reference,
            offsetParent: await (null == u.getOffsetParent ? void 0 : u.getOffsetParent(i.floating)),
            strategy: m
          }) : s.reference),
          y = (await (null == u.getClientRects ? void 0 : u.getClientRects(i.reference))) || [],
          x = a(g);
        const w = await u.getElementRects({
          reference: {
            getBoundingClientRect: function () {
              if (2 === y.length && y[0].left > y[1].right && null != d && null != p) return y.find(t => d > t.left - x.left && d < t.right + x.right && p > t.top - x.top && p < t.bottom + x.bottom) || h;
              if (y.length >= 2) {
                if ("x" === o(r)) {
                  const t = y[0],
                    e = y[y.length - 1],
                    o = "top" === n(r),
                    i = t.top,
                    a = e.bottom,
                    l = o ? t.left : e.left,
                    s = o ? t.right : e.right;
                  return {
                    top: i,
                    bottom: a,
                    left: l,
                    right: s,
                    width: s - l,
                    height: a - i,
                    x: l,
                    y: i
                  };
                }
                const t = "left" === n(r),
                  e = f(...y.map(t => t.right)),
                  i = c(...y.map(t => t.left)),
                  a = y.filter(n => t ? n.left === i : n.right === e),
                  l = a[0].top,
                  s = a[a.length - 1].bottom;
                return {
                  top: l,
                  bottom: s,
                  left: i,
                  right: e,
                  width: e - i,
                  height: s - l,
                  x: i,
                  y: l
                };
              }
              return h;
            }
          },
          floating: i.floating,
          strategy: m
        });
        return s.reference.x !== w.reference.x || s.reference.y !== w.reference.y || s.reference.width !== w.reference.width || s.reference.height !== w.reference.height ? {
          reset: {
            rects: w
          }
        } : {};
      }
    };
  };
const O = function (e) {
  return void 0 === e && (e = 0), {
    name: "offset",
    options: e,
    async fn(r) {
      const {
          x: i,
          y: a
        } = r,
        l = await async function (e, r) {
          const {
              placement: i,
              platform: a,
              elements: l
            } = e,
            s = await (null == a.isRTL ? void 0 : a.isRTL(l.floating)),
            c = n(i),
            f = t(i),
            u = "x" === o(i),
            m = ["left", "top"].includes(c) ? -1 : 1,
            g = s && u ? -1 : 1,
            d = "function" == typeof r ? r(e) : r;
          let {
            mainAxis: p,
            crossAxis: h,
            alignmentAxis: y
          } = "number" == typeof d ? {
            mainAxis: d,
            crossAxis: 0,
            alignmentAxis: null
          } : {
            mainAxis: 0,
            crossAxis: 0,
            alignmentAxis: null,
            ...d
          };
          return f && "number" == typeof y && (h = "end" === f ? -1 * y : y), u ? {
            x: h * g,
            y: p * m
          } : {
            x: p * m,
            y: h * g
          };
        }(r, e);
      return {
        x: i + l.x,
        y: a + l.y,
        data: l
      };
    }
  };
};
function D(t) {
  return "x" === t ? "y" : "x";
}
const E = function (t) {
    return void 0 === t && (t = {}), {
      name: "shift",
      options: t,
      async fn(e) {
        const {
            x: r,
            y: i,
            placement: a
          } = e,
          {
            mainAxis: l = !0,
            crossAxis: c = !1,
            limiter: f = {
              fn: t => {
                let {
                  x: e,
                  y: n
                } = t;
                return {
                  x: e,
                  y: n
                };
              }
            },
            ...m
          } = t,
          g = {
            x: r,
            y: i
          },
          d = await s(e, m),
          p = o(n(a)),
          h = D(p);
        let y = g[p],
          x = g[h];
        if (l) {
          const t = "y" === p ? "bottom" : "right";
          y = u(y + d["y" === p ? "top" : "left"], y, y - d[t]);
        }
        if (c) {
          const t = "y" === h ? "bottom" : "right";
          x = u(x + d["y" === h ? "top" : "left"], x, x - d[t]);
        }
        const w = f.fn({
          ...e,
          [p]: y,
          [h]: x
        });
        return {
          ...w,
          data: {
            x: w.x - r,
            y: w.y - i
          }
        };
      }
    };
  },
  L = function (t) {
    return void 0 === t && (t = {}), {
      options: t,
      fn(e) {
        const {
            x: r,
            y: i,
            placement: a,
            rects: l,
            middlewareData: s
          } = e,
          {
            offset: c = 0,
            mainAxis: f = !0,
            crossAxis: u = !0
          } = t,
          m = {
            x: r,
            y: i
          },
          g = o(a),
          d = D(g);
        let p = m[g],
          h = m[d];
        const y = "function" == typeof c ? c(e) : c,
          x = "number" == typeof y ? {
            mainAxis: y,
            crossAxis: 0
          } : {
            mainAxis: 0,
            crossAxis: 0,
            ...y
          };
        if (f) {
          const t = "y" === g ? "height" : "width",
            e = l.reference[g] - l.floating[t] + x.mainAxis,
            n = l.reference[g] + l.reference[t] - x.mainAxis;
          p < e ? p = e : p > n && (p = n);
        }
        if (u) {
          var w, v;
          const t = "y" === g ? "width" : "height",
            e = ["top", "left"].includes(n(a)),
            o = l.reference[d] - l.floating[t] + (e && (null == (w = s.offset) ? void 0 : w[d]) || 0) + (e ? 0 : x.crossAxis),
            r = l.reference[d] + l.reference[t] + (e ? 0 : (null == (v = s.offset) ? void 0 : v[d]) || 0) - (e ? x.crossAxis : 0);
          h < o ? h = o : h > r && (h = r);
        }
        return {
          [g]: p,
          [d]: h
        };
      }
    };
  },
  k = function (e) {
    return void 0 === e && (e = {}), {
      name: "size",
      options: e,
      async fn(r) {
        const {
            placement: i,
            rects: a,
            platform: l,
            elements: u
          } = r,
          {
            apply: m = () => {},
            ...g
          } = e,
          d = await s(r, g),
          p = n(i),
          h = t(i),
          y = "x" === o(i),
          {
            width: x,
            height: w
          } = a.floating;
        let v, b;
        "top" === p || "bottom" === p ? (v = p, b = h === ((await (null == l.isRTL ? void 0 : l.isRTL(u.floating))) ? "start" : "end") ? "left" : "right") : (b = p, v = "end" === h ? "top" : "bottom");
        const R = w - d[v],
          A = x - d[b];
        let P = R,
          T = A;
        if (y ? T = c(x - d.right - d.left, A) : P = c(w - d.bottom - d.top, R), !r.middlewareData.shift && !h) {
          const t = f(d.left, 0),
            e = f(d.right, 0),
            n = f(d.top, 0),
            o = f(d.bottom, 0);
          y ? T = x - 2 * (0 !== t || 0 !== e ? t + e : f(d.left, d.right)) : P = w - 2 * (0 !== n || 0 !== o ? n + o : f(d.top, d.bottom));
        }
        await m({
          ...r,
          availableWidth: T,
          availableHeight: P
        });
        const O = await l.getDimensions(u.floating);
        return x !== O.width || w !== O.height ? {
          reset: {
            rects: !0
          }
        } : {};
      }
    };
  };
export { m as arrow, v as autoPlacement, i as computePosition, s as detectOverflow, b as flip, P as hide, T as inline, L as limitShift, O as offset, l as rectToClientRect, E as shift, k as size };