import { Inject, Injectable } from '@angular/core';
import { WINDOW_REF } from 'libs/infrastructure/browser/window-ref.token';
import { DOCUMENT } from '@angular/common';
import { InfrastructureConfig } from 'libs/infrastructure';
import { AuthTokenService } from 'libs/infrastructure/authentication/auth-token.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';

import { take } from 'rxjs/operators';

@Injectable()
export class KnowledgeBaseWidgetService {
  private widget: any;

  constructor(
    @Inject(WINDOW_REF) private windowRef: Window,
    @Inject(DOCUMENT) private doc: HTMLDocument,
    private http: HttpClient,
    private authTokenService: AuthTokenService,
    @Inject(INFRASTRUCTURE_CONFIG)
    private infrastructureConfig: InfrastructureConfig
  ) {
    this.widget = (this.windowRef as any).FreshworksWidget;
  }

  public hideWidget() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widget('hide', 'launcher');
  }
  public loadWidget() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widget('boot');
  }

  public destroyWidget() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widget('destroy');
  }

  public authenticateWidget() {
    const token = this.authTokenService.getToken().access_token;

    const headers = new HttpHeaders().append(
      'Content-Type',
      'application/json'
    );

    const path =
      this.infrastructureConfig.environment.graphql_host +
      '/freshdesk/widget/token';

    this.http
      .post(
        path,
        {
          token
        },
        { headers, responseType: 'text' }
      )
      .pipe(take(1))
      .subscribe(token => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.widget('authenticate', {
          token
        });
      });
  }

  public showWidget() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.widget('show', 'launcher');
  }
}
