<nav
  class="navigation-drawer-list"
  [class.navigation-drawer-list--sub]="isSubList"
>
  <ng-container *ngFor="let item of items;">
    <app-navigation-drawer-item
      *ngIf="!item.hide"
      class="navigation-drawer-list__item"
      [item]="item"
      [narrow]="(navigationDrawer.narrowAndNotMobileView$ | async) && !isSubList"
    ></app-navigation-drawer-item>
  </ng-container>

  <ng-content></ng-content>
</nav>
