import { HeadingComponent } from './heading/heading.component';
import { HeaderV2Component } from './header-v2';
import { FooterV2Component } from './footer-v2';
import { NavigationItemV2Component } from './navigation-item-v2/navigation-item-v2.component';
import { ListNavigationV2Component } from './list-navigation-v2/list-navigation-v2.component';
import {
  AppClickAreaDirective,
  AppInputDirective,
  AttachmentsComponent,
  CheckboxComponent,
  CheckComponent,
  CheckIntermediateComponent,
  DateComponent,
  DropdownMultiselectComponent,
  DropdownSelectComponent,
  FlatSelectComponent,
  FormFieldComponent,
  FormFieldErrorComponent,
  FormFieldInfoComponent,
  FormFieldLabelComponent,
  FormFieldLangPickerComponent,
  InternationalPhoneComponent,
  ItemCheckComponent,
  MatchControlValidator,
  MultiselectComponent,
  PasswordComponent,
  RadioButtonComponent,
  RadioGroupComponent,
  RangeSliderComponent,
  SelectComponent,
  SimpleTextInputComponent,
  SliderComponent,
  SlideToggleComponent,
  TimeComponent
} from './form';

import { HeaderComponent } from './header/header.component';

import { FooterComponent, SocialsComponent } from './footer';

import { AddressFormComponent } from './address-form/address-form.component';

import { HightlightedBgComponent } from './hightlighted-bg/hightlighted-bg.component';
import { LogoComponent } from './logo/logo.component';
import { ListNavigationComponent } from './list-navigation/list-navigation.component';
import { MessageComponent } from './message/message.component';
import { NoContentComponent } from './no-content/no-content.component';
import { ScoreCircleComponent } from './score-circle/score-circle.component';
import { WizardStepDirective } from './wizard/wizard-step/wizard-step.directive';
import { WizardProgressComponent } from './wizard/wizard-progress/wizard-progress.component';
import { WizardComponent } from './wizard/wizard.component';
import { WizardFooterComponent } from './wizard/wizard-footer/wizard-footer.component';
import { HintComponent } from './hint/hint.component';
import {
  CookieBannerComponent,
  CustomCookieSettingsModalComponent
} from './cookie-banner';
import { InfoBoxComponent } from './info-box/info-box.component';
import { AvatarComponent } from './avatar/avatar.component';
import { PropertyParkingSpaceComponent } from './parking-spaces/property-parking-space.component';
import { digitalContractComponents } from './digital-contract';

import { CommentsComponent } from './comments/comments.component';

import { CalendarComponent } from './calendar/calendar.component';

import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';

import { InvoicePaymentMethodComponent } from './invoice-payment-method/invoice-payment-method.component';

import { SetPasswordFormComponent } from './set-password-form/set-password-form.component';

import {
  ConfirmationDialogComponent,
  ModalComponent,
  ModalContentComponent,
  ModalFooterComponent,
  RejectionDialogComponent
} from './modal';

import { ToastContainerComponent } from './toast';

import {
  TenantProfileFormComponent,
  KnockoutCriteriaComponent
} from './tenant-profile-form';

import { VerifyEmailBannerComponent } from './verify-email-banner/verify-email-banner.component';
import { PerformanceWarningBannerComponent } from './performance-warning-banner/performance-warning-banner.component';
import { ProfileDkTwoComponent } from './profile-dk-two/profile-dk-two.component';
import { GlobalCustomQuestionsComponent } from './global-custom-questions/global-custom-questions.component';

import { customQuestionsComponents } from './custom-questions';

import {
  AttachmentItemComponent,
  AttachmentPreviewComponent,
  AttachmentsListComponent,
  EditAttachmentComponent,
  FileUploadComponent
} from './attachment';

import {
  PageSizeSelectionComponent,
  PaginationComponent
} from './list-controls/components';

import { ListControlsComponent } from './list-controls/list-controls.component';
import { TableHeaderComponent } from './table/components/header/header.component';

import { EmbeddedLinkComponent } from './embedded-link/embedded-link.component';
import { ColorPickerComponent } from './form/controls/color-picker/color-picker.component';

import {
  ChatAttachmentComponent,
  ChatComponent,
  ChatContactItemComponent,
  ChatContactListComponent,
  ChatCreateModalComponent,
  ChatSettingsModalComponent,
  ChatTextMessageComponent,
  ConversationGeneralConfigFormComponent,
  ConversationRestrictionConfigFormComponent,
  MessageTemplateFormComponent,
  MessageTemplatesComponent,
  MessengerComponent
} from './messenger';

import { PropertyBannerComponent } from './property-banner/property-banner.component';
import { AdBlockInfoComponent } from './ad-block-info/ad-block-info.component';
import { NewUpdateBannerComponent } from './new-update-banner/new-update-banner.component';
import { searchProfileComponents } from './search-profile';
import { AppointmentsModalComponent } from './appointments/appointments-modal/appointments-modal.component';
import { AppointmentSelectionComponent } from './appointments/appointment-selection/appointment-selection.component';
import { ConfirmReasonModalComponent } from './confirm-reason-modal/confirm-reason-modal.component';
import { ConfirmReasonComponent } from './confirm-reason-modal/confirm-reason/confirm-reason.component';
import { PropertyInterestComponent } from './property-interest/property-interest.component';
import { LockableDataComponent } from './lockable-data/lockable-data.component';

// V2 components
import { ModalV2Component } from './modal-v2/modal-v2.component';
import {
  AttachmentItemV2Component,
  AttachmentPreviewV2Component,
  AttachmentsListV2Component
} from './attachment-v2';
import { UpdateModalComponent } from './update-modal/update-modal.component';
import { FieldsetDisabledComponent } from './fieldset-disabled/fieldset-disabled.component';

// Sub collections of components
const formComponents = [
  CheckComponent,
  CheckIntermediateComponent,
  CheckboxComponent,
  DateComponent,
  DropdownMultiselectComponent,
  DropdownSelectComponent,
  FlatSelectComponent,
  FormFieldComponent,
  FormFieldLabelComponent,
  FormFieldErrorComponent,
  FormFieldInfoComponent,
  FormFieldLangPickerComponent,
  ScoreCircleComponent,
  SimpleTextInputComponent,
  ConversationRestrictionConfigFormComponent,
  ConversationGeneralConfigFormComponent,
  SlideToggleComponent,
  ItemCheckComponent,
  ListNavigationV2Component,
  NavigationItemV2Component,
  PasswordComponent,
  RadioButtonComponent,
  RadioGroupComponent,
  ScoreCircleComponent,
  SelectComponent,
  SimpleTextInputComponent,
  SlideToggleComponent,
  SliderComponent,
  TimeComponent,
  InternationalPhoneComponent,
  MultiselectComponent,
  RangeSliderComponent
];
const modalComponents = [
  ModalFooterComponent,
  ModalContentComponent,
  ConfirmationDialogComponent,
  RejectionDialogComponent,
  ModalComponent,
  ModalV2Component,
  ChatCreateModalComponent,
  AppointmentsModalComponent,
  ConfirmReasonModalComponent
];

// Immediate export of services & configs
export { ModalService } from './modal';
export { ToastService } from './toast';
export {
  MessengerFilterService,
  MessengerNotificationService
} from './messenger';

export const legacyComponents = [
  ...formComponents,
  ...modalComponents,
  ColorPickerComponent,
  PageSizeSelectionComponent,
  AddressFormComponent,
  MessageComponent,
  LogoComponent,
  ListNavigationComponent,
  HightlightedBgComponent,
  FileUploadComponent,
  WizardStepDirective,
  WizardProgressComponent,
  WizardComponent,
  WizardFooterComponent,
  CommentsComponent,
  FooterComponent,
  SocialsComponent,
  HeaderComponent,
  NoContentComponent,
  MatchControlValidator,
  HintComponent,
  PaginationComponent,
  AttachmentsListComponent,
  AttachmentItemComponent,
  AttachmentPreviewComponent,
  AttachmentItemV2Component,
  AttachmentPreviewV2Component,
  AttachmentsListV2Component,
  AttachmentsComponent,
  CalendarComponent,
  ToastContainerComponent,
  AdBlockInfoComponent,
  InvoicePaymentMethodComponent,
  SetPasswordFormComponent,
  LoadingSpinnerComponent,
  CookieBannerComponent,
  CustomCookieSettingsModalComponent,
  VerifyEmailBannerComponent,
  PerformanceWarningBannerComponent,
  InfoBoxComponent,
  AppClickAreaDirective,
  AppInputDirective,
  AvatarComponent,
  EditAttachmentComponent,
  ListControlsComponent,
  TableHeaderComponent,
  EmbeddedLinkComponent,
  MessengerComponent,
  ChatComponent,
  ChatContactItemComponent,
  ChatContactListComponent,
  ChatTextMessageComponent,
  ChatAttachmentComponent,
  ChatCreateModalComponent,
  ChatSettingsModalComponent,
  PropertyBannerComponent,
  PropertyParkingSpaceComponent,
  ...digitalContractComponents,
  NewUpdateBannerComponent,
  MessageTemplatesComponent,
  MessageTemplateFormComponent,
  ProfileDkTwoComponent,
  GlobalCustomQuestionsComponent,
  ConfirmReasonComponent,
  AppointmentSelectionComponent,
  PropertyInterestComponent,
  HeadingComponent,
  UpdateModalComponent,
  HeaderV2Component,
  FooterV2Component,
  TenantProfileFormComponent,
  KnockoutCriteriaComponent,
  FieldsetDisabledComponent,
  LockableDataComponent,
  ...searchProfileComponents,
  ...customQuestionsComponents
];
