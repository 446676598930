import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { matchControlValidatorFactory } from 'libs/components/legacy/form';

import { ChangePasswordPayload } from '@ui/shared/models';

@Component({
  selector: 'app-set-password-form',
  templateUrl: './set-password-form.component.html',
  styleUrls: ['./set-password-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetPasswordFormComponent {
  @Input() ctaButtonLabel: string;
  @Input() isSaving: boolean;
  @Output() submitPassword = new EventEmitter<ChangePasswordPayload>();

  public formData: ChangePasswordPayload = {
    password: '',
    confirmedPassword: ''
  };

  constructor(private formBuilder: FormBuilder) {}

  public form = this.formBuilder.group({
    password: ['', matchControlValidatorFactory('confirmedPassword', true)],
    confirmedPassword: ['', matchControlValidatorFactory('password', true)]
  });

  public setPassword(): void {
    this.submitPassword.emit(this.form.value as ChangePasswordPayload);
  }
}
