import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appSideModalContent]'
})
export class SideModalContentDirective {
  @Input() name: string;
  @Input() navName: string;
  constructor(public template: TemplateRef<any>) {}
}
