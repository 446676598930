import { Component, Input } from '@angular/core';

import { CustomQuestion } from '@ui/shared/models';

@Component({
  selector: 'app-available-custom-question',
  templateUrl: './available-custom-question.component.html',
  styleUrls: ['./available-custom-question.component.scss']
})
export class AvailableCustomQuestionComponent {
  @Input() customQuestion: CustomQuestion;
  @Input() selected = false;
}
