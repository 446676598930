import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';
import { NavigationDrawerService } from 'libs/components/organisms/navigation/navigation-drawer';

@Component({
  selector: 'app-navigation-drawer-list',
  templateUrl: './navigation-drawer-list.component.html',
  styleUrls: ['./navigation-drawer-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationDrawerListComponent {
  @Input() items: NavigationItem[];
  @Input() isSubList = false;

  constructor(public navigationDrawer: NavigationDrawerService) {}
}
