import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { InfiniteScrollComponent } from 'libs/components/atoms/infinite-scroll/infinite-scroll.component';
import { DataTableCellDirective } from '../data-table/data-table-cell/data-table-cell.directive';
import {
  DataTableColumn,
  DataTableDefaultConfig
} from '../data-table/data-table.model';

@Component({
  selector: 'app-basic-table',
  templateUrl: './basic-table.component.html',
  styleUrls: ['./basic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasicTableComponent implements AfterContentInit {
  private _data: unknown[];
  @Input() headColumns: Partial<DataTableColumn>[];

  @Input() get data(): unknown[] {
    return this._data;
  }

  set data(value) {
    if (this.scrollable) this.updateViewToScroll(value);
    this._data = value;
  }

  @Input() isLoading: boolean;
  @Input() borderedTable = true;
  @Input() hoveredTable = true;
  @Input() smallTable: boolean;
  @Input() responsiveTable: boolean;
  @Input() stripedTable: boolean;
  @Input() darkTable: boolean;
  @Input() darkHeader: boolean;
  @Input() lightHeader: boolean;
  @Input() showIndex: boolean;
  @Input() scrollable: boolean;
  @Input() hasNextPage: boolean;
  @Input() noDataMessage = DataTableDefaultConfig.noDataMessage;
  @Input() uniqueIdentifier = 'id';
  @Input() maxScrollableHeight = '450px';
  @Output() scrollChange = new EventEmitter();
  @ContentChildren(DataTableCellDirective)
  templates: QueryList<DataTableCellDirective>;
  @ViewChild(InfiniteScrollComponent, { read: ElementRef })
  infiniteScrollRef: ElementRef<HTMLElement>;

  public cellTemplatesMap: { [key: string]: TemplateRef<HTMLDivElement> };

  public ngAfterContentInit() {
    this.cellTemplatesMap = this.templates.reduce((acc, cur) => {
      acc[cur.name] = cur.template;
      return acc;
    }, {});
  }

  public onScrollChange() {
    if (this.hasNextPage) {
      this.scrollChange.emit();
    }
  }

  private updateViewToScroll(data: unknown[]) {
    const isNewData = !data
      ?.map(d => d[this.uniqueIdentifier] as unknown)
      .includes(this.data?.[0]?.[this.uniqueIdentifier]);
    if (isNewData) this.infiniteScrollRef.nativeElement.scrollTop = 0;
  }
}
