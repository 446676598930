import { Pipe, PipeTransform } from '@angular/core';
import { ContactTag, TagOwnershipType } from '@ui/shared/models';
import { sortTags } from 'libs/components/molecules/tag/tag-helper-functions';

@Pipe({ name: 'appGetAssignableContactTags' })
export class GetAssignableContactTagsPipe implements PipeTransform {
  transform(
    contactTags: ContactTag[],
    allContactTags: ContactTag[]
  ): ContactTag[] {
    const nonDynamicTags = allContactTags?.filter(
      tag => tag?.tagOwnershipType !== TagOwnershipType.DYNAMIC
    );
    return sortTags(
      nonDynamicTags?.filter(
        ({ id }) => !contactTags?.some(tag => tag?.id === id)
      )
    );
  }
}
