<div class="custom-questions__form">
  <app-custom-questions-form
    *ngIf="customQuestions?.length > 0"
    [formControl]="control"
    [customQuestions]="customQuestions"
    (validityChange)="onFormValidityChange($event)"
  ></app-custom-questions-form>

  <app-hierarchical-root-question-form
    *ngIf="hierarchicalRootQuestions?.length > 0"
    [formControl]="hierarchicalRootQuestionControl"
    [hierarchicalRootQuestions]="hierarchicalRootQuestions"
    (validityChange)="onHierarchicalFormValidityChange($event)"
  ></app-hierarchical-root-question-form>
</div>

<div *ngIf="hierarchicalQuestionErrorMessage" class="custom-questions__error">
  <p>{{ hierarchicalQuestionErrorMessage | translate }}</p>
</div>
