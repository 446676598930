import { Component, forwardRef, Input } from '@angular/core';

import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { CustomQuestion, CustomQuestionType } from '@ui/shared/models';

import { BaseControl } from 'libs/components/legacy/form';

const BOOLEAN_OPTIONS = [
  { name: 'general.yes_l', value: true },
  { name: 'general.no_l', value: false }
];

@Component({
  selector: 'app-custom-questions-field',
  templateUrl: './custom-questions-field.component.html',
  styleUrls: ['./custom-questions-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomQuestionsFieldComponent),
      multi: true
    }
  ]
})
export class CustomQuestionsFieldComponent extends BaseControl<any> {
  @Input() customQuestion: CustomQuestion;

  public writeValue(value: any) {
    if (this.isMultiple) {
      this.value = value ? value : [];
      return;
    }

    this.value = value;
  }

  public get isMultiple() {
    return this.customQuestion.type === CustomQuestionType.MULTISELECT;
  }

  public get isBoolean() {
    return this.customQuestion.type === CustomQuestionType.BOOLEAN;
  }

  public get options() {
    return this.isBoolean ? BOOLEAN_OPTIONS : this.customQuestion.options;
  }
}
