import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';

import { CookiePreference } from '@ui/shared/models';

@Component({
  selector: 'app-custom-cookie-settings-modal',
  templateUrl: './custom-cookie-settings-modal.component.html',
  styleUrls: ['./custom-cookie-settings-modal.component.scss']
})
export class CustomCookieSettingsModalComponent implements OnInit {
  public form: FormGroup;
  public cookiePreference: CookiePreference;
  public isTenant = true;
  public showMoreRequired = false;
  public showMorePerformance = false;
  public showMoreFunctional = false;

  public get logo() {
    return (
      this.theme?.logo?.url || '/assets/images/logos/logo-immomio-main.svg'
    );
  }

  public get theme() {
    return this.themeService.getActiveTheme;
  }

  constructor(
    private fb: FormBuilder,
    private ngbActiveModal: NgbActiveModal,
    private themeService: ThemeService
  ) {}

  public ngOnInit() {
    this.form = this.fb.group({
      allowRequired: [true],
      allowFunctional: [false],
      allowPerformance: [false],
      lastUpdated: [null]
    });
    this.form.get('allowRequired').disable();
    if (this.cookiePreference) {
      this.form.patchValue(this.cookiePreference);
    }
  }

  public save() {
    this.ngbActiveModal.close({
      ...this.form.value,
      lastUpdated: new Date().getTime()
    });
  }

  public acceptAll() {
    this.ngbActiveModal.close({
      allowRequired: true,
      allowFunctional: true,
      allowPerformance: true,
      lastUpdated: new Date().getTime()
    });
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public toggleMoreRequired() {
    this.showMoreRequired = !this.showMoreRequired;
  }

  public toggleMorePerformance() {
    this.showMorePerformance = !this.showMorePerformance;
  }

  public toggleMoreFunctional() {
    this.showMoreFunctional = !this.showMoreFunctional;
  }
}
