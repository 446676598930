<header
  class="header-container outline-header ios-padding-left ios-padding-right"
>
  <nav class="navbar navbar-expand-lg navbar-light">
    <!-- Visible on large devices -->
    <div class="container-fluid p0 visible-lg-up">
      <div class="col p0 d-flex justify-content-start">
        <app-logo
          [tenantInInternalPool]="tenantInInternalPool"
          [alternativeUrl]="'/'"
          [targetHandling]="'_self'"
        ></app-logo>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <div class="d-flex justify-content-end">
          <ul class="navbar-nav justify-content-end align-items-center">
            <li class="nav-item">
              <app-lang-picker
                *ngIf="showLanguagePicker"
                [availableLanguages]="availableLanguages"
                [defaultLanguageCode]="defaultLanguageCode"
                [ghostButton]="true"
                [ngModel]="currentLanguageCode"
                (ngModelChange)="onLanguageChange($event)"
              ></app-lang-picker>
            </li>
            <li class="nav-item user-menu d-inline-block" ngbDropdown>
              <a
                class="nav-link align-items-center dropdown-toggle dropdown-toggle--frameless d-flex flex-row l-aligned--center p0"
                id="accountDropdown"
                ngbDropdownToggle
              >
                <div
                  class="header-account__container d-flex align-items-center"
                >
                  <span class="pr10 strong"
                    >{{'header.account_settings_l' | translate}}</span
                  >
                  <svg-icon
                    src="/assets/images/icons/nav-settings.svg"
                    [applyClass]="true"
                    [svgStyle]="{'width.px': 15}"
                    class="primary-text-svg-color path settings_icon"
                  ></svg-icon>
                </div>
              </a>
              <div
                ngbDropdownMenu
                class="dropdown-menu dropdown-menu--fully-rounded dropdown-menu--with-top-arrow"
                aria-labelledby="accountDropdown"
              >
                <div
                  class="dropdown-list pl10 pr10 d-flex flex-column align-items-center justify-content-center"
                >
                  <app-avatar
                    *ngIf="user?.profile"
                    [imgSrc]="user?.profile?.portrait | appImageSize:'S'"
                    name="{{ user?.profile?.firstname }} {{ user?.profile?.name }}"
                    class="mb10"
                    size="large"
                  ></app-avatar>
                  <span class="strong mb5"
                    >{{user?.profile | appFullName}}</span
                  >
                  <span>{{user?.email}}</span>
                  <hr class="w-100" />
                  <ng-container
                    *ngFor="let item of navigationUserDropdownItems"
                  >
                    <app-navigation-item-v2
                      class="dropdown-item"
                      [item]="item"
                      (trackEvent)="onTrackClick($event)"
                    ></app-navigation-item-v2>
                  </ng-container>
                  <div class="dropdown-item logout">
                    <app-button
                      class="p-0"
                      [type]="'primary-inverted'"
                      (clickEvent)="onLogout()"
                      >{{'general.logout_a' | translate}}</app-button
                    >
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>
