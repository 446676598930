import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[appTableHeader]'
})
export class DataTableHeaderDirective {
  @Input('appTableHeader') name: string;

  constructor(public template: TemplateRef<HTMLDivElement>) {}
}
