import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import {
  CustomQuestion,
  RootQuestionContainerModel,
  ContactTag
} from '@ui/shared/models';

@Component({
  selector: 'app-attach-custom-question-modal',
  templateUrl: './attach-custom-question-modal.component.html',
  styleUrls: ['./attach-custom-question-modal.component.scss']
})
export class AttachCustomQuestionModalComponent {
  @Input() customQuestions: CustomQuestion[];
  @Input() hierarchicalQuestions: RootQuestionContainerModel[];
  @Input() tags: ContactTag[];

  public selectedIds: string[] = [];

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public save() {
    const selectedHierarchicalQuestions = this.hierarchicalQuestions
      .map(({ id }) => id)
      .filter(id => this.selectedIds.includes(id));

    const selectedCustomQuestions = this.customQuestions
      .map(({ id }) => id)
      .filter(id => this.selectedIds.includes(id));

    this.ngbActiveModal.close({
      selectedHierarchicalQuestions,
      selectedCustomQuestions
    });
  }

  public isQuestionSelected(questionId: string) {
    return this.selectedIds.includes(questionId);
  }

  public toggle(questionId: string) {
    const index = this.selectedIds.findIndex(id => id === questionId);
    if (index > -1) {
      this.selectedIds.splice(index, 1);
    } else {
      this.selectedIds.push(questionId);
    }
  }
}
