<button
  [type]="buttonType"
  [class]="getClassName()"
  [ngClass]="{ 'h-100': useFullContainerSize, 'p0': zeroPadding, 'button--disable-hover': disableHover }"
  appElevation
  [elevation]="getElevation()"
  [clickable]="!isDisabled() && !isGhost() && elevationHoverEffect"
  [disabled]="isDisabled()"
  [attr.aria-label]="ariaLabel"
  (click)="click($event)"
>
  <span [class]="baseClass + '__content'"
    ><div
      *ngIf="iconLeft && !loading"
      [class]="getIconClassName(iconLeft)"
    ></div>
    <!-- TODO: render `inserted-content` only when no content is given (+ remove SCSS interim fix) -->
    <div
      [class]="baseClass + '__inserted-content'"
      [ngClass]="{ 'd-flex': useDisplayFlex }"
    >
      <ng-content></ng-content>
    </div>
    <div
      *ngIf="iconRight && !loading"
      [class]="getIconClassName(iconRight)"
    ></div>
    <ng-content select="[slot=after-button-content]"></ng-content>
    <app-loading-spinner *ngIf="loading"></app-loading-spinner
  ></span>
</button>
