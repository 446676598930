import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { ContextMenuTooltipPlacementEnum } from 'libs/components/molecules/context-menu/context-menu.enum';
import { ContactTag, PropertiesTag, SystemTag } from '@ui/shared/models';

@Component({
  selector: 'app-label-list',
  templateUrl: './label-list.component.html',
  styleUrls: ['./label-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LabelListComponent {
  @Input() assignedTags: Array<ContactTag | PropertiesTag | SystemTag>;
  @Input() assignableTags: Array<ContactTag | PropertiesTag | SystemTag>;
  @Input() isSystemTag = false;
  @Input() amountBeforeTruncation = 2;
  @Input() truncateLabels = true;
  @Input() readonly = false;
  @Input() multilineMode = false;
  @Input() isAddLabelObject = false;

  @Output() assignTag = new EventEmitter<
    ContactTag | PropertiesTag | SystemTag
  >();
  @Output() unassignTag = new EventEmitter<
    ContactTag | PropertiesTag | SystemTag
  >();

  public onAssignTag(tag: ContactTag | PropertiesTag | SystemTag): void {
    this.assignTag.emit(tag);
  }

  public onUnassignTag(tag: ContactTag | PropertiesTag | SystemTag): void {
    this.unassignTag.emit(tag);
  }

  protected readonly ContextMenuTooltipPlacementEnum =
    ContextMenuTooltipPlacementEnum;
}
