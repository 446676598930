<div class="row mt20">
  <div class="col">
    {{availableQuotaLabel | translate}}
    <span *ngIf="availableQuota || availableQuota === 0" class="semibold">
      {{availableQuota}}
    </span>
  </div>
</div>

<hr class="mb30" />

<div class="mt20">
  <div class="row uppercase-m quota-table__header-row">
    <div class="col">{{amountColumnLabel | translate}}</div>
    <div class="col text-start">{{priceColumnLabel | translate}}</div>
    <div class="col-3 text-center">{{cartColumnLabel | translate}}</div>
  </div>

  <div
    *ngFor="let quota of quotaPackages"
    [ngClass]="{'has-booked': quotaPackageCart.get(quota.id)}"
    class="row quota-table__item-row"
  >
    <div class="col text-center">{{quota.quantity}}</div>
    <div class="col text-start">
      {{ quota.postDiscountPrice | currency: 'EUR' }}
    </div>
    <div
      class="col-1 text-center d-flex align-items-center justify-content-center"
    >
      <i
        class="user-select-none color--primary-accent-dark"
        role="button"
        (click)="removeFromCart(quota.id)"
      >
        &minus;
      </i>
    </div>
    <div class="col-1 text-center semibold">
      {{ quotaPackageCart.get(quota.id) || 0 }}
    </div>
    <div
      class="col-1 text-center d-flex align-items-center justify-content-center"
    >
      <i
        role="button"
        class="icon icon--plus color--primary-accent-dark"
        (click)="addToCart(quota.id)"
      ></i>
    </div>
  </div>

  <hr />
  <div class="row">
    <div class="col-5">{{ subTotalLabel | translate }}</div>
    <div class="col semibold">{{ totalAmount | currency: 'EUR' }}</div>
    <div class="col justify-content-end">
      <app-button
        [disabled]="isLoading || quotaPackageCart.size === 0"
        (clickEvent)="onCheckout()"
      >
        {{checkoutLabel | translate}}
      </app-button>
    </div>
  </div>

  <hr class="mb30" />
</div>
