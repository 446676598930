import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { Store } from '@ngrx/store';

import { Subscription } from 'rxjs';
import { filter, pairwise } from 'rxjs/operators';

import * as fromBaseState from '../base-state';

@Injectable()
export class RoutingDetectorService {
  private subscription: Subscription;

  constructor(
    private router: Router,
    private store: Store<fromBaseState.BaseState>
  ) {}

  public start() {
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        pairwise()
      )
      .subscribe(([previous, current]) =>
        this.store.dispatch(new fromBaseState.RouteChange(previous, current))
      );
  }

  public stop() {
    this.subscription.unsubscribe();
  }
}
