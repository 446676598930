import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[appRipple]'
})
export class RippleDirective {
  @Input() appRipple = true;
  @Input() rippleColor = 'rgba(0, 0, 0, 0.2)'; // Default ripple color
  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2
  ) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.appRipple) {
      const ripple: HTMLSpanElement = this.renderer.createElement('span');
      this.renderer.addClass(ripple, 'ripple-effect');

      const rect: DOMRect = this.el.nativeElement.getBoundingClientRect();
      const size: number = Math.max(rect.width, rect.height);
      const x: number = event.clientX - rect.left - size / 2;
      const y: number = event.clientY - rect.top - size / 2;

      this.renderer.setStyle(ripple, 'width', `${size}px`);
      this.renderer.setStyle(ripple, 'height', `${size}px`);
      this.renderer.setStyle(ripple, 'top', `${y}px`);
      this.renderer.setStyle(ripple, 'left', `${x}px`);
      this.renderer.setStyle(ripple, 'background', this.rippleColor); // Set ripple color

      this.renderer.appendChild(this.el.nativeElement, ripple);

      setTimeout(() => {
        this.renderer.removeChild(this.el.nativeElement, ripple);
      }, 300); // Adjust the timeout to control the ripple duration
    }
  }
}
