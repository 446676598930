import { Inject, Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

import { InfrastructureConfig } from '../infrastructure-config.model';
import { INFRASTRUCTURE_CONFIG } from '../infrastructure-config.token';
import { WINDOW_REF } from './window-ref.token';

@Injectable()
export class MetaTagService {
  constructor(
    private meta: Meta,
    public translate: TranslateService,
    @Inject(WINDOW_REF) private windowRef: Window,
    @Inject(INFRASTRUCTURE_CONFIG)
    private infrastructureConfig: InfrastructureConfig
  ) {}

  /**
   * Add all necessary meta tags
   * Description, og:url, og:description, og:locale, og:image
   * Configure infrastructure for full functionality
   */
  public addTags() {
    const { meta } = this.infrastructureConfig.translationKeys;
    const { defaultLanguageCode } = this.infrastructureConfig.languages;
    this.meta.addTags([
      {
        name: 'description',
        content: this.translate.instant(meta.description || 'meta.description')
      },
      { property: 'og:url', content: this.windowRef.location.hostname },
      {
        property: 'og:description',
        content: this.translate.instant(meta.description || 'meta.description')
      },
      { property: 'og:locale', content: defaultLanguageCode },
      {
        property: 'og:image',
        content: `${this.windowRef.location.hostname}/assets/images/logos/logo-immomio-main.svg`
      }
    ]);
  }

  /**
   * Add Meta Tag
   * @param name meta tag name
   * @param content value of meta tag
   */
  public addTag(name: string, content: string) {
    this.meta.addTag({ name, content });
  }

  /**
   * Add Property Meta Tag
   * @param property facebook open graph property name
   * @param content value of open graph property
   */
  public addPropertyTag(property: string, content: string) {
    this.meta.addTag({ property, content });
  }
}
