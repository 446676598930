import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-wizard-footer',
  templateUrl: './wizard-footer.component.html',
  styleUrls: ['./wizard-footer.component.scss']
})
export class WizardFooterComponent {
  @Input() disabled: boolean;
  @Input() hasNext: boolean;
  @Input() hasPrevious: boolean;
  @Input() hasFinish: boolean;
  @Input() hideRequiredHint: boolean;
  @Input() completeActionText: string;

  @Output() nextStep = new EventEmitter();
  @Output() previousStep = new EventEmitter();
  @Output() completeStep = new EventEmitter();

  public onNextStep() {
    this.nextStep.emit();
  }

  public onPreviousStep() {
    this.previousStep.emit();
  }

  public onCompleteStep() {
    this.completeStep.emit();
  }
}
