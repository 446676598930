import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IconTypeEnum } from '@ui/shared/models';
import { ButtonTypeEnum } from 'libs/components/atoms/button/button.enum';

export interface SegmentItem {
  id?: string;
  label?: string;
  icon?: IconTypeEnum;
}

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentComponent),
      multi: true
    }
  ]
})
export class SegmentComponent {
  @Input() segments: SegmentItem[] = [];
  @Input() selectedSegment: SegmentItem = null;
  @Output() selectedSegmentChange = new EventEmitter<SegmentItem>();

  private _value: SegmentItem;

  get value(): SegmentItem {
    return this._value;
  }

  set value(newValue: SegmentItem) {
    this._value = newValue;
    this.selectedSegmentChange.emit(this._value);
    this.onChange(this._value);
  }

  writeValue(value: SegmentItem) {
    this._value = value;
  }

  private onChange = (value: unknown) => value;
  private onTouch = (value: unknown) => value;

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  selectSegment(segment: SegmentItem): void {
    if (segment.id !== this.selectedSegment.id) {
      this.value = segment;
      this.selectedSegment = segment;
    }
  }

  protected readonly ButtonTypeEnum = ButtonTypeEnum;
}
