import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-load-more',
  template: `
    <ng-content></ng-content>
    <div
      *ngIf="showIndicator && !loading"
      class="d-flex justify-content-center"
    >
      <app-button [type]="'link'" (clickEvent)="onLoadMore()">{{
        'LOAD_MORE_L' | translate
      }}</app-button>
    </div>
    <ng-container *ngIf="loading">
      <div
        class="skeleton"
        [style]="skeletonStyle"
        *ngFor="let i of [].constructor(5)"
      ></div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadMoreComponent {
  @Input() showIndicator = true;
  @Input() loading = false;
  @Output() loadMore = new EventEmitter();
  public skeletonStyle = {
    height: '25px',
    margin: '5px 0'
  };

  public onLoadMore() {
    this.loadMore.emit();
  }
}
