import { Component, Input, forwardRef, ViewChild, OnInit } from '@angular/core';

import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AppFormFieldControl } from '../../form-field/form-field-control/form-field-control';
import { BaseControl } from '../base-control';

@UntilDestroy()
@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => CheckboxComponent)
    }
  ]
})
export class CheckboxComponent extends BaseControl<boolean> implements OnInit {
  @Input() label: string;
  @Input() innerHTML: string;
  @Input() hideActiveValue = false;
  @Input() buttonDesign = false;

  @Input() showLargerCheckboxes = false;

  @ViewChild(NgControl, { static: true }) ngControl: NgControl;

  ngOnInit() {
    this.ngControl.statusChanges
      .pipe(untilDestroyed(this))
      .subscribe(() => this.stateChanges.next());
  }

  toggleValue() {
    if (this.disabled) return;

    this.value = !this.value;
  }
}
