<app-card
  *ngIf="projectData"
  [class]="getClassName()"
  (click)="!pageView ? click($event) : null"
  [elevation]="elevation"
  [clickable]="!pageView"
  [padding]="'none'"
>
  <ng-container>
    <div [class]="baseClass + '__cover' ">
      <ng-container *ngIf="!pageView">
        <ng-container *ngTemplateOutlet="badges"></ng-container>
      </ng-container>
      <div [class]="baseClass + '__cover-wrapper'">
        <app-image
          [defaultSrc]="hasPictures ? projectData?.data?.titleImage?.url : imagePlaceholder"
          alt="{{ hasPictures && ('property.fallback_image_l' | translate) }}"
        ></app-image>
        <div
          *ngIf="hasPictures"
          [class]="baseClass + '__cover-background'"
          [style.background-image]="getBackgroundImage"
        ></div>
      </div>
      <app-project-status-pipeline
        *ngIf="projectData?.status && !pageView"
        [class]="baseClass + '__pipeline'"
        [status]="projectData?.status"
        [pageView]="pageView"
      ></app-project-status-pipeline>
    </div>
    <div [class]="baseClass + '__content'" *ngIf="!limitInformation">
      <ng-container *ngIf="pageView">
        <ng-container *ngTemplateOutlet="badges"></ng-container>
      </ng-container>
      <div
        class="semibold"
        *ngIf="!pageView"
        [ngStyle]="{'font-size': titleFontSize}"
      >
        {{projectData?.data.name}}
      </div>
      <ng-container *ngTemplateOutlet="details"></ng-container>
    </div>
  </ng-container>
</app-card>

<!-- PROJECT BADGES + CONTEXT MENU TEMPLATE -->
<ng-template #badges>
  <div [class]="baseClass + '__cover-badges row'" *ngIf="!limitInformation">
    <div class="col-10 d-flex flex-wrap pe-0 position-static">
      <app-badge
        [color]="'primary-accent'"
        [icon]="'clipboard'"
        *ngIf="pageView"
        >{{projectData.status | translate}}</app-badge
      >
      <app-badge [color]="'light-blue'" [icon]="'object'">
        {{ (projectData?.propertyInformation?.housingInformation?.rentedCount ||
        0) }} / {{
        (projectData?.propertyInformation?.housingInformation?.totalCount || 0)
        }}
      </app-badge>
      <app-badge [color]="'light-blue'" [icon]="'parking'">
        {{ (projectData?.propertyInformation?.parkingInformation?.rentedCount ||
        0) }} / {{
        (projectData?.propertyInformation?.parkingInformation?.totalCount || 0)
        }}
      </app-badge>
      <app-badge [color]="'light-blue'" [icon]="'building'">
        {{ (projectData?.propertyInformation?.commercialInformation?.rentedCount
        || 0) }} / {{
        (projectData?.propertyInformation?.commercialInformation?.totalCount ||
        0) }}
      </app-badge>
    </div>
    <div class="col-2 d-flex justify-content-end position-static">
      <app-context-menu
        *ngIf="enableContextMenu"
        placement="left"
        [ghostButton]="pageView"
        [closeOnItemClick]="true"
        [allowSideNavForMobileView]="false"
      >
        <div menu-content class="d-flex flex-column">
          <app-context-menu-item
            [iconLeft]="'edit'"
            (click)="editClick(projectData?.id); $event.stopPropagation()"
            >{{'projects.edit' | translate}}</app-context-menu-item
          >
          <app-context-menu-item
            *ngIf="allowedToDeleteProjects"
            [iconLeft]="'delete'"
            (click)="deleteClick(projectData?.id); $event.stopPropagation()"
            >{{'projects.delete' | translate}}</app-context-menu-item
          >
          <app-context-menu-item
            *ngIf="!pageView"
            [iconLeft]="'copy'"
            (click)="copyClick(projectData?.id); $event.stopPropagation()"
            >{{'projects.copy' | translate}}</app-context-menu-item
          >
          <app-context-menu-item
            [hover]="false"
            [stickToBottom]="true"
            [withTopSpacing]="true"
          >
            <app-button
              [size]="'small'"
              [iconLeft]="'copy'"
              (click)="copyProjectRegistrationLinkClick(projectData?.registrationUrl); $event.stopPropagation()"
            >
              {{ 'projects.copy_project_registration_link_a' | translate }}
            </app-button>
          </app-context-menu-item>
        </div>
      </app-context-menu>
    </div>
  </div>
</ng-template>

<!-- PROJECT DETAILS TEMPLATE -->
<ng-template #details>
  <ng-container *ngIf="pageView">
    <div class="semibold" [ngStyle]="{'font-size': titleFontSize}">
      {{projectData?.data.name}}
    </div>
    <div class="title-l" *ngIf="pageView">{{ projectData?.data.slogan }}</div>
  </ng-container>

  <div [class]="baseClass + '__content-date'">
    <div
      class="icon icon--calendar d-flex align-items-center"
      *ngIf="projectData?.data?.availableFrom"
    >
      <span class="ms-2">{{'projects.date_available' | translate}}</span>
    </div>
    <div>{{projectData?.data?.availableFrom | appDateTime}}</div>
  </div>
  <div class="d-flex flex-wrap mx-0">
    <!-- Price -->
    <ng-container
      [ngTemplateOutlet]="section"
      [ngTemplateOutletContext]="{icon:'money-bag', data:projectData?.propertyInformation?.countInformation?.totalRent, additional:'€', range: true}"
      *ngIf="projectData?.propertyInformation?.countInformation"
    >
    </ng-container>
    <!-- Area size -->
    <ng-container
      [ngTemplateOutlet]="section"
      [ngTemplateOutletContext]="{icon:'area-size', data:projectData?.propertyInformation?.countInformation?.size, additional:'㎡', range: true}"
      *ngIf="projectData?.propertyInformation?.countInformation"
    >
    </ng-container>
    <!-- Number of rooms -->
    <ng-container
      [ngTemplateOutlet]="section"
      [ngTemplateOutletContext]="{icon:'rooms', data:projectData?.propertyInformation?.countInformation?.rooms, additional:'ROOMS_L', range: true}"
      *ngIf="projectData?.propertyInformation?.countInformation"
    >
    </ng-container>
    <ng-container
      [ngTemplateOutlet]="section"
      [ngTemplateOutletContext]="{icon:'eye-open', data:projectData.applyProcessInformation.prospects}"
      *ngIf="projectData?.applyProcessInformation"
    >
    </ng-container>
    <!-- Applicants -->
    <ng-container
      [ngTemplateOutlet]="section"
      [ngTemplateOutletContext]="{icon:'user', data:projectData.applyProcessInformation?.applicants}"
      *ngIf="projectData?.applyProcessInformation"
    >
    </ng-container>
  </div>
  <ng-template
    #section
    let-icon="icon"
    let-data="data"
    let-additional="additional"
    let-range="range"
    let-translate="translate"
  >
    <div [class]="baseClass + '__content-section p-0'">
      <div [class]="'icon icon--' + icon"></div>
      <span *ngIf="range;">
        {{icon === 'money-bag' ? formatCurrencyRange(data) : icon ===
        'area-size' ? formatAreaRange(data) : getFromToRange(data)}}
        <ng-container *ngIf="additional"
          >{{additional | translate}}</ng-container
        ></span
      >
      <span *ngIf="!range">{{data}}</span>
    </div>
  </ng-template>
</ng-template>
