<app-modal>
  <div class="modal-l custom-question__modal">
    <app-modal-content>
      <div class="modal-body custom-question__content">
        <div class="custom-question__header">
          <h3 class="title-xl semibold custom-question__title mb24">
            {{'custom_questions.preview_question_l' | translate}}
          </h3>
        </div>

        <div class="custom-question__form">
          <app-custom-question-form
            [readonly]="true"
            [global]="global"
            [formControl]="questionControl"
          ></app-custom-question-form>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer custom-question__footer">
        <app-button [type]="'light-bordered'" (clickEvent)="dismiss()">
          {{'general.cancel_a' | translate}}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal>
