import { createSelector } from '@ngrx/store';

import * as fromReducers from 'libs/infrastructure/base-state/reducers';
import { ConversationSenderTypes } from 'libs/components/legacy/messenger/model/enum';
import * as fromMessengerReducer from './messenger.reducer';

export const getMessengerState = createSelector(
  fromReducers.getBaseState,
  (state: fromReducers.BaseState) => state.messenger
);

export const getConversationList = createSelector(
  getMessengerState,
  fromMessengerReducer.getConversationList
);

export const getConversationListPage = createSelector(
  getMessengerState,
  fromMessengerReducer.getConversationListPage
);

export const getConversationListActionState = createSelector(
  getMessengerState,
  fromMessengerReducer.getConversationListActionState
);

export const getActiveConversationId = createSelector(
  getMessengerState,
  fromMessengerReducer.getActiveConversationId
);

export const getActiveConversation = createSelector(
  getActiveConversationId,
  getConversationList,
  (conversationId, conversations) =>
    conversations.find(conversation => conversation.id === conversationId)
);

export const getConversationMessagesPage = createSelector(
  getMessengerState,
  fromMessengerReducer.getConversationMessagesPage
);

export const getConversationDetails = createSelector(
  getMessengerState,
  fromMessengerReducer.getConversationDetails
);

export const getConversationMessages = createSelector(
  getMessengerState,
  fromMessengerReducer.getConversationMessages
);

export const getSender = createSelector(
  getMessengerState,
  fromMessengerReducer.getSender
);

export const isSenderLandlord = createSelector(
  getMessengerState,
  state => state.sender === ConversationSenderTypes.LANDLORD
);

export const getConversationMessagesCount = createSelector(
  getConversationList,
  conversations =>
    conversations &&
    conversations
      .filter(c => c && c.unreadMessages > 0)
      .reduce((sum, curr) => sum + curr.unreadMessages, 0)
);

export const getConversationMessagesAttach = createSelector(
  getMessengerState,
  fromMessengerReducer.getConversationMessagesAttach
);

export const getConversationMessagesActionState = createSelector(
  getMessengerState,
  fromMessengerReducer.getConversationMessagesActionState
);

export const getConversationDetailsActionState = createSelector(
  getMessengerState,
  fromMessengerReducer.getConversationDetailsActionState
);

export const getBulkSendMessageActionState = createSelector(
  getMessengerState,
  fromMessengerReducer.getBulkSendMessageActionState
);

export const getUnreadMessageCount = createSelector(
  getMessengerState,
  fromMessengerReducer.getUnreadMessageCount
);

export const getMessageCount = createSelector(
  getConversationMessages,
  messages => {
    return messages ? messages.filter(message => message.read).length : 0;
  }
);

export const getParticipatedAgents = createSelector(
  getMessengerState,
  fromMessengerReducer.getParticipatedAgents
);

export const getParticipatedAgentsActionState = createSelector(
  getMessengerState,
  fromMessengerReducer.getParticipatedAgentsActionState
);

export const getMessageTemplates = createSelector(
  getMessengerState,
  fromMessengerReducer.getMessageTemplates
);

export const getMessageTemplatesActionState = createSelector(
  getMessengerState,
  fromMessengerReducer.getMessageTemplatesActionState
);

export const getMessageTemplatesPage = createSelector(
  getMessengerState,
  fromMessengerReducer.getMessageTemplatesPage
);

export const getParsedMessageTemplates = createSelector(
  getMessengerState,
  fromMessengerReducer.getParsedMessageTemplates
);

export const getParsedMessageTemplatesActionState = createSelector(
  getMessengerState,
  fromMessengerReducer.getParsedMessageTemplatesActionState
);

export const getParsedMessageTemplatesPage = createSelector(
  getMessengerState,
  fromMessengerReducer.getParsedMessageTemplatesPage
);

export const getCreateTemplateActionState = createSelector(
  getMessengerState,
  fromMessengerReducer.getCreateTemplateActionState
);

export const getUpdateTemplateActionState = createSelector(
  getMessengerState,
  fromMessengerReducer.getUpdateTemplateActionState
);

export const getDeleteTemplateActionState = createSelector(
  getMessengerState,
  fromMessengerReducer.getDeleteTemplateActionState
);

export const getConversationArchived = createSelector(
  getMessengerState,
  fromMessengerReducer.getConversationArchived
);

export const getSearchedProperty = createSelector(
  getMessengerState,
  fromMessengerReducer.getSearchedProperty
);
