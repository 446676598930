import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';

import {
  UserSettingsConversationConfig,
  MessageTemplate
} from 'libs/components/legacy/messenger/model/interface';
import { DEFAULT_CONVERSATION_RESTRICTION_CONFIG } from 'libs/components/legacy/messenger/model/enum';
import { stripTypenameProperty } from 'libs/utils';

@Component({
  selector: 'app-chat-settings',
  templateUrl: './chat-settings-modal.component.html',
  styleUrls: ['./chat-settings-modal.component.scss']
})
export class ChatSettingsModalComponent implements OnInit {
  public restrictionConfigFormControl: FormControl;
  public generalConfigFormControl: FormControl;
  public userSettingsConversationConfig: UserSettingsConversationConfig;
  public activeTab: string;
  public conversationMessageTemplateSubstitutionTags: any;
  public newTemplateData: MessageTemplate;

  public save: (messageTemplate: UserSettingsConversationConfig) => void;

  constructor(private ngbActiveModal: NgbActiveModal) {}

  public saveControlValues() {
    return this.save({
      ...this.generalConfigFormControl.value,
      restrictionConfig: this.restrictionConfigFormControl.value
    });
  }

  public ngOnInit() {
    const { restrictionConfig, ...generalConfig } =
      this.userSettingsConversationConfig || {};

    this.generalConfigFormControl = new FormControl(
      stripTypenameProperty(generalConfig)
    );

    const strippedRestrictionConfig = stripTypenameProperty(restrictionConfig);
    this.restrictionConfigFormControl = new FormControl(
      restrictionConfig &&
      !Object.values(strippedRestrictionConfig).every(c => !c)
        ? strippedRestrictionConfig
        : DEFAULT_CONVERSATION_RESTRICTION_CONFIG
    );
  }

  public controlsNeverTouched() {
    return !(
      this.restrictionConfigFormControl.touched ||
      this.generalConfigFormControl.touched
    );
  }

  public close() {
    this.ngbActiveModal.close();
  }

  public dismiss() {
    this.ngbActiveModal.dismiss();
  }
}
