import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NameValue } from '@ui/shared/models';

@Component({
  selector: 'app-confirm-reason',
  templateUrl: './confirm-reason.component.html',
  styleUrls: ['./confirm-reason.component.scss']
})
export class ConfirmReasonComponent {
  @Input() reasonTypeModel: string;
  @Input() otherReasonText: string;
  @Input() reasons: NameValue[];

  @Output() reasonTypeModelChange = new EventEmitter<string>();
  @Output() otherReasonTextChange = new EventEmitter<string>();

  public get showText() {
    return this.reasonTypeModel === 'OTHER_REASON';
  }

  public onReasonTypeModelChange(value: string) {
    this.reasonTypeModelChange.emit(value);
  }

  public onOtherReasonTextChange(value: string) {
    this.otherReasonTextChange.emit(value);
  }
}
