import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { desktopBreakingPoints } from 'libs/components/legacy/messenger/model/interface';
import { BreakpointObserver } from '@angular/cdk/layout';

@Injectable()
export class PageTitleService {
  constructor(
    private router: Router,
    private title: Title,
    private translate: TranslateService,
    private observer: BreakpointObserver
  ) {}

  public run(rootRoute: ActivatedRoute, translate = true) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => rootRoute),
        map(route => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(
          route =>
            route.data as Observable<{ title: string; mobileTitle: string }>
        ),
        withLatestFrom(this.observer.observe(desktopBreakingPoints))
      )
      .subscribe(([routeData, results]) => {
        if ((results.matches && routeData.title) || routeData.mobileTitle) {
          const title = translate
            ? this.translate.instant(
                results.matches ? routeData.title : routeData.mobileTitle
              )
            : results.matches
              ? routeData.title
              : routeData.mobileTitle;
          this.title.setTitle(title);
        }
      });
  }
}
