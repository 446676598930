<app-modal>
  <div class="modal-l attach-custom-question__modal">
    <app-modal-content>
      <div class="modal-body attach-custom-question__content">
        <div class="attach-custom-question__header">
          <p class="uppercase-m semibold attach-custom-question__title">
            {{ 'custom_questions.desired_tenant_profile_l' | translate }}
          </p>
          <h3 class="title-xl semibold attach-custom-question__sub-title mb16">
            {{ 'custom_questions.add_custom_question_l' | translate }}
          </h3>
        </div>

        <div class="attach-custom-question__list pb12">
          <div class="attach-custom-question__list-content">
            <div class="d-flex">
              <div>
                <app-form-field-label
                  >{{ 'custom_questions.select_custom_questions_l' | translate
                  }}</app-form-field-label
                >
              </div>
              <app-hint placement="bottom" class="pl6"
                >{{ 'custom_questions.select_custom_questions_hint' | translate
                }}</app-hint
              >
            </div>

            <div class="mb12" *ngFor="let customQuestion of customQuestions">
              <app-available-custom-question
                [customQuestion]="customQuestion"
                [selected]="isQuestionSelected(customQuestion.id)"
                (click)="toggle(customQuestion.id)"
              >
              </app-available-custom-question>
            </div>

            <div
              class="mb12"
              *ngFor="let hierarchicalQuestion of hierarchicalQuestions"
            >
              <app-available-hierarchical-question
                [hierarchicalQuestion]="hierarchicalQuestion"
                [tags]="tags"
                (selectedChange)="toggle(hierarchicalQuestion.id)"
              >
              </app-available-hierarchical-question>
            </div>
          </div>
        </div>
      </div>
    </app-modal-content>

    <app-modal-footer>
      <div class="modal-footer attach-custom-question__footer">
        <app-button [type]="'light-bordered'" (clickEvent)="dismiss()">
          {{ 'general.cancel_a' | translate }}</app-button
        >
        <app-button (clickEvent)="save()" [disabled]="!selectedIds.length"
          >{{ 'custom_questions.add_question_a' | translate }}</app-button
        >
      </div>
    </app-modal-footer>
  </div>
</app-modal>
