import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as fromBaseState from 'libs/infrastructure/base-state';
import { Observable } from 'rxjs';
import { isValueNotNullAndUndefined } from 'libs/utils';

import { INFRASTRUCTURE_CONFIG } from 'libs/infrastructure/infrastructure-config.token';
import { InfrastructureConfig } from 'libs/infrastructure';

import {
  AvailableLanguageCodesEnum,
  HierarchicalQuestion,
  HierarchicalQuestionAnswer,
  HierarchicalQuestionAnswerModel,
  HierarchicalQuestionBase,
  HierarchicalQuestionRangedAnswerData,
  HierarchicalQuestionType,
  HierarchicalResponseContainer,
  HierarchicalRootQuestionResponse,
  PropertySearcherAnswer,
  RootQuestion,
  RootQuestionContainer
} from '@ui/shared/models';

import moment from 'moment';

@Injectable()
export class HierarchicalQuestionService {
  public get questionControlConfig() {
    return {
      id: [null],
      data: [null],
      answers: [[]],
      userResponse: this.fb.group({
        response: [null, Validators.required],
        answerIds: [null, Validators.required],
        comment: [null]
      })
    };
  }

  constructor(
    private fb: FormBuilder,
    private store: Store<fromBaseState.AppState>,
    private router: Router,
    @Inject(INFRASTRUCTURE_CONFIG) private config: InfrastructureConfig
  ) {}

  private get isTenantApp() {
    return this.config.environment.app_name === 'tenant';
  }

  public sort(
    a:
      | HierarchicalQuestionBase
      | HierarchicalQuestionAnswer
      | HierarchicalQuestionAnswerModel,
    b:
      | HierarchicalQuestionBase
      | HierarchicalQuestionAnswer
      | HierarchicalQuestionAnswerModel
  ) {
    if ((a?.data?.order ?? 1) < (b?.data?.order ?? 0)) return -1;
    if ((a?.data?.order ?? 1) > (b?.data?.order ?? 0)) return 1;
    return 0;
  }

  public getAnsweredRootQuestions(
    rootQuestionContainers: RootQuestionContainer[]
  ) {
    return (rootQuestionContainers || []).map(rootQuestionContainer => {
      const rootQuestion = rootQuestionContainer.rootQuestion;
      const responses = rootQuestionContainer.responses;
      const questions = this.getAnsweredQuestions(
        rootQuestion.questions,
        responses
      ).sort(this.sort);

      return {
        ...rootQuestionContainer,
        rootQuestion: {
          ...rootQuestion,
          questions: this.sortQuestionsByHierarchy(
            questions,
            rootQuestion.mainQuestionId
          )
        },
        responses
      };
    });
  }

  /**
   * The question list from BE is unsorted. We display the questions in the the order they are in the list. When we
   * display a sub-question based on an answer, we want to show it below the current question and
   * not above. Otherwise the user answers a question and the next question might be shown above the current question.
   * So sort the list accordingly: the deeper a question is in the hierarchy,
   * the further down it is sorted inside the list.
   * @param questions
   * @param mainQuestionId
   * @private
   */
  private sortQuestionsByHierarchy(
    questions: HierarchicalQuestion[],
    mainQuestionId: string
  ) {
    const mainQuestion = questions.find(q => q.id === mainQuestionId);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.moveSubQuestionToEndByHierarchy(
      mainQuestion.answers,
      questions,
      questions
    );
  }

  private moveSubQuestionToEndByHierarchy(
    answers: HierarchicalQuestionAnswer[],
    currentList: HierarchicalQuestion[],
    allQuestions: HierarchicalQuestion[]
  ) {
    const subQuestionIds = answers
      .map(a => a.questionIds)
      .reduce((a, b) => a.concat(b), []);
    const subQuestions = subQuestionIds.map(id =>
      allQuestions.find(q => q.id === id)
    );
    if (subQuestions.length === 0) {
      return currentList;
    }
    const sortedQuestions = this.moveSubQuestionsToEnd(
      subQuestions,
      currentList
    );
    const allSubAnswers = subQuestions
      .map(sq => sq.answers)
      .reduce((a, b) => a.concat(b), []);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.moveSubQuestionToEndByHierarchy(
      allSubAnswers,
      sortedQuestions,
      allQuestions
    );
  }

  private moveSubQuestionsToEnd(
    subQuestions: HierarchicalQuestion[],
    sorted: HierarchicalQuestion[]
  ) {
    const list = [...sorted];
    subQuestions.forEach(q => {
      const index = list.indexOf(q);
      if (index > -1) {
        list.splice(index, 1);
      }
      list.push(q);
    });
    return list;
  }

  public getRangedValue(
    value: any,
    type: HierarchicalQuestionType,
    convertToFloat?: boolean
  ): number {
    switch (type) {
      case HierarchicalQuestionType.RANGE_DATE: {
        return moment(value).toDate().getTime();
      }
      default: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return convertToFloat ? parseFloat(value) : value;
      }
    }
  }

  public getAllValidAnswersFromRootQuestion(
    allQuestions: HierarchicalQuestion[],
    mainQuestionId
  ) {
    const rootQuestion = allQuestions.find(q => q.id === mainQuestionId);
    const answers = this.getAnswersWithValidResponse(
      rootQuestion.userResponse,
      rootQuestion
    );
    return answers.concat(...this.getAllValidAnswers(answers, allQuestions));
  }

  private getAllValidAnswers(
    answers: HierarchicalQuestionAnswer[],
    allQuestions: HierarchicalQuestion[]
  ) {
    const subQuestionIds = answers
      .map(a => a.questionIds)
      .reduce((a, b) => a.concat(b), []);
    const subQuestions = subQuestionIds.map(id =>
      allQuestions.find(q => q.id === id)
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return subQuestions.length === 0
      ? []
      : subQuestions.map(q => {
          const subQuestionAnswers = this.getAnswersWithValidResponse(
            q.userResponse,
            q
          );
          return subQuestionAnswers.concat(
            ...this.getAllValidAnswers(subQuestionAnswers, allQuestions)
          );
        });
  }

  public getAnswersWithValidResponse(
    userResponse: PropertySearcherAnswer,
    question: HierarchicalQuestion
  ): HierarchicalQuestionAnswer[] {
    return question.data.type === HierarchicalQuestionType.SELECTION &&
      isValueNotNullAndUndefined(userResponse?.answerIds)
      ? this.getAnswersByAnswerIds(userResponse, question)
      : this.getAnswersByResponse(userResponse, question);
  }

  public getDisplayIndices(
    answersWithValidResponse: HierarchicalQuestionAnswer[],
    questions: HierarchicalQuestion[],
    questionDisplayIndices: string[]
  ) {
    const displayArray: (null | string)[] = [
      ...questionDisplayIndices.map(() => null)
    ];

    // update displayArray to show all (sub-)questions of answers that the PS has given a response to
    answersWithValidResponse
      .map(answer => answer.questionIds)
      .reduce((a: string[], b: string[]) => a.concat(b), [])
      .map(id => ({
        id,
        index: questions.findIndex(q => q.id === id)
      }))
      .forEach(({ index, id }) => (displayArray[index] = id));

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return displayArray;
  }

  /**
   * Range question check.
   * @param response
   * @param answerData
   */
  public isResponseRangeValid(
    response: number,
    answerData: HierarchicalQuestionRangedAnswerData
  ) {
    const { upperBound, lowerBound, type } = answerData;
    let upperBoundCheck = false;
    let upperBoundValue;
    if (upperBound) {
      upperBoundValue = Number.isFinite(upperBound)
        ? upperBound
        : this.getRangedValue(upperBound, type);
      const upperBoundCompare = upperBoundValue - response;
      upperBoundCheck =
        upperBoundCompare > 0 ||
        (answerData.upperBoundIncluded && upperBoundCompare === 0);
    }
    let lowerBoundCheck = false;
    let lowerBoundValue;
    if (lowerBound) {
      lowerBoundValue = Number.isFinite(lowerBound)
        ? lowerBound
        : this.getRangedValue(lowerBound, type);
      const lowerBoundCompare = lowerBoundValue - response;
      lowerBoundCheck =
        lowerBoundCompare < 0 ||
        (answerData.lowerBoundIncluded && lowerBoundCompare === 0);
    }
    return upperBoundValue && lowerBoundValue
      ? upperBoundCheck && lowerBoundCheck
      : upperBoundCheck || lowerBoundCheck;
  }

  /**
   * Get payload for user responses.
   * Only save valid responses: user may answer a question and its subquestions. Then the user changes an answer
   * which gives different subquestions. The answers of the first subquestions should not be considered when
   * sending response to BE.
   * @param formData
   */
  public getHierarchicalRootQuestionPayload(
    formData: RootQuestion[]
  ): HierarchicalRootQuestionResponse[] {
    return formData?.map(item => {
      const mainId = item.mainQuestionId;
      const mainQuestion = item.questions.find(
        question => question.id === mainId
      );
      // filter out duplicate IDs, in case answers have the same sub-question (in these cases we only show
      // the questions once, but will get the question here twice when iterating over the answers).
      const uniqueSubQuestionIds = new Set(
        this.getIdsOfValidSubsequentQuestions(mainQuestion, item.questions)
      );
      const subQuestions = [...uniqueSubQuestionIds].map(id =>
        item.questions.find(q => q.id === id)
      );
      return {
        rootQuestionId: item.id,
        responses: [
          this.convertQuestionToResponse(mainQuestion),
          ...subQuestions.map(q => this.convertQuestionToResponse(q))
        ]
      };
    });
  }

  public getBirthdate(rootQuestions: RootQuestion[]) {
    if (!rootQuestions?.length) return;
    const birthdayQuestion = rootQuestions
      ?.map((rootQuestion: RootQuestion) => rootQuestion.questions)
      .reduce((a: HierarchicalQuestion[], b: HierarchicalQuestion[]) =>
        a.concat(b)
      )
      .find(question => question.data.idType === 'BIRTHDATE');
    return birthdayQuestion?.userResponse?.response
      ? this.getBirthdateFormat(
          birthdayQuestion.userResponse?.response.toString()
        )
      : null;
  }

  private getAnsweredQuestions(
    questions: HierarchicalQuestion[],
    responses: HierarchicalResponseContainer[]
  ) {
    return questions.map(question => {
      return {
        ...question,
        userResponse: this.getUserResponse(
          question.id,
          responses,
          question.data.maxAnswers === 1
        )
      };
    });
  }

  /**
   * Returns the IDs of all questions that are visible to the user based on the given answer; only when
   * the user gives a valid answer the sub-questions are visible. Same holds for each sub-question and its
   * sub-questions in turn.
   * @param question
   * @param questions
   * @private
   */
  private getIdsOfValidSubsequentQuestions(
    question: HierarchicalQuestion,
    questions: HierarchicalQuestion[]
  ) {
    const answers = this.getAnswersWithValidResponse(
      question.userResponse,
      question
    );
    const subsequentQuestionIds = answers
      ?.map(answer => answer.questionIds)
      .reduce((a, b) => a.concat(b), []);
    return subsequentQuestionIds.length > 0
      ? subsequentQuestionIds.concat(
          ...subsequentQuestionIds.map(id =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            this.getIdsOfValidSubsequentQuestions(
              questions.find(q => q.id === id),
              questions
            )
          )
        )
      : subsequentQuestionIds;
  }

  private convertQuestionToResponse(
    question: HierarchicalQuestion
  ): HierarchicalResponseContainer {
    return {
      questionId: question.id,
      data: {
        type: question.data.type,
        comment: question.userResponse?.comment,
        response:
          question.data.type === HierarchicalQuestionType.RANGE_DATE ||
          (question.data.type === HierarchicalQuestionType.INPUT_DATE &&
            question.userResponse)
            ? moment
                .utc(question.userResponse.response)
                .format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            : question.userResponse?.response ?? undefined,
        answerIds: question.userResponse?.answerIds
          ? Array.isArray(question.userResponse.answerIds)
            ? question.userResponse.answerIds
            : [question.userResponse.answerIds]
          : undefined
      }
    };
  }

  /**
   * Get all valid answers based on the userResponse and response formControl.
   * @param userResponse
   * @param question
   * @private
   */
  private getAnswersByResponse(
    userResponse: PropertySearcherAnswer,
    question: HierarchicalQuestion
  ) {
    const value = userResponse?.response;
    if (!value) return [];
    const responseValue = this.getRangedValue(value, question.data.type);
    return question.answers.filter(answer =>
      this.isResponseRangeValid(
        responseValue,
        answer.data as HierarchicalQuestionRangedAnswerData
      )
    );
  }

  /**
   * Get all valid answers based on the userResponse and answerIds formControl.
   * @param userResponse
   * @param question
   * @private
   */
  private getAnswersByAnswerIds(
    userResponse: PropertySearcherAnswer,
    question: HierarchicalQuestion
  ) {
    const value = userResponse?.answerIds;
    if (!value) return [];
    const answerIds = Array.isArray(value) ? value : [value];
    return answerIds
      .map(answerId => question.answers.find(item => item.id === answerId))
      .filter(a => !!a);
  }

  private getUserResponse(
    id: string,
    responses: HierarchicalResponseContainer[],
    isSingle: boolean
  ) {
    const userResponse = responses?.find(
      response => response.questionId.toString() === id.toString()
    );

    if (!userResponse) return null;

    const { data } = userResponse;
    const { answerIds } = data;
    return {
      ...data,
      answerIds: isSingle && answerIds?.length > 0 ? answerIds[0] : answerIds
    };
  }

  private getBirthdateFormat(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  public getLanguageCode(): Observable<AvailableLanguageCodesEnum> {
    /*
      If tenant app or offline selfRegistration,
      then display CQ with tenant's preferred language.
      else, display CQ with default language.
     */

    const isOfflineSelfRegistration =
      this.router.url.includes('selfRegistration');

    return this.isTenantApp || isOfflineSelfRegistration
      ? this.getCurrentLanguageCode()
      : this.getDefaultLanguageCode();
  }

  public getCurrentLanguageCode(): Observable<AvailableLanguageCodesEnum> {
    return this.store.select(fromBaseState.getCurrentLocale);
  }

  public getDefaultLanguageCode(): Observable<AvailableLanguageCodesEnum> {
    return this.store.select(fromBaseState.getDefaultLanguageCode);
  }
}
