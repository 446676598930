import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss']
})
export class HeadingComponent {
  @Input() level: 1 | 2 | 3 | 4;
  @Input() styleType: 100 | 200;

  private baseClass = 'heading';

  private _styleTypeClass(): string {
    return this.styleType && `${this.baseClass}--${this.styleType}`;
  }

  private _makeIterative(obj: any): [any] | [] {
    return obj ? [obj] : [];
  }

  public classString(): string {
    return [
      this.baseClass,
      ...this._makeIterative(this._styleTypeClass())
    ].join(' ');
  }
}
