import { Injectable } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { map, switchMap, tap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import {
  BlockInput,
  BulkSendMessageInput,
  ConversationInput,
  ConversationMessageInput,
  ConversationMessageResponse,
  ConversationMessagesAndDetailsResponse,
  ConversationResponse,
  CountUnreadResponse,
  CreateTemplateResponse,
  LoadParsedTemplate,
  LoadParsedTemplatesResponse,
  LoadTemplateResponse,
  MessageTemplate,
  ParticipatedAgentsResponse,
  SendMessageInNewConversationResponse,
  SendMessageInput,
  SendMessageResponse,
  UpdateTemplateResponse
} from 'libs/components/legacy/messenger/model/interface';
import { getResponseValidator } from 'libs/infrastructure/gql-client/response-validator';
import {
  archiveConversationMutation,
  blockConversation,
  bulkSendMessageMutation,
  conversationMessagesAndDetailsLLQuery,
  conversationMessagesAndDetailsPSQuery,
  countUnread,
  createTemplateMutation,
  deleteTemplateMutation,
  loadParsedTemplatesQuery,
  loadParticipatedAgentsQuery,
  loadTemplateQuery,
  searchConversationMessagesByConversationIdQuery,
  sendMessageMutation,
  unarchiveConversationMutation,
  updateTemplateMutation,
  markConversationAsUnreadMutation,
  searchConversationQueryLL,
  searchConversationQueryPS,
  sendMessageInNewConversationMutationLL,
  sendMessageInNewConversationMutationPS
} from 'libs/infrastructure/base-state/messenger/messenger.queries';
import {
  Attachment,
  Pagination,
  PropertySearcherDocumentTypes,
  ResponseStatus
} from '@ui/shared/models';
import { FileUploadService } from 'libs/infrastructure/file/file-upload.service';
import { stripTypenameProperty } from 'libs/utils';
import { ConversationSenderTypes } from 'libs/components/legacy/messenger/model/enum';

@Injectable()
export class MessengerFacade {
  private findUnreadMessageQuery: QueryRef<ConversationMessageResponse>;
  private countUnreadQuery: QueryRef<CountUnreadResponse>;
  private parsedTemplateQuery: QueryRef<LoadParsedTemplatesResponse>;

  constructor(
    private apollo: Apollo,
    private fileUploadService: FileUploadService
  ) {}

  public stopCountUnreadPolling() {
    if (!this.countUnreadQuery) return;
    this.countUnreadQuery.stopPolling();
    this.countUnreadQuery = null;
  }

  // TODO remove if not needed: query with polling time auto-starts polling, doesn't it?
  public startFindUnreadMessagesPolling(pollInterval: number) {
    if (!this.findUnreadMessageQuery || !pollInterval) return;
    this.findUnreadMessageQuery.startPolling(pollInterval);
  }

  public stopFindUnreadMessagesPolling() {
    if (!this.findUnreadMessageQuery) return;
    this.findUnreadMessageQuery.stopPolling();
  }

  public searchConversation(data: ConversationInput, isLandlordApp: boolean) {
    const payloadLL = {
      searchTerm: data?.search || undefined,
      agentIds: data?.agents || undefined,
      size: 10,
      page: data?.page || 0,
      propertyId: data?.propertyId,
      dkLevelCustomerSettings: data?.dkLevelCustomerSettings,
      archivedByCustomerOnly: data?.archivedByCustomerOnly
    };

    const payloadPs = {
      search: data?.search || undefined,
      agents: data?.agents || undefined,
      size: 10,
      page: data?.page || 0,
      conversationId: data?.conversationId,
      propertyId: data?.propertyId,
      dkLevelCustomerSettings: data?.dkLevelCustomerSettings,
      archivedByCustomerOnly: data?.archivedByCustomerOnly
    };

    return this.apollo
      .query({
        query: isLandlordApp
          ? searchConversationQueryLL
          : searchConversationQueryPS,
        variables: {
          input: isLandlordApp ? payloadLL : payloadPs
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        tap(getResponseValidator<ConversationResponse>()),
        map(res => res?.data?.searchConversation)
      );
  }

  public blockConversation({ conversationId, blocked }: BlockInput) {
    return this.apollo.mutate<ConversationResponse>({
      mutation: blockConversation,
      variables: {
        input: {
          conversationId,
          blocked
        }
      }
    });
  }

  public searchConversationMessagesByConversationId(
    input: ConversationMessageInput
  ) {
    return this.apollo
      .query({
        query: searchConversationMessagesByConversationIdQuery,
        variables: {
          input
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        tap(getResponseValidator<ConversationMessageResponse>()),
        map(res => res?.data?.searchConversationMessagesByConversationId)
      );
  }

  public conversationMessagesAndDetails(
    input: ConversationMessageInput,
    sender: ConversationSenderTypes
  ) {
    return this.apollo
      .query({
        query:
          sender === ConversationSenderTypes.LANDLORD
            ? conversationMessagesAndDetailsLLQuery
            : conversationMessagesAndDetailsPSQuery,
        variables: {
          input
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        tap(getResponseValidator<ConversationMessagesAndDetailsResponse>()),
        map(res => res?.data)
      );
  }

  public sendMessageInNewConversation(
    data: SendMessageInput,
    isLandlordApp: boolean
  ) {
    const { attachments, ...messageData } = data;
    return this.uploadAttachment(attachments?.[0]).pipe(
      switchMap(attachmentsUploaded => {
        return this.apollo
          .mutate({
            mutation: isLandlordApp
              ? sendMessageInNewConversationMutationLL
              : sendMessageInNewConversationMutationPS,
            variables: {
              input: {
                ...messageData,
                attachments: attachmentsUploaded
              }
            }
          })
          .pipe(
            tap(getResponseValidator<SendMessageInNewConversationResponse>()),
            map(res => res?.data?.sendMessageInNewConversation)
          );
      })
    );
  }

  public sendMessage(data: SendMessageInput) {
    const { attachments, ...messageData } = data;
    return this.uploadAttachment(attachments?.[0]).pipe(
      switchMap(attachmentsUploaded => {
        return this.apollo
          .mutate({
            mutation: sendMessageMutation,
            variables: {
              input: {
                ...messageData,
                attachments: attachmentsUploaded
              }
            }
          })
          .pipe(
            tap(getResponseValidator<SendMessageResponse>()),
            map(res => res?.data?.sendMessage)
          );
      })
    );
  }

  public bulkSendMessage(data: BulkSendMessageInput) {
    const { attachments, ...messageData } = data;
    return this.uploadAttachment(attachments?.[0]).pipe(
      switchMap(attachmentsUploaded => {
        return this.apollo
          .mutate({
            mutation: bulkSendMessageMutation,
            variables: {
              input: {
                ...messageData,
                attachments: attachmentsUploaded
              }
            }
          })
          .pipe(tap(getResponseValidator<ResponseStatus>()));
      })
    );
  }

  public loadParticipatedAgents(conversationId: string) {
    return this.apollo
      .query({
        query: loadParticipatedAgentsQuery,
        variables: {
          conversationId
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        tap(getResponseValidator<ParticipatedAgentsResponse>()),
        map(res => res?.data?.loadParticipatedAgents)
      );
  }

  /*
   * we cannot cache this call. Firstly this call needs to be
   * fresh. Secondly caching can cause duplicate messages.
   */
  public findUnreadMessages(
    input: ConversationMessageInput,
    pollInterval: number
  ) {
    this.findUnreadMessageQuery =
      this.apollo.watchQuery<ConversationMessageResponse>({
        query: searchConversationMessagesByConversationIdQuery,
        variables: {
          input: {
            ...input,
            onlyUnread: true
          }
        },
        fetchPolicy: 'no-cache',
        pollInterval
      });

    return this.findUnreadMessageQuery.valueChanges.pipe(
      map(res => res?.data.searchConversationMessagesByConversationId)
    );
  }

  public countUnread(pollInterval: number) {
    this.stopCountUnreadPolling();

    this.countUnreadQuery = this.apollo.watchQuery<CountUnreadResponse>({
      query: countUnread,
      fetchPolicy: 'no-cache',
      pollInterval
    });

    return this.countUnreadQuery.valueChanges.pipe(
      tap(getResponseValidator<CountUnreadResponse>()),
      map(res => res?.data?.countUnread)
    );
  }

  public refetchCountUnread() {
    return from(this.countUnreadQuery.refetch()).pipe(
      tap(getResponseValidator<CountUnreadResponse>()),
      map(res => res?.data?.countUnread)
    );
  }

  public loadTemplate(page: Pagination) {
    return this.apollo
      .query({
        query: loadTemplateQuery,
        variables: {
          page
        },
        fetchPolicy: 'no-cache'
      })
      .pipe(
        tap(getResponseValidator<LoadTemplateResponse>()),
        map(res => res.data && res.data.loadTemplate)
      );
  }

  public loadParsedTemplates(input: LoadParsedTemplate) {
    this.parsedTemplateQuery =
      this.apollo.watchQuery<LoadParsedTemplatesResponse>({
        query: loadParsedTemplatesQuery,
        variables: { input },
        fetchPolicy: 'no-cache'
      });

    return this.parsedTemplateQuery.valueChanges.pipe(
      tap(getResponseValidator<LoadParsedTemplatesResponse>()),
      map(res => res.data && res.data.loadParsedTemplates)
    );
  }

  public createTemplate(templateData: MessageTemplate) {
    const { attachments, ...payload } = templateData;
    return this.uploadAttachment(attachments).pipe(
      switchMap(attachmentsUploaded => {
        return this.apollo
          .mutate({
            mutation: createTemplateMutation,
            variables: {
              template: {
                ...payload,
                attachments: attachmentsUploaded
              }
            },
            update: () => this.refetchParsedTemplates()
          })
          .pipe(
            tap(getResponseValidator<CreateTemplateResponse>()),
            map(res => res.data && res.data.createTemplate)
          );
      })
    );
  }

  public updateTemplate(templateData: MessageTemplate) {
    const { attachments, ...payload } = templateData;
    return this.uploadAttachment(attachments).pipe(
      switchMap(attachmentsUploaded => {
        return this.apollo
          .mutate({
            mutation: updateTemplateMutation,
            variables: {
              template: {
                ...payload,
                attachments: attachmentsUploaded
              }
            },
            update: () => this.refetchParsedTemplates()
          })
          .pipe(
            tap(getResponseValidator<UpdateTemplateResponse>()),
            map(res => res.data && res.data.updateTemplate)
          );
      })
    );
  }

  public deleteTemplate(templateId: string) {
    return this.apollo.mutate({
      mutation: deleteTemplateMutation,
      variables: {
        templateId
      },
      update: () => this.refetchParsedTemplates()
    });
  }

  public markConversationAsUnread(id: string) {
    return this.apollo.mutate({
      mutation: markConversationAsUnreadMutation,
      variables: { id }
    });
  }

  private uploadAttachment(file: Blob | Attachment) {
    if ((file as Attachment)?.url) return of(stripTypenameProperty(file));
    return file
      ? this.fileUploadService.uploadDocuments(
          [{ file } as Attachment],
          PropertySearcherDocumentTypes.SHARED_DOCUMENT
        )
      : of(undefined);
  }

  private refetchParsedTemplates() {
    if (!this.parsedTemplateQuery) {
      return;
    }
    void this.parsedTemplateQuery.refetch();
  }

  public archiveConversation(conversationId: string) {
    return this.apollo.mutate({
      mutation: archiveConversationMutation,
      variables: { conversationId }
    });
  }

  public unarchiveConversation(conversationId: string) {
    return this.apollo.mutate({
      mutation: unarchiveConversationMutation,
      variables: { conversationId }
    });
  }
}
