import { TagComponent } from 'libs/components/molecules/tag/tag.component';
import { FileUploadInputComponent } from './file-upload/file-upload-input.component';
import { cardComponents } from './cards';
import { formControlComponents } from './form/controls';
import { TabNavigationComponent } from './tab-navigation/tab-navigation.component';
import { SmartInputFieldSetComponent } from './smart-input-field-set/smart-input-field-set.component';
import { StatusPipelineBaseComponent } from './status-pipelines/status-pipeline-base.component';
import { ProjectStatusPipelineComponent } from './status-pipelines/project-status-pipeline/project-status-pipeline.component';
import { LandlordInfoComponent } from './landlord-info/landlord-info.component';
import { SwitchComponent } from './switch/switch.component';
import { MapComponent } from './map/map.component';
import { NoDataDisclaimerComponent } from './no-data-disclaimer/no-data-disclaimer.component';
import { SideModalContentDirective } from './side-modal/side-modal-content.directive';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TooltipDirective } from './tooltip/tooltip.directive';
import { SideModalComponent } from './side-modal/side-modal.component';
import { SideSheetContentDirective } from './side-sheet/side-sheet-content.directive';
import { SideSheetComponent } from './side-sheet/side-sheet.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { ContextMenuItemComponent } from './context-menu/context-menu-item/context-menu-item.component';
import { ApplicationStatusPipelineComponent } from './status-pipelines/application-status-pipeline/application-status-pipeline.component';
import { SocialLoginComponent } from './social-login/social-login.component';
import { LangPickerComponent } from './lang-picker/lang-picker.component';
import { StatusInfoComponent } from './status-info/status-info.component';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { quotaButtonComponents } from './quota-button';
import { SegmentComponent } from './segment/segment.component';
import { LabelListComponent } from './label-list/label-list.component';
import {
  GetAssignableContactTagsPipe,
  GetAssignablePropertyTagsPipe,
  GetAssignableSystemTagsPipe,
  LabelListTagComponent
} from './label-list';
import { FunnelComponent } from './funnel/funnel.component';

export const moleculesComponents = [
  ...cardComponents,
  ...formControlComponents,
  ...quotaButtonComponents,
  SmartInputFieldSetComponent,
  NoDataDisclaimerComponent,
  StatusPipelineBaseComponent,
  StatusInfoComponent,
  HeaderMobileComponent,
  ApplicationStatusPipelineComponent,
  ProjectStatusPipelineComponent,
  LandlordInfoComponent,
  SideSheetComponent,
  SideSheetContentDirective,
  SwitchComponent,
  MapComponent,
  SideModalComponent,
  SideModalContentDirective,
  TooltipComponent,
  TooltipDirective,
  TabNavigationComponent,
  ContextMenuComponent,
  ContextMenuItemComponent,
  SocialLoginComponent,
  FileUploadInputComponent,
  LangPickerComponent,
  SegmentComponent,
  LabelListComponent,
  LabelListTagComponent,
  GetAssignableContactTagsPipe,
  GetAssignableSystemTagsPipe,
  GetAssignablePropertyTagsPipe,
  TagComponent,
  FunnelComponent
];
