import { AttachCustomQuestionModalComponent } from './attach-custom-question-modal/attach-custom-question-modal.component';
import { AvailableCustomQuestionComponent } from './available-custom-question/available-custom-question.component';
import { CustomQuestionComponent } from './custom-question/custom-question.component';
import { CustomQuestionDetailsComponent } from './custom-question-details/custom-question-details.component';
import { CustomQuestionPreviewModalComponent } from './custom-question-preview-modal/custom-question-preview-modal.component';
import { CustomQuestionFormComponent } from './custom-question-form/custom-question-form.component';
import { CustomQuestionsFieldComponent } from './custom-questions-field/custom-questions-field.component';
import { CustomQuestionsFormComponent } from './custom-questions-form/custom-questions-form.component';
import { HierarchicalQuestionFormComponent } from './hierarchical-question-form/hierarchical-question-form.component';
import { HierarchicalRootQuestionFormComponent } from './hierarchical-root-question-form/hierarchical-root-question-form.component';
import { CustomQuestionsModalContentComponent } from './custom-questions-modal-content/custom-questions-modal-content.component';
import { AvailableHierarchicalQuestionComponent } from './available-hierarchical-question.component/available-hierarchical-question.component';
import { HierarchicalQuestionsDisplayRootComponent } from './hierarchical-questions-display-root/hierarchical-questions-display-root.component';
import { HierarchicalQuestionsDisplayQuestionComponent } from './hierarchical-questions-display-question/hierarchical-questions-display-question.component';
import { fieldComponents } from './hierarchical-question-field';

export const customQuestionsComponents = [
  AttachCustomQuestionModalComponent,
  AvailableCustomQuestionComponent,
  CustomQuestionComponent,
  CustomQuestionDetailsComponent,
  CustomQuestionPreviewModalComponent,
  CustomQuestionFormComponent,
  CustomQuestionsFieldComponent,
  CustomQuestionsFormComponent,
  HierarchicalQuestionFormComponent,
  HierarchicalRootQuestionFormComponent,
  CustomQuestionsModalContentComponent,
  AvailableHierarchicalQuestionComponent,
  HierarchicalQuestionsDisplayRootComponent,
  HierarchicalQuestionsDisplayQuestionComponent,
  ...fieldComponents
];
