import {
  Input,
  Component,
  forwardRef,
  OnInit,
  ChangeDetectionStrategy
} from '@angular/core';

import {
  ControlValueAccessor,
  FormControl,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
  NG_VALIDATORS
} from '@angular/forms';

import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { NameValue, ParkingSpace } from '@ui/shared/models';

@UntilDestroy()
@Component({
  selector: 'app-property-parking-space',
  templateUrl: './property-parking-space.component.html',
  styleUrls: ['./property-parking-space.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PropertyParkingSpaceComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PropertyParkingSpaceComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PropertyParkingSpaceComponent
  implements OnInit, ControlValueAccessor
{
  @Input() parkingSpaceTypeList: NameValue<string>[];
  @Input() isSalesObject: boolean;
  @Input() includedInAdditionalCost = false;

  public form = this.fb.array<
    FormGroup<{
      type: FormControl<string | null>;
      price: FormControl<number>;
      count: FormControl<number>;
      purchasePrice: FormControl<number>;
    }>
  >([]);
  public value: any;

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  constructor(private fb: FormBuilder) {}

  public ngOnInit() {
    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(res => this.onChange(res));
  }

  public validate() {
    return this.form.valid ? null : { missingFields: true };
  }

  public writeValue(value: ParkingSpace[]) {
    if (!value) return;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.getParkingSpaceFormArray.value.forEach(() =>
      this.getParkingSpaceFormArray.removeAt(0)
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    value.forEach(() => this.form.push(this.getParkingSpaceControl));
    this.value = value;
    this.form.patchValue(value);
  }

  public registerOnChange(fn) {
    this.onChange = fn;
  }

  public registerOnTouched(fn) {
    this.onTouch = fn;
  }

  public get getParkingSpaceFormArray() {
    return this.form;
  }

  public get getParkingSpaceControls() {
    return this.getParkingSpaceFormArray.controls as FormGroup[];
  }

  public addParkingSpace() {
    const newParkingSpace = this.getParkingSpaceControl;
    if (this.includedInAdditionalCost) {
      newParkingSpace.controls['price'].patchValue(0);
    }
    this.getParkingSpaceFormArray.push(newParkingSpace);
  }

  public removeParkingSpace(index: number) {
    this.getParkingSpaceFormArray.removeAt(index);
  }

  private get getParkingSpaceControl() {
    return this.fb.group({
      type: [null as string | null, Validators.required],
      price: [0, Validators.min(0)],
      count: [1, Validators.compose([Validators.required, Validators.min(1)])],
      purchasePrice: [0, Validators.min(0)]
    });
  }
}
