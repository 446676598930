import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerLocation, SearchCity } from '@ui/shared/models';
import {
  LoadCityDistricts,
  SearchForCities,
  SearchProfilesState,
  getSearchCities,
  isCitySearching
} from 'libs/infrastructure/base-state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ControlContainer, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-city-autocomplete',
  templateUrl: './city-autocomplete.component.html',
  styleUrls: ['./city-autocomplete.component.scss']
})
export class CityAutocompleteComponent implements OnInit {
  @Input() disableCityEdit: boolean;
  @Input() country: CustomerLocation = CustomerLocation.DE;
  @Output() cityChange = new EventEmitter();
  public cityGroup: FormGroup;
  public citiesSelector$: Observable<SearchCity[]>;
  public loading = false;
  public isLoading$: Observable<boolean>;

  constructor(
    private store: Store<SearchProfilesState>,
    private controlContainer: ControlContainer
  ) {}

  ngOnInit(): void {
    this.cityGroup = <FormGroup>this.controlContainer.control;
    this.citiesSelector$ = this.store.select(getSearchCities);
    this.isLoading$ = this.store.select(isCitySearching);

    this.patchValues();
  }

  public onSelect(value: SearchCity) {
    this.cityGroup.patchValue(value, { emitEvent: false });
    this.cityChange.emit();
    this.store.dispatch(new LoadCityDistricts(value.id));
  }

  public onNewInput(term: string) {
    this.store.dispatch(new SearchForCities(this.country, term));
  }

  private patchValues() {
    const { name, id } = this.cityGroup.value;

    if (name) {
      this.store.dispatch(new SearchForCities(this.country, name));
      this.store.dispatch(new LoadCityDistricts(id));
    }
  }
}
