<ng-container [ngSwitch]="level">
  <h1 *ngSwitchCase="1" [ngClass]="classString()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h1>
  <h2 *ngSwitchCase="2" [ngClass]="classString()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h2>
  <h3 *ngSwitchCase="3" [ngClass]="classString()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h3>
  <h4 *ngSwitchCase="4" [ngClass]="classString()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </h4>
  <span *ngSwitchDefault [ngClass]="classString()">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </span>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
