import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  BaseNavigationItem as NavigationItem,
  BreakPointsMaxInPx
} from '@ui/shared/models';
import { NavigationAlignment } from './navigation.model';

export interface NavigationParams {
  dark?: boolean;
  overlay?: boolean;
  alignment?: NavigationAlignment;
  narrow?: boolean;
  open?: boolean;
  navigationItems?: NavigationItem[];
}

@UntilDestroy()
@Injectable()
export class NavigationService {
  private _dark: BehaviorSubject<boolean>;
  private _overlay: BehaviorSubject<boolean>;
  private _alignment: BehaviorSubject<NavigationAlignment>;
  private _narrow: BehaviorSubject<boolean>;
  private _narrowManualChange: BehaviorSubject<boolean>;
  private _open: BehaviorSubject<boolean>;
  private _openFloatingSubMenu: BehaviorSubject<boolean>;
  private _mobileView: BehaviorSubject<boolean>;
  private _floatingSubMenuParentItem: BehaviorSubject<NavigationItem>;
  private _navigationItems: BehaviorSubject<NavigationItem[]>;

  public initialised = false;

  constructor(private observer: BreakpointObserver) {}

  public get dark$() {
    return this._dark.asObservable().pipe(distinctUntilChanged());
  }

  public get overlay$() {
    return this._overlay.asObservable().pipe(distinctUntilChanged());
  }

  public get alignment$() {
    return this._alignment.asObservable().pipe(distinctUntilChanged());
  }

  public get narrow$() {
    return this._narrow.asObservable().pipe(distinctUntilChanged());
  }

  public get narrowManualChange$() {
    return this._narrowManualChange.asObservable().pipe(distinctUntilChanged());
  }

  public get open$() {
    return this._open.asObservable().pipe(distinctUntilChanged());
  }

  public get mobileView$() {
    return this._mobileView.asObservable().pipe(distinctUntilChanged());
  }

  public get openInMobileView$() {
    return combineLatest(
      [this.open$, this.mobileView$],
      (open, mobileView) => open && mobileView
    );
  }

  public get openFloatingSubMenu$() {
    return this._openFloatingSubMenu
      .asObservable()
      .pipe(distinctUntilChanged());
  }

  public get floatingSubMenuParentItem$() {
    return this._floatingSubMenuParentItem
      .asObservable()
      .pipe(distinctUntilChanged());
  }

  public get navigationItems$() {
    return this._navigationItems.asObservable().pipe(distinctUntilChanged());
  }

  public init(params: NavigationParams = {}) {
    const {
      dark = false,
      overlay = false,
      alignment = NavigationAlignment.LEFT,
      open = false,
      narrow = false,
      navigationItems = []
    } = params;

    this._dark = new BehaviorSubject<boolean>(dark);
    this._overlay = new BehaviorSubject<boolean>(overlay);
    this._alignment = new BehaviorSubject<NavigationAlignment>(alignment);
    this._narrow = new BehaviorSubject<boolean>(narrow);
    this._narrowManualChange = new BehaviorSubject<boolean>(narrow);
    this._open = new BehaviorSubject<boolean>(open);
    this._navigationItems = new BehaviorSubject<NavigationItem[]>(
      navigationItems
    );

    this._mobileView = new BehaviorSubject<boolean>(null);
    this._openFloatingSubMenu = new BehaviorSubject<boolean>(null);
    this._floatingSubMenuParentItem = new BehaviorSubject<NavigationItem>(null);

    combineLatest([this.mobileView$, this.overlay$])
      .pipe(untilDestroyed(this))
      .subscribe(([mobileView, overlay]) => {
        if (!overlay) {
          if (mobileView) {
            this.close();
          } else {
            this.open();
          }
        }
      });

    this.observer
      .observe(`(max-width: ${BreakPointsMaxInPx.MD}px)`)
      .pipe(untilDestroyed(this))
      .subscribe(result => {
        this.setMobileView(result.matches);
      });

    this.initialised = true;
  }

  public complete() {
    this._dark.complete();
    this._overlay.complete();
    this._alignment.complete();
    this._narrow.complete();
    this._narrowManualChange.complete();
    this._open.complete();
    this._mobileView.complete();
    this._openFloatingSubMenu.complete();
    this._floatingSubMenuParentItem.complete();
    this._navigationItems.complete();
  }

  public setDark(dark: boolean) {
    this._dark.next(dark);
  }

  public setOverlay(overlay: boolean) {
    this._overlay.next(overlay);
  }

  public setAlignment(alignment: NavigationAlignment) {
    this._alignment.next(alignment);
  }

  public setNarrow(narrow: boolean) {
    this._narrow.next(narrow);
    this._narrowManualChange.next(narrow);
  }

  public setMobileView(mobileView: boolean) {
    this._mobileView.next(mobileView);
  }

  public open() {
    this._open.next(true);
  }

  public close() {
    this._open.next(false);
    this._openFloatingSubMenu.next(false);
  }

  public openFloatingSubMenu() {
    this._openFloatingSubMenu.next(true);
  }

  public closeFloatingSubMenu() {
    this._openFloatingSubMenu.next(false);
  }

  public setFloatingSubMenuParentItem(item: NavigationItem) {
    this._floatingSubMenuParentItem.next(item);
  }

  public setNavigationItems(items: NavigationItem[]) {
    this._navigationItems.next(items);
  }

  public setNarrowManualChange(narrow: boolean) {
    this._narrowManualChange.next(narrow);
  }
}
