<div class="profile__content">
  <form [formGroup]="form" class="profile__subform">
    <div class="form__section card">
      <div class="card-body">
        <app-form-field>
          <app-form-field-label
            >{{ 'general.birthdate_l' | translate }}</app-form-field-label
          >
          <app-date
            formControlName="dateOfBirth"
            [readonly]="!!birthDate"
            [startDate]="startDateStruct"
            [maxDate]="maxDateStruct"
            [minDate]="minDateStruct"
          ></app-date>
        </app-form-field>
        <hr />
        <ng-container formGroupName="profession">
          <div class="row mb25">
            <div class="col-12 col-md-6">
              <app-form-field>
                <app-form-field-label
                  >{{ 'general.profession_type_l' | translate
                  }}</app-form-field-label
                >
                <app-select
                  [items]="employmentTypes"
                  formControlName="type"
                ></app-select>
              </app-form-field>
            </div>

            <div class="col-12 col-md-6">
              <app-form-field>
                <app-form-field-label
                  >{{ 'general.profession_name_l' | translate
                  }}</app-form-field-label
                >
                <input
                  appInput
                  [placeholder]="'general.select_profession_name' | translate"
                  formControlName="subType"
                  class="form-control"
                />
              </app-form-field>
            </div>
          </div>
          <app-form-field #incomeEl>
            <app-form-field-label
              >{{ 'profile.what_is_household_income_l' | translate
              }}</app-form-field-label
            >
            <app-slider
              [min]="0"
              [max]="8000"
              [unit]="'€'"
              [unitMax]="('profile.unit_max' | translate)"
              [step]="100"
              formControlName="income"
            ></app-slider>
          </app-form-field>
        </ng-container>
        <hr />
        <app-form-field class="mb25 d-block">
          <app-form-field-label
            >{{ 'general.household_type_l' | translate }}</app-form-field-label
          >
          <app-flat-select
            class="form__flat-select"
            [items]="householdTypes"
            formControlName="householdType"
          ></app-flat-select>
        </app-form-field>
        <app-form-field class="mb25 d-block">
          <app-form-field-label
            >{{ 'profile.people_in_household_count_l' | translate
            }}</app-form-field-label
          >
          <input
            appInput
            type="number"
            [placeholder]="(getPlaceholderResidents | translate)"
            min="{{minNumberOfResidents}}"
            max="{{maxNumberOfResidents}}"
            formControlName="residents"
            class="form-control"
          />
        </app-form-field>
        <app-form-field class="mb25 d-block">
          <app-form-field-label
            >{{ 'profile.has_animals_l' | translate }}</app-form-field-label
          >
          <app-flat-select
            class="form__flat-select boolean-flat-select"
            [items]="booleanOptions"
            formControlName="animals"
          ></app-flat-select>
        </app-form-field>
        <app-form-field *ngIf="showMoveInDate" class="mb25 d-block">
          <app-form-field-label
            >{{ 'general.move_in_date_l' | translate }}</app-form-field-label
          >
          <app-date formControlName="moveInDate" [minDate]="minDate"></app-date>
        </app-form-field>

        <app-form-field>
          <app-form-field-label
            >{{ 'profile.has_guarantor_l' | translate }}</app-form-field-label
          >
          <app-flat-select
            class="form__flat-select boolean-flat-select"
            [items]="booleanOptions"
            formControlName="guarantorExist"
          ></app-flat-select>
        </app-form-field>
      </div>
    </div>

    <div *ngIf="showFurtherInformation" class="mb20">
      <app-form-field>
        <app-form-field-label
          >{{ 'general.short_bio_l' | translate }}</app-form-field-label
        >
        <textarea
          appInput
          count="true"
          [maxValue]="700"
          formControlName="furtherInformation"
          class="form-control"
        ></textarea>
      </app-form-field>
    </div>
  </form>
</div>
