import { Component, Input, Output, EventEmitter } from '@angular/core';

import { CustomQuestion } from '@ui/shared/models';

@Component({
  selector: 'app-custom-question',
  templateUrl: './custom-question.component.html',
  styleUrls: ['./custom-question.component.scss']
})
export class CustomQuestionComponent {
  @Input() showPreview = true;
  @Input() customQuestion: CustomQuestion;
  @Output() delete = new EventEmitter<string>();
  @Output() preview = new EventEmitter<CustomQuestion>();

  onPreview() {
    this.preview.emit(this.customQuestion);
  }

  onDelete() {
    this.delete.emit(this.customQuestion.id);
  }
}
