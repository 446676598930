import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  biggerThanValidator,
  integerValidator,
  lessThanValidator
} from 'libs/components/legacy/form';
import {
  ConditionData,
  emptyConditions,
  Prioset,
  PriosetCondition
} from '@ui/shared/models';
import {
  ConditionId,
  CustomQuestion,
  CustomQuestionAssociation,
  HousingPermission,
  Residents,
  RootQuestionContainerModel,
  SearchPeriod
} from '@ui/shared/models';
import { conditionallyRequiredValidation } from 'libs/components/legacy/form/controls/validation/validators';

@Injectable()
export class TenantProfileFormService {
  public profileForm: FormGroup;
  private readonly: boolean;
  private hasCustomQuestionsAccess: boolean;
  private editableConditions: ConditionId[];

  public get conditionsFormArray() {
    return this.profileForm?.get('data.conditions') as FormArray;
  }

  public get customQuestionsForm() {
    return this.profileForm?.get('customQuestions') as FormArray;
  }

  public get hierarchicalQuestionsForm() {
    return this.profileForm?.get('hierarchicalQuestions') as FormArray;
  }

  public get housingConditionFormIndex(): number {
    return this.conditionsFormArray.controls.findIndex(
      (condition: FormGroup) =>
        condition.value.data.conditionId === ConditionId.HOUSING_PERMISSION
    );
  }

  public get searchPeriodConditionFormIndex(): number {
    return this.conditionsFormArray.controls.findIndex(
      (condition: FormGroup) =>
        condition.value.data.conditionId === ConditionId.SEARCH_PERIOD
    );
  }

  public get residentsConditionFormIndex(): number {
    return this.conditionsFormArray.controls.findIndex(
      (condition: FormGroup) =>
        condition.value.data.conditionId === ConditionId.RESIDENTS
    );
  }

  public isConditionDisabled(conditionId: ConditionId): boolean {
    return this.readonly && !this.editableConditions.includes(conditionId);
  }

  private get prioSetForm() {
    return this.fb.group({
      id: '',
      name: ['', Validators.required],
      description: ['', Validators.required],
      template: [false],
      customQuestions: this.fb.array([]),
      hierarchicalQuestions: this.fb.array([]),
      data: this.fb.group({
        age: this.fb.group({
          value: this.fb.group({
            value: [0],
            lowerBound: [
              0,
              Validators.compose([
                Validators.min(0),
                lessThanValidator('upperBound')
              ])
            ],
            upperBound: [
              0,
              Validators.compose([
                Validators.min(0),
                biggerThanValidator('lowerBound')
              ])
            ]
          }),
          knockout: [false]
        }),
        employmentType: this.fb.group({
          value: this.fb.group({
            value: [0],
            choice: [[]]
          }),
          knockout: [false]
        }),
        monthlyIncome: this.fb.group({
          value: this.fb.group({
            value: [0],
            lowerBound: 1,
            upperBound: 4
          }),
          knockout: [false]
        }),
        children: this.fb.group({
          value: [0],
          knockout: [false]
        }),
        smoking: this.fb.group({
          value: [0],
          knockout: [false]
        }),
        animals: this.fb.group({
          value: [0],
          knockout: [false]
        }),
        householdType: this.fb.group({
          value: this.fb.group({
            value: [0],
            choice: [[]]
          }),
          knockout: [false]
        }),
        conditions: this.fb.array([
          this.fb.group({
            value: false,
            importance: 10,
            knockout: true,
            data: this.fb.group({
              conditionId: ConditionId.HOUSING_PERMISSION,
              housingPermissionTypes: [[]],
              amountPeopleLowerBound: 0,
              amountPeopleUpperBound: 0
            })
          }),
          this.fb.group({
            value: false,
            importance: 0,
            knockout: false,
            data: this.fb.group({
              conditionId: ConditionId.SEARCH_PERIOD,
              months: 6
            })
          }),
          this.fb.group({
            value: false,
            importance: 0,
            knockout: false,
            data: this.fb.group({
              conditionId: ConditionId.RESIDENTS,
              amountPeopleLowerBound: 0,
              amountPeopleUpperBound: 0
            })
          })
        ]),
        editableConditions: [[]]
      })
    });
  }

  constructor(private fb: FormBuilder) {
    this.profileForm = this.prioSetForm;
  }

  public init(readonly: boolean, hasCustomQuestionsAccess: boolean) {
    this.readonly = readonly;
    this.hasCustomQuestionsAccess = hasCustomQuestionsAccess;
  }

  public patchValues(valueToPatch: Prioset) {
    const {
      id,
      name = '',
      description = '',
      template = false,
      data = {},
      customQuestions,
      hierarchicalQuestions
    } = valueToPatch || {};
    const { conditions, ...restData } = data;

    const valuesToPatch = {
      id,
      name,
      description,
      template,
      data: restData,
      customQuestions: customQuestions || [],
      hierarchicalQuestions: hierarchicalQuestions || []
    };

    this.editableConditions = data.editableConditions;
    this.profileForm.patchValue(valuesToPatch);
    this.patchPriosetConditions(conditions);
    if (this.hasCustomQuestionsAccess) {
      this.patchCustomQuestions(customQuestions);
      this.patchHierarchicalQuestions(hierarchicalQuestions);
    }
  }

  public addNewCustomQuestions(ids: string[]) {
    const values = [
      ...this.customQuestionsForm.value,
      ...ids.map(id => ({ id, importance: 5, knockout: false }))
    ];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.customQuestionsForm.value.forEach(() =>
      this.customQuestionsForm.removeAt(0)
    );
    this.addCustomQuestionsControlsAndPatch(values);
  }

  public addNewHierarchicalQuestions(ids: string[]) {
    const values = [
      ...this.hierarchicalQuestionsForm.value,
      ...ids.map(id => ({ id, importance: 5 }))
    ];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.hierarchicalQuestionsForm.value.forEach(() =>
      this.hierarchicalQuestionsForm.removeAt(0)
    );
    this.addHierarchicalQuestionsControlsAndPatch(values);
  }

  public removeCustomQuestion(id) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const values = this.customQuestionsForm.value.filter(
      value => value.id !== id
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.customQuestionsForm.value.forEach(() =>
      this.customQuestionsForm.removeAt(0)
    );
    this.addCustomQuestionsControlsAndPatch(values);
  }

  public removeHierarchicalQuestion(id: string): void {
    const index = (
      this.hierarchicalQuestionsForm.value as RootQuestionContainerModel[]
    ).findIndex(q => q.id === id);
    this.hierarchicalQuestionsForm.removeAt(index);
  }

  private patchPriosetConditions(conditions: PriosetCondition[] = []) {
    const conditionsToBePatched = this.conditionsToBePatched(conditions);

    [...this.conditionsFormArray.value].forEach((controlValue, i) => {
      conditionsToBePatched.some(({ value, data, knockout, importance }) => {
        if (data.conditionId === controlValue.data?.conditionId) {
          this.conditionsFormArray.setControl(
            i,
            this.fb.group({
              value: value ?? !!conditions.length,
              importance,
              knockout,
              data: this.fb.group(this.getSpecificConditionData(data))
            })
          );
          return true;
        }
      });
    });
  }

  private conditionsToBePatched(
    conditions: PriosetCondition[]
  ): PriosetCondition[] {
    if (conditions.length === emptyConditions.length) return conditions;
    if (!conditions.length) return emptyConditions;

    return emptyConditions.reduce(
      (
        updatedConditions: PriosetCondition[],
        emptyCondition: PriosetCondition
      ) => {
        const index = conditions.findIndex(
          condition =>
            condition.data.conditionId === emptyCondition.data.conditionId
        );
        updatedConditions.push(index > -1 ? conditions[index] : emptyCondition);
        return updatedConditions;
      },
      []
    );
  }

  private getSpecificConditionData(data: ConditionData) {
    switch (data.conditionId) {
      case ConditionId.HOUSING_PERMISSION:
        return this.getHousingConditionData(data);
      case ConditionId.SEARCH_PERIOD:
        return this.getSearchPeriodConditionData(data);
      case ConditionId.RESIDENTS:
        return this.getResidentsConditionData(data);
    }
  }

  private getHousingConditionData({
    conditionId,
    housingPermissionTypes,
    amountPeopleLowerBound,
    amountPeopleUpperBound
  }: HousingPermission) {
    return {
      conditionId,
      housingPermissionTypes: [housingPermissionTypes],
      amountPeopleLowerBound: [
        amountPeopleLowerBound || 0,
        Validators.compose([
          Validators.min(0),
          integerValidator,
          lessThanValidator('amountPeopleUpperBound'),
          conditionallyRequiredValidation(
            () =>
              this.conditionsFormArray
                .at(this.housingConditionFormIndex)
                .get('value').value as boolean
          )
        ])
      ],
      amountPeopleUpperBound: [
        amountPeopleUpperBound || 5,
        Validators.compose([
          Validators.min(0),
          integerValidator,
          biggerThanValidator('amountPeopleLowerBound'),
          conditionallyRequiredValidation(
            () =>
              this.conditionsFormArray
                .at(this.housingConditionFormIndex)
                .get('value').value as boolean
          )
        ])
      ]
    };
  }

  private getSearchPeriodConditionData({ conditionId, months }: SearchPeriod) {
    return {
      conditionId,
      months
    };
  }

  private getResidentsConditionData({
    conditionId,
    amountPeopleLowerBound,
    amountPeopleUpperBound
  }: Residents) {
    return {
      conditionId,
      amountPeopleLowerBound: [
        amountPeopleLowerBound,
        Validators.compose([
          Validators.min(0),
          integerValidator,
          lessThanValidator('amountPeopleUpperBound')
        ])
      ],
      amountPeopleUpperBound: [
        amountPeopleUpperBound,
        Validators.compose([
          Validators.min(0),
          integerValidator,
          biggerThanValidator('amountPeopleLowerBound')
        ])
      ]
    };
  }

  private addCustomQuestionsControlsAndPatch(
    cq: CustomQuestionAssociation[] = []
  ) {
    cq.forEach(({ id, importance, knockout }) => {
      const control = this.fb.group({
        id,
        importance,
        knockout
      });
      this.customQuestionsForm.push(control);
    });
  }

  private addHierarchicalQuestionsControlsAndPatch(
    hq: RootQuestionContainerModel[] = []
  ) {
    hq.forEach(({ id, importance }) => {
      const control = this.fb.group({
        id,
        importance
      });
      this.hierarchicalQuestionsForm.push(control);
    });
  }

  private patchCustomQuestions(customQuestions: CustomQuestion[]) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.customQuestionsForm.value.forEach(() =>
      this.customQuestionsForm.removeAt(0)
    );
    this.addCustomQuestionsControlsAndPatch(
      customQuestions?.map(({ id, importance, knockout }) => ({
        id,
        importance,
        knockout
      }))
    );
  }

  private patchHierarchicalQuestions(
    hierarchicalQuestions: RootQuestionContainerModel[]
  ) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    this.hierarchicalQuestionsForm.value.forEach(() =>
      this.hierarchicalQuestionsForm.removeAt(0)
    );
    this.addHierarchicalQuestionsControlsAndPatch(
      hierarchicalQuestions?.map(({ id, importance }) => ({
        id,
        importance
      }))
    );
  }
}
