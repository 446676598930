<div class="container">
  <div class="row">
    <div
      class="attachment-item__info col-9"
      (click)="!disableDownload && attachment.url && onPreview()"
      [class.preview]="!disableDownload && attachment.url"
    >
      <div class="attachment-item__order-menu" *ngIf="orderable">
        <i class="icon icon--chevron-up" (click)="onMoveUp()"></i>
        <i class="icon icon--chevron-down" (click)="onMoveDown()"></i>
      </div>
      <div *ngIf="showPreview" class="attachment-item__preview">
        <app-attachment-preview
          [imgSrc]="imgSrc || ''"
          [isDocument]="isDocument"
          [attachment]="attachment"
          (preview)="onPreview()"
          (download)="onDownload()"
        >
        </app-attachment-preview>
      </div>
      <div class="attachment-item__details">
        <app-simple-text-input
          [formControl]="nameControl"
          *ngIf="editable"
          [hideIcon]="hideIcons"
        ></app-simple-text-input>
        <p class="default-s attachment-details__name" *ngIf="!editable">
          {{ name | translate }}
        </p>
        <p class="default-s attachment-details__size">
          <span *ngIf="attachment?.size"
            >{{attachment?.size | appFileSize}}</span
          >
          <span *ngIf="hasExifMetadata" class="exif-metadata-info"
            >{{ 'attachments.exif_metadata_info' | translate }}</span
          >
        </p>
      </div>
    </div>

    <div class="attachment-item__actions upload-progress col-3 g-0">
      <div
        *ngIf="showDownload && !hasFile"
        class="download-attachment-button"
        [ngClass]="{ 'mr12': showRemove }"
      >
        <a
          *ngIf="!blockDownload && !disableDownload"
          class="attachment-item__action-icon"
          (click)="onDownload()"
        >
          <!-- {{attachment?.title || attachment?.name}} -->
          <svg-icon
            src="/assets/images/icons/icon-download.svg"
            [applyClass]="true"
            [svgStyle]="{'width.px': 15}"
            class="secondary-svg-color path rect"
          ></svg-icon>
        </a>
        <span
          *ngIf="blockDownload || disableDownload"
          class="attachment-item__action-icon disabled"
        >
          <svg-icon
            *ngIf="!blockDownload && disableDownload"
            src="/assets/images/icons/icon-download.svg"
            [applyClass]="true"
            [svgStyle]="{'width.px': 15}"
            class="secondary-svg-color path rect"
          ></svg-icon>
          <svg-icon
            *ngIf="blockDownload"
            src="/assets/images/icons/lock-icon.svg"
            [applyClass]="true"
            [svgStyle]="{'width.px': 15}"
            class="secondary-svg-color path rect"
          ></svg-icon>
        </span>
      </div>

      <div *ngIf="showRemove" class="attachment-item__action-icon">
        <svg-icon
          src="/assets/images/icons/icon-bin.svg"
          [applyClass]="true"
          [svgStyle]="{'width.px': 15}"
          class="secondary-svg-color path rect"
          (click)="onRemove()"
        ></svg-icon>
      </div>
      <div *ngIf="editable" class="attachment-item__action-icon">
        <app-context-menu>
          <div menu-content>
            <app-context-menu-item (clickEvent)="editAttachment()"
              >{{'general.edit_attachment_a' |
              translate}}</app-context-menu-item
            >
          </div>
        </app-context-menu>
      </div>
    </div>
  </div>
</div>
