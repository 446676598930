<div class="mb10" ngbAccordion>
  <div [ngbAccordionItem]="'panel-0'" [collapsed]="!isNewSP">
    <div ngbAccordionHeader>
      <div class="d-flex justify-content-between align-items-center">
        <button class="btn btn-link p-2" ngbAccordionToggle>
          {{'landlord_districts.panel_name_l' | translate}}
        </button>
      </div>
    </div>
    <div ngbAccordionCollapse>
      <div
        *ngFor="let city of landlordCityPacket.citiesMap | keyvalue; let lastItem = last;"
        class="accordion-body"
        ngbAccordionBody
      >
        <ng-template>
          <span class="title-s semibold">{{city.key}}</span>

          <div *ngFor="let districtsContainer of city.value" class="mt10 mb10">
            <span
              *ngIf="districtsContainer.groupedDistrictsName"
              class="default-s"
            >
              {{ districtsContainer.groupedDistrictsName }}
            </span>
            <app-dropdown-multiselect
              [itemValueKey]="'id'"
              [items]="districtsContainer.districts"
              [showToggleAll]="true"
              [showBadges]="true"
              [formControl]="$any(form.get('landlordDistricts')).at(districtsContainer.districtIndex)"
            >
              <div dropdown-button>{{ 'ALL_DISTRICTS_L' | translate }}</div>
              <ng-template let-item> {{ item.name }} </ng-template>
            </app-dropdown-multiselect>
          </div>
          <hr *ngIf="!lastItem" />
        </ng-template>
      </div>
    </div>
  </div>
</div>
