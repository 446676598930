import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import {
  AvailableLanguageCodesEnum,
  BaseNavigationItem as NavigationItem,
  LandlordUser,
  Language,
  PropertySearcherUser
} from '@ui/shared/models';

@Component({
  selector: 'app-header-v2',
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderV2Component {
  @Input() navigationUserDropdownItems: NavigationItem[];
  @Input() user: LandlordUser | PropertySearcherUser;
  @Input() tenantInInternalPool: boolean;
  @Input() showSearchIcon: boolean;
  @Input() showLanguagePicker = false;
  @Input() availableLanguages: Language[];
  @Input() defaultLanguageCode: AvailableLanguageCodesEnum;
  @Input() currentLanguageCode: AvailableLanguageCodesEnum;

  public visibleItems: NavigationItem[];

  @Output() openSearch = new EventEmitter();
  @Output() hasPermission = new EventEmitter();
  @Output() languageChange = new EventEmitter<string>();
  @Output() logout = new EventEmitter<string>();

  public sidenavOpened = false;

  public onLanguageChange(value: string) {
    this.languageChange.emit(value);
  }

  public onLogout() {
    this.logout.emit();
  }
}
