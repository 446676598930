<div class="row col">
  <app-form-field-label
    [showRequiredMarker]="true"
    *ngIf="hierarchicalQuestion.data?.title && currentLanguage"
  >
    {{(hierarchicalQuestion.data?.title[currentLanguage] ||
    hierarchicalQuestion.data?.title[defaultLanguage]) | translate}}
  </app-form-field-label>
</div>
<div class="row">
  <div class="col-12">
    <app-form-field *ngIf="isDate || isRangeDate">
      <app-date
        [(ngModel)]="value"
        [startDate]="isFutureDate ? startDateFutureStruct : startDateStruct"
        [maxDate]="isFutureDate ? maxDateFutureStruct : maxDateStruct"
        [minDate]="minDateStruct"
      ></app-date>
    </app-form-field>

    <app-form-field *ngIf="isText">
      <input
        type="text"
        required
        appInput
        [placeholder]="'custom_questions.type_answer_l' | translate"
        [(ngModel)]="value"
        class="form-control"
    /></app-form-field>

    <app-form-field *ngIf="isRangeNumber">
      <input
        type="number"
        required
        appInput
        [placeholder]="'custom_questions.type_answer_l' | translate"
        [(ngModel)]="value"
        class="form-control"
    /></app-form-field>
  </div>
</div>
