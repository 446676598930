<div
  ngbDropdown
  class="dropdown-multiselect__container"
  [autoClose]="'outside'"
  (openChange)="setShowFilters($event)"
>
  <button
    type="button"
    class="d-flex btn align-items-center"
    id="dropdown-multiselect"
    ngbDropdownToggle
    [disabled]="disabled"
  >
    <div
      *ngIf="!value || !value.length; else optionsSelectedButton"
      class="dropdown-toggle__inner"
    >
      <ng-content select="[dropdown-button]"></ng-content>
    </div>

    <ng-template #optionsSelectedButton>
      <div
        class="dropdown-toggle__inner"
        *ngIf="!showBadges"
        [ngSwitch]="value?.length"
      >
        <ng-template
          *ngSwitchCase="1"
          [ngTemplateOutlet]="templateRef"
          [ngTemplateOutletContext]="{$implicit: selectedItems[0] || {}}"
        ></ng-template>
        <ng-container *ngSwitchDefault>
          <ng-content select="[dropdown-button-multi]"></ng-content>
        </ng-container>
      </div>
      <div *ngIf="showBadges" class="badges-container">
        <ng-container
          *ngIf="items?.length !== selectedItems?.length; else allSelectedTemplate"
        >
          <app-badge
            *ngFor="let item of selectedItems | slice: 0: numberOfBadgesToShow"
            [clickable]="true"
            [withCloseButton]="true"
            (closeEvent)="removeItem($event, item)"
            (clickEvent)="$event.stopPropagation()"
          >
            {{item.name}}
          </app-badge>
          <app-badge
            *ngIf="selectedItems.length > numberOfBadgesToShow"
            (clickEvent)="$event.stopPropagation()"
            >+ {{selectedItems.length - numberOfBadgesToShow}}</app-badge
          >
        </ng-container>

        <ng-template #allSelectedTemplate>
          <app-badge
            [clickable]="true"
            [withCloseButton]="true"
            (closeEvent)="removeAllItems($event)"
            (clickEvent)="$event.stopPropagation()"
            >{{ 'mutli_select.all_items_selected_l' | translate }}</app-badge
          >
        </ng-template>
      </div>
    </ng-template>

    <div
      class="dropdown-arrow__container ms-auto pr0 d-flex align-items-center"
    >
      <i
        *ngIf="selectedItems.length || predicate === true"
        class="icon icon--close ms-2"
        (click)="clear($event)"
      ></i>
      <i
        [ngClass]="isDropdownOpened ? 'chevron-rotated' : 'chevron-non-rotated'"
        class="icon icon--chevron-down ms-2"
      ></i>
    </div>
  </button>

  <div
    cdkDropListGroup
    ngbDropdownMenu
    aria-labelledby="dropdown-multiselect"
    [cdkDropListGroupDisabled]="!draggable"
    [class.relative-positioned]="relativelyPositioned"
  >
    <div
      class="dropdown__items"
      cdkDropList
      [cdkDropListData]="items"
      (cdkDropListDropped)="drop($event)"
    >
      <form [formGroup]="dropdownForm">
        <div *ngIf="showToggleAll" class="toggle-all-item">
          {{ 'dropdown_multi-select.select_all_l' | translate }}

          <app-check
            class="dropdown__check"
            [ngModel]="toggle"
            (ngModelChange)="toggleAll($event)"
            [ngModelOptions]="{standalone: true}"
          >
          </app-check>
        </div>
        <div
          *ngIf="showAndOrFilter"
          (click)="onPredicateChange(!predicate)"
          class="toggle-all-item"
        >
          {{ 'ps_filter.checkbox_label' | translate }}
          <app-check
            class="dropdown__check"
            [ngModel]="predicate"
            (ngModelChange)="onPredicateChange($event)"
            [ngModelOptions]="{standalone: true}"
          ></app-check>
        </div>
        <hr *ngIf="showAndOrFilter" />
        <div
          class="dropdown__item"
          *ngFor="let item of items; index as i"
          cdkDragLockAxis="y"
          [cdkDragDisabled]="!dropdownForm.value[item[itemValueKey]]"
          [ngClass]="{'cursor-grab highlight': draggable && dropdownForm.value[item[itemValueKey]]}"
          (click)="onOptionClick(i)"
          cdkDrag
        >
          <ng-container *ngIf="item.isDivider; else elseBlock">
            <div class="w-100">
              <hr />
            </div>
          </ng-container>
          <ng-template #elseBlock>
            <ng-template
              [ngTemplateOutlet]="templateRef"
              [ngTemplateOutletContext]="{$implicit: item}"
            ></ng-template>
            <app-check
              class="dropdown__check"
              formControlName="{{item[itemValueKey]}}"
            ></app-check>
          </ng-template>
        </div>
        <div
          *ngIf="items.length === 0 && emptyPlaceholder"
          class="dropdown__item dropdown__item--disabled"
        >
          {{emptyPlaceholder | translate}}
        </div>
      </form>
    </div>
    <div *ngIf="showApplyButton" class="dropdown__actions">
      <button
        class="btn btn-apply"
        [disabled]="items.length === 0"
        (click)="apply()"
      >
        {{'APPLY_A' | translate}}
      </button>
    </div>
  </div>
</div>
