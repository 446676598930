import { QuotaStylePipe } from './pipes/quota-button.pipe';
import { QuotaIconPipe } from './pipes/quota-icon.pipe';
import { QuotaNumberPipe } from './pipes/quota-number.pipe';
import { QuotaTranslationPipe } from './pipes/quota-translation.pipe';
import { QuotaButtonComponent } from './quota-button.component';

export const quotaButtonComponents = [
  QuotaButtonComponent,
  QuotaStylePipe,
  QuotaIconPipe,
  QuotaTranslationPipe,
  QuotaNumberPipe
];
