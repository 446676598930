import { Component, Inject, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WINDOW_REF } from 'libs/infrastructure';

@Component({
  selector: 'app-update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss']
})
export class UpdateModalComponent {
  @Input() isStillValid: boolean;

  public storeURL: string;

  constructor(
    private ngbActiveModal: NgbActiveModal,
    @Inject(WINDOW_REF) private windowRef: Window
  ) {}

  close() {
    this.ngbActiveModal.close();
  }

  dismiss() {
    this.ngbActiveModal.dismiss();
  }

  public openStore() {
    this.windowRef.open('https://tenant.immomio.com', '_system');
  }
}
