<div
  class="no-data-disclaimer"
  [class.dashed-border]="showDashedBorder"
  [ngStyle]="{ scale: scale }"
>
  <!-- TODO: retrieve alt text translation from Phrase -->
  <app-image
    *ngIf="showImage"
    class="no-data-disclaimer__image"
    [defaultSrc]="imageSrc"
    alt="404"
  ></app-image>

  <span [class.m-0]="showDashedBorder" class="no-data-disclaimer__title title-m"
    >{{ title | translate }}</span
  >
  <span *ngIf="description" class="no-data-disclaimer__description semibold"
    >{{ description | translate }}</span
  >
</div>
