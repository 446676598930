<app-tag
  class="labels-tag me-2 align-self-center"
  [name]="name"
  [tagType]="tagType"
  [isSystemTag]="isSystemTag"
  [nowrap]="true"
>
  <i
    [class]="'icon icon--close c-pointer'"
    icon
    *ngIf="readonly ? false : (isSystemTag ? (tag | appIsItemInArray : [SystemTag.TENANT, SystemTag.BUYER]) : tagOwnershipType !== TagOwnershipType.DYNAMIC)"
    (click)="unassignTag.emit(tag)"
    [ngClass]="tag?.ownershipType === 'SHARED' ? 'white-svg-color' : 'black-svg-color'"
  ></i>
</app-tag>
