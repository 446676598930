import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import {
  AvailableLanguageCodesEnum,
  HierarchicalQuestion,
  HierarchicalQuestionType,
  IconTypeEnum
} from '@ui/shared/models';
import { HierarchicalQuestionService } from 'libs/services';
import { BadgeColorEnum } from 'libs/components/atoms/badge/badge.enum';

@UntilDestroy()
@Component({
  selector: 'app-hierarchical-question-form',
  templateUrl: './hierarchical-question-form.component.html',
  styleUrls: ['./hierarchical-question-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HierarchicalQuestionFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => HierarchicalQuestionFormComponent),
      multi: true
    }
  ]
})
export class HierarchicalQuestionFormComponent
  implements OnInit, ControlValueAccessor
{
  @Input() hierarchicalQuestion: HierarchicalQuestion;
  @Input() hierarchicalQuestions: HierarchicalQuestion[];
  @Input() currentLanguage: AvailableLanguageCodesEnum;
  @Input() defaultLanguage: AvailableLanguageCodesEnum;
  @Input() isProcessing: boolean;

  @Output() validityChange = new EventEmitter<boolean>();
  @Output() answerChange = new EventEmitter<any>();

  public form: FormGroup;
  public value: HierarchicalQuestion;

  private onChange = (value: unknown) => value;
  private onTouch = () => null;

  public get responseControl() {
    return this.form.get('userResponse').get('response') as FormControl;
  }

  public get answerIdsControl() {
    return this.form.get('userResponse').get('answerIds');
  }

  public get commentControl() {
    return this.form.get('userResponse').get('comment');
  }

  constructor(
    private fb: FormBuilder,
    private hierarchicalQuestionService: HierarchicalQuestionService
  ) {}

  public ngOnInit() {
    this.form = this.fb.group(
      this.hierarchicalQuestionService.questionControlConfig
    );

    this.form.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value: HierarchicalQuestion) => {
        this.value = value;
        this.onChange(this.value);
        this.onTouch();
      });

    if (
      this.hierarchicalQuestion.data.type === HierarchicalQuestionType.SELECTION
    ) {
      this.responseControl.disable();
    } else {
      const validator =
        this.hierarchicalQuestion.data.type ===
        HierarchicalQuestionType.RANGE_NUMBER
          ? Validators.pattern(/^\d+$/)
          : Validators.nullValidator;
      this.responseControl.setValidators([Validators.required, validator]);
      this.answerIdsControl.disable();
    }
  }

  public validate() {
    this.validityChange.emit(this.form.valid);
    return this.form.valid ? null : { missingFields: true };
  }

  public onAnswerChanged(value: any) {
    this.answerChange.emit(value);
  }

  public writeValue(value: HierarchicalQuestion): void {
    this.value = value || ({} as HierarchicalQuestion);
    const valueToPatch = {
      ...value,
      userResponse: {
        response: value?.userResponse?.response || null,
        answerIds: value?.userResponse?.answerIds || null,
        comment: value?.userResponse?.comment || null
      }
    };
    this.form.patchValue(valueToPatch);
  }

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouch = fn;
  }

  protected readonly BadgeColorEnum = BadgeColorEnum;
  protected readonly IconTypeEnum = IconTypeEnum;
}
