import {
  ChangeDetectorRef,
  Component,
  ContentChildren,
  forwardRef,
  Injector,
  Input,
  QueryList,
  ViewEncapsulation
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { BaseControl } from '../../base-control';
import { RadioButtonComponent } from '../radio-button/radio-button.component';
import { AppFormFieldControl } from '../../../form-field/form-field-control/form-field-control';

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupComponent),
      multi: true
    },
    {
      provide: AppFormFieldControl,
      useExisting: forwardRef(() => RadioGroupComponent)
    }
  ]
})
export class RadioGroupComponent extends BaseControl<string> {
  @ContentChildren('radioButon', { descendants: true })
  _radios: QueryList<RadioButtonComponent>;
  @Input() isUnselectable = true;

  constructor(
    private cd: ChangeDetectorRef,
    protected injector: Injector
  ) {
    super(injector);
  }

  public writeValue(value: string): void {
    this.value = this.value === value && this.isUnselectable ? null : value;
  }
}
