import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BodyModifierClass } from 'libs/infrastructure/browser/body/body.model';

@Injectable()
export class BodyService {
  private renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private document
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  private addBodyClass(name: string) {
    this.renderer.addClass(document.body, name);
  }

  private removeBodyClass(name: string) {
    this.renderer.removeClass(document.body, name);
  }

  public setBodyModifierClass(name: BodyModifierClass): void {
    this.addBodyClass(name);
  }

  public unsetBodyModifierClass(name: BodyModifierClass): void {
    this.removeBodyClass(name);
  }

  public hasBodyModifierClass(name: BodyModifierClass): boolean {
    return document.body.classList.contains(name);
  }
}
