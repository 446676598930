import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnInit
} from '@angular/core';
import { Store } from '@ngrx/store';

import * as fromBaseState from 'libs/infrastructure/base-state';
import { WINDOW_REF } from 'libs/infrastructure/browser/window-ref.token';
import { Attachment } from '@ui/shared/models';
import { ThemeService } from 'libs/infrastructure/theme/theme.service';
import { ThemeUrls } from 'libs/infrastructure/theme/theme-urls';
import { Theme } from 'libs/infrastructure/theme/symbols';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoComponent implements OnInit {
  @Input() url = '/';
  @Input() alternativeUrl: string;
  @Input() preventClick: boolean;
  @Input() landlordLogo: Attachment;
  @Input() tenantInInternalPool: boolean;
  @Input() targetHandling = '_blank';

  private theme: Theme;

  ngOnInit() {
    this.themeService.themeChange
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => {
        this.changeDetectorRef.markForCheck();
      });
  }

  public get logo() {
    this.theme = this.themeService.getActiveTheme;
    return this.theme.name === ThemeUrls.IMMOMIO && this.tenantInInternalPool
      ? this.theme.iTPLogo
      : this.theme.logo;
  }

  public get logoExist() {
    return !this.logo && !this.landlordLogo;
  }

  public get isImmomio() {
    return this.logo?.title === 'immomio';
  }

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private store: Store<fromBaseState.AppState>,
    private themeService: ThemeService,
    @Inject(WINDOW_REF) private windowRef: Window
  ) {}

  onClick() {
    if (this.preventClick) return;
    const url = this.alternativeUrl || this.theme?.alternativeUrl;
    url
      ? this.windowRef.open(url, this.targetHandling)
      : this.store.dispatch(
          new fromBaseState.Go({
            path: [this.url || 'properties']
          })
        );
  }
}
