import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-context-menu-item',
  templateUrl: './context-menu-item.component.html',
  styleUrls: ['./context-menu-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContextMenuItemComponent {
  @Input() iconLeft;
  @Input() iconRight;
  @Input() iconSpacing: boolean;
  @Input() withTopSpacing: boolean;
  // Use iconSpacing="true" if there is items with icon and items without, so there is nice alignment
  @Input() active = false;
  @Input() hover = true;
  @Input() disabled = false;
  @Input() highlightTextRed = false;
  @HostBinding('class.stick-to-bottom') @Input() stickToBottom = false;

  @Output() clickEvent = new EventEmitter<any>();

  click(event: any) {
    if (this.disabled) return;
    this.clickEvent.emit(event);
  }
}
