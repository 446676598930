<div
  appElevation
  #switchContainer
  [ngClass]="{'fade-left': leftFade, 'fade-right': rightFade,'switch-container':enableScrollFade}"
  (scroll)="( enableScrollFade && onHorizontalScroll() )"
  (wheel)="( enableScrollFade && onWheelScroll($event) ) "
  [elevation]="( enableScrollFade && elevation)"
>
  <div
    appElevation
    [elevation]="( !enableScrollFade && elevation)"
    class="switch"
    [ngClass]="{'switch--disabled': disabled}"
    [class]="'switch--size-' + size"
  >
    <div
      *ngFor="let item of config; index as i;last as isLast"
      [ngClass]="{'d-flex align-items-center': true ,'switch__container': !enableDivider}"
    >
      <ng-container *ngIf="!item.invisible">
        <div
          class="switch__item"
          [ngClass]="{'switch__item--active': selectedItem === item.id ,'switch__item--disabled': item.disabled}"
          [ngbTooltip]="(item.disabled ? item.tooltip : null) | translate"
          (click)="selectItem(item)"
        >
          <span *ngIf="item.label"> {{ item.label | translate}} </span>
          <app-badge
            *ngIf="item.amount !== undefined"
            [ngClass]="{'ms-2': item.label}"
            [color]="(item.id === selectedItem ? 'neutral-light' : 'neutral-light-dark')"
          >
            {{ item.amount }}
          </app-badge>
        </div>

        <div class="switch__divider" *ngIf="!isLast && enableDivider"></div>
      </ng-container>
    </div>
  </div>
</div>
