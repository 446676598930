import { Attribute, Directive, forwardRef } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';

import moment from 'moment';

import {
  coerceBooleanProperty,
  escapeRegexCharacters,
  RegexTypes
} from 'libs/utils';
import {
  CustomQuestionOption,
  DigitalContractItpState,
  DigitalContractWorkflowState,
  LandlordUser,
  SchufaVerificationState,
  SearchDistrict
} from '@ui/shared/models';

/**
 * This function returns errors for a target control in a validation relation,
 * when related control is already valid, so the target control's related error
 * can be removed.
 */
function getUpdatedTargetErrors(target: AbstractControl, errorName: string) {
  const filteredErrors = {};

  Object.keys(target.errors || {})
    .filter(key => key !== errorName)
    .forEach(key => {
      filteredErrors[key] = target.errors[key];
    });

  return filteredErrors && Object.keys(filteredErrors).length
    ? filteredErrors
    : null;
}

export function matchControlValidatorFactory(
  matchControlName: string,
  isSource = false
) {
  return (control: AbstractControl) => {
    if (!matchControlName || !control.parent) return null;

    const selfValue = control.value;
    const target = control.parent.get(matchControlName);

    if (target && selfValue !== target.value && !isSource)
      return { matchControl: true };

    if (target && selfValue === target.value && isSource) {
      target.setErrors(getUpdatedTargetErrors(target, 'matchControl'));
    }

    if (target && selfValue !== target.value && isSource) {
      target.setErrors({ ...target.errors, matchControl: true });
    }

    return null;
  };
}

export function lessThanValidator(controlName: string) {
  return (control: AbstractControl) => {
    if (!controlName || !control.parent) return null;

    const selfValue = control.value;

    const target = control.parent.get(controlName);
    const targetValue = target.value;

    if (selfValue > targetValue) {
      return {
        lessThan: true
      };
    }

    target.setErrors(getUpdatedTargetErrors(target, 'biggerThan'));

    return null;
  };
}

export function biggerThanValidator(controlName: string) {
  return (control: AbstractControl) => {
    if (!controlName || !control.parent) return null;

    const selfValue = control.value;

    const target = control.parent.get(controlName);
    const targetValue = target.value;

    if (selfValue < targetValue) {
      return {
        biggerThan: true
      };
    }

    target.setErrors(getUpdatedTargetErrors(target, 'lessThan'));

    return null;
  };
}

export function zipCodeValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;

  // @TODO think of localisation in terms of zipCodes

  const zipCode = control.value;
  if (zipCode.length < 4 || zipCode.length > 6) {
    return { invalidZipCode: true };
  }
  return null;
}

export function atLeastOneElementValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;
  if (control.value.length === 0) {
    return { emptyArray: true };
  }
  return null;
}

export function atLeastOneControlHasValueInsideValidator(
  control: FormArray
): ValidationErrors | null {
  const anyControlHasValue = (control.value as []).find(
    (value: []) => !!value.length
  );
  return anyControlHasValue ? null : { allControlsAreEmpty: true };
}

export function urlValidator({
  requireHTTP = false
}: {
  requireHTTP?: boolean;
} = {}): ValidatorFn {
  return function validate(control: AbstractControl) {
    if (!control.value) return null;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    const url = control.value.toString() as string;

    if (!url.match(RegexTypes.URL)) {
      return { invalidUrl: true };
    }

    if (
      requireHTTP &&
      !(url.startsWith('https://') || url.startsWith('http://'))
    ) {
      return { invalidUrl: true };
    }

    return null;
  };
}

export function ibanValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;

  const iban = control.value;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  if (!iban.toString().match(/^[A-Z]{2}(?:[ ]?[0-9]){18,20}$/gi)) {
    return { invalidIban: true };
  }
  return null;
}

export function aesSchufaStateValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;

  const state = control.value;
  if (
    state &&
    (state === SchufaVerificationState.MAX_SCHUFA_TRIES_EXCEEDED ||
      state === SchufaVerificationState.SCHUFA_VERIFICATION_ALREADY_FINISHED)
  ) {
    return { aesSchufaFailed: true };
  }
  return null;
}

export function aesItpStateValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;

  const state = control.value;
  if (
    state &&
    (state === DigitalContractItpState.FAILED ||
      state === DigitalContractItpState.TECHNICAL_ERROR ||
      state === DigitalContractItpState.UNKNOWN)
  ) {
    return { aesItpFailed: true };
  }
  return null;
}

export function getRequiredValidator(required: boolean) {
  return required ? Validators.required : Validators.nullValidator;
}

export function getArrayListValidator(required: boolean) {
  return required ? minArraySizeValidator(1) : Validators.nullValidator;
}

export function aesWorkflowStateValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;

  const state = control.value;
  if (
    state &&
    (state === DigitalContractWorkflowState.AES_CODE_NOT_ALLOWED ||
      state === DigitalContractWorkflowState.AES_CODE_FAILED)
  ) {
    return { aesCodeNotAllowed: true };
  }
  return null;
}

export function minDateValidator(date: Date, format = 'YYYY-MM-DD') {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    if (!moment(control.value, format).isValid()) {
      return { invalidDate: true };
    }

    return moment(control.value, format).isBefore(moment(date).format(format))
      ? { tooEarlyDate: true }
      : null;
  };
}

export function isValidDateValidator(
  control: AbstractControl
): ValidationErrors | null {
  return !control.value || moment(control.value, 'YYYY-MM-DD').isValid()
    ? null
    : { invalidDate: true };
}

export function dateRangeValidator(
  controlName: string | AbstractControl,
  isStart = false,
  format = 'YYYY-MM-DD'
) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    if (!moment(control.value, format).isValid()) {
      return { invalidDate: true };
    }
    const selfDate = moment(control.value, format);
    let ref = controlName as AbstractControl;
    if (typeof controlName === 'string') {
      ref = control.parent.get(controlName);
    }
    const refDate = moment(ref.value, format);

    if (isStart && refDate && selfDate.isAfter(refDate)) {
      ref.setErrors({ ...ref.errors, dateRangeTo: true });
      return { dateRangeStart: true };
    }

    if (!isStart && refDate && selfDate.isBefore(refDate)) {
      ref.setErrors({ ...ref.errors, dateRangeStart: true });
      return { dateRangeTo: true };
    }

    if (isStart) {
      ref.setErrors(getUpdatedTargetErrors(ref, 'dateRangeTo'));
    } else {
      ref.setErrors(getUpdatedTargetErrors(ref, 'dateRangeStart'));
    }

    return null;
  };
}

export function restrictionRuleValidator(
  controlNames: string[] | AbstractControl[]
) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent || !control.value) return null;
    controlNames.forEach(name => {
      let ref = name as AbstractControl;
      if (typeof name === 'string') {
        ref = control.parent?.get(name);
      }
      ref?.patchValue(false, { onlySelf: true });
    });
  };
}

export function numberRangeValidator(
  ref: AbstractControl,
  isStart = false
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (isStart && control.value > ref.value) {
      ref.setErrors({ ...ref.errors, biggerThan: true });
      return { lessThan: true };
    }

    if (!isStart && control.value < ref.value) {
      ref.setErrors({ ...ref.errors, lessThan: true });
      return { biggerThan: true };
    }

    if (isStart) {
      ref.setErrors(getUpdatedTargetErrors(ref, 'biggerThan'));
    } else {
      ref.setErrors(getUpdatedTargetErrors(ref, 'lessThan'));
    }
    return null;
  };
}

export function maxDateValidator(date: Date, format = 'YYYY-MM-DD') {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    if (!moment(control.value, format).isValid()) {
      return { invalidDate: true };
    }

    return moment(control.value, format).isAfter(moment(date).format(format))
      ? { tooLateDate: true }
      : null;
  };
}

export function equalValidator(ref: AbstractControl) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;
    if (control.value === ref.value) {
      ref.setErrors({ ...ref.errors, canNotBeEqual: true });
      return { canNotBeEqual: true };
    }
    ref.setErrors(getUpdatedTargetErrors(ref, 'canNotBeEqual'));
    return null;
  };
}

export function integerValidator(
  control: AbstractControl
): ValidationErrors | null {
  return control.value && !Number.isInteger(control.value)
    ? { notInteger: true }
    : null;
}

export function forbiddenCharactersValidator(chars: any, isRegex?: boolean) {
  const regexp = isRegex
    ? chars
    : // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      new RegExp(chars.map(char => escapeRegexCharacters(char)).join('|'), 'g');

  return (control: AbstractControl) => {
    if (!control.value) {
      return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return regexp.exec(control.value as string)
      ? { forbiddenCharacters: true }
      : null;
  };
}

export function optionsValidator(
  control: FormArray | FormGroup
): ValidationErrors | null {
  let isValid = false;
  if (Array.isArray(control)) {
    isValid =
      !control.value ||
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      control.value.some((option: CustomQuestionOption) => option.desired);
  }
  if (control !== null && typeof control === 'object') {
    isValid = Object.keys(control.value).some(
      key => (control.value[key] as CustomQuestionOption).desired
    );
  }
  return isValid ? null : { atLeastOneDesiredAnswer: true };
}

export function isAtLeastOneValueTrueInFormArray(
  property: string
): ValidatorFn {
  return function validate(control: FormArray) {
    if (!control || !control.value) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return control.value.some(item => item[property])
      ? null
      : { noTrueValue: true };
  };
}

export function oneTrueBooleanInFormArrayValidator(): ValidatorFn {
  return function validate(control: AbstractControl) {
    if (!control || !control.value) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return control.value.includes(true) ? null : { noTrueValue: true };
  };
}

export function oneTrueBooleanInFormGroupValidator(): ValidatorFn {
  return function validate(formGroup: AbstractControl) {
    if (!formGroup || !formGroup.value) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    return Object.values(formGroup.value).some(value => value)
      ? null
      : { noTrueValue: true };
  };
}

export function isPropertyUniqueWithAdditionalData(
  property: string,
  matchControlName: string,
  userList: LandlordUser[]
): ValidatorFn {
  return function validate(control: FormArray) {
    if (!matchControlName || !control.parent) return null;

    const target = control.parent.get(matchControlName) as FormArray;
    const user = userList?.find(
      (item: LandlordUser) => item.id === target.value
    );
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    const listOfProperties = control.value.map(item => item[property]);
    if (user && user[property]) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      listOfProperties.push(user[property]);
    }
    return setDuplicatesAndReturnError(control, listOfProperties, property);
  };
}

export function isAtLeastOneValueTrueInFormGroup(): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    if (!formGroup) return null;
    return (
      Object.keys(formGroup.controls)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .map(key => formGroup.controls[key].value)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .reduce((a, b) => a || b, false)
        ? null
        : { noControlIsTrue: true }
    );
  };
}

export function isPropertyUnique(property: string): ValidatorFn {
  return function validate(control: FormArray) {
    if (!control || !control.value) {
      return null;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    const listOfProperties = control.value.map(item => item[property]);
    return setDuplicatesAndReturnError(control, listOfProperties, property);
  };
}

function setDuplicatesAndReturnError(
  control: FormArray,
  listOfProperties: string[],
  property: string
) {
  setErrorsListItemsDuplicates(control, listOfProperties, property);
  return hasDuplicates(control, property) ? { isDuplicateEmail: true } : null;
}

function hasDuplicates(list: FormArray, property: string) {
  const seen = new Set();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
  return list.value.some(
    currentObject => seen.size === seen.add(currentObject[property]).size
  );
}

function setErrorsListItemsDuplicates(
  formGroup: FormArray,
  listOfProperties: string[],
  property: string
) {
  formGroup.controls.forEach((control: FormGroup) => {
    const number = listOfProperties.filter(
      byProp => byProp === control.value[property]
    )?.length;
    if (number > 1) {
      control.controls[property].setErrors({ isDuplicateEmail: true });
      control.controls[property].markAsTouched();
    } else {
      control.controls[property].setErrors(
        getUpdatedTargetErrors(control.controls[property], 'isDuplicateEmail')
      );
    }
  });
}

/*
  add Validators.required conditionally on a formControl
 */
export function conditionallyRequiredValidation(predicate: () => boolean) {
  return (control: AbstractControl): ValidationErrors | null => {
    if (predicate()) {
      return Validators.required(control);
    }

    return null;
  };
}

export function cityNotRepeatedValidator(
  control: FormControl
): ValidationErrors | null {
  const childrenFormArray = control?.parent?.parent?.parent as FormArray;
  if (!control || !control.value || childrenFormArray?.length === 1)
    return null;
  const mappedCitiesNames = (childrenFormArray.value as SearchDistrict[]).map(
    group => group.city.name.toLowerCase()
  );
  const map = new Map<string, boolean>();
  const exists = mappedCitiesNames.some(name => {
    if (map.has(name)) {
      return true;
    } else {
      map.set(name, true);
    }
  });
  return exists ? { cityExists: true } : null;
}

export function patternValidator(
  regex: RegExp,
  error: ValidationErrors
): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } => {
    if (!control.value) {
      // if control is empty return no error
      return null;
    }

    // test the value of the control against the regexp supplied
    const valid = regex.test(control.value);

    // if true, return no error (no error), else return error passed in the second parameter
    return valid ? null : error;
  };
}

export function customPhoneValidator(
  control: FormControl
): ValidationErrors | null {
  if (control && (control.value === '' || control.value === null)) return null;
  const regex = new RegExp(RegexTypes.INTERNATIONAL_PHONE_CONTRACTS);
  return regex.test(control.value) ? null : { internationalPhone: true };
}

export function minArraySizeValidator(minRequired = 1): ValidatorFn {
  return function validate(formGroup: FormArray) {
    if (
      !formGroup ||
      !formGroup.value ||
      formGroup.value.length < minRequired
    ) {
      return {
        listIsEmpty: true
      };
    }
    return null;
  };
}

export function customEmailValidator(
  control: FormControl
): ValidationErrors | null {
  if (control && (control.value === '' || control.value === null)) return null;
  if (/\s/gi.test(control.value)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    control.patchValue(control.value.replace(/\s/g, ''));
  }
  return Validators.email(control);
}

export function customEmailPrefixValidator(
  control: AbstractControl
): ValidationErrors | null {
  if (!control.value) return null;

  const VALID_SUFFIX = '@valid-suffix.com';

  if (/\s/g.test(control.value)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    control.patchValue(control.value.replace(/\s/g, ''));
  }

  const controlCopyWithUpdatedValue = {
    ...control,
    value: `${String(control.value)}${VALID_SUFFIX}`
  } as FormControl;

  return Validators.email(controlCopyWithUpdatedValue);
}

export function customAtLeastOneCheckedValidator(
  matchControlName: string,
  value: string,
  minRequired = 1
): ValidatorFn {
  return function validate(formGroup: FormGroup) {
    if (!matchControlName || !formGroup.parent) return null;

    const target = formGroup.parent.get(matchControlName);

    const numberChecked = Object.keys(formGroup.controls).filter(
      key => formGroup.controls[key].value['checked']
    ).length;

    if (numberChecked < minRequired && target.value === value) {
      return {
        requireCheckboxToBeChecked: true
      };
    }

    return null;
  };
}

export function uniquePacketNameFormValidator(names: string[]): ValidatorFn {
  return function validate(control: AbstractControl) {
    if (names.includes(control?.value)) {
      control.markAsTouched({ onlySelf: true });
      return {
        duplicatePacketName: true
      };
    } else {
      return null;
    }
  };
}

export function onlyWhitespaceValidator(
  control: AbstractControl<string>
): ValidationErrors | null {
  if (!control.value) return null;

  return control.value.replaceAll(' ', '').length === 0
    ? { required: true }
    : null;
}

@Directive({
  selector: `
    [appMatchControl][formControlName],[appMatchControl][formControl],
    [appMatchControl][ngModel]
  `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => MatchControlValidator),
      multi: true
    }
  ]
})
export class MatchControlValidator implements Validator {
  private validator: (control: AbstractControl) => ValidationErrors;

  constructor(
    @Attribute('matchControl') matchControl: string,
    @Attribute('isTarget') isTarget: boolean
  ) {
    this.validator = matchControlValidatorFactory(
      matchControl,
      coerceBooleanProperty(isTarget)
    );
  }

  validate(control: AbstractControl): ValidationErrors {
    return this.validator(control);
  }
}

export function maxFileSize(maxSize: number) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const file = control.value;
    if (file) {
      const fileSize = file.size;
      if (fileSize > maxSize) {
        return {
          max: true
        };
      }
    }
    return null;
  };
}

export function stringAlreadyExistsInArrayValidator(
  invalidArray: string[]
): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (
      control.value &&
      !invalidArray.find(
        item => item.toLowerCase() === (control.value as string).toLowerCase()
      )
    ) {
      return null; // Valid
    }
    return { alreadyExistsInArray: { value: control.value } }; // Invalid
  };
}
