<div class="message-template__container">
  <p>{{ 'messenger.templates_description_l' | translate }}</p>
  <app-button
    class="d-inline-block mt10 mb10"
    (clickEvent)="createTemplate()"
    [disabled]="(processingAction$ | async)"
    [loading]="(processingAction$ | async)"
  >
    {{'messenger.create_template_a' | translate}}
  </app-button>
  <div class="messenger-template__create-container" *ngIf="showCreateTemplate">
    <app-message-template-form
      [activeTemplate]="activeTemplate"
      [conversationMessageTemplateSubstitutionTags]="conversationMessageTemplateSubstitutionTags"
      (saveTemplate)="saveTemplate($event)"
    >
      <app-button
        cancel-button
        class="secondary mr10"
        [type]="'light-bordered'"
        (clickEvent)="cancelCreate()"
      >
        {{ 'CANCEL_A' | translate }}
      </app-button>
    </app-message-template-form>
  </div>
  <div class="messenger-template__scroll-container">
    <div ngbAccordion [closeOthers]="true" (shown)="panelChanged($event)">
      <ng-container
        *ngIf="!(loadedTemplatesActionState$ | async)?.pending; else loading"
      >
        <ng-container *ngFor="let template of templates;let i = index">
          <div [ngbAccordionItem]="'message-template-panel-' + i">
            <div ngbAccordionHeader>
              <button ngbAccordionButton class="d-flex justify-content-between">
                <div class="title">{{template.title}}</div>
                <app-button
                  class="ms-2"
                  [iconLeft]="'remove'"
                  [type]="'context-menu'"
                  (clickEvent)="deleteTemplate($event, template?.id, i)"
                  [disabled]="(createActionState$ | async)?.pending || (updateActionState$ | async).pending || (deleteActionState$ | async).pending"
                >
                </app-button>
              </button>
            </div>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <app-message-template-form
                    [activeTemplate]="activeTemplate"
                    [conversationMessageTemplateSubstitutionTags]="conversationMessageTemplateSubstitutionTags"
                    (saveTemplate)="saveTemplate($event)"
                  ></app-message-template-form>
                </ng-template>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <app-list-controls
    class="d-block mt15"
    [pageSize]="loadedTemplatePagination?.size"
    [page]="loadedTemplatePagination?.page + 1"
    [totalCount]="loadedTemplatePagination?.totalElements"
    (pageChange)="onPageChange($event)"
    (sizeChange)="onSizeChange($event)"
  ></app-list-controls>
</div>
<ng-template #loading>
  <div class="loading-area">
    <app-loading-spinner></app-loading-spinner>
  </div>
</ng-template>
