import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

import { BaseNavigationItem as NavigationItem } from '@ui/shared/models';

@Component({
  selector: 'app-navigation-item-v2',
  templateUrl: './navigation-item-v2.component.html',
  styleUrls: ['./navigation-item-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationItemV2Component {
  @Input() item: NavigationItem;
  @Input() exact: true;
  @Output() trackEvent = new EventEmitter<string>();

  public onTrackEvent(event?: string) {
    this.trackEvent.emit(event);
  }
}
