<div class="status-info">
  <div *ngIf="!loading" class="status-info__wrapper">
    <div *ngIf="subject" class="status-info__subject">
      {{ subject | translate }}
    </div>
    <div class="status-info__header">
      <ng-content select="[status-info-header]"></ng-content>
    </div>
    <div [style.width]="imageSize">
      <app-image
        *ngIf="imageSrc"
        class="status-info__image"
        [defaultSrc]="imageSrc"
      ></app-image>
      <div *ngIf="svgXML" class="status-info__svg" [innerHTML]="svgXML"></div>
    </div>
    <div *ngIf="title" class="status-info__title title-xxl">
      {{ title | translate }}
    </div>
    <div class="status-info__text">
      <ng-content select="[status-info-description]"></ng-content>
    </div>
    <div class="status-info__content">
      <ng-content></ng-content>
    </div>
    <div class="status-info__button">
      <ng-content select="[status-info-button]"></ng-content>
    </div>
  </div>
  <div *ngIf="loading" class="status-info__spinner">
    <app-loading-spinner></app-loading-spinner>
  </div>
</div>
