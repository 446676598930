import {
  ComponentRef,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewContainerRef
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Tooltip, TooltipProgress, TooltipTour } from '@ui/shared/models';
import { TooltipComponent } from './tooltip.component';
import { TooltipPosition } from './tooltip.enum';

@UntilDestroy()
@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit {
  @Input() appTooltipAnchor: string;
  @Input() set appTooltipProgress(value: TooltipProgress) {
    this.tooltipProgress = value;

    if (this.componentRef) {
      this.componentRef.instance.tooltipProgress = value;
    }
  }
  @Input() appTooltipPosition: TooltipPosition = TooltipPosition.BELOW;

  @Output() appTooltipContinueEvent = new EventEmitter<Tooltip>();
  @Output() appTooltipCloseEvent = new EventEmitter<TooltipTour>();

  protected tooltipProgress: TooltipProgress;
  protected componentRef: ComponentRef<TooltipComponent>;
  private maxTooltipWidth = 300;

  constructor(public viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.viewContainerRef.clear();
    this.componentRef = this.viewContainerRef.createComponent(TooltipComponent);

    const host = this.viewContainerRef.element.nativeElement;
    if (this.appTooltipPosition === TooltipPosition.BELOW) {
      // eslint-disable-next-line
      host.appendChild(this.componentRef.location.nativeElement);
    }

    this.componentRef.instance.tooltipAnchor = this.appTooltipAnchor;
    this.componentRef.instance.anchorElementRef = this.viewContainerRef.element;
    this.componentRef.instance.tooltipProgress = this.tooltipProgress;
    this.componentRef.instance.tooltipPosition = this.appTooltipPosition;
    this.componentRef.instance.outHorizontalBounds =
      this.isTooltipOutOfHorizontalBounds();
    this.componentRef.instance.outVerticalBounds = false;

    this.componentRef.instance.continueEvent
      .pipe(untilDestroyed(this))
      .subscribe(tooltip => this.appTooltipContinueEvent.emit(tooltip));
    this.componentRef.instance.closeEvent
      .pipe(untilDestroyed(this))
      .subscribe(tooltipTour => this.appTooltipCloseEvent.emit(tooltipTour));
  }

  public isTooltipOutOfHorizontalBounds(): boolean {
    const boundingClientRect =
      // eslint-disable-next-line
      this.viewContainerRef.element?.nativeElement?.getBoundingClientRect();
    return (
      document.documentElement.scrollWidth -
        boundingClientRect.left -
        window.scrollX <
      this.maxTooltipWidth
    );
  }
}
