import {
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Directive
} from '@angular/core';

import { NgControl } from '@angular/forms';

import { Subject } from 'rxjs';
import { coerceBooleanProperty } from 'libs/utils';
import { AppFormFieldControl } from 'libs/components/legacy/form/form-field/form-field-control/form-field-control';
import { InputFieldsValueAccessor } from './input-fields-value-accessor';

@Directive()
export abstract class InputFieldsDirective<T>
  extends InputFieldsValueAccessor<T>
  implements AppFormFieldControl<T>, OnChanges, OnDestroy
{
  protected ngControl: NgControl;
  protected _required = false;
  protected _disabled = false;
  protected _readOnly = false;

  stateChanges: Subject<void> = new Subject();

  @Input()
  get required() {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  @Input()
  get disabled() {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  @Input()
  get readOnly() {
    return this._readOnly;
  }
  set readOnly(value: boolean) {
    this._readOnly = coerceBooleanProperty(value);
  }

  @Input() id: string;
  @Input() placeholder: string;

  // TODO: remove them, as they are probably too specific for the general InputFieldsDirective
  @Input() count: boolean;
  @Input() counter: number;

  /**
   * temporary workaround. Angular shows warnings when [disabled] is set on an element
   * with already has [formControl] or formControlName attribute. Because those two directives
   * are part of ReactiveForms, Angular suggests to disable controls via FormControl objects, not in the
   * template itself (which is normal for TemplateDrivenForms).
   * We could use FormControl passed from the parent element, but we cannot inject it right now,
   * because of NG_VALUE_ACCESSOR - related FormControl tries to inject this class, and when this class
   * tries to inject FormControl (NgControl) in the same time, we got cyclic dependency.
   *
   * In order to fix it properly, we need to find a way to inject related FormControl (NgControl) into our
   * custom form control, and then we can use it directly.
   */
  @Input()
  forceDisabled = false;

  get errors() {
    return this.ngControl.errors;
  }

  get touched() {
    return this.ngControl.touched;
  }

  setDisabledState(isDisabled: boolean) {
    this._disabled = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges) {
    Object.keys(changes).forEach(key => {
      if (changes[key].currentValue !== changes[key].previousValue)
        this.stateChanges.next();
    });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }
}
