import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-service-card',
  templateUrl: './service-card.component.html',
  styleUrls: ['./service-card.component.scss']
})
export class ServiceCardComponent {
  @Input() showNewMarker: boolean;
  @Input() headline: string;
  @Input() description: string;
  @Input() copyTextLabel: string;
  @Input() copyText: string;
  @Input() copyTextDescriptionTitle: string;
  @Input() copyTextDescription: string;

  @Input() price: string;
  @Input() buttonText: string;
  @Input() logoUrl: string;
  @Output() copyEvent = new EventEmitter<string>();
  @Output() buttonEvent = new EventEmitter<Event>();

  public baseClass = 'service-card';

  public copy() {
    this.copyEvent.emit(this.copyText);
  }

  public buttonPress(event: Event) {
    this.buttonEvent.emit(event);
  }
}
