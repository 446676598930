<div
  class="app-form-field-wrapper"
  [class.app-form-field-wrapper--disabled]="disabled"
  [class.app-form-field-wrapper--disabled-click-area]="disableClickArea"
  appClickArea
  [disabledClickArea]="disableClickArea"
>
  <ng-container *ngIf="!labelBelowInput">
    <ng-template [ngTemplateOutlet]="labelWrapper"></ng-template>
  </ng-container>

  <div class="app-input-wrapper">
    <ng-content selector="input app-flat-select app-date"></ng-content>
    <span
      class="completed-mark"
      *ngIf="markCompleted && controlTouched && !errors.length"
    >
      <img src="/assets/images/icons/icon-check.svg" />
    </span>
  </div>

  <div class="input-messages">
    <div class="app-form-field-information">
      <ng-content select="app-form-field-info"></ng-content>
    </div>

    <div
      class="app-form-field-errors"
      [class.app-form-field-errors--below]="labelBelowInput"
      *ngIf="controlTouched && !disableErrorMessages"
    >
      <ng-content select="app-error"></ng-content>
      <app-form-field-error *ngFor="let error of errors"
        >{{error | translate}}</app-form-field-error
      >
    </div>

    <div class="input-counter" *ngIf="fieldControl?.count">
      {{fieldControl?.counter}} {{'general.input_counter_l' | translate}}
    </div>
  </div>

  <ng-container
    *ngIf="labelBelowInput && !(controlTouched && !disableErrorMessages && errors.length > 0)"
  >
    <ng-template [ngTemplateOutlet]="labelWrapper"></ng-template>
  </ng-container>
</div>

<ng-template #labelWrapper>
  <div
    class="app-input-label-wrapper"
    [class.app-input-label-wrapper--below]="labelBelowInput"
    *ngIf="hasProjectedLabelChild() || label"
    [ngSwitch]="hasProjectedLabelChild()"
  >
    <ng-container *ngSwitchCase="true">
      <ng-content select="app-form-field-label"></ng-content>
    </ng-container>
    <app-form-field-label
      *ngSwitchCase="false"
      [smallText]="labelBelowInput"
      [idFor]="controlId"
      [showRequiredMarker]="showRequiredMarker"
      [disabled]="disabled"
    >
      {{label | translate}}
    </app-form-field-label>
    <ng-content select="app-hint"></ng-content>
  </div>
</ng-template>
