import { Component, Input, OnChanges } from '@angular/core';

import { SCREEN_LARGE_MIN } from 'libs/config/screen-sizes.config';
import { buildBEMClassNamesByGivenBaseClassAndFlags } from 'libs/utils/class-names';
import { isTouchScreen } from 'libs/utils';

import { PropertyType } from '@ui/shared/models';
import {
  CurrentStatusConfig,
  PipelineSteps
} from './status-pipeline-base.config';

@Component({
  selector: 'app-status-pipeline',
  templateUrl: './status-pipeline-base.component.html',
  styleUrls: ['./status-pipeline-base.component.scss']
})
export class StatusPipelineBaseComponent implements OnChanges {
  @Input() pageView = false;
  @Input() steps: PipelineSteps;
  @Input() currentStatus: CurrentStatusConfig;
  @Input() propertyType: PropertyType = PropertyType.FLAT;

  public baseClass = 'status-pipeline-base';

  public get narrowScreen(): boolean {
    return !this.pageView || window.innerWidth < SCREEN_LARGE_MIN;
  }

  public get tooltipOpenDelay(): number {
    return isTouchScreen() ? 0 : 1000;
  }

  public get tooltipCloseDelay(): number {
    return isTouchScreen() ? 0 : 300;
  }

  private get pipelineSteps(): PipelineSteps {
    const {
      stepId: currentStatusStepId,
      tooltip: currentStatusTooltip,
      label: currentStatusLabel,
      showAsFullscreen: currentStatusShowAsFullscreen
    } = this.currentStatus.pipeline;

    return this.steps.map((step, stepId) => {
      const isActive = stepId === currentStatusStepId;
      return {
        ...step,
        isActive,
        label: isActive && currentStatusLabel ? currentStatusLabel : step.label,
        hasPassed:
          currentStatusShowAsFullscreen || stepId < currentStatusStepId,
        tooltip: isActive ? currentStatusTooltip : step.label
      };
    });
  }

  public getClassName(): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(this.baseClass, {
      'page-view': this.pageView
    });
  }

  public getStepListClassName(hidden: boolean): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__step-list`,
      {
        hidden
      }
    );
  }

  public getStepItemClassName(
    first: boolean,
    last: boolean,
    active: boolean,
    passed: boolean
  ): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__step-item`,
      {
        first,
        last,
        active,
        passed
      }
    );
  }

  public getFullscreenStatusClassName(
    hide: boolean,
    isTenant: boolean
  ): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__fullscreen-status`,
      {
        'color-grey': hide,
        'color-green': isTenant
      }
    );
  }

  public getStepWrapperClassName(first: boolean, last: boolean): string {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
    return buildBEMClassNamesByGivenBaseClassAndFlags(
      `${this.baseClass}__step-wrapper`,
      {
        first,
        last
      }
    );
  }

  ngOnChanges(): void {
    this.steps = this.pipelineSteps;
  }
}
