<div
  class="tab-wrapper position-relative"
  [ngClass]="{'tab-navigation--fade-left': leftFade, 'tab-navigation--fade-right': rightFade}"
>
  <div class="start"></div>
  <div
    class="tab-navigation"
    #tabNavigation
    (scroll)="onHorizontalScroll()"
    (wheel)="onWheelScroll($event)"
  >
    <a
      *ngFor="let item of navigationItems; let i = index"
      class="tab-navigation__link"
      routerLink="{{ item.link }}"
      routerLinkActive="active"
      (click)="onClick(item.link, i)"
    >
      {{item.label | translate}}
    </a>
  </div>
  <div class="end"></div>
</div>
