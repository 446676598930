import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from 'libs/services';

@Pipe({ name: 'appMessageTime' })
export class MessageTimePipe implements PipeTransform {
  constructor(private dateTimeService: DateTimeService) {}

  transform(date: Date | string) {
    if (!date) return '';
    return this.dateTimeService.getDateCalenderInFormat(date);
  }
}
